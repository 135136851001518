<div class="information">
  <div class="information__card" *ngIf="!listOfFiles">
    <p>{{ 'archive.list' | translate }}</p>
  </div>
</div>
<div *ngIf="listOfFiles">
  <div class="listManager">
    <div *ngFor="let item of listOfFiles; let i = index">
      <div class="listManager__file">
        <input
          (click)="updateSelectedItem(i, $event.target.checked)"
          type="checkbox"
          name="selecionados"
          id="itensSelecionados_{{ item }}" />
        <label for="itensSelecionados_{{ item }}">{{ item }}</label>
      </div>
    </div>
  </div>
</div>
<ng-template #uploadFile>
  <app-upload-field-files
    [fieldKey]="fieldKey"
    [offCanvasUploadArquivos]="offCanvasUploadArquivos"
    (updateFilesList)="getFiles()"></app-upload-field-files>
</ng-template>
<app-offcanvas
  size="medium"
  [show]="false"
  [showSubject]="offCanvasUploadArquivos"
  title="file-manager.upload-files"
  [bodyTemplate]="uploadFile">
</app-offcanvas>
<ng-template #sendFiles>
  <app-send-field-files
    [fileName]="fileName"
    [fieldKey]="fieldKey"
    [offCanvasDevices]="offCanvasDevices"
    [filesToAssociate]="filesToAssociate"
    [baseUrl]="baseUrl"></app-send-field-files>
</ng-template>
<app-offcanvas
  size="medium"
  [show]="false"
  [showSubject]="offCanvasDevices"
  title="fields.send-files-to-device"
  [bodyTemplate]="sendFiles">
</app-offcanvas>
<div class="hidden__mobile">
  <div class="buttons__top">
    <app-button
      type="button"
      color="warning"
      icon="agres-upload"
      (btnClick)="openOffCanvasUpload()"
      title="{{ 'global.upload' | translate }}">
    </app-button>
    <app-button
      *ngIf="filesToAssociate.length > 0"
      id="btn-selecionados"
      type="button"
      color="primary"
      icon="agres-send"
      (btnClick)="openOffCanvasDevices()"
      title="{{ 'file-manager.send-to-device' | translate }}">
    </app-button>
    <app-button
      *ngIf="filesToAssociate.length > 0"
      type="button"
      color="success"
      icon="agres-download"
      (btnClick)="downloadFile()"
      title="{{ 'global.download' | translate }}">
    </app-button>
  </div>
</div>
<app-menu-pwa>
  <div class="pwa__list--info">
    <button type="button" (click)="openOffCanvasUpload()">
      <i class="agres-upload"></i>
      <p>{{ 'global.upload' | translate }}</p>
    </button>
  </div>
  <div class="pwa__list--info" *ngIf="filesToAssociate.length > 0">
    <button type="button" id="btn-selecionados" (click)="openOffCanvasDevices()">
      <i class="agres-send"></i>
      <p>{{ 'global.send' | translate }}</p>
    </button>
  </div>
  <div class="pwa__list--info" *ngIf="filesToAssociate.length > 0">
    <button type="button" id="btn-selecionados" (click)="downloadFile()">
      <i class="agres-download"></i>
      <p>{{ 'global.download' | translate }}</p>
    </button>
  </div>
</app-menu-pwa>
