<app-alert></app-alert>
<div class="panel">
  <div class="panel__group">
    <div class="panel__search">
      <form onsubmit="event.preventDefault();" role="search" class="search-group">
        <div class="field">
          <div class="field__wrap">
            <input
              id="search"
              type="search"
              (keyup)="updateSearch($event)"
              placeholder="{{ 'global.search' | translate }}"
              class="field__input" />
            <span class="field__view">
              <app-agres-icons iconsClass="agres-search"></app-agres-icons>
            </span>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
<div class="information">
  <div class="information__card" *ngIf="!devicesList">
    <p>{{ 'archive.without-devices' | translate }}</p>
  </div>
</div>
<section class="searchMobile">
  <div class="cardList">
    <ng-container *ngFor="let device of realtimeDevices">
      <ng-container *ngIf="filterDevice(device.deviceId)">
        <div class="cardFleet">
          <div class="cardFleet__content" (click)="deviceSelect(device)" id="{{ device.DeviceId }}">
            <div class="cardFleet__content--icon">
              <i class="agres-fleet-monitoring"></i>
            </div>
            <ng-container *ngIf="this.isDeviceOnline(device.timestamp); else devicesOff">
              <div class="cardFleet__content--info">
                <h1>{{ device.deviceId }}</h1>
                <p *ngIf="(device.workStatus | lowercase) === 'working'" class="cardFleet__content--info---success">
                  {{ 'monitoring.working' | translate }}
                </p>
                <p *ngIf="(device.workStatus | lowercase) === 'not_working'" class="cardFleet__content--info---warning">
                  {{ 'monitoring.moving' | translate }}
                </p>
              </div>
            </ng-container>
            <ng-template #devicesOff>
              <div class="cardFleet__content--info">
                <h1>{{ device.deviceId }}</h1>
                <p class="cardFleet__content--info---danger">
                  {{ 'monitoring.offline' | translate }}
                </p>
              </div>
            </ng-template>
          </div>
          <div class="cardFleet__action">
            <app-button
              type="button"
              color="card"
              size="full"
              icon="agres-action"
              [disabled]="
                (device.workStatus | lowercase) !== 'not_working' && (device.workStatus | lowercase) !== 'working'
              "
              (btnClick)="deviceSelect(device)"
              id="{{ device.DeviceId }}"
              title="{{ 'global.view' | translate }}">
            </app-button>
          </div>
        </div>
      </ng-container>
    </ng-container>
  </div>
</section>
<app-menu-pwa>
  <div class="pwa__list--info">
    <button type="button" (click)="dashboard()">
      <i class="agres-app"></i>
      <p>{{ 'sidebar.title-dashboard' | translate }}</p>
    </button>
  </div>
</app-menu-pwa>
