import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { AppConstants } from 'app/app.constants';
import { FileManagerService } from 'app/core/services/file-manager.service';
import { AgresSpinnerCanvasService } from 'app/shared/components/agres-spinner-canvas/agres-spinner-canvas.service';
import { AlertService } from 'app/shared/components/alert/alert.service';
import { UserFilesManager } from 'app/utils/user-files-manager';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-upload-field-files',
  templateUrl: './upload-field-files.component.html',
  styleUrls: ['./upload-field-files.component.scss'],
})
export class UploadFieldFilesComponent {
  @Input() offCanvasUploadArquivos = new Subject<boolean>();
  @Input() listOfFiles: Array<string>;
  @Input() fieldKey: string;
  @Output() updateFilesList = new EventEmitter();

  public fileSelected: boolean;
  public shapefileForm: FormGroup;

  filesReceived: Array<File> = [];
  userLogged: string;
  baseUrl: string;
  errorMessage = 'global.alert.msg-unexpected-error';
  private cloudantManager: UserFilesManager;

  constructor(
    public translateService: TranslateService,
    public fileManagerService: FileManagerService,
    private readonly formBuilder: FormBuilder,
    public alertService: AlertService,
    public loadingAgresSpinnerCanvas: AgresSpinnerCanvasService,
    public httpClient: HttpClient,
  ) {
    this.cloudantManager = new UserFilesManager(fileManagerService, httpClient);
  }

  ngOnInit() {
    this.userLogged = JSON.parse(
      localStorage.getItem(AppConstants.KEYS_LOCAL_STORAGE.ISO_USUARIO),
    ).id;
  }

  handleFileUpload(event) {
    this.fileSelected = true;
    for (const file of event.target.files) {
      this.filesReceived.push(file);
    }
  }

  hideModal() {
    this.fileSelected = false;
    this.offCanvasUploadArquivos.next(false);
  }

  createArrayToUpload() {
    if (this.filesReceived) {
      this.translateService.get('global.loading.map-wait').subscribe((res) => {
        this.loadingAgresSpinnerCanvas.toShow(res);
      });
      this.fileSelected = false;
      this.uploadFiles(this.filesReceived);
    }
  }

  async uploadFiles(filesToUpload: Array<File>) {
    if (filesToUpload) {
      this.cloudantManager
        .saveDataAndUploadUserFiles(
          this.userLogged,
          filesToUpload,
          'fields',
          this.fieldKey,
        )
        .subscribe(
          () => {
            this.feedBackToUser('global.alert.send-success', 'success');
            this.filesReceived = [];
            this.offCanvasUploadArquivos.next(false);
            this.loadingAgresSpinnerCanvas.toHide();
            this.updateFilesList.emit();
          },
          (error) => {
            console.error('Error:', error);
          },
        );
    }
  }

  private feedBackToUser(message: string, type: string) {
    this.translateService.get(message).subscribe((res: string) => {
      switch (type) {
        case 'success':
          this.alertService.success(res);
          break;
        case 'error':
          this.alertService.error(res);
          break;
        case 'info':
          this.alertService.info(res);
          break;
        default:
          this.alertService.info(res);
          break;
      }
    });
  }
}
