import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import {
  Feature,
  MultiPolygon,
  Polygon,
  area,
  bbox,
  bboxPolygon,
  featureCollection,
} from '@turf/turf';
import { TimeoutEnum } from 'app/core/enum/timeout.enum';
import { AnalyticsService } from 'app/core/services/analytics.service';
import { ConfirmacaoModalService } from 'app/core/services/confirmacaoModal.service';
import { LoadingSpinnerService } from 'app/core/services/loading-spinner.service';
import { UserTalhaoListService } from 'app/core/services/user-talhao-list.service';
import { AlertService } from 'app/shared/components/alert/alert.service';
import { Subject } from 'rxjs';

interface ReferenceCoordinate {
  lat: number;
  lng: number;
}
@Component({
  selector: 'app-lista-talhoes',
  templateUrl: './lista-talhoes.component.html',
  styleUrls: ['./lista-talhoes.component.scss'],
})
export class ListaTalhoesComponent implements OnInit {
  mostrarModalConfirmacao = false;
  mensagemModalConfirmacao = '';
  tipoModalConfirmacao = '';
  tituloModalConfirmacao = '';
  modalTranslateButtonConfirmText = '';
  modalTranslateButtonCancelText = '';
  idArquivoSelecionadoExcluir: any;
  STATUS_CONFIRMED_DELETE = 'confirmado';
  fetchFieldData: Subject<boolean> = new Subject();
  updateFieldIdToBeDeletedSubject: Subject<string> = new Subject();

  valueInputSearch: string;
  hideAll = true;
  totalFields = 0;
  valueOpenTalhaoById = false;

  talhaoId: string | null;

  statusTalhao: 'create' | 'view' | 'edit' | 'delete';
  showRecommendationMapUpload = false;
  showModalSubject: Subject<boolean> = new Subject();
  referenceCoordinateSubject: Subject<ReferenceCoordinate> = new Subject();
  constructor(
    public translate: TranslateService,
    public router: Router,
    public translateService: TranslateService,
    public confirmacaoModalService: ConfirmacaoModalService,
    public talhaoService: UserTalhaoListService,
    public alertService: AlertService,
    public loadingSpinner: LoadingSpinnerService,
    public readonly analyticsService: AnalyticsService,
  ) {
    this.analyticsService.trackEvent('open_list_field_page', 'List Field', '');
  }

  ngOnInit(): void {
    this.confirmacaoModalService.changeData(null);
    this.analyticsService.sendPageView('/my-fields/');

    this.confirmacaoModalService.currentData.subscribe((data) => {
      if (data != null) {
        if (this.statusTalhao === 'delete' && this.talhaoId) {
          if (data.message === this.STATUS_CONFIRMED_DELETE) {
            this.updateFieldIdToBeDeletedSubject.next(this.talhaoId);
            this.deleteField(this.talhaoId);
          }
          this.statusTalhao = undefined;
          this.talhaoId = null;
        }
      }
    });
    this.analyticsService.trackEvent('accessed_the_field_page', 'List Field', '');
  }

  importSHP(): void {
    this.showModalSubject.next(true);
    this.analyticsService.trackEvent(
      'clicked_on_import_shp_button',
      'Import SHP',
      '',
    );
  }

  recebeOEventEmiterParaAbrirTalhoesPorId() {
    this.valueOpenTalhaoById = true;

    return (talhaoId: string | null, talhaoIdToBeDeleted: string | null) => {
      if (talhaoId != talhaoIdToBeDeleted) {
        this.talhaoId = talhaoId;
        this.statusTalhao = 'view';
        this.analyticsService.trackEvent(
          'opened_field_by_id',
          'List Field',
          '',
        );
      }
    };
  }

  recebeOEventEmiterParaEditarTalhoesPorId() {
    this.valueOpenTalhaoById = true;

    return (
      talhaoId: string | null,
      talhaoIdToBeDeleted: string | null,
    ) => {
      if (talhaoId != talhaoIdToBeDeleted) {
        this.talhaoId = talhaoId;
        this.statusTalhao = 'edit';
      }
    };
  }

  changeTalhaoIdOnDelete() {
    return (
      talhaoId: string | null,
      talhaoIdToBeDeleted: string | null,
    ) => {
      if (talhaoId != talhaoIdToBeDeleted) {
        this.talhaoId = talhaoId;
        this.statusTalhao = 'delete';
        this.translateConfirmationMessage();

        this.analyticsService.trackEvent(
          'clicked_on_delete_field',
          'List Field',
          '',
        );
      }
    };
  }

  onEmitVoltarParaListaDeTalhoes() {
    this.valueOpenTalhaoById = false;
    return () => {
      this.talhaoId = null;
    };
  }

  atualizarBusca(event): void {
    this.valueInputSearch = event.target.value;

    this.analyticsService.trackEvent(
      'clicked_on_search_field',
      'List Field',
      this.valueInputSearch,
    );
  }

  createTalhao(featureCollection?: string): void {
    this.router.navigate(['/my-fields/criar-talhao'], {
      queryParams: {
        featureCollection,
      },
    });

    this.analyticsService.trackEvent(
      'clicked_on_create_field',
      'List Field',
      '',
    );
  }

  translateConfirmationMessage() {
    this.translateService
      .get('global.alert.delete-title')
      .subscribe((res: string) => {
        this.mostrarModalConfirmacao = true;
        this.mensagemModalConfirmacao = '';
        this.tituloModalConfirmacao = res;
        this.tipoModalConfirmacao = 'warning';
        this.modalTranslateButtonConfirmText = 'global.delete';
        this.modalTranslateButtonCancelText = 'global.cancel';
      });
  }

  dashboard() {
    this.router.navigate(['/']);
  }

  deleteField(fieldId) {
    this.talhaoService.deleteTalhao(fieldId).subscribe(
      (result) => {
        this.translateService
          .get('global.alert.success-delete')
          .subscribe((res: string) => {
            this.alertService.success(res, TimeoutEnum.MediumLong);
          });
        this.totalFields--;
        if (this.totalFields === 0) {
          this.showAlertMessage();
        } else {
          this.fetchFieldData.next(true);
        }
        this.analyticsService.trackEvent('deleted_field', 'Delete Field', '');
      },
      (error) => {
        this.analyticsService.trackEvent(
          'error_when_deleting_field',
          'Delete Field',
          '',
        );
        this.updateFieldIdToBeDeletedSubject.next('');
        let errorMessage = '';
        switch (error.status) {
          case 409:
            errorMessage = 'fields.fields-not-found';
            break;

          default:
            errorMessage = 'global.validate.message-error-delete';
        }
        if (error.error !== undefined) {
          this.translateService
            .get(errorMessage)
            .subscribe((msgErro: string) => {
              this.alertService.error(msgErro, TimeoutEnum.VeryLong);
            });
        } else {
          this.translateService
            .get('global.validate.message-error-delete')
            .subscribe((msgErro: string) => {
              this.alertService.error(msgErro, TimeoutEnum.VeryLong);
            });
        }
      },
    );
  }

  actionHome() {
    this.router.navigate(['/']);
  }

  showAlertMessage() {
    this.translateService
      .get('global.validate.all-deleted-fields')
      .subscribe((res: string) => {
        this.alertService.warning(res, TimeoutEnum.VeryLong);
      });
  }
  onReceiveRecommendationMap(shapefile: {
    propertiesToUse?: string;
    shapefileArray: Feature<Polygon | MultiPolygon>[];
  }) {
    let features = shapefile.shapefileArray;
    features = filterInsignificantPolygons(features);
    const bboxFeature = JSON.stringify(
      bboxPolygon(bbox(featureCollection(features))),
    );
    this.createTalhao(bboxFeature);
    this.analyticsService.trackEvent(
      'uploaded_shapefile_to_create_field',
      'Import ShapeFile',
      '',
    );
  }

}
function filterInsignificantPolygons(
  features: Feature<
    Polygon | MultiPolygon,
    { [name: string]: any } | undefined
  >[],
) {
  let biggestPolygonArea = getAreaFromBiggestPolygon(features);

  features = features.filter((shapefileFeature) => {
    return area(shapefileFeature) >= biggestPolygonArea * 0.33;
  });
  return features;
}

function getAreaFromBiggestPolygon(
  features: Feature<
    MultiPolygon | Polygon,
    { [name: string]: any } | undefined
  >[],
) {
  let biggestPolygon = 0;

  features.forEach((shapefileFeature) => {
    const featureArea = area(shapefileFeature);
    if (featureArea > biggestPolygon) {
      biggestPolygon = featureArea;
    }
  });
  return biggestPolygon;
}
