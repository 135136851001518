import { throwError as observableThrowError } from 'rxjs';
import { HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';

import { AppConstants } from '../../app.constants';

export abstract class ServiceBase {
  public readonly UrlServiceV1 = environment.urlApi;
  public readonly UrlServiceOauthV1 = environment.urlApi;
  private readonly environmentName = environment.environmentName;
  protected readonly subscriptionKey = environment.subscriptionKey;

  public obterUsuarioLogado(): any {
    return JSON.parse(
      localStorage.getItem(AppConstants.KEYS_LOCAL_STORAGE.ISO_USUARIO),
    );
  }

  public obterTokenUsuario(): string {
    return localStorage.getItem(
      AppConstants.KEYS_LOCAL_STORAGE.ISO_ACCESS_TOKEN,
    );
  }

  public obterHeaderJson() {
    return {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };
  }

  public obterHeaderUrlencoded() {
    return {
      headers: new HttpHeaders({
        'Content-Type': 'application/x-www-form-urlencoded',
      }),
    };
  }

  public obterAuthHeader() {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${this.obterTokenUsuario()}`,
        'Ocp-Apim-Subscription-Key': this.subscriptionKey,
      }),
    };
    if (this.environmentName === 'LOCAL') {
      headers.headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'app-user-data': localStorage.getItem(
          AppConstants.KEYS_LOCAL_STORAGE.ISO_USUARIO,
        ),
      });
    }
    return headers;
  }

  public extractData<T>(response: T): T | null {
    return response ?? null;
  }

  public serviceError(error: Response) {
    return observableThrowError(error);
  }
}
