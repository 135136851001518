<!-- <div class="row">
  <div class="col-xs-6 col-sm-6 col-md-6 col-lg-4 col-xl-4 col-xxl-3" *ngFor="let item of dataNvgs; let i = index">

  </div>

</div>

 <div *ngFor="let file of listFile">

  <div *ngIf="isFreeTier" class="pull-left"><span
    class="text-warning">-<b>{{'archive.file-available'| translate}}</b>{{
    item.labelTimeStamp }}</span></div>
<div *ngIf="item.status == CONST_STATUS.ERROR" class="text-danger pull-left">{{
item.msgError | translate }}</div>

  <button (click)="confirmarCompatibilidadeNvg(item._id)" class="button_warning" *ngIf="item.status ==CONST_STATUS.ERROR &&errorsVisibleNotifyNvg.indexOf(item.msgError) === -1 &&
  nvgArrayNotifySupported !=null && nvgArrayNotifySupported.indexOf(item._id) === -1"
      [attr.disabled]="schedulingNotify ? 'disabled' : null">
      {{'archive.notify-when-compatible' | translate}} </button>

      <button class="button__success"
      *ngIf="nvgArrayNotifySupported != null && nvgArrayNotifySupported.indexOf(item._id) !== -1"
      [attr.disabled]="'disabled'"> {{'archive.notify-scheduling-success' | translate}}
  </button>
</div> -->

<app-confirmar-modal
  *ngIf="mostrarModalConfirmacao"
  [(modal)]="mostrarModalConfirmacao"
  [mensagem]="mensagemModalConfirmacao"
  [tipo]="tipoModalConfirmacao"
  [titulo]="tituloModalConfirmacao"
  [conteudo]="idFileSelectedToDelete"
  [confirmButtonText]="modalTranslateButtonConfirmText"
  [cancelButtonText]="modalTranslateButtonCancelText"
  (modalChange)="mostrarModalConfirmacao = $event">
</app-confirmar-modal>
