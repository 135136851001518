export type GradientColors = number[][];

export class Gradient {
  constructor(private colors: GradientColors) {
    if (colors == null) {
      throw new Error("colors can't be null to instantiate linear-gradient");
    }

    if (colors.length == null || colors.length < 2) {
      throw new Error(
        'needs at least two colors to instantiate linear-gradient',
      );
    }

    for (let i = 0; i < colors.length; i++) {
      let color = colors[i];

      if (color.length == null || color.length !== 3) {
        throw new Error(
          `Error at element ${i}. Expected an RGB array such as [100, 100, 20], instead got  [${color}]`,
        );
      }

      for (let j = 0; j < 3; j++) {
        let c = color[j];

        if (c < 0 || c > 255) {
          throw new Error(
            `Error at element ${i}. Expected an RGB array such as [100, 100, 20], instead got [${color}]`,
          );
        }
      }
    }

    this.colors = colors;
  }

  calcArray(value: number): number[] {
    if (value < 0 || value > 1) {
      throw new Error('The input value must be between 0 and 1');
    }
    // offset represents where the value lies in the gradient
    let offset = value * (this.colors.length - 1);
    let whole = Math.floor(offset);
    let decimal = offset - whole;
    let lowerColor = this.colors[whole];

    if (decimal === 0.0) {
      return lowerColor;
    }

    let higherColor = this.colors[whole + 1];
    let r = lowerColor[0] * (1 - decimal) + higherColor[0] * decimal;
    let g = lowerColor[1] * (1 - decimal) + higherColor[1] * decimal;
    let b = lowerColor[2] * (1 - decimal) + higherColor[2] * decimal;

    return [Math.round(r), Math.round(g), Math.round(b)];
  }

  calcHex(value: number) {
    return this.tohex(this.calcArray(value));
  }

  private pad2(str: string) {
    if (str.length == 1) {
      return `0${str}`;
    } else {
      return str;
    }
  }

  private tohex(rgb: number[]) {
    return `#${this.pad2(rgb[0].toString(16))}${this.pad2(
      rgb[1].toString(16),
    )}${this.pad2(rgb[2].toString(16))}`;
  }
}
