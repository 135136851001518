export namespace NumberMapping {
  export function mapValues(
    value: number,
    fromMin: number,
    fromMax: number,
    toMin: number,
    toMax: number,
  ): number {
    value = Math.max(fromMin, Math.min(value, fromMax));

    const normalizedValue = (value - fromMin) / (fromMax - fromMin);
    const mappedValue = normalizedValue * (toMax - toMin) + toMin;

    return mappedValue;
  }
}
