<app-alert></app-alert>
<div *ngIf="talhaoId === null || talhaoId === undefined || statusTalhao === 'delete'">
  <div class="panel">
    <div class="panel__group">
      <div class="panel__search">
        <form onsubmit="event.preventDefault();" role="search">
          <div class="field">
            <div class="field__wrap">
              <input
                id="search"
                type="search"
                aria-label="search"
                placeholder="{{ 'global.search' | translate }}"
                (keyup)="atualizarBusca($event)"
                class="field__input" />
              <span class="field__view">
                <app-agres-icons iconsClass="agres-search"></app-agres-icons>
              </span>
            </div>
          </div>
        </form>
      </div>
    </div>
    <div class="panel__group">
      <div class="panel__group--buttons hidden__mobile">
        <app-button
          type="button"
          color="warning"
          icon="agres-upload"
          (btnClick)="importSHP()"
          title="{{ 'global.import-shp' | translate }}">
        </app-button>
        <app-button
          type="button"
          color="primary"
          icon="agres-field"
          (btnClick)="createTalhao()"
          title="{{ 'global.create' | translate }}">
        </app-button>
      </div>
    </div>
  </div>
  <ng-template #resultBody>
    <app-form-recommendation-map-upload
      [showModalSubject]="showModalSubject"
      [referenceCoordinateSubject]="referenceCoordinateSubject"
      (recommendationMapSent)="onReceiveRecommendationMap($event)"
      (modalUpdatedState)="(undefined)"
      [uploadDBF]="false">
    </app-form-recommendation-map-upload>
  </ng-template>
  <app-offcanvas
    size="medium"
    title="tasks.map-recommendation"
    [show]="showRecommendationMapUpload"
    [showSubject]="showModalSubject"
    title="{{ 'fields.create' | translate }}"
    [bodyTemplate]="resultBody">
  </app-offcanvas>
</div>
<app-menu-pwa>
  <div class="pwa__list--info">
    <button type="button" (click)="dashboard()">
      <i class="agres-app"></i>
      <p>{{ 'sidebar.title-dashboard' | translate }}</p>
    </button>
  </div>
  <div class="pwa__list--info">
    <button type="button" (click)="importSHP()">
      <i class="agres-upload"></i>
      <p>{{ 'global.import-shp' | translate }}</p>
    </button>
  </div>
  <div class="pwa__list--info">
    <button type="button" (click)="createTalhao()">
      <i class="agres-field"></i>
      <p>{{ 'fields.create' | translate }}</p>
    </button>
  </div>
</app-menu-pwa>
<div *ngIf="talhaoId === null || talhaoId === undefined || statusTalhao === 'delete'">
  <app-card-talhoes
    [emitEventOpenTalhaoById]="recebeOEventEmiterParaAbrirTalhoesPorId()"
    [emitEventEditTalhaoById]="recebeOEventEmiterParaEditarTalhoesPorId()"
    [changeTalhaoIdOnDelete]="changeTalhaoIdOnDelete()"
    [valueInputSearch]="valueInputSearch"
    [fetchFieldData]="fetchFieldData"
    [updateFieldIdToBeDeleted]="updateFieldIdToBeDeletedSubject">
  </app-card-talhoes>
</div>
<div>
  <app-visualizar-talhoes
    *ngIf="statusTalhao === 'view' && talhaoId !== null"
    [onEmitVoltarParaListaDeTalhoes]="onEmitVoltarParaListaDeTalhoes()"
    [emitEventEditTalhaoById]="recebeOEventEmiterParaEditarTalhoesPorId()"
    [talhaoId]="talhaoId">
  </app-visualizar-talhoes>
  <app-editar-talhoes
    *ngIf="statusTalhao === 'edit' && talhaoId !== null"
    [onEmitVoltarParaListaDeTalhoes]="onEmitVoltarParaListaDeTalhoes()"
    [talhaoId]="talhaoId"
    [rev]="rev">
  </app-editar-talhoes>
</div>
<app-confirmar-modal
  *ngIf="mostrarModalConfirmacao"
  [(modal)]="mostrarModalConfirmacao"
  [mensagem]="mensagemModalConfirmacao"
  [tipo]="tipoModalConfirmacao"
  [titulo]="tituloModalConfirmacao"
  [conteudo]="idArquivoSelecionadoExcluir"
  [confirmButtonText]="modalTranslateButtonConfirmText"
  [cancelButtonText]="modalTranslateButtonCancelText"
  (modalChange)="mostrarModalConfirmacao = $event">
</app-confirmar-modal>
<app-tutoriais-talhoes></app-tutoriais-talhoes>
