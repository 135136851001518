<app-alert></app-alert>
<app-tabs *ngIf="true">
  <app-tab #tab1 tabTitle="{{ 'profile.my-data' | translate }}">
    <ng-container *ngIf="tab1.selected">
      <form class="form-horizontal" (ngSubmit)="onSubmit()" [formGroup]="myForm">
        <div class="profile">
          <div class="profile__label">{{ 'profile.photo' | translate }}</div>
          <div class="profile__control">
            <div class="profile__control--avatar">
              <img [src]="profileImage" (load)="handleImageLoad()" />
              <app-agres-icons iconsClass="agres-image"></app-agres-icons>
            </div>
            <div class="profile__control--file">
              <input
                (change)="uploadImageToCOS($event)"
                type="file"
                id="imageUpload"
                accept=".png, .jpg, .jpeg, .bmp, .hepb"
                alt="{{ 'profile.photo' | translate }}" />
              <button for="imageUpload">{{ 'profile.upload' | translate }}</button>
            </div>
          </div>
        </div>
        <div class="field__row">
          <div class="field">
            <div class="field__label">{{ 'profile.name' | translate }}</div>
            <div class="field__wrap">
              <input class="field__input" type="text" formControlName="name" id="name" required />
            </div>
          </div>
          <div class="field">
            <div class="field__label">{{ 'global.mail' | translate }}</div>
            <div class="field__wrap">
              <input class="field__input" type="email" id="email" formControlName="id" />
            </div>
          </div>
          <!-- TELEFONE INPUT -->
          <div class="field">
            <div class="field__label">{{ 'global.phone' | translate }}</div>
            <div class="field__wrap">
              <input
                class="field__input"
                type="text"
                formControlName="phoneNumber"
                (input)="formatPhoneNumber()"
                id="phoneNumber" />
            </div>
          </div>
        </div>
        <div class="field__row">
          <div class="field">
            <div class="field__label">{{ 'global.identification' | translate }}</div>
            <div class="field__wrap">
              <input
                class="field__input"
                type="text"
                formControlName="nationalIdNumber"
                id="nationalIdNumber"
                [mask]="outsideBrazil ? '' : getIdentificationMask()"
                maxlength="18"
                [class.error]="!isCpfCnpjValid(myForm?.get('nationalIdNumber').value)" />
              <span
                class="field__input--alert"
                *ngIf="!isCpfCnpjValid(myForm?.get('nationalIdNumber').value) && !outsideBrazil">
                {{ 'global.validate.error-document-data' | translate }}
              </span>
            </div>
          </div>
          <div class="field">
            <div class="field__label">{{ 'global.company-name' | translate }}</div>
            <div class="field__wrap">
              <input
                class="field__input"
                type="text"
                name="company"
                id="company"
                formControlName="company"
                [class.error]="!myForm.controls.company.valid" />
            </div>
          </div>
        </div>
        <div class="field__space"></div>
        <ng-container formGroupName="addressFormGroup">
          <div class="field__row">
            <div class="field">
              <div class="field__label">{{ 'global.postalCode' | translate }}</div>
              <div class="field__wrap">
                <input
                  class="field__input"
                  type="text"
                  name="postalCode"
                  id="postalCode"
                  formControlName="postalCode"
                  (blur)="outsideBrazil ? '' : checkCep($event)" />
              </div>
            </div>
            <div class="field">
              <div class="field__label">{{ 'global.city' | translate }}</div>
              <div class="field__wrap">
                <input class="field__input" maxlength="50" type="text" name="city" id="city" formControlName="city" />
              </div>
            </div>
            <div class="field">
              <div class="field__label">{{ 'global.state' | translate }}</div>
              <div class="field__wrap">
                <input
                  class="field__input"
                  type="text"
                  name="uf"
                  id="uf"
                  maxlength="25"
                  formControlName="stateOrProvince" />
              </div>
            </div>
          </div>
          <div class="field__row">
            <div class="field">
              <div class="field__label">{{ 'global.country' | translate }}</div>
              <div class="field__wrap">
                <div class="field__select">
                  <select formControlName="country">
                    <option *ngFor="let country of countries" [value]="country">
                      {{ 'global.country_codes.' + country | translate }}
                    </option>
                  </select>
                </div>
                <span
                  class="field__input--alert"
                  *ngIf="displayMessage.country && !outsideBrazil"
                  [innerHTML]="displayMessage.country">
                </span>
              </div>
            </div>
          </div>
        </ng-container>

        <div class="hidden__mobile">
          <div class="field__btns">
            <app-button
              type="submit"
              color="primary"
              icon="agres-check"
              styleClass="field__btns--margin"
              [disabled]="disableButton || !myForm.valid"
              title="{{ 'global.save' | translate }}">
            </app-button>
            <app-button
              type="button"
              color="danger"
              icon="agres-close"
              styleClass="field__btns--margin"
              (btnClick)="clearFormUserData()"
              title="{{ 'global.clear' | translate }}">
            </app-button>
          </div>
          <small
            ><a class="link-delete" [routerLink]="['/manage/delete-account']">{{
              'global.delete_account' | translate
            }}</a></small
          >
        </div>
      </form>

      <app-menu-pwa>
        <div class="pwa__list--info">
          <button type="button" (click)="onSubmit()" [disabled]="disableButton || !myForm.valid">
            <i class="agres-check"></i>
            <p>{{ 'global.save' | translate }}</p>
          </button>
        </div>
        <div class="pwa__list--info">
          <button type="button" (click)="clearFormUserData()">
            <i class="agres-close"></i>
            <p>{{ 'global.clear' | translate }}</p>
          </button>
        </div>
        <div class="pwa__list--info">
          <button type="button" (click)="linkToDeleteAccountPage()">
            <i class="agres-delete"></i>
            <p>{{ 'global.delete_account' | translate }}</p>
          </button>
        </div>
      </app-menu-pwa>
    </ng-container>
  </app-tab>
  <app-tab #tab2 tabTitle="{{ 'global.password' | translate }}">
    <div *ngIf="tab2.selected">
      <form (ngSubmit)="onSubmitNewPassword()" [formGroup]="myFormPassword">
        <div class="field__row">
          <div class="field">
            <div class="field__label">{{ 'profile.title-new-password' | translate }}</div>
            <div class="field__wrap">
              <input
                class="field__input"
                type="password"
                formControlName="password"
                value=""
                [type]="isPasswordVisible ? 'text' : 'password'"
                minlength="4"
                maxlength="16"
                autocomplete="off" />
              <span class="field__view">
                <app-agres-icons
                  styleClass="field__view"
                  [ngClass]="isPasswordVisible ? 'agres-eye' : 'agres-eye-off'"
                  (click)="togglePassword()"></app-agres-icons>
              </span>
            </div>
          </div>
          <div class="field">
            <div class="field__label">{{ 'profile.title-new-password-confirm' | translate }}</div>
            <div class="field__wrap">
              <input
                type="password"
                formControlName="confirmPassword"
                class="field__input"
                value=""
                [type]="isConfirmPasswordVisible ? 'text' : 'password'"
                minlength="4"
                maxlength="16"
                autocomplete="off" />
              <span class="field__view">
                <app-agres-icons
                  styleClass="field__view"
                  [ngClass]="isConfirmPasswordVisible ? 'agres-eye' : 'agres-eye-off'"
                  (click)="confirmTogglePassword()"></app-agres-icons>
              </span>
            </div>
          </div>
        </div>
        <div class="hidden__mobile">
          <div class="field__btns">
            <app-button type="submit" color="primary" icon="agres-check" title="{{ 'global.update' | translate }}">
            </app-button>
            <app-button
              type="button"
              color="danger"
              icon="agres-close"
              (click)="clearFormPassword()"
              title="{{ 'global.clear' | translate }}">
            </app-button>
          </div>
        </div>
      </form>
      <app-menu-pwa>
        <div class="pwa__list--info">
          <button type="button" (click)="onSubmitNewPassword()">
            <i class="agres-check"></i>
            <p>{{ 'global.update' | translate }}</p>
          </button>
        </div>
        <div class="pwa__list--info">
          <button type="button" (click)="clearFormPassword()">
            <i class="agres-close"></i>
            <p>{{ 'global.clear' | translate }}</p>
          </button>
        </div>

      </app-menu-pwa>
    </div>
  </app-tab>
</app-tabs>
