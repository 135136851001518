import { ConfirmacaoModalModule } from './../confirmacao-modal/confirmacao-modal..module';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';

import { StarterViewComponent } from './starterview.component';

import { PeityModule } from '../../modules/charts/peity';
import { SparklineModule } from '../../modules/charts/sparkline';
import { ToastrModule } from 'ngx-toastr';
import { CommonModule } from '@angular/common';
import { HttpClientJsonpModule, HttpClientModule } from '@angular/common/http';
import { GoogleMapsModule } from '@angular/google-maps';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { AppSharedModule } from 'app/app-shared.module';
import { GerenciamentoDeTalhoesModule } from './my-fields/gerenciamento-de-talhoes/gerenciamento-de-talhoes.module';
import { TranslateModule } from '@ngx-translate/core';
import { FieldFilesComponent } from 'app/modules/appviews/my-fields/visualizar-talhoes/field-files/field-files.component';
import { UploadFieldFilesComponent } from 'app/modules/appviews/my-fields/visualizar-talhoes/upload-field-files/upload-field-files.component';
import { SendFieldFilesComponent } from './my-fields/visualizar-talhoes/send-field-files/send-field-files.component';
import { FormsModule } from '@angular/forms';

@NgModule({
  declarations: [
    StarterViewComponent,
    FieldFilesComponent,
    UploadFieldFilesComponent,
    SendFieldFilesComponent,
  ],
  imports: [
    BrowserModule,
    CommonModule,
    RouterModule,
    ToastrModule.forRoot(),
    PeityModule,
    SparklineModule,
    HttpClientModule,
    HttpClientJsonpModule,
    GoogleMapsModule,
    MatAutocompleteModule,
    AppSharedModule,
    GerenciamentoDeTalhoesModule,
    ConfirmacaoModalModule,
    TranslateModule,
    FormsModule,
  ],

  exports: [
    StarterViewComponent,
    FieldFilesComponent,
    UploadFieldFilesComponent,
    SendFieldFilesComponent,
  ],
})
export class AppviewsModule {}
