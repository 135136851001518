import { BehaviorSubject } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { ServiceBase } from './service.base';
import { string } from '../../../../vendor/shp-write/shpwrite';

@Injectable()
export class NvgFilesService extends ServiceBase {
  constructor(public http: HttpClient) {
        super();
    }

    public dataSource = new BehaviorSubject<any>([]);
    currenteData = this.dataSource.asObservable();

    changeData(data: any[]) {
        this.dataSource.next(data);
    }

  uploadFile(fileName: string) {
        return this.http
            .get(
                this.UrlServiceV1 + 'task-files',{ params:{fileName}, ...this.obterAuthHeader(), responseType: 'text'},
            )
      .pipe(map(this.extractData<string>), catchError(this.serviceError));
    }
}
