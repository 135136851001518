import { BaseLocation } from '../../../../shared/models/baseLocation';
import { Location } from '../../../../shared/models/location';
import { Machine } from '../../../../shared/models/machine';
import { Navigation } from '../../../../shared/models/navigation';
import { Operation } from '../../../../shared/models/operation';
import { Telemetry } from '../../../../shared/models/telemetry';

export class DeviceInfoModel {
  constructor(
    public id: string = 'Default Id',
    public gwAddr: string = 'Default Id',
    public status: RealtimeDeviceStatus = RealtimeDeviceStatus.OFFLINE,
    public message: DeviceMessageModel = new DeviceMessageModel(),
    public showTrail?: boolean,
    public hidden?: boolean,
    public secArray?: any,
  ) {}
}

export interface DeviceDTO {
  DeviceId: string;
  deviceId: string;
  location: { elevation: number; latitude: number; longitude: number };
  timestamp: string;
  workStatus: string;
}

export interface DeviceInfo {
  deviceId: string;
  deviceStatus: RealtimeDeviceStatus;
}

export interface RealtimeDevice extends DeviceDTO {
  status: RealtimeDeviceStatus;
}

export enum RealtimeDeviceStatus {
  OFFLINE,
  MOVING,
  WORKING,
}

export class DeviceMessageModel {
  constructor(
    public d: DynamicMessageModel = new DynamicMessageModel(),
    public e: StaticMessageModel = new StaticMessageModel(),
    public id: string = 'Default Id',
    public location: Location = new Location(),
  ) {}
}

export class DynamicMessageModel {
  constructor(
    public id: string = 'Default Id',
    public gw_addr: string = 'Default gw_addr',
    public deviceId: string = 'Default Device Id',
    public node_addr: string = 'Default Node Address',
    public location: Location = new Location(),
    public operation: Operation = new Operation(),
    public machine: Machine = new Machine(),
    public navigation: Navigation = new Navigation(),
    public telemetry: Telemetry = new Telemetry(),
    public base_location: BaseLocation = new BaseLocation(),
    public timestamp: number = 0,
  ) {}
}

export class StaticMessageModel {
  constructor(
    public machine_name: string = 'Default Machine Name',
    public impl_width: number = 0,
    public timestamp: number = 0,
    public node_addr: string = 'Default Node Address',
    public app_version: string = 'Default App Version',
    public crop_timestamp: number = 0,
    public nozz_number: string = 'Default Nozzle Number',
    public field_name: string = 'Default Field Name',
    public machine_model: number = 0,
    public operator: string = 'Default Operator',
    public gw_addr: string = 'Default Gateway Address',
    public crop_type: string = 'Default Crop Type',
    public manufacturer: string = 'Default Manufacturer',
    public sec_number: string = 'Default Section Number',
    public event_name: string = 'Default Event Name',
    public nozz_spacing: number = 0,
    public nozz_per_section: Array<number> = [0],
    public crop_location: {
      crop_lat: number;
      crop_lon: number;
    } = { crop_lat: 0, crop_lon: 0 },
  ) {}
}

export function convertToRealtimeStatus(status: string) {
  if (status === 'Working') return RealtimeDeviceStatus.WORKING;
  if (status === 'Not_Working') return RealtimeDeviceStatus.MOVING;
  else return RealtimeDeviceStatus.OFFLINE;
}
