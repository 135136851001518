import {
  Feature,
  FeatureCollection,
  area,
  featureCollection,
} from '@turf/turf';
/// <reference types="google.maps" />

import { HttpClient } from '@angular/common/http';
import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  ViewChildren,
} from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormControl,
  FormControlName,
  UntypedFormGroup,
  Validators,
  FormGroup,
  FormBuilder,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { PrevisaoTempoService } from 'app/core/services/previsao-tempo.service';
import { UserTalhaoListService } from 'app/core/services/user-talhao-list.service';
import { AlertService } from 'app/shared/components/alert/alert.service';
import { GenericValidator } from 'app/utils/generic-form-validator';
import { Observable, of, Subject, Subscription } from 'rxjs';
import {
  catchError,
  debounceTime,
  distinctUntilChanged,
  map,
} from 'rxjs/operators';
import { AnalyticsService } from 'app/core/services/analytics.service';
import { Geometry } from '@turf/turf';
import { AppConstants } from 'app/app.constants';

declare var google: any;

@Component({
  selector: 'app-cria-talhoes',
  templateUrl: './cria-talhoes.component.html',
  styleUrls: ['./cria-talhoes.component.scss'],
})
export class CriaTalhoesComponent implements OnInit, OnDestroy {
  offCanvasOne = new Subject<boolean>();
  talhaoById = [];
  blockButton: boolean = false;
  isGoogleMapLoaded: boolean;
  mapPolygonColorSubject: Subject<string> = new Subject();
  updateIsPolygonClosed: Subject<boolean> = new Subject();
  googleMapCenterCoordinate: Subject<{ lat: number; lng: number }> =
    new Subject();

  selectedColor = '#000';

  @Input() emitEventEditarTalhaoById;
  @ViewChildren(FormControlName, { read: ElementRef })
  control = new UntypedFormControl('');
  cityInputUpdate = new Subject<string>();
  formInputElements: ElementRef[];
  filteredStreets: any[];
  cityName = '';

  fetchFieldData: Subject<boolean> = new Subject();
  public showModalSubject: Subject<boolean> = new Subject();
  public showModal: boolean;
  public sizeModal: 'sm' | 'md' | 'lg' | 'xl' | 'fullscreen' = 'md';

  geometry: FeatureCollection = {
    features: [
      {
        geometry: {
          coordinates: [],
          type: 'Polygon',
        },
        properties: {},
        type: 'Feature',
      },
    ],
    type: 'FeatureCollection',
  };
  isPolygonClosed = false;
  isEditingPolygon = false;
  polygons: google.maps.LatLngLiteral[][] = [];

  showPolygon = false;

  apiLoaded: Observable<boolean>;

  vertices: google.maps.LatLngLiteral[] = [];

  mapOptions: google.maps.MapOptions = { draggable: false };

  markerPositions: google.maps.LatLngLiteral[] = [];
  createFieldForm: UntypedFormGroup;
  // formInputsLatLng: FormGroup;
  searchForm: FormGroup;
  locations: any[] = [];

  public validationMessages: { [key: string]: { [key: string]: string } };
  public genericValidator: GenericValidator;
  public displayMessage: { [key: string]: string } = {};
  private readonly subscription: Subscription;

  inputLat: number;
  inputLong: number;
  formData: { latitude: number; longitude: number };
  enteredCharacters: string = '';
  initialFieldArea: string;
  exceededCharacterLimit: boolean = false;
  userId: string;
  taskCenter: any;
  constructor(
    httpClient: HttpClient,
    public router: Router,
    public userTalhoesService: UserTalhaoListService,
    public translateService: TranslateService,
    public formBuilder: UntypedFormBuilder,
    private formBuilderSearch: FormBuilder,
    public previsaoTempoService: PrevisaoTempoService,
    public alertAction: AlertService,
    public alertService: AlertService,
    private activatedRoute: ActivatedRoute,
    private readonly cdr: ChangeDetectorRef,
    private readonly analyticsService: AnalyticsService,
  ) {
    const usuarioLogado = JSON.parse(
      localStorage.getItem(AppConstants.KEYS_LOCAL_STORAGE.ISO_USUARIO),
    );
    this.userId = usuarioLogado.id;
    if (!this.userId.includes('@agres.com.br')) {
      this.analyticsService.sendPageView('/criar-talhao/');
      this.analyticsService.trackEvent(
        'Accessed the create field',
        'Create_Field',
        this.userId,
      );
    }
    this.validationMessages = {
      name: {
        required: '',
      },
      description: {},
    };

    this.populateValidateObj();
    this.genericValidator = new GenericValidator(this.validationMessages);
    this.apiLoaded = httpClient
      .jsonp(
        'https://maps.googleapis.com/maps/api/js?key=AIzaSyAWHf8YuyJtv42nFtws9Rj4cGmut3S-yTo',
        'callback',
      )
      .pipe(
        map(() => true),
        catchError(() => of(false)),
      );
  }
  openInfomationsOfField() {
    this.getCityName(this.geometry);
    if (this.isPolygonClosed) {
      this.isEditingPolygon = false;
      this.offCanvasOne.next(true);
    }
  }

  ngOnInit(): void {
    this.analyticsService.trackEvent(
      'create_field_page_has_been_opened',
      'Open Page Create Field',
      '',
    );
    this.activatedRoute.queryParams.subscribe((params) => {
      let shapefileFeatureCollection: string | FeatureCollection =
        params.featureCollection;
      if (shapefileFeatureCollection) {
        shapefileFeatureCollection = featureCollection([
          JSON.parse(shapefileFeatureCollection as string) as Feature,
        ]);
        this.initialFieldArea = (
          area(shapefileFeatureCollection) / 10000
        ).toFixed(3);
        this.geometry = shapefileFeatureCollection;
        this.isPolygonClosed = true;
      } else if (params.center) {
        this.taskCenter = JSON.parse(params.center);
      }
    });
    this.cityInputUpdate
      .pipe(debounceTime(350), distinctUntilChanged())
      .subscribe((event: any) => {
        this.updateAutocompleteCitiesList(event.target.value);
      });

    this.createFieldForm = this.formBuilder.group({
      area: [this.initialFieldArea],
      name: ['', [Validators.required]],
      description: ['', [Validators.required, Validators.maxLength(200)]],
    });

    this.searchForm = this.formBuilderSearch.group({
      searchCity: [''],
      lineName: [''],
      selectedLocation: [''],
    });
  }

  onGoogleMapLoaded(mapLoaded): void {
    this.isGoogleMapLoaded = mapLoaded;
    this.cdr.detectChanges();
    if (this.taskCenter) {
      this.centerGoogleMap(this.taskCenter.lat, this.taskCenter.lng);
    }
  }

  togglePolygonEditButtonTitle(): string {
    if (
      (this.geometry.features[0].geometry as Geometry).coordinates.length > 0
    ) {
      return this.translateService.instant('fields.title-button-draw-the-hole');
    }
    return this.translateService.instant('fields.title-button-draw-the-field');
  }

  callTogglePolygonDrawing() {
    this.updateIsPolygonClosed.next(false);
    this.isEditingPolygon = true;
    this.isPolygonClosed = false;
    if (!this.userId.includes('@agres.com.br')) {
      this.analyticsService.trackEvent(
        'Clicked on draw field button',
        'Create_Field',
        this.userId,
      );
    }
  }

  hideOffCanvas(): void {
    this.offCanvasOne.next(false);
  }

  populateValidateObj() {
    this.translateService
      .get('global.validate.user-required')
      .subscribe((res: string) => {
        this.validationMessages.name.required = res;
      });
  }
  isValidLatitude(latitude: string): boolean {
    const trimmedLatitude = latitude.replace(/\s+/g, '');
    const latitudeRegex = /^[-+]?([1-8]?\d(\.\d+)?|90(\.0+)?)$/;
    return latitudeRegex.test(trimmedLatitude);
  }
  isValidLongitude(longitude: string): boolean {
    const trimmedLongitude = longitude.replace(/\s+/g, '');
    const longitudeRegex = /^[-+]?((1[0-7]|[1-9])?\d(\.\d+)?|180(\.0+)?)$/;
    return longitudeRegex.test(trimmedLongitude);
  }
  isCommaAfterLatitude(input: string): boolean {
    const indexOfComma = input.indexOf(',');
    const indexOfNewline = input.indexOf('\n');
    return indexOfComma !== -1 && indexOfComma > indexOfNewline;
  }
  handleChange(event) {
    const inputValue: string = event.target.value;
    if (event.key === 'Enter' || event.type === 'submit') {
      this.enteredCharacters = inputValue.substring(
        inputValue.lastIndexOf('\n') + 1,
      );
      if (!this.isCommaAfterLatitude(this.enteredCharacters)) {
        this.translateService
          .get('global.alert.search-cordinate')
          .subscribe((res: string) => {
            this.alertService.warning(res);
          });
        return;
      }
      const [latitude, longitude] = this.enteredCharacters.split(',');
      if (this.isValidLatitude(latitude) && this.isValidLongitude(longitude)) {
        const parsedLatitude = parseFloat(latitude);
        const parsedLongitude = parseFloat(longitude);
        this.centerGoogleMap(parsedLatitude, parsedLongitude);
      } else {
        this.translateService
          .get('global.validate.incorrect-coordinate')
          .subscribe((res: string) => {
            this.alertService.warning(res);
          });
      }
    }
    this.cityInputUpdate.next(event);
  }

  updateAutocompleteCitiesList(location: string) {
    this.previsaoTempoService
      .getSearchLocation(location)
      .subscribe((result) => {
        const uniqueIds = new Set();
        const unique = result.filter((element) => {
          const isDuplicate = uniqueIds.has(element.address);
          uniqueIds.add(element.address);
          return !isDuplicate;
        });

        this.filteredStreets = unique;
      });
  }

  onInputChange(event: any) {
    const endereco = event.target.value;
    if (endereco) {
      this.previsaoTempoService.buscarCoordenadas(endereco).subscribe(
        (data: any) => {
          if (data.status === 'OK') {
            this.locations = data.results;
          } else {
            console.error(`Erro na requisição: ${data.status}`);
          }
        },
        (error) => {
          console.error(error);
        },
      );
    }
  }
  centerGoogleMap(latitude: number, longitude: number) {
    this.googleMapCenterCoordinate.next({ lat: latitude, lng: longitude });
  }

  addMarker(event: google.maps.MapMouseEvent) {
    this.markerPositions.push(event.latLng.toJSON());
  }

  options: google.maps.MapOptions = {
    center: { lat: 40, lng: -20 },
    zoom: 13,
    fullscreenControl: false,
    rotateControl: false,
    scaleControl: false,
    streetViewControl: false,
    mapTypeControl: false,
    mapTypeId: 'satellite',
  };

  cancelEditPolygons(): void {
    if (!this.userId.includes('@agres.com.br')) {
      this.analyticsService.trackEvent(
        'Clicked on the cancel fields button',
        'Create_Field',
        this.userId,
      );
    }
    this.polygons = [];
    this.markerPositions = [];
    this.router.navigate(['/my-fields']);
  }

  closePolygon(event) {
    this.vertices = [...this.markerPositions, this.markerPositions[0]];
    this.polygons.push(this.vertices);
  }

  clearPolygons() {
    if (!this.userId.includes('@agres.com.br')) {
      this.analyticsService.trackEvent(
        'Clicked on the clear fields button',
        'Create_Field',
        this.userId,
      );
    }
    (this.geometry.features[0].geometry as Geometry).coordinates = [];
    this.isEditingPolygon = true;
    this.isPolygonClosed = false;
    this.fetchFieldData.next(true);
    this.createFieldForm.controls.area.setValue(0);
    this.updateIsPolygonClosed.next(false);
  }

  createTalhao() {
    this.blockButton = true;
    this.userTalhoesService
      .postTalhao({
        data: {
          geometry: this.geometry,
          name: this.createFieldForm.controls.name.value,
          city: this.cityName,
          color: this.selectedColor,
          description: this.createFieldForm.controls.description.value,
        },
      })
      .subscribe(
        (talhoes) => {
          if (talhoes) {
            this.translateService
              .get('global.alert.http-status-code.200')
              .subscribe((res: string) => {
                this.alertAction.info(res);
                this.router.navigate(['/my-fields']);
                if (!this.userId.includes('@agres.com.br')) {
                  this.analyticsService.trackEvent(
                    'Created a field',
                    'Create_Field',
                    this.userId,
                  );
                }
              });
          } else {
            this.translateService
              .get('global.validate.http-status-code-400')
              .subscribe((res: string) => {
                this.alertAction.error(res);
                if (!this.userId.includes('@agres.com.br')) {
                  this.analyticsService.trackEvent(
                    'Not created a field',
                    'Create Field',
                    this.userId,
                  );
                }
              });
          }
        },
        () => {
          this.translateService
            .get('global.validate.http-status-code-400')
            .subscribe((res: string) => {
              this.alertAction.error(res);
              if (!this.userId.includes('@agres.com.br')) {
                this.analyticsService.trackEvent(
                  'Not created a field',
                  'Create Field',
                  this.userId,
                );
              }
            });
        },
      );
  }

  onUpdatePolygons() {
    return (geometry, polygonStatus: boolean, area: number) => {
      if (Object.keys(geometry).length > 0 && polygonStatus && area) {
        this.createFieldForm.controls.area.setValue(area.toFixed(3));
        this.isPolygonClosed = polygonStatus;
        this.geometry = geometry;
        this.getCityName(geometry);
        this.isEditingPolygon = false;
      }
    };
  }

  getCityName(geometry) {
    const coordinates = geometry.features[0].geometry.coordinates[0][0];
    this.previsaoTempoService
      .getLocationName(coordinates[1], coordinates[0])
      .subscribe((cityName) => {
        this.cityName = cityName;
      });
  }

  selectClick(event) {
    this.mapPolygonColorSubject.next(event.target.value);
    this.selectedColor = event.target.value;
  }

  selectCoresTalhao(event) {
    this.mapPolygonColorSubject.next(event.target.value);
    this.selectedColor = event.target.value;
  }
  importShp(): void {
    this.translateService
      .get('Estamos trabalhando nesta funcionalidade!')
      .subscribe((res: string) => {
        this.alertAction.info(res);
      });
  }

  openModal() {
    this.showModal = true;
    this.sizeModal = 'md';
    this.showModalSubject.next(true);
  }
  closeModal() {
    this.showModal = false;
    this.showModalSubject.next(false);
  }

  verifyTextAreaSize() {
    const textareaSize = this.createFieldForm.controls.description.value.length;
    if (textareaSize > 200) {
      this.exceededCharacterLimit = true;
      console.log(this.exceededCharacterLimit);
    } else {
      this.exceededCharacterLimit = false;
    }
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
