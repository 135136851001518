import { UserTalhaoListService } from 'app/core/services/user-talhao-list.service';
import { HttpClient } from '@angular/common/http';
import {
  Component,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
  EventEmitter,
  AfterViewChecked,
} from '@angular/core';

import { Observable, of, Subject } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { GoogleMap } from '@angular/google-maps';
import { AppConstants } from 'app/app.constants';
import { FeatureCollection, Geometry, Position } from '@turf/turf';
import { AnalyticsService } from 'app/core/services/analytics.service';
declare var google: any;

@Component({
  selector: 'app-mapa-talhao',
  templateUrl: './mapa-talhao.component.html',
  styleUrls: ['./mapa-talhao.component.scss'],
})
export class MapaTalhaoComponent
  implements OnInit, OnDestroy, AfterViewChecked
{
  googleMap: GoogleMap;
  overlayPolygon: any;
  @ViewChild(GoogleMap, { static: false }) set map(googleMap: GoogleMap) {
    if (googleMap) {
      this.initDrawingManager(googleMap);
    }
  }
  area = undefined;
  @Output() startMapLoaded = new EventEmitter<boolean>();
  @Input() polygonColor?: string = '#000';
  @Input() updatePolygons;
  @Input() polygonColorsSubject: Subject<string>;
  @Input() googleMapCenterCoordinate: Subject<{ lat: number; lng: number }>;
  @Input() talhaoId: string | null = null;
  @Input() updateIsPolygonClosed: Subject<boolean>;
  @Input() mode: 'create' | 'edit' | 'view';
  @Input() mapHeight: string;
  @Input() geometry: FeatureCollection = {
    features: [
      {
        geometry: {
          coordinates: [[]],
          type: 'Polygon',
        },
        properties: {
          fill: this.polygonColor,
          'fill-opacity': 0.6,
        },
        type: 'Feature',
      },
    ],
    type: 'FeatureCollection',
  };
  @Input() resetData: Subject<boolean>;

  apiLoaded: Observable<boolean>;

  googleMapsOverlay: any;
  polygons: google.maps.LatLngLiteral[][] = [];
  vertices: google.maps.LatLngLiteral[] = [];
  mapOptions: google.maps.MapOptions = { draggable: true };

  options: google.maps.MapOptions = {
    zoom: 3,
    mapTypeId: 'satellite',
    disableDefaultUI: false,
    zoomControl: true,
    fullscreenControl: true,
    center: { lat: -10.33, lng: -53.2 },
  };
  ngAfterViewChecked() {
    const markerButton = document.querySelector('[aria-label="Marcadores"]');
    if (markerButton instanceof HTMLElement) {
      markerButton.click();
      const divToRemove = document.querySelector('.gmnoprint.gm-style-mtc-bbw');
      const divToRemovePegman = document.querySelector(
        '[aria-label="Arraste o Pegman até o mapa para abrir o Street View"]',
      );
      if (divToRemove instanceof HTMLElement) {
        divToRemove.remove();
        divToRemovePegman.remove();
      }
    }
  }

  markerPositions: google.maps.LatLngLiteral[] = [];

  drawingOptions: google.maps.drawing.DrawingManagerOptions;
  googleMapsDrawingManager: google.maps.drawing.DrawingManager;

  googleMapPolygon: google.maps.Polygon;

  polygonsOptions: google.maps.PolygonOptions = {
    fillColor: this.polygonColor,
    fillOpacity: 0.6,
  };

  input: any;
  streets: string[] = [];
  private polygonButtonPressed = false;

  constructor(
    httpClient: HttpClient,
    public userTalhoesService: UserTalhaoListService,
    public readonly analyticsService: AnalyticsService,
  ) {
    this.apiLoaded = httpClient
      .jsonp(
        'https://maps.googleapis.com/maps/api/js?key=AIzaSyAWHf8YuyJtv42nFtws9Rj4cGmut3S-yTo&libraries=drawing',
        'callback',
      )
      .pipe(
        map(() => {
          this.drawingOptions = {
            drawingControl: false,
            drawingControlOptions: {
              drawingModes: [google.maps.drawing],
              position: google.maps.ControlPosition.RIGHT_CENTER,
            },
            polygonOptions: {
              editable: true,
              fillColor: this.polygonColor,
            },
            ...this.drawingOptions,
          };
          return true;
        }),
        catchError(() => of(false)),
      );
  }
  ngOnDestroy(): void {
    google = undefined;
  }

  ngOnInit(): void {
    if (this.googleMapCenterCoordinate !== undefined) {
      this.googleMapCenterCoordinate.subscribe(
        (coordinate: { lat: number; lng: number }) => {
          this.centerGoogleMap(coordinate);
        },
      );
    }

    this.polygonsOptions.fillColor = this.polygonColor;

    if (this.resetData)
      this.resetData.subscribe((v) => {
        if (this.googleMapPolygon !== undefined) {
          this.googleMapPolygon.setMap(null);
          this.googleMapPolygon = undefined;
        }

        if (this.googleMapsOverlay !== undefined) {
          this.googleMapsDrawingManager.setOptions(this.drawingOptions);
          this.googleMapsOverlay.overlay.setMap(null);
        }
      });

    if (this.polygonColorsSubject)
      this.polygonColorsSubject.subscribe((color) => {
        this.drawingOptions.polygonOptions.fillColor = color;
        this.drawingOptions.polygonOptions.strokeColor = color;

        this.geometry.features[0].properties.fill = color;
        if (this.googleMapPolygon !== undefined) {
          this.googleMapPolygon.setOptions({
            fillColor: color,
            strokeColor: color,
          });
        }

        if (this.googleMapsOverlay !== undefined) {
          this.overlayPolygon.setOptions({
            fillColor: color,
            strokeColor: color,
          });
        } else {
          if (this.googleMap !== undefined) {
            if (this.googleMapsDrawingManager !== undefined) {
              this.googleMapsDrawingManager.setOptions(this.drawingOptions);
            }
          }
        }
      });
    if (this.updateIsPolygonClosed)
      this.updateIsPolygonClosed.subscribe((isPolygonClosed) => {
        if (isPolygonClosed) {
          this.googleMapsDrawingManager.setDrawingMode(null);
        } else {
          this.googleMapsDrawingManager.setDrawingMode(
            google.maps.drawing.OverlayType.POLYGON,
          );
        }
      });
  }

  initDrawingManager(map: GoogleMap) {
    if (map && map.googleMap) {
      if (this.updatePolygons) {

        const localStorageUserLocation = localStorage.getItem(
          AppConstants.KEYS_LOCAL_STORAGE.USER_LOCATION,
        );
        if (localStorageUserLocation !== null) {

          if (map && map.googleMap) {

            map.googleMap.setCenter(JSON.parse(localStorageUserLocation));
            map.googleMap.setZoom(15);
          }
        }
        this.googleMap = map;

        const drawingManager = new google.maps.drawing.DrawingManager(
          this.drawingOptions,
        );

        this.googleMapsDrawingManager = drawingManager;
        drawingManager.setMap(map.googleMap);
        this.startMapLoaded.emit(true);
        drawingManager.addListener('overlaycomplete', (event) => {
          this.removeUndoButton();
          this.googleMapsOverlay = event;

          this.area = (
            google.maps.geometry.spherical.computeArea(
              event.overlay.getPath(),
            ) / 10000
          ).toFixed(3);

          drawingManager.setOptions({
            drawingControl: false,
            drawingMode: null,
          });
          this.updatePolygonPaths(event.overlay);
          if (this.googleMapPolygon !== undefined) {

            this.googleMapPolygon.setMap(null);
          }
        });

        drawingManager.addListener('polygoncomplete', (event) => {
          this.overlayPolygon = event;

          event.addListener('mouseup', (polygonEvent) => {
            if (!!polygonEvent.vertex || !!polygonEvent.edge) {
              this.area = (
                google.maps.geometry.spherical.computeArea(event.getPath()) /
                10000
              ).toFixed(3);
              this.updatePolygonPaths(event);
            }
          });
        });
      }

      let coordinatesData: Position | { lat: number; lng: number }[] = (
        this.geometry.features[0].geometry as Geometry
      ).coordinates[0] as Position;

      if (Array.isArray(coordinatesData) && coordinatesData.length > 0) {

        coordinatesData = coordinatesData.map((latLong) => ({
          lat: latLong[1],
          lng: latLong[0],
        }));
        if (this.updatePolygons)
          this.updatePolygons(this.geometry, true, this.area);

        this.googleMapPolygon = new google.maps.Polygon({
          paths: coordinatesData,
          fillOpacity: 0.6,
          editable: this.mode != 'view',
          fillColor: this.polygonColor,
          strokeColor: this.polygonColor,
        });

        this.googleMapPolygon.addListener('mouseup', (polygonEvent) => {
          if (!!polygonEvent.vertex || !!polygonEvent.edge || this.geometry) {
            this.updatePolygonPaths(this.googleMapPolygon);
            this.analyticsService.trackEvent('Started_Polygon_Editing', 'Edit Polygon', '');
          }
        });

        this.googleMapPolygon.setMap(map.googleMap);

        let bounds = new google.maps.LatLngBounds();

        this.googleMapPolygon.getPath().forEach(function (path) {
          bounds.extend(path);
        });

        map.fitBounds(bounds);
        this.removeUndoButton();
      } else {
        this.getLocation();
      }
    }
  }

  removeUndoButton() {
    if (this.googleMap === undefined) return;
    const undoPolygonChangeButton: HTMLImageElement = [
      ...this.googleMap.googleMap.getDiv().getElementsByTagName('img'),
    ].find((img) => img.src.includes('undo_poly.png'));

    if (!undoPolygonChangeButton) {
      setTimeout(() => {
        this.removeUndoButton();
      }, 200);
    } else {
      undoPolygonChangeButton.parentElement.remove();
    }
  }

  private updatePolygonPaths(event: any) {
    let paths = event
      .getPath()
      .getArray()
      .map((latLgn) => [latLgn.lng(), latLgn.lat()]);
    paths.push(paths[0]);
    (this.geometry.features[0].geometry as Geometry).coordinates[0] = paths;
    this.area = (
      google.maps.geometry.spherical.computeArea(event.getPath()) / 10000
    ).toFixed(3);
    this.updatePolygons(this.geometry, true, this.area);
  }

  getLocation() {
    if (navigator.geolocation && map && this.googleMap) {
      navigator.geolocation.getCurrentPosition(
        (position: any) => {
          if (position) {
            const lat = position.coords.latitude;
            const lng = position.coords.longitude;

            localStorage.setItem(
              AppConstants.KEYS_LOCAL_STORAGE.USER_LOCATION,
              JSON.stringify({ lat, lng }),
            );

            this.googleMap.googleMap.setCenter({ lat, lng });
            this.googleMap.googleMap.setZoom(15);
          }
        },
        (error: any) => {
          if (error.code === 1) {
            localStorage.removeItem(
              AppConstants.KEYS_LOCAL_STORAGE.USER_LOCATION,
            );
          }
        },
      );
    }
  }
  centerGoogleMap(coordinate: { lat: number; lng: number }) {
    this.googleMap.googleMap.setCenter(coordinate);
    this.googleMap.googleMap.setZoom(14);
  }

  togglePolygonDrawing() {
    if (this.polygonButtonPressed) {
      this.googleMapsDrawingManager.setDrawingMode(null);
      this.polygonButtonPressed = false;
    } else {
      this.googleMapsDrawingManager.setDrawingMode(
        google.maps.drawing.OverlayType.POLYGON,
      );
      this.polygonButtonPressed = true;
    }
  }
}
