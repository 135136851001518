import {
  merge as observableMerge,
  fromEvent as observableFromEvent,
  Observable,
  Subject,
} from 'rxjs';
import {
  Component,
  OnInit,
  AfterViewInit,
  Input,
  Output,
  EventEmitter,
  ViewChildren,
  ElementRef,
} from '@angular/core';
import {
  FormControlName,
  UntypedFormGroup,
  UntypedFormBuilder,
  Validators,
} from '@angular/forms';
import { GenericValidator } from 'app/utils/generic-form-validator';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { Device } from 'app/shared/models/device';
import { DeviceService } from 'app/core/services/device.service';
import { UserService } from 'app/core/services/user.service';
@Component({
  selector: 'app-form-add-device',
  templateUrl: './add-device.component.html',
  styleUrls: ['./add-device.component.scss'],
})
export class AddDeviceComponent implements OnInit, AfterViewInit {
  @Input() showSubject: Subject<boolean>;
  @Input() deviceList: Array<any> = [];
  @Output() modalChange: EventEmitter<boolean>;
  @ViewChildren(FormControlName, { read: ElementRef })
  formInputElements: ElementRef[];

  public users: Array<{ id: number; name: string }>;

  public device: Device;
  public registrarForm: UntypedFormGroup;
  public genericValidator: GenericValidator;

  public disableButton;
  public listaIdAlocado;
  public arrayIdAlocado: any[] = [];
  public selectedPermisao;
  public selectedPerfil;
  public perfilInvalido = false;

  public errors: any[] = [];

  public displayMessage: { [key: string]: string } = {};
  public validationMessages: { [key: string]: { [key: string]: string } };

  constructor(
    public fb: UntypedFormBuilder,
    public translateService: TranslateService,
    public toastrService: ToastrService,
    public deviceService: DeviceService,
    public userService: UserService,
  ) {
    this.validationMessages = {
      IdIsoView: {
        required: '',
        maxlength: '',
      },
      selectedUser: {
        required: '',
      },
    };

    this.translateService
      .get('global.validate.IdIsoView-required')
      .subscribe((res: string) => {
        this.validationMessages.IdIsoView.required = res;
      });

    this.translateService
      .get('global.validate.IdIsoView-maxlength')
      .subscribe((res: string) => {
        this.validationMessages.IdIsoView.maxlength = res;
      });

    this.translateService
      .get('global.validate.selectedUser-required')
      .subscribe((res: string) => {
        this.validationMessages.selectedUser.required = res;
      });

    this.genericValidator = new GenericValidator(this.validationMessages);
    this.modalChange = new EventEmitter<boolean>();
  }

  ngOnInit() {
    this.userService.getUserEmail().subscribe((users) => {
      this.users = users;
    });
    this.registrarForm = this.fb.group({
      AutorizoUso: 'true',
      IdIsoView: [
        '',
        [Validators.required, Validators.maxLength(6), Validators.minLength(1)],
      ],
      selectedUser: ['', [Validators.required]],
    });
    this.disableButton = false;
  }

  ngAfterViewInit() {
    let controlBlurs: Observable<any>[] = this.formInputElements.map(
      (formControl: ElementRef) =>
        observableFromEvent(formControl.nativeElement, 'blur'),
    );
    observableMerge(...controlBlurs).subscribe(() => {
      this.displayMessage = this.genericValidator.processMessages(
        this.registrarForm,
      );
    });
  }

  loadDevice() {
    this.deviceService.getAllDevices().subscribe((res) => {
      this.deviceService.changeData(res);
    });
  }

  adicionarDevice() {
    this.displayMessage = this.genericValidator.processMessages(
      this.registrarForm,
    );
    this.disableButton = false;
    if (
      this.registrarForm.valid &&
      this.registrarForm.dirty &&
      this.validarNovoDevice()
    ) {
      this.disableButton = true;

      var device = this.montarObjDevice();

      this.deviceService
        .postDevice({ ...device, users: [device.selectedUser] })
        .subscribe(
          (data) => {
            this.onSaveComplete(data);
          },
          (error) => {
            this.onError(error);
          },
        );
    }
  }

  validarNovoDevice() {
    let device = Object.assign({}, this.device, this.registrarForm.value);

    if (
      this.deviceList.find((x) => x.IdIsoView == device.IdIsoView) == undefined
    ) {
      return true;
    } else {
      this.translateService
        .get('global.validate.error-add-id')
        .subscribe((res: string) => {
          this.toastrService.error(res);
          return false;
        });
    }
  }

  montarObjDevice() {
    let device = Object.assign({}, this.device, this.registrarForm.value);

    device.AutorizoUso == !true
      ? (device.AutorizoUso = false)
      : (device.AutorizoUso = true);
    device['_id'] = device.IdIsoView;
    device['DeviceId'] = device.IdIsoView;
    device['DeviceType'] = 'DeviceType';
    device['IdIsoView'] = device.IdIsoView;
    device['selectedUser'] = device.selectedUser;

    return device;
  }

  onSaveComplete(response: any) {
    this.errors = [];
    this.loadDevice();
    this.showSubject.next(false);
    this.disableButton = false;
    this.translateService
      .get('global.alert.success-add')
      .subscribe((res: string) => {
        this.toastrService.success(res);
      });
  }

  onError(fail: any) {
    this.disableButton = false;
    this.errors = [fail.error];

    this.translateService
      .get('error.500.error-occurred')
      .subscribe((res: string) => {
        this.toastrService.error(this.errors[0], res);
      });
  }

  public hideModal(): void {
    this.showSubject.next(false);
  }
}
