import { UserService } from 'app/core/services/user.service';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { TimeoutEnum } from 'app/core/enum/timeout.enum';
import { LoginService } from 'app/core/services/login.service';
import { AlertService } from 'app/shared/components/alert/alert.service';
import { User } from 'app/shared/models/user';
import { AgresSpinnerService } from 'app/shared/components/agres-spinner/agres-spinner.service';

@Component({
  selector: 'app-delete-account',
  templateUrl: './delete-account.component.html',
  styleUrls: ['./delete-account.component.scss'],
})
export class DeleteAccountComponent implements OnInit {
  public usuario: User = new User();
  public isConfirmPasswordVisible = false;

  public myDeleteForm: FormGroup;
  public deleteData: { email: string; password: string } = {
    email: '',
    password: '',
  };
  public loginExecuting = false;

  constructor(
    private readonly formBuilder: FormBuilder,
    private readonly translate: TranslateService,
    private readonly alertService: AlertService,
    private readonly loginService: LoginService,
    private readonly deleteUserService: UserService,
    private readonly router: Router,
    private readonly agresSpinnerService: AgresSpinnerService,
  ) {}

  ngOnInit() {
    this.myDeleteForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required]],
    });
  }

  onSubmit() {}

  backToProfile() {
    history.back();
  }

  confirmTogglePassword() {
    this.isConfirmPasswordVisible = !this.isConfirmPasswordVisible;
  }

  onDeleteUser() {
    this.loginExecuting = true;
    const email = this.myDeleteForm.value.email;
    const password = this.myDeleteForm.value.password;

    if (this.myDeleteForm.valid) {
      this.loginService.login(email, password).subscribe(
        (data: any) => {
          this.deleteUserService.fakeDeleteUser(data.access_token).then(
            (res) => {
              this.translate
                .get('global.validate.deleted-account')
                .subscribe((result: string) => {
                  this.alertService.success(result);
                  this.loginExecuting = false;
                });

              setTimeout(() => {
                this.router.navigate(['/login']);
              }, TimeoutEnum.MediumShort);
            },
            (error) => {
              console.error(error);
            },
          );
        },
        (error) => {
          if (error.status === 400) {
            this.translate
              .get('global.validate.user-login')
              .subscribe((res: string) => {
                this.alertService.error(res);
                this.loginExecuting = false;
              });
          }
        },
      );
    } else {
      this.translate
        .get('global.validate.fields')
        .subscribe((result: string) => {
          this.alertService.warning(result);
          this.loginExecuting = false;
        });
    }
  }
}
