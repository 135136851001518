import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
} from '@angular/core';
import { TimeoutEnum } from 'app/core/enum/timeout.enum';
import { UserTalhaoListService } from 'app/core/services/user-talhao-list.service';
import { Subject } from 'rxjs';
import { AgresSpinnerService } from '../../agres-spinner/agres-spinner.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-card-talhoes',
  templateUrl: './card-talhoes.component.html',
  styleUrls: ['./card-talhoes.component.scss'],
})
export class CardTalhoesComponent implements OnInit, OnChanges, AfterViewInit {
  @Input() emitEventOpenTalhaoById;
  @Input() emitEventEditTalhaoById;
  @Input() emitEventOperationById;
  @Input() valueInputSearch;
  @Input() changeTalhaoIdOnDelete;
  @Input() fetchFieldData: Subject<boolean>;
  @Input() updateFieldIdToBeDeleted: Subject<string>;
  @Output() nomeTalhaoEmitido: EventEmitter<string> =
    new EventEmitter<string>();
  @Output() talhaoDeletadoEmitido: EventEmitter<string> =
    new EventEmitter<string>();

  fieldIdToBeDeleted: string = '';
  originalsTalhoesItens = [];
  filteredData: any[] = [];
  talhoesById: any[];
  talhoesItens = [];
  talhoes = [];
  imagePath;
  rowsPerPage: number = 12;
  pagination: number[] = [0, this.rowsPerPage];
  previousPages: number[] = [];
  page: number = 0;
  router: any;
  totalFields = 0;

  constructor(
    public userTalhoesService: UserTalhaoListService,
    public loadingAgresSpinner: AgresSpinnerService,
    public translateService: TranslateService,
  ) {}

  ngOnChanges(): void {
    this.searchFilterField(this.valueInputSearch);
  }

  ngOnInit(): void {
    this.updateFieldIdToBeDeleted.subscribe((fieldIdToBeDeleted: string) => {
      this.fieldIdToBeDeleted = fieldIdToBeDeleted;
      this.nomeTalhaoEmitido.emit(this.fieldIdToBeDeleted);
    });
  }

  ngAfterViewInit(): void {
    this.translateService
      .get('global.loading.fields')
      .subscribe((res: string) => {
        this.loadingAgresSpinner.toShow(res);
      });

    setTimeout(() => {
      this.getFields();
      this.fetchFieldData.subscribe((v) => {
        this.getFields();
      });
    }, TimeoutEnum.Short);
  }

  private getFields() {
    this.userTalhoesService.getAllTalhoes().subscribe((talhoes) => {
      if (talhoes) {
        this.talhoesItens = talhoes
          .filter((talhao) => talhao)
          .map((talhao) => {
            if (talhao.staticMapImage === undefined) {
              talhao.staticMapImage =
                '../../../../../assets/images/default/thumbnail-talhao.png';
            } else {
              talhao.staticMapImage =
                'data:image/jpg;base64,' + talhao.staticMapImage;
            }

            talhao.totalArea = (+talhao.totalArea).toFixed(3);
            return {
              ...talhao,
            };
          });

        this.originalsTalhoesItens = this.talhoesItens;

        this.totalFields = talhoes.length;
      } else {
        this.talhoesItens = [];
        this.originalsTalhoesItens = [];
        this.totalFields = 0;
      }

      this.loadingAgresSpinner.toHide();
    });
  }

  paginationList() {
    return this.talhoesItens.slice(this.pagination[0], this.pagination[1]);
  }

  clickPagination(index: any): void {
    const startingPage = (index - 1) * this.rowsPerPage;

    this.pagination = [startingPage, startingPage + this.rowsPerPage];

    this.page = index - 1;
  }

  searchFilterField(valueInputSearch) {
    if (valueInputSearch != undefined) {
      this.talhoesItens = this.originalsTalhoesItens.filter((item: any) => {
        const parcialMath: boolean = item.name
          .toLowerCase()
          .includes(valueInputSearch.toLowerCase());

        return parcialMath;
      });
    } else {
      this.talhoesItens = this.originalsTalhoesItens;
    }
  }

  enableActionButton(itemId: string, fieldIdToBeDeleted: string): boolean {
    return itemId === fieldIdToBeDeleted;
  }

  onCardClick(itemId: string, fieldIdToBeDeleted: string): void {
    this.emitEventOpenTalhaoById(itemId, fieldIdToBeDeleted);
  }

  onMenuClick(event: Event): void {
    event.stopPropagation();
  }
}
