import { JWTPayload } from 'jose';
import { TokenApi } from './token-api';

export class User {
  name: string;
  nationalIdNumber: string;
  role: string;
  phoneNumber: string;
  company: string;
  refresh_token?: string;
  tokenApi?: TokenApi = new TokenApi();
  address: Address;
  password?: string;
  metadata?: JWTPayload & { role?: string };
  access_token?: string;
  email?: string;
  _rev?: string;
  id?: string;
}

export type Address = {
  postalCode?: string;
  country?: string;
  city?: string;
  stateOrProvince?: string;
  additionalInfo?: string;
  neighborhood?: string;
  street?: string;
  number?: string;
};
