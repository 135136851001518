<div *ngIf="!hideAll">
  <div class="forecast-search">
    <form role="search" [formGroup]="searchForm">
      <div class="agr--input">
        <div class="agr--input__group">
          <div class="agr--input__group--icon">
            <app-agres-icons iconsClass="agres-search"></app-agres-icons>
          </div>
          <ng2-completer
            [(ngModel)]="searchStr"
            textSearching="{{ 'weather.search' | translate }}"
            formControlName="searchControl"
            [inputClass]="'agr--input__group--input'"
            [datasource]="dataService"
            [minSearchLength]="3"
            [pause]="500"
            (selected)="onCitySelected($event)"></ng2-completer>
        </div>
      </div>
    </form>
    <div *ngIf="!isLocationValid">
      <div class="forecast-search__alert">
        <span class="forecast-search__alert--close" onclick="this.parentElement.style.display='none';">×</span>
        {{ 'weather.active-text' | translate }}
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-md-3">
      <div *ngFor="let forecast of summarizedForecast; let i = index">
        <ng-container *ngIf="i === 0">
          <div class="card">
            <div class="weather">
              <div class="weather__icon">
                <img src="{{ forecast.iconConditionTemp }}" alt="" />
              </div>
              <div class="weather__temperature">
                <h1>{{ forecast.temperature }} {{ 'global.temperature-symbol' | translate }}</h1>
                <p>
                  {{ forecast.datePrevision | date: 'EEEE' : '' : translateService.currentLang | lowercase }},
                  {{ currentTime }}
                </p>
                <hr />
                <div class="weather__condition">
                  <div class="weather__condition--info">
                    <img src="./../../../../assets/images/weather/cloud-light.png" alt="{{ forecast.wxPhraseLong }}" />
                    <p>{{ forecast.wxPhraseLong }}</p>
                  </div>
                  <div class="weather__condition--info">
                    <img src="./../../../../assets/images/weather/drop-light.png" alt=" {{ forecast.precipChance }}" />
                    <p>
                      {{ 'weather.weather-rain' | translate }} {{ forecast.precipChance
                      }}{{ 'global.percentage' | translate }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
    <div class="col-md-9">
      <p>
        <strong>{{ 'weather.forecast3days' | translate }}</strong>
      </p>
      <div class="list-weather">
        <div class="list-weather__card" *ngFor="let forecast of summarizedForecast">
          <div class="list-weather__card--icon">
            <img src="{{ forecast.iconConditionTemp }}" alt="imagem de icones climaticos" />
          </div>
          <div class="list-weather__card--info">
            <p>{{ forecast.iconCondition }}</p>
            <div class="list-weather__card--info---condition">
              <span class="list-weather__card--info---condition----max"
                >{{ forecast.temperatureMax }} {{ 'global.temperature-symbol' | translate }}
                <app-agres-icons iconsClass="agres-weather-max" styleClass="text-danger"></app-agres-icons>
              </span>
              <span class="list-weather__card--info---condition----min"
                >{{ forecast.temperatureMin }} {{ 'global.temperature-symbol' | translate }}
                <app-agres-icons iconsClass="agres-weather-min" styleClass="text-info"></app-agres-icons>
              </span>
            </div>
            <div class="list-weather__card--info---day">
              {{ forecast.datePrevision | date: 'EEEE' : '' : translateService.currentLang | lowercase }}
            </div>
            <div class="list-weather__card--info---rain">
              <img src="./../../../../assets/images/weather/drop-light.png" alt="" />
              <span>{{ forecast.precipChance }}{{ 'global.percentage' | translate }}</span>
            </div>
          </div>
        </div>
      </div>
      <p>
        <strong>{{ 'weather.forecastDayNow' | translate }}</strong>
      </p>
      <div class="list-weather">
        <div *ngFor="let forecast of summarizedForecast; let i = index">
          <ng-container *ngIf="i === 0">
            <div class="list-weather__highlights">
              <p>{{ 'weather.weather-uv' | translate }} - UV</p>
              <hr />
              <div class="card-body">
                <div class="weather--uv">{{ forecast.indiceRayUv }}{{ 'global.percentage' | translate }}</div>
              </div>
            </div>
            <div class="list-weather__highlights">
              <p>{{ 'weather.weather-speed' | translate }}</p>
              <hr />
              <div class="weather--wind">
                <h1>{{ forecast.windSpeed }}</h1>
                <span>{{ 'global.speed-symbol' | translate }}</span>
              </div>
            </div>
            <div class="list-weather__highlights">
              <p>{{ 'weather.humilty' | translate }}</p>
              <hr />
              <div class="weather--wind">
                <h1>{{ forecast.relativeHumidity }}</h1>
                <span>{{ 'global.percentage' | translate }}</span>
              </div>
              <div class="progress progress__vertical">
                <div
                  class="progress__vertical--color"
                  [@progressState]="{ value: 'end', params: { progress: progress } }">
                  <span>{{ forecast.relativeHumidity }}</span>
                </div>
              </div>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <div class="tab">
          <input type="checkbox" id="chck1" />
          <label class="tab-label" for="chck1">{{ 'weather.spray' | translate }}</label>
          <div class="tab-content" *ngIf="lineChartDataSprayingIndex">
            <div *ngIf="lineChartDataSprayingIndex && colourIndex">
              <canvas
                [width]="3212"
                [height]="190"
                id="canvas-spraying-index"
                baseChart
                #myul
                [datasets]="lineChartDataSprayingIndex"
                [labels]="lineChartLabels"
                [options]="sprayingCoefficientChartOptions"
                [colors]="lineChartColorsMix"
                [legend]="lineChartLegend"
                [chartType]="barChartType"
                (chartHover)="chartHovered($event)"
                (chartClick)="chartClicked($event)">
              </canvas>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<app-menu-pwa>
  <div class="pwa__list--info">
    <button type="button" (click)="dashboard()">
      <i class="agres-app"></i>
      <p>{{ 'sidebar.title-dashboard' | translate }}</p>
    </button>
  </div>
</app-menu-pwa>
