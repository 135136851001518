import { BehaviorSubject } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { INTERCEPTInternalServerError } from '../interceptors/contexts';
import { ServiceBase } from './service.base';

@Injectable()
export class NvgHistService extends ServiceBase {
  constructor(public http: HttpClient) {
    super();
  }

  public dataSource = new BehaviorSubject<any>([]);
  currenteData = this.dataSource.asObservable();

  changeData(data: any[]) {
    this.dataSource.next(data);
  }

  postHist(params) {
    return this.http
      .post(this.UrlServiceV1 + 'task-hist', params, this.obterAuthHeader())
      .pipe(map(this.extractData), catchError(this.serviceError));
  }

  getHist(
    itemsPerSection: number,
    section: number,
    searchTerm: string,
    params?: { metadata: boolean },
  ) {
    return this.http
      .get(this.UrlServiceV1 + 'task-hist', {
        ...this.obterAuthHeader(),
        params: { ...params, section, itemsPerSection, searchTerm },
        context: new HttpContext().set(INTERCEPTInternalServerError, false),
      })
      .pipe(map(this.extractData), catchError(this.serviceError));
  }

  excluirNvg(nvg) {
    return this.http
      .delete(this.UrlServiceV1 + 'task-hist/' + nvg, this.obterAuthHeader())
      .pipe(map(this.extractData), catchError(this.serviceError));
  }

  excluirAllFiles() {
    return this.http
      .delete(this.UrlServiceV1 + 'task-hist', this.obterAuthHeader())
      .pipe(map(this.extractData), catchError(this.serviceError));
  }

  getMultipleHist(nvgIds: Array<string>, fetchNvgTrabalhos: boolean) {
    return this.http
      .get(`${this.UrlServiceV1}task-hist`, {
        ...this.obterAuthHeader(),
        params: {
          bulk: true,
          tasks: nvgIds,
        },
      })
      .pipe(
        map(this.extractData<Record<string, any>>),
        catchError(this.serviceError),
      );
  }
}
