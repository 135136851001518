/// <reference types="google.maps" />
import { WeatherForecast } from '../../../../core/interface/weather-forecast';
import { Field } from '../../../../core/interface/entity';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { PrevisaoTempoService } from 'app/core/services/previsao-tempo.service';
import { UserTalhaoListService } from 'app/core/services/user-talhao-list.service';
import { HttpClient } from '@angular/common/http';
import { Subject } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { AutomaticFarmApiService } from 'app/core/services/automatic-farm-api.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthorizationService } from 'app/core/services/authorization.service';
import { AppConstants } from 'app/app.constants';
import { _roleEnumUserClass } from 'app/utils/role.utils';
import { AgresSpinnerService } from 'app/shared/components/agres-spinner/agres-spinner.service';
import { TimeoutEnum } from 'app/core/enum/timeout.enum';
import { FileManagerService } from 'app/core/services/file-manager.service';
import * as JSZip from 'jszip';
import { AlertService } from 'app/shared/components/alert/alert.service';
import { AgresSpinnerCanvasService } from 'app/shared/components/agres-spinner-canvas/agres-spinner-canvas.service';
import { Location } from '@angular/common';
import { FeatureCollection } from 'isoxml';
import { UserFilesManager } from 'app/utils/user-files-manager';
import { AnalyticsService } from 'app/core/services/analytics.service';
import { Preferences } from 'app/shared/models/preferences.model';
import { TrailClass } from 'app/utils/trail.utils';
import { ShapefileReader } from 'app/utils/shapefile-reader';
import { NvgHistService } from 'app/core/services/nvg-hist.service';

@Component({
  selector: 'app-visualizar-talhoes',
  templateUrl: './visualizar-talhoes.component.html',
  styleUrls: ['./visualizar-talhoes.component.scss'],
})
export class VisualizarTalhoesComponent implements OnInit {
  offCanvasOne = new Subject<boolean>();
  offCanvasTwo = new Subject<boolean>();
  offCanvasTree = new Subject<boolean>();
  offCanvasDevices = new Subject<boolean>();
  public offCanvasArquivos = new Subject<boolean>();
  @Input() title: string;
  @Input() fieldId: string | null;
  @Output() sendGeometryData = new EventEmitter<any>();
  @Output() formPrescriptionMapData = new EventEmitter<any>();
  @Input() onEmitVoltarParaListaDeTalhoes;
  @Input() emitEventEditTalhaoById;

  errorMessage = 'global.alert.msg-unexpected-error';

  public actionType: string = 'none';

  selectedColor = '#000';

  isPolygonClosed = true;

  loading = false;

  fieldIdToBeDeleted: string = '';

  talhoes = [];

  filesUploaded = [];

  talhaoById: Field;

  imagePath;

  filteredData: any[] = [];

  talhoesById: any[];

  public months = [];
  filesToAssociate: Array<string> = [];

  public summarizedForecast = {
    icon: '',
    precipChance: '',
    relativeHumidity: '',
    windSpeed: '',
    wxPhraseLong: '',
    temperature: '',
    temperatureMax: 0,
    temperatureMin: 0,
  };

  public userLat = null;
  public userLng = null;

  public searchStr: string;

  public isLocationValid: boolean = false;

  apiData: boolean = false;

  geometry: FeatureCollection<any> = {
    features: [
      {
        geometry: {
          coordinates: [[]],
          type: 'Polygon',
        },
        properties: {},
        type: 'Feature',
      },
    ],
    type: 'FeatureCollection',
  };

  options: google.maps.MapOptions = {
    zoom: 8,
    mapTypeId: 'satellite',
    disableDefaultUI: true,
  };
  polygonsOptions: google.maps.PolygonOptions;
  vertices: google.maps.LatLngLiteral[] = [];

  public recomendationData: any;
  public ndviActivated = false;
  public functionActiveNdvi = false;
  public mapNotWorking: boolean = false;
  public echartNotWorking: boolean = false;
  public prescriptionMapDontWorking: boolean = false;
  public clickedCreateMapPrescriptions: boolean = false;

  public showFertilizanteForm: boolean = false;
  public showIrrigacaoForm: boolean = false;

  public isButtonClickable: boolean = true;

  public showDataIrrigation: boolean = false;
  public showDataFertilization: boolean = false;
  public showButtonShapefileDownload: boolean = false;

  public showButtonRecomendation: boolean = false;

  public messageErrorResponseApi: any;
  public auth: any;
  public prescriptionForm: FormGroup;
  public recomendationForm: FormGroup;
  public showSpinnerInMapPrescription: boolean = false;
  public showSpinnerInMapPrescriptionOld: boolean = false;
  public limitSizeMessageErrorMapNdvi: any;
  public hideNdviMapSection: boolean = false;
  private cloudantManager: UserFilesManager;
  updateIsPolygonClosed: Subject<boolean> = new Subject();
  fetchFieldData: Subject<boolean> = new Subject();

  userNameLogged = '';
  userRole = '';
  ROLE_USERS_ENUM = _roleEnumUserClass.ROLE_ENUM;
  ndviwidget: any;
  devices: any[];
  deviceSelected: any;
  fileName: string;
  fieldsNames: any;
  alertTermsPrescriptions: boolean = true;
  userLogged: any;
  showOffCanvasArquivos: boolean;
  fieldKey: string;
  recommendationMapArray;
  guides: any[];
  guidesArray: Array<string>;
  boundariesArray: any;
  preferences: Preferences;
  trailClass: TrailClass;
  shpFiles: Array<string>;
  baseUrl: string;
  menuFieldFileStatus = false;
  titleButtonMenuFieldFile: string = 'Abrir menu de arquivos';
  listOfFiles: Array<string>;
  shapefileReader: ShapefileReader;
  userId: string;
  private loadedField = new Subject<Field>();
  loadedField$ = this.loadedField.asObservable();
  showButton = false;

  constructor(
    public userTalhoesService: UserTalhaoListService,
    public translateService: TranslateService,
    public previsaoTempoService: PrevisaoTempoService,
    public alertService: AlertService,
    public httpClient: HttpClient,
    public router: Router,
    public automaticFarmSolutionApi: AutomaticFarmApiService,
    private formBuilder: FormBuilder,
    public authorizationService: AuthorizationService,
    public loadingAgresSpinner: AgresSpinnerService,
    public fileManagerService: FileManagerService,
    public loadingAgresSpinnerCanvas: AgresSpinnerCanvasService,
    public _location: Location,
    public readonly analyticsService: AnalyticsService,
    private readonly activeRoute: ActivatedRoute,
    public readonly nvgHistService: NvgHistService,
  ) {
    this.shapefileReader = new ShapefileReader();
    this.trailClass = new TrailClass();
    const usuarioLogado = JSON.parse(
      localStorage.getItem(AppConstants.KEYS_LOCAL_STORAGE.ISO_USUARIO),
    );
    this.userId = usuarioLogado.id;
    if (this.userId.includes('@agres.com.br')) {
      this.analyticsService.trackEvent(
        'Opened a field',
        'My Fields',
        this.userId,
      );
    }

    this.auth = authorizationService.gethandler();
    this.prescriptionForm = this.formBuilder.group({
      initialMap: [
        { value: 'EsriWorldImagery', disabled: true },
        Validators.required,
      ],
      baseMap: [{ value: 'one', disabled: true }, Validators.required],
      geographicReference: [
        { value: 'WGS84', disabled: true },
        Validators.required,
      ],
      numberZone: [
        { value: '', disabled: false },
        [Validators.required, Validators.min(0), Validators.max(5)],
      ],
      indexVegetation: [
        { value: 'NDVI', disabled: false },
        Validators.required,
      ],
      productName: [{ value: '', disabled: false }, Validators.required],
      productType: [{ value: '', disabled: false }, Validators.required],
      userFertilizer: [{ value: '', disabled: false }, Validators.required],
      strategy: [{ value: '', disabled: false }, Validators.required],
      doseDifference: [
        { value: '', disabled: false },
        [Validators.required, Validators.min(0), Validators.max(100)],
      ],
      averageLitersPerHectare: [
        { value: '', disabled: false },
        [Validators.min(0), Validators.max(9999)],
      ],
    });

    this.recomendationForm = this.formBuilder.group({});
    this.cloudantManager = new UserFilesManager(fileManagerService, httpClient);
  }

  ngAfterViewInit() {
    this.translateService
      .get('global.alert.ndvi-1')
      .subscribe((res: string) => {
        this.loadingAgresSpinner.toShow(res);
      });
  }

  ngOnInit(): void {
    this.activeRoute.params.subscribe((params) => {
      this.fieldId = params['fieldId'];

      if (this.fieldId) {
        this.openTalhaoById(this.fieldId);
        this.loadedField$.subscribe((talhao) => {
          this.talhaoById = talhao; // Atualiza talhaoById após carregamento
          this.getFieldData(); // Chama função após talhao carregado
        });
      }

      if (this.fieldId !== null && this.fieldId !== undefined) {
        this.openTalhaoById(this.fieldId);
      }
    });
    this.polygonsOptions = {
      fillColor: this.talhaoById?.color || '#ff0000',
      fillOpacity: 0.6,
    };

    this.userRole = new AuthorizationService().getLoggedUserRole();
    this.userLogged = JSON.parse(
      localStorage.getItem(AppConstants.KEYS_LOCAL_STORAGE.ISO_USUARIO),
    );

    this.userNameLogged = this.userLogged.Nome;

    if (this.userRole === 'FreeTier') {
      this.hideNdviMapSection = true;
    } else {
      this.hideNdviMapSection = false;
    }
  }

  getFieldData() {
    const cropName = this.talhaoById.name;
    const params = { metadata: true };
    const itemsPerSection = 0;
    const section = 0;
    const searchTerm = '';

    this.nvgHistService
      .getHist(itemsPerSection, section, searchTerm, params, cropName)
      .subscribe(
        (fieldData) => {
          const stringData = JSON.stringify(fieldData);
          const parseData = JSON.parse(stringData);
          const countTaskField = parseData.count;

          if (countTaskField > 0) {
            this.showButton = true;
          } else {
            this.showButton = false;
          }
        },
        (error) => {
          console.error(error);
        },
      );
  }

  showIrrigacao(): void {
    this.translateService
      .get('global.alert.development-text')
      .subscribe((res: string) => {
        this.alertService.info(res);
      });
  }

  unzipFiles(arquivoZip: File, newFileName): Promise<Array<File>> {
    const promisses: Array<Promise<File>> = [];
    return new Promise((resolve, reject) => {
      let jszip = new JSZip();
      jszip.loadAsync(arquivoZip).then(
        (zip) => {
          Object.keys(zip.files).forEach((filename) => {
            if (!filename.endsWith('.cpg') && !filename.endsWith('.prj')) {
              promisses.push(
                new Promise<File>((resolve, reject) => {
                  zip.files[filename].async('blob').then(
                    (blobContent) => {
                      const fileExtension = this.getFileExtension(filename);
                      const fullName = newFileName + '.' + fileExtension;
                      resolve(
                        new File([blobContent], fullName, {
                          type: blobContent.type,
                        }),
                      );
                    },
                    (error) => {
                      reject(this.errorMessage);
                    },
                  );
                }),
              );
            }
          });
          resolve(Promise.all(promisses));
        },
        (error) => {
          reject(this.errorMessage);
        },
      );
    });
  }

  getFileExtension(filename: string) {
    return filename.split('.').pop();
  }

  uploadFilesToCos(fileList: Array<File>, fieldName: string) {
    const promisses: Array<Promise<Array<string> | string>> = [];
    let baseUrl: string = '';
    const fileName: Array<string> = [];
    return new Promise((resolve, reject) => {
      fileList.forEach((file) => {
        promisses.push(
          new Promise((resolve) => {
            const fileExtension = this.getFileExtension(file.name);
            const fullName = fieldName + '.' + fileExtension;
            fileName.push(fullName);
          }),
        );
      });
      resolve(
        Promise.all(promisses).then(
          (promisses) => {
            return [
              fileName,
              baseUrl.substring(0, baseUrl.lastIndexOf('/') + 1),
            ];
          },
          (error) => {
            reject(this.errorMessage);
          },
        ),
      );
    });
  }

  feedbackToUser(message: string) {
    this.translateService.get(message).subscribe((res: string) => {
      this.alertService.info(res);
    });
  }

  async openOffCanvasDevices() {
    this.translateService.get('global.loading.map-wait').subscribe((res) => {
      this.loadingAgresSpinnerCanvas.toShow(res);
    });
    const talhao = this.talhaoById.name
      .replace(/\s+/g, '_')
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '');

    this.fileManagerService
      .getById(this.userLogged.id)
      .subscribe(({ response }) => {
        if (response.files.fields[this.fieldId]) {
          const fileName = talhao + '.shp';
          const files = response.files.fields[this.fieldId].filter(
            (file) => file === fileName,
          );
          if (files.length > 0) {
            const iterations = 10000;
            for (let i = 1; i < iterations; i++) {
              if (
                !response.files.fields[this.fieldId].includes(
                  `${talhao}_(${i}).shp`,
                )
              ) {
                this.fileName = `${talhao}_(${i})`;
                this.sendMapPrescription();
                break;
              }
            }
          } else {
            this.fileName = talhao;
            this.sendMapPrescription();
          }
        }
      });
  }

  async sendMapPrescription() {
    const geometryData = this.geometry;
    const talhao = this.fileName
      .replace(/\s+/g, '_')
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '');

    this.automaticFarmSolutionApi
      .generateShapefile(geometryData, this.fieldId)
      .then((shapefile) => {
        const zip = new File([shapefile.body], talhao + '.zip', {
          type: shapefile.body.type,
        });

        this.unzipFiles(shapefile.body, talhao).then(
          (filesToUpload) => {
            filesToUpload.push(zip);
            this.createArray(filesToUpload);
            this.cloudantManager
              .saveDataAndUploadUserFiles(
                this.userLogged.id,
                filesToUpload,
                'fields',
                this.fieldId,
              )
              .subscribe(
                () => {
                  this.feedBackToUser('global.alert.send-success', 'success');
                  this.loadingAgresSpinnerCanvas.toHide();
                  this.cloudantManager.getUserFiles(this.userLogged).subscribe(
                    () => {
                      this.loadingAgresSpinner.toHide();
                      this.offCanvasDevices.next(true);
                    },
                    () => {
                      this.loadingAgresSpinner.toHide();
                    },
                  );
                },
                () => {},
              );
          },
          (error) => {
            this.feedbackToUser(error);
          },
        );
      });
  }

  createArray(filesArray: Array<File>) {
    this.filesToAssociate = [];
    filesArray.forEach((file) => {
      if (!file.name.endsWith('.zip')) {
        this.filesToAssociate.push(file.name);
      }
    });
    this.fileName = '';
  }

  async onSubmit() {
    if (this.prescriptionForm.valid) {
      this.showSpinnerInMapPrescription = true;
      this.showSpinnerInMapPrescriptionOld = true;
      await this.sendDataFormToGenerateUrlMapPrescriptionAndDownloadShapfile();
      await this.postDataToPrescriptionNdviMap();
      this.updateQuantityOfGeneratedMaps();
      this.translateService.get('global.loading.map-wait').subscribe((res) => {
        this.loadingAgresSpinnerCanvas.toShow(res);
      });
    } else {
      this.translateService
        .get('global.validate.form-invalid')
        .subscribe((translatedErrorMessage) => {
          this.alertService.error(translatedErrorMessage, TimeoutEnum.Medium);
        });
    }
  }

  openFormGeneratePrescriptionMap(): void {
    this.offCanvasOne.next(true);
    this.showSpinnerInMapPrescriptionOld = false;
    this.showFertilizanteForm = true;
    this.showIrrigacaoForm = false;
    this.showButtonRecomendation = true;
    this.showDataFertilization = false;
    this.showDataIrrigation = false;
    this.sendGeometryData.emit(this.geometry);
    if (!this.userId.includes('@agres.com.br')) {
      this.analyticsService.trackEvent(
        'Click button prescription map',
        'My Fields',
        this.userId,
      );
    }
  }

  onSubmitRecomendationForm() {
    if (this.recomendationForm.valid) {
      this.sendDataRecomendationFormToService();
      setTimeout(() => {
        this.postDataToRecomendationData();
      }, 1000);
    } else {
      this.translateService
        .get('global.validate.form-invalid')
        .subscribe((translatedErrorMessage) => {
          this.alertService.error(translatedErrorMessage, TimeoutEnum.Medium);
        });
    }
  }

  sendDataFormToGenerateUrlMapPrescriptionAndDownloadShapfile() {
    const dataFilledInForm = this.prescriptionForm.value;
    this.automaticFarmSolutionApi.dadosDoFormularioMapaDePrescricaoRecebidos =
      dataFilledInForm;
  }

  sendDataRecomendationFormToService() {
    const dataFilledInRecomendationForm = this.recomendationForm.value;
    this.automaticFarmSolutionApi.dadosDoFormularioMapaDeRecomendationRecebidos =
      dataFilledInRecomendationForm;
  }

  setActionType(actionType: string) {
    this.actionType = actionType;
    this.postDataToGetWidgetMapNdvi(this.geometry);
    if (!this.functionActiveNdvi) {
      this.postDataToEchartsNdviMap(this.geometry);
    }
  }

  postDataToGetWidgetMapNdvi(geometry: any) {
    const statusMessages = {
      400: 'global.validate.error-limit-size-map-ndvi',
      500: 'global.validate.error-map-ndvi-not-generated',
    };

    this.translateService
      .get('global.alert.ndvi-1')
      .subscribe((res: string) => {
        this.loadingAgresSpinner.toShow(res);
      });
    this.automaticFarmSolutionApi
      .getWidgetMapNdvi(geometry, this.fieldId, this.actionType)
      .subscribe(
        (response) => {
          if (this.functionActiveNdvi) {
            this.salvePolygons();
            if (!this.userId.includes('@agres.com.br')) {
              this.analyticsService.trackEvent(
                'NDVI Map Generated Successfully',
                'My Fields',
                this.userId,
              );
            }
          } else {
            this.loadingAgresSpinner.toHide();
            const iframe: any = document.getElementById(
              'iframeWidgetMapNdvi',
            ) as HTMLIFrameElement;
            iframe.srcdoc = response.data;
            this.ndviwidget = response.data;
            if (!this.userId.includes('@agres.com.br')) {
              this.analyticsService.trackEvent(
                'View field with NDVI map',
                'My Fields',
                this.userId,
              );
            }
          }
        },
        (error) => {
          if (this.functionActiveNdvi) {
            this.salvePolygons();
          } else {
            this.limitSizeMessageErrorMapNdvi =
              statusMessages[error.response.status] || 'Erro desconhecido';

            switch (error.response.status) {
              case 400:
                this.loadingAgresSpinner.toHide();
                this.mapNotWorking = true;
                this.translateService.get(this.limitSizeMessageErrorMapNdvi);
                break;

              case 401:
                this.loadingAgresSpinner.toHide();
                this.mapNotWorking = true;
                break;

              case 402:
                this.loadingAgresSpinner.toHide();
                this.mapNotWorking = true;
                break;

              case 500:
                if (this.userRole === 'Admin') {
                  this.loadingAgresSpinner.toHide();
                  this.mapNotWorking = true;
                  this.translateService.get(this.limitSizeMessageErrorMapNdvi);
                }
                break;

              default:
                this.loadingAgresSpinner.toHide();
                this.limitSizeMessageErrorMapNdvi =
                  statusMessages[error.response.status] || 'Erro desconhecido';
                this.mapNotWorking = true;
                this.translateService
                  .get('global.validate.error-map-ndvi-not-generated')
                  .subscribe((res: string) => {
                    this.alertService.error(res, TimeoutEnum.Medium);
                  });
                break;
            }
          }
          if (!this.userId.includes('@agres.com.br')) {
            this.analyticsService.trackEvent(
              'Error map ndvi not generated',
              this.userId,
              error.response.status,
            );
          }
        },
      );
  }

  postDataToEchartsNdviMap(geometry) {
    this.automaticFarmSolutionApi
      .getEchartsNdvi(geometry, this.fieldId, this.actionType)
      .subscribe(
        (response) => {
          const iframe: any = document.getElementById(
            'iframeEchatsMapNdvi',
          ) as HTMLIFrameElement;
          iframe.srcdoc = response.data;
        },
        () => {
          this.loadingAgresSpinner.toHide();
          this.echartNotWorking = true;
        },
      );
  }

  postDataToPrescriptionNdviMap() {
    if (this.isButtonClickable) {
      this.isButtonClickable = false;
      this.automaticFarmSolutionApi
        .getWidgetPrescriptionMap(this.geometry, this.fieldId)
        .subscribe(
          (response) => {
            const iframe: any = document.getElementById(
              'iframePrescriptionMapNdvi',
            ) as HTMLIFrameElement;
            iframe.srcdoc = response.data;
            const iframe2: any = document.getElementById(
              'iframeWidgetMapNdvi2',
            ) as HTMLIFrameElement;
            iframe2.srcdoc = this.ndviwidget;
            this.showDataIrrigation = true;
            this.showButtonShapefileDownload = true;
            this.showDataFertilization = true;
            this.showSpinnerInMapPrescription = false;
            this.showSpinnerInMapPrescriptionOld = true;
            this.loadingAgresSpinnerCanvas.toHide();
            if (!this.userId.includes('@agres.com.br')) {
              this.analyticsService.trackEvent(
                'Prescription_Map_Generated_Successfully',
                'My Fields',
                this.userId,
              );
            }
          },
          (error) => {
            this.prescriptionMapDontWorking = true;
            this.translateService
              .get('global.alert.prescription-map-not-available')
              .subscribe((translatedErrorMessage) => {
                this.alertService.error(
                  translatedErrorMessage,
                  TimeoutEnum.Medium,
                );
              });
            console.error(
              'Erro request prescription map API:',
              error.response.status,
            );
            if (!this.userId.includes('@agres.com.br')) {
              this.analyticsService.trackEvent(
                'Error map prescription not generated',
                'My Fields',
                this.userId,
              );
            }
          },
        );
      setTimeout(() => {
        this.isButtonClickable = true;
      }, TimeoutEnum.Medium);
    }
  }

  async downloadShapfileMapPrescription() {
    this.feedBackToUser('global.info-download-files', 'info');
    if (this.isButtonClickable) {
      this.isButtonClickable = false;
      const geometryData = this.geometry;
      const fieldName = this.talhaoById.name;
      this.automaticFarmSolutionApi.downloadShapefile(
        geometryData,
        fieldName,
        this.fieldId,
      );
      if (!this.userId.includes('@agres.com.br')) {
        this.analyticsService.trackEvent(
          'Click download SHP map prescription',
          'My Fields',
          this.userId,
        );
      }
      setTimeout(() => {
        this.isButtonClickable = true;
      }, 3000);
    }
  }

  private feedBackToUser(message: string, type: string) {
    this.translateService.get(message).subscribe((res: string) => {
      switch (type) {
        case 'success':
          this.alertService.success(res);
          break;
        case 'error':
          this.alertService.error(res);
          break;
        case 'info':
          this.alertService.info(res);
          break;
        default:
          this.alertService.info(res);
          break;
      }
    });
  }

  postDataToRecomendationData() {
    this.translateService
      .get('global.alert.alert-prescription-map')
      .subscribe((res: string) => {
        this.loadingAgresSpinner.toShow(res);
      });
    this.automaticFarmSolutionApi.getRecomendationData().subscribe(
      (response) => {
        this.recomendationData = response.data;
        this.showDataIrrigation = true;
        this.showDataFertilization = true;
        this.loadingAgresSpinner.toHide();
      },
      () => {
        this.loadingAgresSpinner.toHide();
        this.prescriptionMapDontWorking = true;
        this.translateService
          .get('global.alert.not-available-data-prescription-map')
          .subscribe((translatedErrorMessage) => {
            this.alertService.error(translatedErrorMessage, TimeoutEnum.Medium);
          });
      },
    );
  }

  goBack() {
    if (!this.userId.includes('@agres.com.br')) {
      this.analyticsService.trackEvent(
        'Click on back map prescription',
        'My Fields',
        this.userId,
      );
    }
    this._location.back();
  }

  openOffCanvasOne() {
    this.offCanvasOne.next(true);
  }

  openOffCanvasTwo() {
    this.offCanvasTwo.next(true);
    this.sendGeometryData.emit(this.geometry);
  }

  disagreePrescription() {
    if (!this.userId.includes('@agres.com.br')) {
      this.analyticsService.trackEvent(
        'Click on disagree map prescription',
        'My Fields',
        this.userId,
      );
    }
    this.offCanvasOne.next(false);
  }

  agreePrescription() {
    this.alertTermsPrescriptions = false;
    this.clickedCreateMapPrescriptions = true;

    if (this.clickedCreateMapPrescriptions) {
      if (!this.userId.includes('@agres.com.br')) {
        this.analyticsService.trackEvent(
          'Click on agree map prescription',
          'My Fields',
          this.userId,
        );
      }
    }
  }

  return(): void {
    this.offCanvasOne.next(false);
  }

  dashboard() {
    this.router.navigate(['/']);
  }

  openOffCanvasRecomendationFertilizatino() {
    this.offCanvasTree.next(true);
  }

  onDeviceChange(event): void {
    this.deviceSelected = event.target.value;
  }

  openTalhaoById(id: string): void {
    this.userTalhoesService.getTalhaoId(id).subscribe((talhoes: Field) => {
      const talhao = talhoes;
      this.talhaoById = talhao;
      this.getFieldKey();
      this.getUserFiles();
      this.loadForecast(
        talhao.geometry.features[0].geometry.coordinates[0][0][1],
        talhao.geometry.features[0].geometry.coordinates[0][0][0],
      );
      this.selectedColor = talhao.color;
      if (talhao.staticMapImage === null) {
        talhao.staticMapImage =
          '../../../../../assets/images/gallery/thumbnail-talhaoImageById.png';
      } else {
        talhao.staticMapImage =
          'data:image/jpg;base64,' + talhao.staticMapImage;
      }
      talhao.totalArea = (+talhao.totalArea).toFixed(3);
      this.geometry = this.talhaoById.geometry;
      this.showBoundaries();
      this.ndviActivated = this.talhaoById.ndvi;
      if (this.ndviActivated) {
        if (this.geometry) {
          this.apiData = true;
          this.setActionType('none');
        }
      } else if (this.geometry) {
        this.apiData = true;
        this.loadingAgresSpinner.toHide();
      }
      // Emitir o talhao carregado
      this.loadedField.next(talhao);
    });
  }
  getUserFiles() {
    this.cloudantManager
      .getUserFiles(this.userLogged.id)
      .subscribe(({ response }) => {
        if (response?.files?.fields) {
          const files = response.files.fields.hasOwnProperty(this.fieldId)
            ? response.files.fields[this.fieldId]
            : undefined;
          if (files) {
            this.shpFiles = files;
          } else {
            this.listOfFiles = [];
          }
        }
      });
  }

  getFieldKey() {
    this.fieldKey = this.talhaoById.name
      .replace(/\s+/g, '_')
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '')
      .toLowerCase();
  }

  updateQuantityOfGeneratedMaps() {
    let quantityMaps = [];
    this.userTalhoesService
      .getTalhaoId(this.fieldId)
      .subscribe((talhoes: Field) => {
        if (!talhoes.prescriptionMapQuantity) {
          quantityMaps.push(new Date().toISOString());
        } else {
          quantityMaps = talhoes.prescriptionMapQuantity;
          quantityMaps.push(new Date().toISOString());
        }

        this.userTalhoesService.putTalhao({
          data: {
            geometry: talhoes.geometry,
            name: talhoes.name,
            id: this.fieldId,
            color: talhoes.color,
            description: talhoes.description,
            ndvi: talhoes.ndvi,
            ndvi_date: talhoes.ndvi_date,
            city: talhoes.city,
            prescriptionMapQuantity: quantityMaps,
          },
        });
      });
  }

  activeNdvi() {
    if (!this.userId.includes('@agres.com.br')) {
      this.analyticsService.trackEvent(
        'Click on activate NDVI',
        'My Fields',
        this.userId,
      );
    }
    this.functionActiveNdvi = true;
    this.setActionType('new');
  }

  salvePolygons() {
    this.userTalhoesService
      .putTalhao({
        data: {
          geometry: this.geometry,
          name: this.talhaoById.name,
          id: this.fieldId,
          color: this.selectedColor,
          description: this.talhaoById.description,
          ndvi: true,
          ndvi_date: new Date().toISOString(),
          city: this.talhaoById.city,
        },
      })

      .subscribe(
        (talhoes) => {
          if (talhoes) {
            this.translateService
              .get('fields.ndvi-activated')
              .subscribe((res: string) => {
                this.alertService.info(res, TimeoutEnum.VeryLong);
              });
            window.location.reload();
          } else {
            this.translateService
              .get('fields.error-activate-ndvi')
              .subscribe((res: string) => {
                this.alertService.error(res, TimeoutEnum.VeryLong);
                window.location.reload();
              });
          }
        },
        () => {
          this.translateService
            .get('fields.error-activate-ndvi')
            .subscribe((res: string) => {
              this.alertService.error(res, TimeoutEnum.VeryLong);
              window.location.reload();
            });
        },
      );
  }

  openOffCanvasArquivos() {
    this.offCanvasArquivos.next(true);
    if (!this.userId.includes('@agres.com.br')) {
      this.analyticsService.trackEvent(
        'Click button my files',
        'My Fields',
        this.userId,
      );
    }
  }

  loadForecast(lat, lng) {
    this.previsaoTempoService
      .getTimeNextThreeDays(lat, lng, this.translateService.currentLang)
      .subscribe(
        (dailyWeatherForecast) => {
          this.summarizedForecast = <WeatherForecast>(
            this.formatSummarizedForecast(dailyWeatherForecast[0])
          );
        },
        () => {
          this.translateService
            .get('error.500.error-occurred-try-again')
            .subscribe((res: string) => {
              this.alertService.error(res);
            });
        },
      );
  }

  formatSummarizedForecast(dailyWeatherForecast): WeatherForecast {
    return {
      icon: dailyWeatherForecast.weather[0].icon,
      precipChance: dailyWeatherForecast.clouds,
      relativeHumidity: dailyWeatherForecast.humidity,
      windSpeed: dailyWeatherForecast.wind_speed,
      wxPhraseLong:
        dailyWeatherForecast.weather[0].description.charAt(0).toUpperCase() +
        dailyWeatherForecast.weather[0].description.slice(1),
      temperature: dailyWeatherForecast.temp.day,
      temperatureMax: Math.round(dailyWeatherForecast.temp.max),
      temperatureMin: Math.round(dailyWeatherForecast.temp.min),
    };
  }

  createTarefa(): void {
    this.router.navigate([`/my-fields/criar-tarefa/${this.fieldId}`]);
    if (!this.userId.includes('@agres.com.br')) {
      this.analyticsService.trackEvent(
        'Click button tasks fields',
        'My Fields',
        this.userId,
      );
    }
  }

  openTarefaDoTalhaoById(): void {
    if (!this.userId.includes('@agres.com.br')) {
      this.analyticsService.trackEvent(
        'Click button tasks fields',
        'My Fields',
        this.userId,
      );
    }
    this.router.navigate([
      `/my-fields/gerenciamento-de-talhoes/listagem-tarefa-talhao/${this.fieldId}`,
    ]);
  }

  onUpdatePolygons() {
    return (geometry, polygonStatus: boolean, area?: number) => {
      this.isPolygonClosed = polygonStatus;
      this.geometry = geometry;
    };
  }

  async showShapefiles(array: {
    file: string;
    type: string;
    operation: string;
  }) {
    if (array.type === 'boundaries') {
      if (array.operation === 'clear') {
        this.boundariesArray = [];
      } else {
        this.showBoundaries();
      }
    } else {
      const shpFileName = array.file;
      let shpFile = await this.downloadFiles(shpFileName);
      const shp = await this.shapefileReader.openShapefile(shpFile);

      if (array.type === 'recommendation_files') {
        if (array.operation === 'clear') {
          this.recommendationMapArray = null;
        } else {
          const dbfFileName = this.shpFiles[array.type].filter(
            (file) => shpFileName.split('.')[0] + '.dbf' === file,
          )[0];
          if (dbfFileName) {
            const dbfFile = await this.downloadFiles(dbfFileName);
            const dbf = await this.shapefileReader.openDbfFile(dbfFile);
            const shapefileCoordinate =
              this.shapefileReader.getCoordinatesFromShapefile(shp);
            const referenceCoordinate = {
              lat: this.talhaoById.geometry.features[0].geometry
                .coordinates[0][0][1],
              lng: this.talhaoById.geometry.features[0].geometry
                .coordinates[0][0][0],
            };
            this.showRecommendationMap(
              shp,
              dbf,
              shapefileCoordinate,
              referenceCoordinate,
            );
          }
        }
      } else if (array.type === 'guides_files') {
        if (array.operation === 'clear') {
          this.guidesArray = [];
        } else {
          this.showGuides(shp);
        }
      }
    }
  }

  showBoundaries() {
    this.boundariesArray = [];
    let coordinates = [];

    this.geometry.features[0].geometry.coordinates.forEach((position) => {
      coordinates.push(
        position.map((item) => {
          return { lat: item[1], lng: item[0] };
        }),
      );
    });
    this.boundariesArray.push({
      paths: coordinates,
      color: this.selectedColor,
    });
  }
  showGuides(shp) {
    this.guides = [];
    shp.forEach((item) => {
      if (item.geometry.type === 'MultilineString') {
        item.geometry.coordinates.forEach((guide) =>
          this.guides.push({
            paths: guide.map((item) => {
              return { lat: item[1], lng: item[0] };
            }),
          }),
        );
      } else if (item.geometry.type === 'LineString') {
        this.guides.push({
          paths: item.geometry.coordinates.map((item) => {
            return { lat: item[1], lng: item[0] };
          }),
        });
      }
    });

    this.guidesArray = this.guides;
  }

  showRecommendationMap(
    shp,
    dbf,
    shapefileCoordinate,
    referenceCoordinate,
  ): void {
    if (
      !this.shapefileReader.isReferenceCoordinateFarFromShapefile(
        referenceCoordinate,
        shapefileCoordinate,
      )
    ) {
      const shapefile = this.shapefileReader.linkDBFDataToShapefileData(
        shp,
        dbf,
      );
      const property = this.getProperty(dbf);
      this.encontrarValoresRepresentativos(dbf, property);
      this.createRecommendedTrail(shapefile, property);
    } else {
      this.translateService
        .get('tasks.recommendation_map.error.map-too-distant-from-reference')
        .subscribe((translate) => {
          this.alertService.warning(translate);
        });
    }
  }

  encontrarValoresRepresentativos(array, property) {
    const arr = array.map((value) => value[property]);
    const n = arr.length;
    if (n === 0) return [];

    const frequencia = {};
    arr.forEach((num) => {
      frequencia[num] = (frequencia[num] || 0) + 1;
    });

    const valoresPorFrequencia = Object.keys(frequencia)
      .map(Number)
      .sort((a, b) => frequencia[b] - frequencia[a]);

    const media = arr.reduce((sum, num) => sum + num, 0) / n;

    const desvioPadrao = Math.sqrt(
      arr.reduce((sum, num) => sum + Math.pow(num - media, 2), 0) / n,
    );

    const valoresPorProximidadeMedia = arr
      .slice()
      .sort((a, b) => Math.abs(a - media) - Math.abs(b - media));

    const topValores = new Set([
      ...valoresPorFrequencia.slice(0, 5),
      ...valoresPorProximidadeMedia.slice(0, 5),
    ]);

    this.subtitleArrayToPreferences(Array.from(topValores).slice(0, 5));
  }

  subtitleArrayToPreferences(arraySubtitle: number[]) {
    this.preferences = <Preferences>{
      red: arraySubtitle[0],
      orange: arraySubtitle[1],
      yellow: arraySubtitle[2],
      lemonGreen: arraySubtitle[3],
      green: arraySubtitle[4],
    };
  }

  getProperty(file): string {
    let property: string;

    const keys = Object.keys(file[0]);

    if (keys.length > 1) {
      keys.forEach((key) => {
        if (typeof file[0][key] === 'number') {
          property = key;
        }
      });
    } else {
      property = keys[0];
    }
    return property;
  }

  async downloadFiles(fileName: string): Promise<File> {
    const file = await this.cloudantManager.getFiles(
      this.userLogged.id,
      [fileName],
      'fields',
      this.fieldId,
    );

    return file;
  }

  createRecommendedTrail(shapefileArray, propertyToUse) {
    const mapFilterByProperty = shapefileArray.filter(
      (polygon) => polygon.properties[propertyToUse],
    );
    const clearTrail = (trailToClear) => {
      if (trailToClear) trailToClear.length = 0;
    };
    const customTrail = [];
    mapFilterByProperty.forEach((polygon) => {
      const convertedPolygon = this._convertTurfPolygonToCustomWithColor(
        polygon,
        propertyToUse,
      );
      customTrail.push(convertedPolygon);
    });
    this.recommendationMapArray = customTrail;
  }

  _convertTurfPolygonToCustomWithColor(turfPolygon, propertyToUse) {
    const color = this.trailClass.trailColorPref(
      'false',
      undefined,
      turfPolygon.properties[propertyToUse],
      this.preferences,
      'desc',
    ).trailColorScale;

    const customPolygon = {
      color: color,
      paths: [],
      valueOperation: turfPolygon.properties[propertyToUse],
    };

    if (turfPolygon.geometry.type === 'MultiPolygon') {
      customPolygon.paths = turfPolygon.geometry.coordinates.map((polygon) => {
        const lastIndex = polygon[0].length;
        return polygon[0].map((coord, index) => {
          if (index < lastIndex) return { lat: coord[1], lng: coord[0] };
        });
      });
    } else if (turfPolygon.geometry.type === 'Polygon') {
      const lastIndex = turfPolygon.geometry.coordinates[0].length;
      customPolygon.paths = turfPolygon.geometry.coordinates[0].map(
        (coord, index) => {
          if (index < lastIndex) return { lat: coord[1], lng: coord[0] };
        },
      );
    }

    return customPolygon;
  }

  openSidebarMenuFieldFiles() {
    this.menuFieldFileStatus = !this.menuFieldFileStatus;
    if (this.menuFieldFileStatus) {
      this.titleButtonMenuFieldFile = 'fields.close-files-panel';
    } else {
      this.titleButtonMenuFieldFile = 'fields.open-files-panel';
    }
  }

  navigateToTaskListWithFieldName() {
    this.router.navigate(['/gestao-operacao/gestao-arquivo'], {
      queryParams: { fieldName: this.talhaoById.name },
    });
  }

  navigateToEditFieldPage() {
    this.router.navigate([`/my-fields/edit/${this.fieldId}`]);
  }
}
