import {
  Component,
  OnInit,
  Input,
  EventEmitter,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { IndexdbService } from 'app/core/services/indexdb.service';
import { ReportService } from 'app/core/services/report.service';

interface RasterMapImagesB64 {
  backgroundImages: Array<string>;
  rasterImages: Array<string>;
  singleBackgroundImage: string;
}

interface ImageData {
  imagem: {
    rasterImage: string;
    backgroundImage: string;
  };
}

@Component({
  selector: 'app-gestao-arquivo-card-image',
  templateUrl: 'gestao-arquivo-card-image.component.html',
  styleUrls: ['gestao-arquivo-card-image.component.scss'],
})
export class GestaoArquivoCardImageComponent implements OnInit {
  @Input() workId: string;
  @Input() statusChangeEventEmitter: EventEmitter<string>;
  @Input() imageData: ImageData;
  public defaultBackgroundImage = './assets/images/default/no-photo.jpg';
  public spinner = './assets/images/default/no-photo.jpg';
  public showSpinner: boolean = true;
  public backgroundImage = '';
  public rasterImage = '';
  public indexDBBackgroundImages: string[] = [];
  public indexDBRasterImages: string[] = [];

  rasterImageB64IndexDB;
  bkgrndImageB64IndexDB;

  imagensDoIndexDataBase;

  constructor(
    public reportService: ReportService,
    private indexdbService: IndexdbService,
    public translateService: TranslateService,
  ) {}

  ngOnInit(): void {
    this.statusChangeEventEmitter.subscribe((status) => {
      if (status === 'completed') {
        this.getImageThumbails();
      }
    });

    this.getImageThumbails();
  }

  private getImageThumbails() {
    this.indexdbService
      .getDataIndexDBById(this.workId)
      .then((res: ImageData | null) => {
        if (res?.imagem.backgroundImage && res?.imagem.rasterImage) {
          this.rasterImage = res?.imagem.rasterImage;
          this.backgroundImage = res?.imagem.backgroundImage;
        } else {
          if (this.workId) {
            this.reportService
              .getImageThumbnails(this.workId)
              .subscribe((data: RasterMapImagesB64) => {
                if (data.singleBackgroundImage !== '') {
                  this.backgroundImage = data.singleBackgroundImage;
                } else if (data.backgroundImages[0]) {
                  this.backgroundImage = data.backgroundImages[0];
                }

                if (data.rasterImages.length > 0) {
                  this.rasterImage = data.rasterImages[0];
                }

                if (this.backgroundImage)
                  this.backgroundImage =
                    'data:image/png;base64,' + this.backgroundImage;
                if (this.rasterImage)
                  this.rasterImage =
                    'data:image/png;base64,' + this.rasterImage;

                const imageObjects = {
                  rasterImage: `${this.rasterImage}`,
                  backgroundImage: `${this.backgroundImage}`,
                };
                this.indexdbService
                  .saveImage(this.workId, imageObjects)
                  .then(() => {})
                  .catch((error) => {
                    console.error(error);
                  });
              });
          }
        }
      });
  }
}
