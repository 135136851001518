import { HttpClient } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AppConstants } from 'app/app.constants';
import { FileManagerService } from 'app/core/services/file-manager.service';
import { AgresSpinnerCanvasService } from 'app/shared/components/agres-spinner-canvas/agres-spinner-canvas.service';
import { AlertService } from 'app/shared/components/alert/alert.service';
import { UserFilesManager } from 'app/utils/user-files-manager';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-field-files',
  templateUrl: './field-files.component.html',
  styleUrls: ['./field-files.component.scss'],
})
export class FieldFilesComponent implements OnInit {
  @Input() fieldName: string;
  userLogged: string;
  listOfFiles: Array<string>;
  baseUrl: string;
  fieldKey: string;
  public offCanvasUploadArquivos = new Subject<boolean>();
  public offCanvasDevices = new Subject<boolean>();
  fileName: string;
  filesToAssociate: Array<string> = [];
  private cloudantManager: UserFilesManager;

  constructor(
    public fileManagerService: FileManagerService,
    public translateService: TranslateService,
    public httpClient: HttpClient,
    public alertService: AlertService,
    public loadingAgresSpinnerCanvas: AgresSpinnerCanvasService,
  ) {
    this.cloudantManager = new UserFilesManager(fileManagerService, httpClient);
  }

  ngOnInit() {
    this.userLogged = JSON.parse(
      localStorage.getItem(AppConstants.KEYS_LOCAL_STORAGE.ISO_USUARIO),
    ).id;
    this.getFieldKey();
    this.getFiles();
    this.translateService.get('global.loading.map-wait').subscribe((res) => {
      this.loadingAgresSpinnerCanvas.toShow(res);
    });
  }

  getFiles() {
    this.cloudantManager.getUserFiles(this.userLogged).subscribe(
      ({ response }) => {
        if (response?.files?.fields) {
          this.listOfFiles = response.files.fields.hasOwnProperty(this.fieldKey)
            ? response.files.fields[this.fieldKey]
            : undefined;
        }
        this.loadingAgresSpinnerCanvas.toHide();
      },
      () => {
        this.loadingAgresSpinnerCanvas.toHide();
      },
    );
  }

  getFieldKey() {
    this.fieldKey = this.fieldName
      .replace(/\s+/g, '_')
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '')
      .toLowerCase();
  }

  updateSelectedItem(index: number, checked: boolean) {
    if (!checked) {
      this.filesToAssociate = this.filesToAssociate.filter(
        (item) => item !== this.listOfFiles[index],
      );
    } else {
      this.filesToAssociate.push(this.listOfFiles[index]);
    }
  }

  openOffCanvasUpload() {
    this.offCanvasUploadArquivos.next(true);
  }

  downloadFile() {
    this.feedBackToUser('global.info-download-files', 'info');
    this.cloudantManager.downloadUserFiles(
      this.userLogged,
      this.filesToAssociate,
      'fields',
      this.fieldKey,
    );
  }

  private feedBackToUser(message: string, type: string) {
    this.translateService.get(message).subscribe((res: string) => {
      switch (type) {
        case 'success':
          this.alertService.success(res);
          break;
        case 'error':
          this.alertService.error(res);
          break;
        case 'info':
          this.alertService.info(res);
          break;
        default:
          this.alertService.info(res);
          break;
      }
    });
  }

  async openOffCanvasSelecteds(): Promise<void> {
    this.offCanvasDevices.next(true);
    this.fileName = '';
  }

  openOffCanvasDevices() {
    this.offCanvasDevices.next(true);
    this.fileName = '';
  }
}
