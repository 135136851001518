import { catchError, map } from 'rxjs/operators';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { ServiceBase } from './service.base';

@Injectable({
  providedIn: 'root',
})
export class CosService extends ServiceBase {
  constructor(private http: HttpClient) {
    super();
  }

  getPressignUrl() {
    const url = this.UrlServiceV1 + 'usuario/';

    return this.http
      .post(url, { getPresignedUrl: true }, this.obterAuthHeader())
      .pipe(
        map(this.extractData<Record<string, any>>),
        catchError(this.serviceError),
      );
  }
}
