import {
  HttpClient,
  HttpHeaders,
  HttpParams,
  HttpUrlEncodingCodec,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { User } from '../../shared/models/user';
import { BehaviorSubject, Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { ServiceBase } from './service.base';
import { environment } from 'environments/environment';

@Injectable()
export class UserService extends ServiceBase {
  public usuarioLogado;
  public dataSource = new BehaviorSubject<any>([]);
  currentData = this.dataSource.asObservable();

  constructor(public http: HttpClient) {
    super();

    this.usuarioLogado = this.obterUsuarioLogado();
  }

  private getOcpAPIMSubscriptionKey() {
    if (environment.environmentName !== 'LOCAL') {
      return {
        headers: new HttpHeaders({
          'Ocp-Apim-Subscription-Key': this.subscriptionKey,
        }),
      };
    } else {
      return {};
    }
  }

  changeData(data: any[] | User) {
    if (!(data instanceof Array)) data = [data];
    this.dataSource.next(data);
  }

  getUsuario(): Observable<any> {
    this.usuarioLogado = this.obterUsuarioLogado();
    return this.http
      .get(
        `${this.UrlServiceV1}user?id=${this.usuarioLogado.id}`,
        this.obterAuthHeader(),
      )
      .pipe(map(this.extractData<User>), catchError(this.serviceError));
  }

  getUsuarioByEmail(email): Observable<any> {
    return this.http
      .get(`${this.UrlServiceV1}user?id=${email}`, this.obterAuthHeader())
      .pipe(map(this.extractData), catchError(this.serviceError));
  }

  getUserEmail(): Observable<any> {
    this.usuarioLogado = this.obterUsuarioLogado();
    const params = new HttpParams({
      encoder: new HttpUrlEncodingCodec(),
    }).set('getEmail', 'true');

    return this.http
      .get(this.UrlServiceV1 + 'user/', {
        ...this.obterAuthHeader(),
        params,
      })
      .pipe(map(this.extractData), catchError(this.serviceError));
  }

  getProfileImage(url: string) {
    return this.http.get(url, { responseType: 'blob' }).pipe(this.extractData);
  }

  getUsuarios(): Observable<User[]> {
    return this.http
      .get<User[]>(this.UrlServiceV1 + 'user', this.obterAuthHeader())
      .pipe(catchError(this.serviceError));
  }

  registerUsuario(user): Observable<any> {
    return this.http
      .post(`${this.UrlServiceV1}user?id=${user.id}`, this.obterAuthHeader())
      .pipe(map(this.extractData), catchError(this.serviceError));
  }

  patchUser(user: Partial<User>, options?: (keyof User)[]): Observable<any> {
    return this.http
      .patch(this.UrlServiceV1 + 'user/', user, {
        params: { options },
        ...this.obterAuthHeader(),
      })
      .pipe(
        map(this.extractData<Record<any, string>>),
        catchError(this.serviceError),
      );
  }

  saveUsuarioFreeTier(user): Observable<any> {
    return this.http
      .post(
        this.UrlServiceOauthV1 + 'signup',
        user,
        this.getOcpAPIMSubscriptionKey(),
      )
      .pipe(map(this.extractData), catchError(this.serviceError));
  }

  forgotPassword(email: string, lang: string): Observable<any> {
    return this.http
      .get(
        this.UrlServiceOauthV1 +
          `recover-password?` +
          `email=${email}&` +
          `lang=${lang}`,
        this.getOcpAPIMSubscriptionKey(),
      )
      .pipe(map(this.extractData), catchError(this.serviceError));
  }

  redefinirSenha(password: string, hash: string): Observable<any> {
    return this.http
      .get(
        this.UrlServiceOauthV1 +
          `recover-password?` +
          `password=${password}&` +
          `hash=${encodeURIComponent(hash)}`,
        this.getOcpAPIMSubscriptionKey(),
      )
      .pipe(map(this.extractData), catchError(this.serviceError));
  }

  confirmarCadastroUsuarioFreeTier(hash: string): Observable<any> {
    return this.http
      .patch(
        `${this.UrlServiceOauthV1}signup`,
        { hash },
        this.getOcpAPIMSubscriptionKey(),
      )
      .pipe(map(this.extractData), catchError(this.serviceError));
  }

  uploadImageProfile(formData: any, url: string) {
    return this.http
      .put(url, formData, {
        headers: new HttpHeaders({ 'Content-Type': 'image/jpeg' }),
      })
      .pipe(map(this.extractData), catchError(this.serviceError));
  }

  fakeDeleteUser(token: any): Promise<any> {
    const url = `${this.UrlServiceV1}disable-user`;
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Ocp-Apim-Subscription-Key': this.subscriptionKey,
      Authorization: `Bearer ${token}`,
    });

    if (environment.environmentName === 'LOCAL') {
      headers = new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      });
    }

    return this.http.post(url, {}, { headers }).toPromise();
  }
}
