import { ProgressBarEnum } from './../../../core/enum/progress-bar-animate.enum';
import {
  Component,
  OnInit,
  ViewEncapsulation,
  Renderer2,
  ViewChild,
  ElementRef,
} from '@angular/core';
import { TranslateService, TranslationChangeEvent } from '@ngx-translate/core';
import { Options } from 'ng5-slider';
import { CompleterService, RemoteData, CompleterItem } from 'ng2-completer';
import { PrevisaoTempoService } from 'app/core/services/previsao-tempo.service';
import {
  UntypedFormGroup,
  UntypedFormControl,
  UntypedFormBuilder,
} from '@angular/forms';
import { LocaleClass } from 'app/utils/locale.util';
import { ToastrService } from 'ngx-toastr';
import { environment } from 'environments/environment';
import { AppConstants } from 'app/app.constants';
import { concatMap } from 'rxjs/operators';
import {
  trigger,
  state,
  style,
  transition,
  animate,
} from '@angular/animations';
import { UserService } from 'app/core/services/user.service';
import { AgresSpinnerService } from 'app/shared/components/agres-spinner/agres-spinner.service';
import { TimeoutEnum } from 'app/core/enum/timeout.enum';
import { Router } from '@angular/router';
import { AnalyticsService } from 'app/core/services/analytics.service';

@Component({
  selector: 'app-previsao-tempo',
  templateUrl: './previsao-tempo.component.html',
  styleUrls: ['./previsao-tempo.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,

  animations: [
    trigger('progressState', [
      state(
        'start',
        style({
          height: '0%',
        }),
      ),
      state(
        'end',
        style({
          height: '{{ progress }}%',
        }),
        { params: { progress: '0' } },
      ),
      transition('start => end', animate('1000ms ease-out')),
    ]),
  ],
})
export class PrevisaoTempoComponent implements OnInit {
  @ViewChild('hourHand') hourHand: ElementRef;
  @ViewChild('minuteHand') minuteHand: ElementRef;
  @ViewChild('secondHand') secondHand: ElementRef;

  customIcons = {
    '01d': './../../../../assets/images/weather/sun-light.png',
    '02d': './../../../../assets/images/weather/partial-cloudy-light.png',
    '03d': './../../../../assets/images/weather/mostly-cloudy-light.png',
    '04d': './../../../../assets/images/weather/mostly-cloud-light.png',
    '09d': './../../../../assets/images/weather/heavy-rain-light.png',
    '10d': './../../../../assets/images/weather/rainyday-light.png',
    '11d': './../../../../assets/images/weather/thunderstorm-light.png',
    '13d': './../../../../assets/images/weather/heavy-snowfall-light.png',
    '50d': './../../../../assets/images/weather/heavy-wind-light.png',
  };

  progress: number = 0;
  uv: number = 0;
  humidityAir = 0;
  public iconCondition = '';
  public srcIconForecast =
    'http://openweathermap.org/img/wn/' + this.iconCondition + '@4x.png';

  public teste = [1, 2, 3, 4, 5];
  public modal = false;
  public cropsList: Array<string>;

  public lineChartLegend = false;
  public lineChartType = 'line';

  public isLocationValid: boolean = false;

  // Graph Time
  public lineChartLabels: Array<any>;
  public lineChartDataTemperature: Array<any>;
  public lineChartDataPrecipChance: Array<any>;
  public lineChartDataPrecipWindSpeed: Array<any>;
  public lineChartDataRelativeHumidity: Array<any>;
  public lineChartDataSprayingIndex: Array<any>;
  public barChartType = 'bar';

  // Graph Agriculture
  public lineChartLabelsAgriculture: Array<any>;
  public lineChartDataSoilMoisture: Array<any>;
  public lineChartDataSoilTemperature: Array<any>;
  public lineChartDataEvapotranspirationCrop: Array<any>;
  public lineChartDataEvapotranspirationModel: Array<any>;
  public lineChartDataIrradianceDirectNormal: Array<any>;
  public colorspray = '';
  locale = new LocaleClass(Location);
  public cropSelectedNow;

  value = 5;
  options: Options = {
    floor: 0,
    ceil: 100,
  };

  valueProfundidade = 5;
  optionsProfundidade: Options = {
    floor: 0,
    ceil: 200,
  };

  public indiceUv;

  public canvasWidth: number;
  public optionsGraph;
  public markerConfig;
  public bottomLabel;
  public bottomLabelFont = 28;

  searchForm: UntypedFormGroup;
  searchControl: UntypedFormControl;
  public searchStr: string;
  public selectedCity: CompleterItem;
  public selectedCityAgriculture: CompleterItem;

  public searchData;
  dataService: RemoteData;

  public latSelected;
  public lngSeleted;
  public cropSelected;
  public summarizedForecast;
  public firstPositionSummarizedDataObject;
  public spray;
  public sprayingCoefficientColor = '';

  public weekDays = [0, 1, 2, 3, 4, 5, 6];
  public months = [];

  public dataLabelsGrafico = null;

  public userLat = null;
  public userLng = null;

  public LOCATION_NAME_DEFAULT = 'Colombo, Paraná, Brasil';

  public currentLocation: string;
  public alertsArray = [];

  public velMaxVento = 50.0; // a maior velocidade do vento seria 50km.. parece falso!
  public tempMax = 100.0; // a temperatura maxima pode ser 100, mas pode ser negativa
  public humidityMax = 105.0; // nao sei o que dizer
  public cloudsMax = 70; // se 0 significa ceu limpo, 100 significa nublado, entao 70 seria maximo?

  public currentTime;
  // public zoom;
  public origin;
  public destination;
  public newGaugeValue;
  public gaugeValue;
  public colourIndex = [];

  hideAll: boolean = true;
  isLoading: boolean = true;
  private userId: string;
  constructor(
    public translateService: TranslateService,
    public completerService: CompleterService,
    public toastr: ToastrService,
    public fb: UntypedFormBuilder,
    public previsaoTempoService: PrevisaoTempoService,
    public renderer: Renderer2,
    private userService: UserService,
    public loadingAgresSpinner: AgresSpinnerService,
    public router: Router,
    private readonly analyticsService: AnalyticsService,
  ) {
    const usuarioLogado = JSON.parse(
      localStorage.getItem(AppConstants.KEYS_LOCAL_STORAGE.ISO_USUARIO),
    );
    this.userId = usuarioLogado.id;
    console.log('previsao do tempo email user-->', this.userId);
    setInterval(() => {
      this.displayCurrentTime();
    }, TimeoutEnum.Medium);
    this.searchControl = this.fb.control('');
    this.searchForm = this.fb.group({
      searchControl: this.searchControl,
    });

    this.dataService = completerService.remote(
      environment.urlApi + `weather`,
      null,
      'address',
    );
    this.dataService.urlFormater((term: any) => {
      return (
        environment.urlApi +
        `weather?operation=location&filterType=search&id=${term}`
      );
    });
    this.dataService.requestOptions(
      this.previsaoTempoService.obterAuthHeader(),
    );
    if (this.dataService.hasError)
      this.dataService.error((err) => console.log(err));
    this.modal = false;
    if (this.translateService.currentLang === undefined)
      this.translateService.currentLang = AppConstants.LANGUAGES.PT_BR;
    this.setEventChangeLanguage();
    this.setChartOptionForAllCharts();
  }

  public setChartOptionForAllCharts() {
    // Spraying Coefficient chart
    this.sprayingCoefficientChartOptions.scales.yAxes[0].scaleLabel.labelString =
      '(%)';
    // Temperature Coefficient chart, max value doesn't apply
    this.temperatureChartOptions.scales.yAxes[0].scaleLabel.labelString =
      '(°C)';
    delete this.temperatureChartOptions.scales.yAxes[0].ticks.max;
    // Precipitation Coefficient chart
    this.PrecipitationChartOptions.scales.yAxes[0].scaleLabel.labelString =
      '(%)';
    // WindSpeed Coefficient chart, max value doesn't apply
    this.windSpeedChartOptions.scales.yAxes[0].scaleLabel.labelString =
      '(Km/h)';
    delete this.windSpeedChartOptions.scales.yAxes[0].ticks.max;
    // Air umidity Coefficient chart
    this.AirUmidityChartOptions.scales.yAxes[0].scaleLabel.labelString = '(%)';
  }

  ngAfterViewInit() {
    this.translateService
      .get('global.loading.forecast-data')
      .subscribe((res: string) => {
        this.loadingAgresSpinner.toShow(res);
      });
  }

  ngOnInit() {
    this.analyticsService.sendPageView('/gestao-operacao/previsao-tempo');
    this.loadMonths();
    this.searchStr = this.LOCATION_NAME_DEFAULT;
    this.getUserAddress();

    this.analyticsService.trackEvent(
      'accessed_the_weather_forecast_page',
      'pageViewed',
      'successfully accessed the weather forecast page',
    );
  }

  getUserAddress() {
    this.userService.getUsuario().subscribe((loggedUser) => {
      const address = loggedUser?.address || {};
      const addressParts = ['Cidade', 'Estado', 'Pais'];

      const validAddressParts = addressParts
        .map((part) => address[part])
        .filter(Boolean);

      if (validAddressParts.length > 0) {
        this.searchStr = validAddressParts.join(', ');
        this.loadPrettyNameAndForecastForLocation(this.searchStr);
      } else {
        this.getLocation();
      }
    });
  }

  public loadMonths() {
    this.translateService.get('months').subscribe((res: string) => {
      this.months = [];
      const months = res.split(',');

      months.forEach((element) => {
        this.months.push(element);
      });
    });
  }

  loadPrettyNameAndForecastForLocation(
    location: string | undefined,
    latitude?: number | undefined,
    longitude?: number | undefined,
  ): void {
    let previsaoTempoService;
    if (
      location !== undefined &&
      latitude === undefined &&
      longitude === undefined
    ) {
      previsaoTempoService =
        this.previsaoTempoService.getSearchLocation(location);
    } else {
      if (latitude !== undefined && longitude !== undefined) {
        previsaoTempoService = this.previsaoTempoService.getLocationName(
          latitude,
          longitude,
        );
      }
    }
    previsaoTempoService
      .pipe(
        concatMap((listOfCities) => {
          const hasCitiesList =
            listOfCities !== undefined && listOfCities != null;
          // listOfCities.length > 0;

          if (
            hasCitiesList &&
            listOfCities instanceof Array &&
            listOfCities.length > 0
          ) {
            this.userLat = listOfCities[0].latitude;
            this.userLng = listOfCities[0].longitude;
            this.searchStr = listOfCities[0].address;

            this.loadForecast(this.userLat, this.userLng);
            // this.loadWeatherAlerts(this.userLat, this.userLng);
            this.isLocationValid = true;
            return this.previsaoTempoService.getLocationName(
              this.userLat,
              this.userLng,
            );
          } else {
            if (typeof listOfCities == 'string') {
              this.searchStr = listOfCities;

              if (latitude !== undefined && longitude !== undefined) {
                this.loadForecast(latitude, longitude);
                // .loadWeatherAlerts(latitude, longitude);
                this.isLocationValid = true;
                return this.previsaoTempoService.getLocationName(
                  latitude,
                  longitude,
                );
              }
            }
            if ((listOfCities as Array<string>).length == 0) {
              this.getLocation();
            }
          }
        }),
      )
      .subscribe({
        next: (locationName) => {
          if (locationName !== ' ') {
            this.searchStr = locationName;
          }
          this.currentLocation = locationName;
        },
        error: () => {
          this.translateService
            .get('error.500.error-occurred-try-again')
            .subscribe((res: string) => {
              this.toastr.error(res);
            });
        },
      });
  }

  formatSummarizedForecast(dailyWeatherForecast) {
    return dailyWeatherForecast.map((item) => {
      this.updateGaugeValue(item.uvi);
      this.updateProgress(item.humidity);
      this.loadingAgresSpinner.toHide();
      this.isLoading = false;
      this.hideAll = false;

      return {
        iconConditionTemp:
          this.customIcons[item.weather[0].icon] || item.weather[0].icon,
        indiceRayUv: item.uvi,
        precipChance: item.clouds,
        relativeHumidity: item.humidity,
        windSpeed: item.wind_speed,
        wxPhraseLong:
          item.weather[0].description.charAt(0).toUpperCase() +
          item.weather[0].description.slice(1),
        temperature: Math.round(item.temp.day),
        datePrevision: this.converterUtcForDate(item.dt),
        temperatureMax: Math.round(item.temp.max),
        temperatureMin: Math.round(item.temp.min),
        localHour: new Date().toLocaleTimeString(),
      };
    });
  }

  updateProgress(relativeHumidity: number) {
    this.progress = relativeHumidity;
  }

  updateGaugeValue(indiceRayUv: string) {
    this.newGaugeValue = indiceRayUv;
  }

  getMin(array, attrib) {
    return array.reduce(function (prev, curr) {
      return prev[attrib] < curr[attrib] ? prev : curr;
    });
  }

  getMax(array, attrib) {
    return array.reduce(function (prev, curr) {
      return prev[attrib] > curr[attrib] ? prev : curr;
    });
  }

  getAvg(array, attrib) {
    let index = 0;
    let sum = 0;
    array.map(function (item) {
      index++;
      sum += Number(item[attrib]);
    });
    if (index === 0 || sum === 0) return 0;
    return sum / index;
  }

  groupBy(list, keyGetter) {
    const map = new Map();
    list.forEach((item) => {
      const key = keyGetter(item);
      const collection = map.get(key);
      if (!collection) map.set(key, [item]);
      else collection.push(item);
    });
    return map;
  }

  selectedDepth(value) {
    this.valueProfundidade = value;
  }

  selectedMaturity(value) {
    this.value = value;
  }

  loadForecast(lat, lng) {
    this.translateService.get('months').subscribe((resMonths: string) => {
      this.months = [];
      const months = resMonths.split(',');

      months.forEach((element) => {
        this.months.push(element);
      });
    });

    this.previsaoTempoService
      .getTimeNextThreeDays(lat, lng, this.translateService.currentLang)
      .subscribe(
        (dailyWeatherForecast) => {
          this.summarizedForecast =
            this.formatSummarizedForecast(dailyWeatherForecast);
        },
        () => {
          this.translateService
            .get('error.500.error-occurred-try-again')
            .subscribe((res: string) => {
              this.toastr.error(res);
            });
        },
      );

    this.previsaoTempoService
      .getTime(lat, lng, this.translateService.currentLang)
      .subscribe(
        (data) => {
          if (data instanceof Array) {
            this.lineChartLabels = data.map((timestamp) => {
              return this.timeConverter(timestamp.dt);
            });

            this.lineChartDataTemperature = [];
            this.paintLineChartDataSpraying(data);
            this.paintLineChartDataTemperature(data);
            this.paintLineChartDataPrecipChance(data);
            this.paintLineChartDataPrecipWindSpeed(data);
            this.paintLineChartDataRelativeHumidity(data);
            this.paintSprayingCoefficientGraph(data);
            this.updateSprayingCoefficientIndexColours();

            this.sprayingCoefficientColor = this.colourIndex[0];
            this.lineChartDataTemperature = [
              { data: this.lineChartDataTemperature },
            ];
            this.lineChartDataPrecipChance = [
              { data: this.lineChartDataPrecipChance },
            ];
            this.lineChartDataPrecipWindSpeed = [
              { data: this.lineChartDataPrecipWindSpeed },
            ];
            this.lineChartDataRelativeHumidity = [
              { data: this.lineChartDataRelativeHumidity },
            ];
            this.lineChartDataSprayingIndex = [
              { data: this.lineChartDataSprayingIndex },
            ];
            this.spray = this.lineChartDataSprayingIndex[0].data[0];
          }
        },
        () => {
          this.translateService
            .get('error.500.error-occurred-try-again')
            .subscribe((res: string) => {
              this.toastr.error(res);
            });
        },
      );
  }

  updateSprayingCoefficientIndexColours() {
    setTimeout(() => {
      this.lineChartColorsMix[0].backgroundColor = this.colourIndex;
      this.lineChartColorsMix = [...this.lineChartColorsMix];
    }, 50);
  }

  paintSprayingCoefficientGraph(coefArr: Array<any>): void {
    this.colourIndex = [];
    this.lineChartDataSprayingIndex = [];

    coefArr.forEach((value) => {
      const windCoef = 1.0 - (1.0 / this.velMaxVento) * value.wind_speed;
      const tempCoef = 1.0 - (1.0 / this.tempMax) * value.temp;
      const humidityCoef = 1.0 - (1.0 / this.humidityMax) * value.humidity;
      const rainCoef = 1.0 - (1.0 / this.cloudsMax) * value.clouds;
      let valueIndexSpraying =
        Math.round(
          (windCoef * tempCoef * humidityCoef * rainCoef + 0.31) * 100 * 1e2,
        ) / 1e2;

      valueIndexSpraying = this.checkValueIndexSpraying(valueIndexSpraying);

      if (valueIndexSpraying < 5) {
        this.colourIndex.push('#E50600');
      } else if (valueIndexSpraying >= 5 && valueIndexSpraying < 10) {
        this.colourIndex.push('#E01D03');
      } else if (valueIndexSpraying >= 10 && valueIndexSpraying < 15) {
        this.colourIndex.push('#DB3306');
      } else if (valueIndexSpraying >= 15 && valueIndexSpraying < 20) {
        this.colourIndex.push('#D64708');
      } else if (valueIndexSpraying >= 20 && valueIndexSpraying < 25) {
        this.colourIndex.push('#D15A0B');
      } else if (valueIndexSpraying >= 25 && valueIndexSpraying < 30) {
        this.colourIndex.push('#CC6B0E');
      } else if (valueIndexSpraying >= 30 && valueIndexSpraying < 35) {
        this.colourIndex.push('#C77B10');
      } else if (valueIndexSpraying >= 35 && valueIndexSpraying < 40) {
        this.colourIndex.push('#C28912');
      } else if (valueIndexSpraying >= 40 && valueIndexSpraying < 45) {
        this.colourIndex.push('#BD9614');
      } else if (valueIndexSpraying >= 45 && valueIndexSpraying < 50) {
        this.colourIndex.push('#B8A216');
      } else if (valueIndexSpraying >= 50 && valueIndexSpraying < 55) {
        this.colourIndex.push('#B3AC18');
      } else if (valueIndexSpraying >= 55 && valueIndexSpraying < 60) {
        this.colourIndex.push('#A6AE1A');
      } else if (valueIndexSpraying >= 60 && valueIndexSpraying < 65) {
        this.colourIndex.push('#94A91B');
      } else if (valueIndexSpraying >= 65 && valueIndexSpraying < 70) {
        this.colourIndex.push('#83A41D');
      } else if (valueIndexSpraying >= 70 && valueIndexSpraying < 75) {
        this.colourIndex.push('#749F1E');
      } else if (valueIndexSpraying >= 75 && valueIndexSpraying < 80) {
        this.colourIndex.push('#669A1F');
      } else if (valueIndexSpraying >= 80 && valueIndexSpraying < 85) {
        this.colourIndex.push('#589520');
      } else if (valueIndexSpraying >= 85 && valueIndexSpraying < 90) {
        this.colourIndex.push('#4C9021');
      } else if (valueIndexSpraying >= 90 && valueIndexSpraying < 95) {
        this.colourIndex.push('#418B22');
      } else {
        this.colourIndex.push('#378623');
      }

      this.lineChartDataSprayingIndex.push(valueIndexSpraying);
    });
  }

  paintLineChartDataSpraying(coefArr: Array<any>): void {
    this.lineChartDataSprayingIndex = [];

    coefArr.forEach((value) => {
      const windCoef = 1.0 - (1.0 / this.velMaxVento) * value.wind_speed;
      const tempCoef = 1.0 - (1.0 / this.tempMax) * value.temp;
      const humidityCoef = 1.0 - (1.0 / this.humidityMax) * value.humidity;
      const rainCoef = 1.0 - (1.0 / this.cloudsMax) * value.clouds;
      let valueIndexSpraying =
        Math.round(
          (windCoef * tempCoef * humidityCoef * rainCoef + 0.31) * 100 * 1e2,
        ) / 1e2;

      valueIndexSpraying = this.checkValueIndexSpraying(valueIndexSpraying);

      this.lineChartDataSprayingIndex.push(valueIndexSpraying);
    });
  }

  paintLineChartDataTemperature(coefArr: Array<any>): void {
    this.lineChartDataTemperature = [];

    coefArr.forEach((value) => {
      this.lineChartDataTemperature.push(value.temp);
    });
  }

  paintLineChartDataPrecipChance(coefArr: Array<any>): void {
    this.lineChartDataPrecipChance = [];

    coefArr.forEach((value) => {
      this.lineChartDataPrecipChance.push(value.clouds);
    });
  }

  paintLineChartDataPrecipWindSpeed(coefArr: Array<any>): void {
    this.lineChartDataPrecipWindSpeed = [];

    coefArr.forEach((value) => {
      this.lineChartDataPrecipWindSpeed.push(value.wind_speed);
    });
  }

  paintLineChartDataRelativeHumidity(coefArr: Array<any>): void {
    this.lineChartDataRelativeHumidity = [];

    coefArr.forEach((value) => {
      this.lineChartDataRelativeHumidity.push(value.humidity);
    });
  }

  checkValueIndexSpraying(indexValue: number) {
    if (indexValue > 100) return 100;
    return indexValue;
  }

  public onCitySelected(selected: CompleterItem) {
    if (!selected) selected = this.selectedCity;

    if (selected) {
      this.selectedCity = selected;
      const lat = selected.originalObject.latitude;
      const lng = selected.originalObject.longitude;

      this.userLat = lat;
      this.userLng = lng;
      this.currentLocation = selected.title;
      this.loadForecast(lat, lng);
    } else {
      this.loadForecast(this.userLat, this.userLng);
      this.previsaoTempoService
        .getLocationName(this.userLat, this.userLng)
        .subscribe({
          next: (data) => {
            this.searchStr = data;
          },
          error: () => {
            this.translateService
              .get('error.500.error-occurred-try-again')
              .subscribe((res: string) => {
                this.toastr.error(res);
              });
          },
        });
    }
  }

  timeConverter(UNIX_timestamp) {
    const dateTimeStamp = new Date(UNIX_timestamp * 1000);
    const months = this.months;
    const month = months[dateTimeStamp.getMonth()];
    const date = dateTimeStamp.getDate();
    const hour = dateTimeStamp.getHours();
    const time = date + ' ' + month + ' ' + hour + ':00';
    return time;
  }

  converterUtcForDate(UNIX_timestamp) {
    return new Date(UNIX_timestamp * 1000);
  }

  timeConverterDay(UNIX_timestamp) {
    const dateTimeStamp = new Date(UNIX_timestamp * 1000);
    const day = this.weekDays[dateTimeStamp.getMonth()];
    const date = dateTimeStamp.getDate();
    const time = date + ' ' + day;
    return time;
  }

  setEventChangeLanguage() {
    this.translateService.onLangChange.subscribe(
      (event: TranslationChangeEvent) => {
        if (!!this.userLat && !!this.userLng) {
          this.loadForecast(this.userLat, this.userLng);
        }
      },
    );
  }

  getLocation() {
    if (
      navigator.geolocation &&
      this.userLat == undefined &&
      this.userLng == undefined
    ) {
      navigator.geolocation.getCurrentPosition(
        (position: any) => {
          if (position) {
            const latitude = position.coords.latitude;
            const longitude = position.coords.longitude;
            this.userLat = latitude;
            this.userLng = longitude;

            this.loadForecast(this.userLat, this.userLng);
            // this.loadWeatherAlerts(this.userLat, this.userLng);

            this.previsaoTempoService
              .getLocationName(this.userLat, this.userLng)
              .subscribe((resultPrettyName) => {
                this.isLocationValid = true;
                this.searchStr = resultPrettyName;
              });
          }
        },
        (error: any) => {
          console.log(error);
        },
      );
    } else {
      this.translateService
        .get('error.geolocation-not-supported')
        .subscribe((resGeolicationNotSupport: string) => {
          this.toastr.error(resGeolicationNotSupport);
        });
    }
  }

  public lineChartData: Array<any> = [{ data: [27, 15, 25, 18, 24, 13, 20] }];

  public lineChartOptions: any = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      xAxes: [
        {
          ticks: {
            display: true,
          },
        },
      ],
      yAxes: [
        {
          display: true,
          ticks: {
            suggestedMin: 0,
            beginAtZero: true,
            max: 100,
          },
          scaleLabel: {
            display: true,
            labelString: '',
          },
        },
      ],
    },
  };

  public sprayingCoefficientChartOptions = JSON.parse(
    JSON.stringify(this.lineChartOptions),
  );
  public temperatureChartOptions = JSON.parse(
    JSON.stringify(this.lineChartOptions),
  );
  public PrecipitationChartOptions = JSON.parse(
    JSON.stringify(this.lineChartOptions),
  );
  public windSpeedChartOptions = JSON.parse(
    JSON.stringify(this.lineChartOptions),
  );
  public AirUmidityChartOptions = JSON.parse(
    JSON.stringify(this.lineChartOptions),
  );

  public lineChartColors: Array<any> = [
    {
      backgroundColor: 'rgba(255,255,0,0.3)',
      borderColor: 'rgba(255,255,0, 1.0)',
      pointBackgroundColor: 'rgb(255,255,0)',
      pointBorderColor: '#fff',
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderColor: 'rgba(148,159,177,0.8)',
    },
  ];

  public lineChartColorsBlue: Array<any> = [
    {
      backgroundColor: 'rgba(0,255,255,0.3)',
      borderColor: 'rgba(0,255,255, 1.0)',
      pointBackgroundColor: 'rgb(0,255,255)',
      pointBorderColor: '#fff',
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderColor: 'rgba(148,159,177,0.8)',
    },
  ];

  public lineChartColorsGreen: Array<any> = [
    {
      backgroundColor: 'rgba(0,255,0,0.3)',
      borderColor: 'rgba(0,255,0, 1.0)',
      pointBackgroundColor: 'rgb(0,255,0)',
      pointBorderColor: '#fff',
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderColor: 'rgba(148,159,177,0.8)',
    },
  ];

  public lineChartColorsMix = [
    {
      backgroundColor: [],
    },
  ];

  public lineChartColorsBrown: Array<any> = [
    {
      backgroundColor: 'rgba(128,0,0,0.3)',
      borderColor: 'rgba(128,0,0, 1.0)',
      pointBackgroundColor: 'rgb(128,0,0)',
      pointBorderColor: '#fff',
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderColor: 'rgba(148,159,177,0.8)',
    },
  ];

  public lineChartColorsYellowDark: Array<any> = [
    {
      backgroundColor: 'rgba(255, 161, 0,0.3)',
      borderColor: 'rgba(255, 161, 0, 1.0)',
      pointBackgroundColor: 'rgb(255, 161, 0)',
      pointBorderColor: '#fff',
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderColor: 'rgba(148,159,177,0.8)',
    },
  ];

  public lineChartColorsPurple: Array<any> = [
    {
      backgroundColor: 'rgba(128,0,128,0.3)',
      borderColor: 'rgba(128,0,128, 1.0)',
      pointBackgroundColor: 'rgb(128,0,128)',
      pointBorderColor: '#fff',
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderColor: 'rgba(148,159,177,0.8)',
    },
  ];

  public selectedGrapColor;
  public selectedGrapData: Array<any>;
  public selectedTypeGraph;
  public titleTime;

  fullgraph(tipo) {
    this.translateService.get('forecast-graphics').subscribe((res: string) => {
      const graphs = res.split(',');

      switch (tipo) {
        case 'temperature':
          this.selectedGrapColor = this.lineChartColors;
          this.selectedGrapData = this.lineChartDataTemperature;
          this.selectedTypeGraph = this.lineChartType;
          this.titleTime = graphs[0];
          break;
        case 'rain':
          this.selectedGrapColor = this.lineChartColorsBlue;
          this.selectedGrapData = this.lineChartDataPrecipChance;
          this.selectedTypeGraph = this.lineChartType;
          this.titleTime = graphs[1];
          break;
        case 'spring-speed':
          this.selectedGrapColor = this.lineChartColorsGreen;
          this.selectedGrapData = this.lineChartDataPrecipWindSpeed;
          this.selectedTypeGraph = this.lineChartType;
          this.titleTime = graphs[2];
          break;
        case 'humilty':
          this.selectedGrapColor = this.lineChartColorsPurple;
          this.selectedGrapData = this.lineChartDataRelativeHumidity;
          this.selectedTypeGraph = this.lineChartType;
          this.titleTime = graphs[3];
          break;
        case 'soil-humilty':
          this.selectedGrapColor = this.lineChartColors;
          this.selectedGrapData = this.lineChartDataSoilMoisture;
          this.selectedTypeGraph = this.lineChartType;
          this.titleTime = graphs[4];
          break;
        case 'soil-temperature':
          this.selectedGrapColor = this.lineChartColorsBlue;
          this.selectedGrapData = this.lineChartDataSoilTemperature;
          this.selectedTypeGraph = this.lineChartType;
          this.titleTime = graphs[5];
          break;
        case 'evapotranspiration-plant':
          this.selectedGrapColor = this.lineChartColorsGreen;
          this.selectedGrapData = this.lineChartDataEvapotranspirationCrop;
          this.selectedTypeGraph = this.lineChartType;
          this.titleTime = graphs[6];
          break;
        case 'soil-evapotranspiration':
          this.selectedGrapColor = this.lineChartColorsBrown;
          this.selectedGrapData = this.lineChartDataEvapotranspirationModel;
          this.selectedTypeGraph = this.lineChartType;
          this.titleTime = graphs[7];
          break;
        case 'irradiation':
          this.selectedGrapColor = this.lineChartColorsYellowDark;
          this.selectedGrapData = this.lineChartDataIrradianceDirectNormal;
          this.selectedTypeGraph = this.lineChartType;
          this.titleTime = graphs[8];
          break;
        case 'spray':
          this.selectedGrapColor = this.lineChartColorsMix;
          this.selectedGrapData = this.lineChartDataSprayingIndex;
          this.selectedTypeGraph = this.barChartType;
          this.titleTime = graphs[9];
          break;
      }
      this.modal = true;
    });
  }

  public randomize(): void {
    const _lineChartData: Array<any> = new Array(this.lineChartData.length);
    for (let i = 0; i < this.lineChartData.length; i++) {
      _lineChartData[i] = {
        data: new Array(this.lineChartData[i].data.length),
        label: this.lineChartData[i].label,
      };
      for (let j = 0; j < this.lineChartData[i].data.length; j++) {
        _lineChartData[i].data[j] = Math.floor(Math.random() * 100 + 1);
      }
    }
    this.lineChartData = _lineChartData;
  }

  public chartClicked(e: any): void {}
  public chartHovered(e: any): void {}

  public barChartOptions: any = {
    scaleShowVerticalLines: false,
    responsive: true,
    maintainAspectRatio: false,
    xAxes: [
      {
        ticks: {
          display: true,
        },
      },
    ],
  };

  public grapfColors = [
    {
      backgroundColor: [
        'rgba(92, 184, 92,1)',
        'rgba(255, 195, 0, 1)',
        'rgba(217, 83, 79,1)',
        'rgba(129, 78, 40, 1)',
        'rgba(129, 199, 111, 1)',
      ],
    },
  ];

  closeModal() {
    this.modal = false;
  }

  animateProgressBar() {
    $('.animated-progress span').each(function () {
      $(this).animate(
        {
          width: $(this).attr('data-progress') + '%',
        },
        ProgressBarEnum.Fast,
      );
      $(this).text($(this).attr('data-progress') + '%');
    });
  }

  dashboard() {
    this.router.navigate(['/']);
  }

  displayCurrentTime() {
    const dataAtual = new Date();
    const hora = dataAtual.getHours();
    const minutos = dataAtual.getMinutes();
    const segundos = dataAtual.getSeconds();

    this.currentTime = `${hora.toString().padStart(2, '0')}:${minutos
      .toString()
      .padStart(2, '0')}:${segundos.toString().padStart(2, '0')}`;
  }
}
