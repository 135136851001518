import {
  merge as observableMerge,
  fromEvent as observableFromEvent,
  Observable,
  Subject,
} from 'rxjs';
import {
  Component,
  OnInit,
  AfterViewInit,
  Input,
  Output,
  EventEmitter,
  ViewChildren,
  ElementRef,
  ViewChild,
} from '@angular/core';
import { User } from 'app/shared/models/user';
import {
  FormControlName,
  UntypedFormBuilder,
  Validators,
  FormControl,
  FormGroup,
  AbstractControl,
} from '@angular/forms';
import { GenericValidator } from 'app/utils/generic-form-validator';
import { UserService } from 'app/core/services/user.service';
import { TranslateService } from '@ngx-translate/core';
import { AppConstants } from 'app/app.constants';
import { ValidateId } from 'app/shared/validators/ValidateId';
import { PostalCodeService } from 'app/core/services/postalCode.service';
import { AlertService } from '../../alert/alert.service';
import { TimeoutEnum } from 'app/core/enum/timeout.enum';

type AddressFormGroup = FormGroup<{
  country: AbstractControl<any, any>;
  city: AbstractControl<any, any>;
  stateOrProvince: AbstractControl<any, any>;
  postalCode: AbstractControl<any, any>;
}>;

@Component({
  selector: 'app-form-atualizacao-cadastro',
  templateUrl: './atualizacao-cadastro.component.html',
  styleUrls: ['./atualizacao-cadastro.component.scss'],
})
export class AtualizacaoCadastroFormComponent implements OnInit, AfterViewInit {
  @Input() offCanvas: boolean;
  @Input() userId: string;
  user: User;
  @Input() showOffCanvasSubject: Subject<boolean>;
  @Output() modalChange: EventEmitter<boolean>;
  @ViewChildren(FormControlName, { read: ElementRef })
  formInputElements: ElementRef[];
  public outsideBrazil: boolean = false;
  public registrarForm: FormGroup<{
    phoneNumber: AbstractControl<string>;
    documentIdentification: AbstractControl<string>;
    company: AbstractControl<string>;
    country: AbstractControl<string>;
    city: AbstractControl<string>;
    stateOrProvince: AbstractControl<string>;
    postalCode: AbstractControl<string>;
    addressFormGroup: AddressFormGroup;
  }>;
  public validationMessages: { [key: string]: { [key: string]: string } };
  public genericValidator: GenericValidator;
  public displayMessage: { [key: string]: string } = {};
  public errors: any[] = [];
  public selectedPermisao;
  public disableButton;
  public telefoneFormat;
  public loggedUser: User;
  public countries: Array<string> = AppConstants.COUNTRY_CODES;
  public postalCode: string;
  public senhaInvalidaIgual = false;
  public senhaInvalidaMinLength = false;

  constructor(
    private cepService: PostalCodeService,
    public fb: UntypedFormBuilder,
    public translateService: TranslateService,
    public AlertService: AlertService,
    public usuarioService: UserService,
    public postalCodeService: PostalCodeService,
    public alertService: AlertService,
  ) {
    this.validationMessages = {
      phoneNumber: {
        required: '',
        maxlength: '',
        minlength: '',
      },
      documentIdentification: {
        required: '',
        cpf: '',
        cnpj: '',
      },
      company: {
        required: '',
        minlength: '',
      },
      country: {
        required: '',
      },
      city: {
        required: '',
        minlength: '',
      },
      stateOrProvince: {
        required: '',
        minlength: '',
      },
      postalCode: {
        required: '',
        minlength: '',
      },
    };

    this.populateValidateObj();

    this.genericValidator = new GenericValidator(this.validationMessages);
  }

  populateValidateObj() {
    this.translateService
      .get('global.validate.fields')
      .subscribe((res: string) => {
        this.validationMessages.documentIdentification.required = res;
      });

    this.translateService
      .get('global.validate.error-phone-data')
      .subscribe((res: string) => {
        this.validationMessages.phoneNumber.required = res;
      });
    this.translateService
      .get('global.validate.error-user-data-incorrect-text')
      .subscribe((res: string) => {
        this.validationMessages.company.required = res;
      });
    this.translateService
      .get('global.validate.identification-required')
      .subscribe((res: string) => {
        this.validationMessages.country.required = res;
      });
    this.translateService
      .get('global.validate.identification-required')
      .subscribe((res: string) => {
        this.validationMessages.city.required = res;
      });
    this.translateService
      .get('global.validate.identification-required')
      .subscribe((res: string) => {
        this.validationMessages.stateOrProvince.required = res;
      });
    this.translateService
      .get('global.validate.error-document-data')
      .subscribe((res: string) => {
        this.validationMessages.documentIdentification.cpf = res;
      });
    this.translateService
      .get('global.validate.error-document-data')
      .subscribe((res: string) => {
        this.validationMessages.documentIdentification.cnpj = res;
      });

    this.translateService
      .get('global.validate.identification-required')
      .subscribe((res: string) => {
        this.validationMessages.postalCode.required = res;
      });
  }

  ngOnInit() {
    this.usuarioService.getUsuarioByEmail(this.userId).subscribe(
      (user: User) => {
        this.user = user;
        this.preencherForm();
      },
      (e) => {
        this.translateService.get('profile.failed').subscribe((res: string) => {
          this.AlertService.warning(res, TimeoutEnum.VeryLong);
        });
      },
    );

    this.registrarForm = this.fb.group({
      documentIdentification: [['']],
      company: ['', [Validators.required]],
      addressFormGroup: this.fb.group({
        country: ['', [Validators.required]],
        city: ['', [Validators.required]],
        stateOrProvince: ['', [Validators.required]],
        postalCode: ['', [Validators.required, Validators.minLength(2)]],
      }),
      phoneNumber: ['', [Validators.required]],
    });
    this.registrarForm
      .get('addressFormGroup')
      .valueChanges.subscribe((data) => {
        this.outsideBrazil = data.country !== 'BRA';
      });

    this.disableButton = false;
  }

  preencherForm() {
    let phoneNumber = this.user.phoneNumber
      ? this.removeMask(this.user.phoneNumber)
      : '';

    let cidade = '';
    let estado = '';
    let pais = '';
    let postalCode = '';

    if (this.user.address) {
      postalCode = this.user.address.postalCode
        ? this.user.address.postalCode
        : '';
      cidade = this.user.address.city ? this.user.address.city : '';
      estado = this.user.address.stateOrProvince
        ? this.user.address.stateOrProvince
        : '';
      pais = this.user.address.country ? this.user.address.country : '';
    }

    this.registrarForm.patchValue({
      documentIdentification: this.user['nationalIdNumber'],
      company: this.user['company'],
      addressFormGroup: {
        postalCode,
        city: cidade,
        stateOrProvince: estado,
        country: pais,
      },
      phoneNumber,
    });
  }

  identificationConditional() {
    this.outsideBrazil = !this.outsideBrazil;
    const checkIdentification = <FormControl>(
      this.registrarForm.get('documentIdentification')
    );
    const postalCode = <FormControl>this.registrarForm.get('postalCode');
    const cidade = <FormControl>this.registrarForm.get('city');
    const estado = <FormControl>(
      this.registrarForm.get('stateOrProvince')
    );
    const pais = <FormControl>this.registrarForm.get('country');
    if (this.outsideBrazil) {
      checkIdentification.setValidators(null);
      postalCode.setValidators(null);
      cidade.setValidators(null);
      estado.setValidators(null);
      pais.setValidators(null);
    } else {
      checkIdentification.setValidators([
        Validators.required,
        ValidateId.ValidadeCPFCNPJ,
      ]);
      postalCode.setValidators([Validators.required]);
      cidade.setValidators([Validators.required]);
      estado.setValidators([Validators.required]);
      pais.setValidators([Validators.required]);
    }
    checkIdentification.updateValueAndValidity();
    postalCode.updateValueAndValidity();
    cidade.updateValueAndValidity();
    estado.updateValueAndValidity();
    pais.updateValueAndValidity();
  }

  getIdentificationMask(): string {
    return this.isCPF() ? '000.000.000-009' : '00.000.000/0000-00';
  }

  isCPF(): boolean {
    const documentIdentification =
      this.registrarForm.value.documentIdentification;
    return documentIdentification == null || documentIdentification.length < 12;
  }

  isCelular(): boolean {
    return this.registrarForm.value.phoneNumber.length == 11;
  }

  removeMask(phoneNumber: string) {
    let telefoneSemMask = phoneNumber.replace('(', '');
    telefoneSemMask = telefoneSemMask.replace(')', '');
    telefoneSemMask = telefoneSemMask.replace('-', '');
    telefoneSemMask = telefoneSemMask.replace(' ', '');

    return telefoneSemMask;
  }

  checkCep(postalCode: Event) {
    const postalCodeValue = (postalCode.target as HTMLInputElement).value;
    if (postalCodeValue) {
      this.cepService.searchCEP(postalCodeValue).subscribe(
        (dados) => {
          this.addPostalCodeDataIntoForm(
            dados,
            this.registrarForm.controls.addressFormGroup,
          );
        },
        (error) => {
          if (error) {
            this.translateService
              .get('global.validate.error-cep')
              .subscribe((res: string) => {
                this.alertService.error(res);
              });
          }
        },
      );
    }
  }

  addPostalCodeDataIntoForm(
    postalCodeData: { [key: string]: any } | null,
    form: AddressFormGroup,
  ) {
    if (postalCodeData instanceof Object) {
      if ('erro' in postalCodeData) {
        this.translateService
          .get('global.validate.error-cep')
          .subscribe((res: string) => {
            this.alertService.error(res);
          });
      } else {
        if (postalCodeData.city) {
          form.get('city').disable();
        }

        if (postalCodeData.uf) {
          form.get('stateOrProvince').disable();
        }
        form.patchValue({
          city: postalCodeData.city,
          stateOrProvince: postalCodeData.uf,
        });
      }
    }
  }

  popularTelefone(telefoneForm) {
    if (!telefoneForm.includes('-') || !telefoneForm.includes('(')) {
      if (telefoneForm.length == 10) {
        return (
          '(' +
          telefoneForm.substr(0, 2) +
          ') ' +
          telefoneForm.substr(2, 4) +
          '-' +
          telefoneForm.substr(6, 4)
        );
      } else {
        return (
          '(' +
          telefoneForm.substr(0, 2) +
          ') ' +
          telefoneForm.substr(2, 5) +
          '-' +
          telefoneForm.substr(7, 4)
        );
      }
    } else {
      telefoneForm = telefoneForm
        .replace('(', '')
        .replace(')', '')
        .replace('-', '');

      if (telefoneForm.length == 10) {
        return (
          '(' +
          telefoneForm.substr(0, 2) +
          ') ' +
          telefoneForm.substr(2, 4) +
          '-' +
          telefoneForm.substr(6, 4)
        );
      } else {
        return (
          '(' +
          telefoneForm.substr(0, 2) +
          ') ' +
          telefoneForm.substr(2, 5) +
          '-' +
          telefoneForm.substr(7, 4)
        );
      }
    }
  }

  ngAfterViewInit() {
    let controlBlurs: Observable<Event>[] = this.formInputElements.map(
      (formControl: ElementRef) =>
        observableFromEvent(formControl.nativeElement, 'blur'),
    );

    observableMerge(...controlBlurs).subscribe((value) => {
      this.displayMessage = this.genericValidator.processMessages(
        this.registrarForm,
      );
    });
  }



  montarObjUsuario() {
    if (this.outsideBrazil) {
      this.telefoneFormat = this.registrarForm.controls.phoneNumber.value;
    } else {
      this.telefoneFormat = this.popularTelefone(
        this.registrarForm.controls.phoneNumber.value,
      );
    }
    const addressFormGroup = this.registrarForm.controls.addressFormGroup;
    const usuario = {
      id: this.user.id,
      name: this.user.name,
      role: this.user.role,
      nationalIdNumber:
        this.registrarForm.controls.documentIdentification.value,
      company: this.registrarForm.controls.company.value,
      address: {
        postalCode: addressFormGroup.get('postalCode').value,
        country: addressFormGroup.get('country').value,
        city: addressFormGroup.get('city').value,
        stateOrProvince: addressFormGroup.get('stateOrProvince').value,
      },
      phoneNumber: this.telefoneFormat,
      OutsideBrazil: this.outsideBrazil,
    };

    return usuario;
  }

  editUsuario() {
    this.displayMessage = this.genericValidator.processMessages(
      this.registrarForm,
    );
    if (this.registrarForm.valid && this.registrarForm.dirty) {
      let usuario = this.montarObjUsuario();
      this.disableButton = true;
      this.usuarioService.patchUser(usuario).subscribe(
        () => {
          this.onSaveComplete();
        },
        (error) => {
          this.onError(error);
        },
      );
    }
  }

  onSaveComplete() {
    this.errors = [];
    this.disableButton = true;
    this.showOffCanvasSubject.next(false);

    this.translateService
      .get('profile.success-msg')
      .subscribe((res: string) => {
        this.AlertService.success(res);
      });

    setTimeout(() => {
      window.localStorage.removeItem(AppConstants.KEYS_LOCAL_STORAGE.ROLE);
      window.localStorage.removeItem(
        AppConstants.KEYS_LOCAL_STORAGE.ISO_USUARIO,
      );
      window.localStorage.removeItem(
        AppConstants.KEYS_LOCAL_STORAGE.DATE_EXPIRES,
      );
      window.localStorage.removeItem(
        AppConstants.KEYS_LOCAL_STORAGE.REFRESH_TOKEN,
      );
      window.localStorage.removeItem(
        AppConstants.KEYS_LOCAL_STORAGE.ISO_ACCESS_TOKEN,
      );
      window.document.location.reload();
    }, 5000);
  }

  onError(fail: any) {
    this.disableButton = false;
    this.errors = [fail.error];

    this.translateService
      .get('error.500.error-occurred-try-again')
      .subscribe((res: string) => {
        this.AlertService.error(this.errors[0], res);
      });
  }

  public hideOffCanvas(): void {
    this.showOffCanvasSubject.next(false);
    window.localStorage.setItem(
      AppConstants.KEYS_LOCAL_STORAGE.HIDE_MODAL,
      'true',
    );
  }
}
