<app-alert></app-alert>
<div class="panel">
  <div class="panel__group">
    <div class="panel__search">
      <div class="panel__flex">
        <p>
          {{ 'global.edit' | translate }}: <strong>{{ talhaoName }}</strong>
        </p>
      </div>
    </div>
  </div>
  <div class="panel__group">
    <div class="panel__group--buttons hidden__mobile">
      <app-button
        type="button"
        color="return"
        icon="agres-return"
        (btnClick)="cancelEditPolygons()"
        title="{{ 'global.back' | translate }}">
      </app-button>
      <app-button
        type="button"
        color="danger"
        icon="agres-close"
        [disabled]="!isPolygonClosed"
        (btnClick)="clearPolygons()"
        title="{{ 'global.clear' | translate }}">
      </app-button>
      <app-button
        type="button"
        color="primary"
        icon="agres-field"
        (btnClick)="callTogglePolygonDrawing()"
        [title]="togglePolygonEditButtonTitle()">
      </app-button>
    </div>
  </div>
</div>
<ng-template #resultBody>
  <div *ngIf="isPolygonClosed && !isEditingPolygon">
    <form [formGroup]="editFieldForm" novalidate (ngSubmit)="editField()">
      <div *ngIf="cityName">
        <p>{{ 'global.city' | translate }}:</p>
        <p class="cardField__content--info---title">
          {{ cityName }}
        </p>
      </div>
      <div class="agr--input" id="campoNomeTalhao">
        <div class="field__label" for="floatingInputNameTalhao" id="labelNomeTalhao">
          {{ 'global.area' | translate }}
        </div>
        <div class="field__wrap">
          <input formControlName="area" type="text" class="field__input" value="" readonly />
          <span class="field__view">
            <app-agres-icons iconsClass="agres-field"></app-agres-icons>
          </span>
        </div>
      </div>
      <div class="field">
        <div class="field__label" for="labelNomeTalhao">
          {{ 'global.title' | translate }}
        </div>
        <div class="field__wrap">
          <input
            formControlName="name"
            type="text"
            class="field__input"
            [ngClass]="{ 'has-error': displayMessage.name }"
            id="nomeDoTalhao" />
          <span class="field__input--alert" *ngIf="displayMessage.name" [innerHTML]="displayMessage.name"> </span>
        </div>
      </div>
      <div class="field">
        <div class="field__label" for="fieldColor">
          {{ 'global.color' | translate }}
        </div>
        <div class="field__wrap">
          <input
            type="color"
            id="fieldColor"
            (onclick)="selectClick($event)"
            (change)="selectCoresTalhao($event)"
            [value]="selectedColor"
            class="field__input"
            value=""
            readonly />
        </div>
      </div>
      <div class="field">
        <div class="field__label" for="description">
          {{ 'global.description' | translate }}
        </div>
        <div class="field__wrap">
          <textarea formControlName="description" id="description" class="field__input" value=""> </textarea>
        </div>
      </div>
    </form>
  </div>
</ng-template>
<ng-template #resultFooter>
  <div class="hidden__mobile">
    <app-button
      type="submit"
      color="primary"
      (click)="editField()"
      icon="agres-field"
      [disabled]="!editFieldForm.valid"
      title="{{ 'global.save' | translate }}">
    </app-button>
  </div>
  <app-menu-pwa>
    <div class="pwa__list--info">
      <button type="submit" (click)="hideOffCanvas()" data-bs-dismiss="offcanvas">
        <i class="agres-return"></i>
        <p>{{ 'global.back' | translate }}</p>
      </button>
    </div>
    <div class="pwa__list--info">
      <button type="submit" (click)="editField()" [disabled]="!editFieldForm.valid">
        <i class="agres-field"></i>
        <p>{{ 'global.save' | translate }}</p>
      </button>
    </div>
  </app-menu-pwa>
</ng-template>
<app-offcanvas
  size="medium"
  [show]="false"
  [showSubject]="offCanvasOne"
  [bodyTemplate]="resultBody"
  [footerTemplate]="resultFooter"
  title="{{ 'fields.create' | translate }}">
</app-offcanvas>
<app-menu-pwa>
  <div class="pwa__list--info pwa__list--info---active">
    <button type="button" (click)="callTogglePolygonDrawing()">
      <i class="agres-field-edit"></i>
      <p>{{ togglePolygonEditButtonTitle() }}</p>
    </button>
  </div>
  <div class="pwa__list--info">
    <button type="button" [disabled]="!isPolygonClosed" (click)="clearPolygons()">
      <i class="agres-close"></i>
      <p>{{ 'global.clear' | translate }}</p>
    </button>
  </div>
</app-menu-pwa>
<section class="mapMobile">
  <app-mapa-talhao
    *ngIf="!loading && geometry !== undefined"
    [updatePolygons]="onUpdatePolygons()"
    [resetData]="fetchFieldData"
    [polygonColorsSubject]="mapPolygonColorSubject"
    [geometry]="geometry"
    [polygonColor]="selectedColor"
    [updateIsPolygonClosed]="updateIsPolygonClosed"
    [mode]="'edit'"
    mapHeight="800px">
  </app-mapa-talhao>
</section>
<app-tutoriais-talhoes></app-tutoriais-talhoes>
