import { center, polygon } from '@turf/turf';
// IMPORT CORE
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  QueryList,
  ViewChildren,
} from '@angular/core';

// IMPORT MODELS
import { Location } from '@angular/common';
import { Preferences } from 'app/shared/models/preferences.model';
//import { Etapa } from '../../../shared/models/etapa';
import { E, Evento } from '../../../shared/models/evento';
import { EventoNVG } from '../../../shared/models/eventoNVG';

// IMPORT SERVICES
import { NvgShapefileService } from 'app/core/services/nvg-shapefile.service';
import { UserService } from 'app/core/services/user.service';

// IMPORT NVGFILES
import { NvgFilesService } from 'app/core/services/nvg-files.service';
import { NvgStorageService } from 'app/core/services/nvg-storage.service';
import { NvgTrabalhoService } from 'app/core/services/nvg-trabalho.service';

// IMPORT UTIL
import { TrailClass } from 'app/utils/trail.utils';

import { ConvertUnit } from 'app/shared/pipes/convertUnit.pipe';
import { GuideType } from 'app/utils/guide.utils';
import { OPERACAO } from 'app/utils/report-object-generator';

// IMPORT OTHERS
import * as turf from '@turf/turf';
import { ReportBuilder } from 'app/shared/reportBuilder';
import 'jquery';
import * as $ from 'jquery';
import 'jquery-slimscroll';

import { transition, trigger, useAnimation } from '@angular/animations';
import { TranslateService } from '@ngx-translate/core';
import { slideInRight, slideOutRight } from 'ng-animate';
import { ToastrService } from 'ngx-toastr';

import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { HttpClient } from '@angular/common/http';
import { MapMarker } from '@angular/google-maps';
import { ActivatedRoute, Router } from '@angular/router';
import { AppConstants } from 'app/app.constants';
import { TimeoutEnum } from 'app/core/enum/timeout.enum';
import { ReportService } from 'app/core/services/report.service';
import { AgresSpinnerService } from 'app/shared/components/agres-spinner/agres-spinner.service';
import { AlertService } from 'app/shared/components/alert/alert.service';
import { EventoISOBUS } from 'app/shared/models/eventoISOBUS';
import { TranslateUtil } from 'app/utils/translate.utils';
import { Observable, Subject, merge, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import {
  DevicePropertiesAndReferences,
  IsobusObjectBuilder,
} from './isobus-object-builder';
import { UserTalhaoListService } from 'app/core/services/user-talhao-list.service';

declare var google: any;
interface ReferenceCoordinate {
  lat: number;
  lng: number;
}

const FIELD_INST_RATE = 'inst_rate';
const FIELD_SPEED = 'speed';
const FIELD_OVERLAP = 'overlap';
const FIELD_ALTIMETRY = 'altitude';

const USER_MAP_COLOR = 'user_map_color.';

const SUBTITLE_PREFERENCES = {
  SPEED: 'tasks.machine-speed',
  APPLICATION_RATE: 'tasks.application-rate',
  DISTRIBUTED_LOAD: 'tasks.distributed-load',
  SEEDING_RATE: 'tasks.seeding-rate',
  SEEDS: 'tasks.tabs.seeds',
  ALTITUDE: 'tasks.altitude',
};

const TIPO_MARCADORES = {
  GUIDES: 'guides',
  MARKERS: 'markers',
  BOUNDARIES: 'boundaries',
  PAUSE_POINT: 'pausePointInit',
  PAUSE_END_POINT: 'pausePointEnd',
  STOP_POINT: 'stopPoint',
  TEMPERATURE_EXCEEDED_POINT: 'exceededTemperaturePoint',
  POWER_POINT: 'powerPoints',
};

const NVG_MAP_INDEX = {
  INST_RATE: 0,
  SPEED: 1,
  OVERLAP: 2,
  ALTIMETRY_OR_RECOMMENDATION: 3,
  RECOMMENDATION_OR_ALTIMETRY: 4,
};

const HEIGTH_SIDES_MENU = '220px';
const COLOR_SIDES_MENU = '#cecece';

interface CurrentViewportBreakpoint {
  mobile: boolean;
  tablet: boolean;
  desktop: boolean;
}

interface MapBackgroundAndRasterImages {
  backgroundImages: Array<string>;
  rasterImages: Array<string>;
  singleBackgroundImage: string;
}
type RasterType = 'inst_rate' | 'speed' | 'overlap' | 'altitude';

interface AvailableMapTypeToSelect {
  key: RasterType;
  value:
    | 'application-rate'
    | 'map-speed'
    | 'map-altimetry'
    | 'map-transgression';
}
interface PathPoint {
  lng: number;
  lat: number;
}

interface Boundary {
  path: PathPoint[];
}
type Map = 0 | 1;

//refatorar estas interfaces
interface Etapa {
  e: Evento;
  app_version: string;
  machine_name: string;
  crop_type: string;
  operator: string;
  deviceId: number;
  serial_number: string;
  cover_area: number;
  travel_dist: number;
  avg_speed: number;
  speed: number;
  avg_rate: number;
  app_vol: number;
  sec_number: number;
  nozz_spacing: number;
  nozz_per_section: number;
  nozz_number: number;
  distance_from_antenna_to_hitch: number;
  impl_width: number;
  timestamp: string;
  endTimestamp: string;
  operation: string;
  start_location: {
    latitude: number;
    longitude: number;
  };
  end_location: {
    latitude: number;
    longitude: number;
  };
}

interface EcuData {
  implementWidth: number;
  numberOfSections: number;
  averageApplicationRate: [number, string];
}

interface StageInfo {
  startTime: string;
  stopTime: string;
  ecusData: {
    [key: string]: EcuData;
  }[];
}

interface MergedData {
  [key: string]: EcuData &
    Etapa & {
      startTime: string;
      stopTime: string;
    };
}

interface FilteredEcusDataStageInfo {
  implementWidth: number;
  numberOfSections: number;
  averageApplicationRate: [number, string];
}

interface StageIsobusObjectData {
  implementWidth: number;
  numberOfSections: number;
  averageApplicationRate: [number, string];
  appVersion: string;
  machineName: string;
  cropType: string;
  operator: string;
  deviceId: number;
  serialNumber: string;
  coverArea: number;
  travelDist: number;
  avgSpeed: number;
  distanceFromAntennaToHitch: number;
  implWidth: number;
  timestamp: string;
  endTimestamp: string;
  startLocation: {
    latitude: number;
    longitude: number;
  };
  endLocation: {
    latitude: number;
    longitude: number;
  };
  startTime: string;
  stopTime: string;
}

@Component({
  selector: 'app-resultado-navegacao',
  templateUrl: 'resultado-navegacao.component.html',
  styleUrls: ['resultado-navegacao.component.scss'],
  providers: [ConvertUnit],
  animations: [
    trigger('slideMap', [
      transition(
        ':enter',
        useAnimation(slideInRight, { params: { timing: 1.5 } }),
      ),
      transition(
        ':leave',
        useAnimation(slideOutRight, { params: { timing: 1.5 } }),
      ),
    ]),
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ResultadoNavegacaoComponent
  implements OnInit, OnDestroy, AfterViewInit
{
  isobusObject: { [key: string]: any };
  selectedIsobusApplicationRate0: string;
  selectedIsobusApplicationRate1: string;
  fieldId: string;
  legendValidityChange(isLegendValid: boolean, errorValuesLegend: string) {
    this[errorValuesLegend] = !isLegendValid;
    this.seeButtonSave = isLegendValid;
  }
  apiLoaded: Observable<boolean>;

  @ViewChildren('guiasSideMenu') guiasSideMenuElements: QueryList<ElementRef>;

  nvgStorageData: EventoNVG | EventoISOBUS;
  operation_type = null;

  _opened = false;

  bounds: any;
  mapOneLoaded = false;
  mapTwoLoaded = false;
  numericCoverArea;
  centerMap: any;

  currentUnitSystem: string;
  unitSystemOptions = [
    AppConstants.UNIT_SYSTEM_OPTIONS.METRIC,
    AppConstants.UNIT_SYSTEM_OPTIONS.IMPERIAL,
  ];

  // VARIÁVES MAPA
  mapLat = 41.9171;
  mapLng = 8.7094;
  map: any;
  map1: any;
  mapZoom = 2;
  MAP_MAX_ZOOM_DEFAULT = 19;
  MAP_MAX_ZOOM = this.MAP_MAX_ZOOM_DEFAULT;

  splitScreen = false;
  lastInfoWindow: any;
  guideInfoWindowPoint = { lat: 0, long: 0 };

  mapTypesOptions = ['satellite', 'terrain', 'hybrid', 'roadmap'];

  hasAltimetryMap = false;
  mapaType = this.mapTypesOptions[0];

  mapa0 = 0;
  mapa1 = 0;

  point: google.maps.Point;
  scaleSize: google.maps.Size;

  mapOptions: google.maps.MapOptions = {
    center: { lat: this.mapLat, lng: this.mapLng },
    zoom: this.mapZoom,
    mapTypeId: this.mapaType,
    disableDefaultUI: true,
    disableDoubleClickZoom: true,
    fullscreenControl: true,
    zoomControl: true,
  };

  secondMapOptions: google.maps.MapOptions = {
    ...this.mapOptions,
    scaleControl: true,
    panControl: true,
    scrollwheel: null,
    fullscreenControl: false,
  };

  trailPolygonOptions: google.maps.PolygonOptions = {
    fillOpacity: 0.5,
    strokeWeight: 0,
    zIndex: 1,
    clickable: false,
  };

  MapasNvg = ['map-application', 'map-speed', 'map-transgression'];

  mapImageIndex = {
    inst_rate: 0,
    speed: 1,
    overlap: 2,
    altitude: 3,
  } as const;

  // VARIÁVEIS ABAS
  etapas: Etapa;
  timestamp_etapa_selecionada: string;
  endTimestamp_etapa_selecionada: string;
  travelDistance: number;
  etapasArr = [];

  stageQuantity: number;
  selectedStageIsobus: MergedData | null = null;
  selectedTotal: any;
  selectedKey: string = '';

  // VARIÁVEIS DO RASTRO
  workStatus: any;
  deviceAngle: number;
  sectionNumber: number;
  sectionArr = [];

  trailsM1: [[] | {}, [], [], [], [], [], [], []] = [
    [],
    [],
    [],
    [],
    [],
    [],
    [],
    [],
  ];
  trailsM2: [[] | {}, [], [], [], [], [], [], []] = [
    [],
    [],
    [],
    [],
    [],
    [],
    [],
    [],
  ];

  trailsIsobusMap1: Array<any> = [[], [], [], [], [], [], [], []];

  guides = [];
  firstGuideIndex: number;
  selectedGuide: any;
  htmlindexSelectedGuide: number;

  trailHidden = 'false';
  sectionArrLeft: Array<string> = [];
  preferences: Array<Preferences> = [];

  errorValuesLegend0 = false;
  errorValuesLegend1 = false;
  seeButtonSave = true;

  totalChangesLegendMap1: number;
  totalChangesLegendMap2: number;

  trabalho: Partial<Evento> | undefined;
  guias: Array<any>;
  marcadores: Array<any>;
  etapa: Array<any>;
  stageInfo: StageInfo[];
  stageInfoIsobus: any;
  devices: DevicePropertiesAndReferences[];
  deviceName: string;

  subtitles: any;
  altimetryPoints: Array<any>;
  boundaries: Array<any> = [];

  // VARIÁVEIS DAS CORES RASTRO
  instantRate: any;
  trailColorScale: string;
  trailColorSel = '#00cc00';
  trailClass: TrailClass;
  guideTypeEnum: typeof GuideType;

  // VARIÁVEIS DO HISTÓRICO DE NAVEGAÇÃO
  distanceUnit = 'km';
  speed = 0;
  app_vol = 0;
  tempoTotalOperacao: string | [number, string] = [0, ''];
  tempoEfetivoOperacao: string | [number, string] = [0, ''];
  tempoOciosoOperacao: string | [number, string] = [0, ''];
  percentTotalOperation: string | [number, string] = [0, ''];
  taskData: { [key: string]: any } = {};
  ecuData: { [key: string]: any } = {};
  objEntriesTotals: any;

  devicesIsobus: any[] = [];
  selectedDevice: string = '';
  isoBusEvento: { [key: string]: any };
  defaultSelect = '';
  trails: { [key: string]: Array<any> };
  applicationRates: string[] = [
    'applicationRateSolid',
    'applicationRateSeed',
    'applicationRateLiquid',
  ];
  bbox = [];
  boundingBox = [];
  bboxPolygon: turf.AllGeoJSON;
  startPin: Array<any> = [{ lat: 0, lng: 0, title: null }];
  showWorkMark: boolean;
  startLat: any;
  startLng: any;
  endLat: any;
  endLng: any;
  stageInfoIsobusStartTime: string = '';
  stageInfoIsobusStopTime: string = '';

  tipo1MapaEscolhido = '';
  tipo2MapaEscolhido = '';

  // VARIÁVEIS NVGFILES
  etapasNvg: any;
  stageNvgData: any;
  stageIsobusData: any;
  stageIsobusObjectData: StageIsobusObjectData;
  nvgName: string;
  firstLoad: boolean;
  presign: any;
  fileData: string | ArrayBuffer;
  fileName: string;

  labelInicio: string;
  labelFim: string;
  labelEtapa: string;

  logoAgres = new Image();
  // VARIÁVEIS PARA CONTROLE DE ABAS
  abaPrincipal = 't';
  abaEtapa = 'e';

  marcadoresArrayL = [];

  replayController: {
    isRunning: any;
    terminate: any;
    play?: () => Promise<void>;
    pause?: () => void;
    stop?: () => Promise<void>;
    canPlay?: () => boolean;
    canStop?: () => boolean;
    speedUp?: () => void;
    speedDown?: () => void;
    stepOneForward?: () => Promise<void>;
    stepOneBackwards?: () => void;
    getCurrentReproductionSpeed?: () => number;
    getMaxIndex?: () => number;
    getProgress?: () => any;
    progressBarClick?: (value: any) => void;
    fitMapAtStep?: () => void;
    getCurrentLocation?: () => any;
  };

  showRecommendationMapUpload = false;
  referenceCoordinate: ReferenceCoordinate;
  loadDistribution: string | null;

  public readonly ERROR_ON_FIELD = 'error';

  OPERATIONS = OPERACAO;

  destroyed = false;
  id: string;
  type: string;
  googleMapsApiLoaded: boolean = false;
  isMap1BeingEdited: boolean;
  isMap2BeingEdited: boolean;
  showModalSubject: Subject<boolean> = new Subject();
  referenceCoordinateSubject: Subject<ReferenceCoordinate> = new Subject();
  viewportBreakpoints: CurrentViewportBreakpoint = {
    mobile: true,
    tablet: false,
    desktop: false,
  };
  mapImages: MapBackgroundAndRasterImages = undefined;

  selectedMapImage: AvailableMapTypeToSelect = {
    key: 'inst_rate',
    value: 'application-rate',
  };
  readonly preferencesInputSorting: Preferences = {
    red: 0,
    orange: 1,
    yellow: 2,
    lemonGreen: 3,
    green: 4,
  };
  taskSummaryBoxValue: [string, string | undefined, boolean | undefined];
  isIsobusData: boolean = false;
  selectedMap1Value: number = 0;
  selectedMap2Value: number = 0;
  stageStartTime;
  stageStopTime;
  taskIsobusType: any;
  nvgData: any;
  hasField = false;
  operationsFieldsTitle: string;
  fieldButton = false;

  //TODO:verificar duplicados EE TIPAR CORRETAMENTE
  deviceNames: { name: string; serial: string }[] = [];
  filteredTask: string[] = [];
  filteredEcu: string[] = [];
  totals: any[] = [];
  totalObjects: any = {};
  filteredEcusDataStageInfo: FilteredEcusDataStageInfo;
  stageIsobusMergedData: any;
  foundApplicationRate: any;
  operationType: any;
  boundBoxLoadingIntervalRef: NodeJS.Timeout;
  constructor(
    public httpClient: HttpClient,
    public toastr: ToastrService,
    public cdr: ChangeDetectorRef,
    public translateService: TranslateService,
    public convertUnitPipe: ConvertUnit,
    public usuarioService: UserService,
    public nvgFilesService: NvgFilesService,
    public nvgStorageService: NvgStorageService,
    public nvgTrabalhoService: NvgTrabalhoService,
    public nvgShapefileService: NvgShapefileService,
    public route: ActivatedRoute,
    public router: Router,
    public reportService: ReportService,
    private eleRef: ElementRef,
    private breakpointObserver: BreakpointObserver,
    public loadingAgresSpinner: AgresSpinnerService,
    private alertService: AlertService,
    private _location: Location,
    public userTalhoesService: UserTalhaoListService,
  ) {
    this.currentUnitSystem = this.convertUnitPipe.getCurrentUnitSystem();
    this.setTranslateFieldsOnMap();
    this.trailClass = new TrailClass();
    this.guideTypeEnum = GuideType;
    this.changeLanguage();
    this.replayController = this.NavigationReplay();
  }

  ngOnInit() {
    this.taskSummaryBoxValue = this.taskSummaryBoxApplyedValue();

    this.updateSelectedMap2Value();
    this.logoAgres.src = '/assets/images/default/logo-agres.png';
    this.showModalSubject.subscribe((showModal: boolean) => {
      this.showRecommendationMapUpload = showModal;
    });
    const layoutChanges = this.breakpointObserver.observe([
      '(max-width: 480px)',
      '(max-width: 640px)',
    ]);
    const handleLayoutChanges = (result: {
      matches: boolean;
      breakpoints: { [key: string]: boolean };
    }) => {
      const breakpointEntries: Array<[string, boolean]> = Object.entries(
        result.breakpoints,
      );
      const mobileBreakPointFilter = (entries: [string, boolean]) =>
        entries[1] && entries[0] === '(max-width: 480px)';
      const tabletBreakPointFilter = (entries: [string, boolean]) =>
        (entries[1] && entries[0] === '(max-width: 640px)') ||
        (!entries[1] && entries[0] === '(max-width: 480px)');
      const desktopBreakPointFilter = (entries: [string, boolean]) =>
        !entries[1] && entries[0] === '(max-width: 640px)';

      const isMobile = breakpointEntries.some(mobileBreakPointFilter);
      const isTablet = breakpointEntries.every(tabletBreakPointFilter);
      const isDesktop = breakpointEntries.some(desktopBreakPointFilter);
      if (isMobile) {
        this.fetchMapImage(this.id);
      } else if (isDesktop) {
        this.setGoogleMapComponentObservable(this.httpClient);
      }
      this.viewportBreakpoints = {
        mobile: isMobile && !(isTablet || isDesktop),
        tablet: isTablet && !isDesktop,
        desktop: isDesktop,
      };
    };
    const handleQueryParams = (params) => {
      const id = params['id'];
      this.id = id;
      const type = params['type'];
      this.type = type;

      if (id == null || id == '') {
        this.translateService
          .get('page-erros.500.error-occurred-try-again')
          .subscribe((res: string) => {
            this.toastr.error(res);

            setTimeout(function () {
              this.router.navigate(['/gestao-operacao/historico-navegacao']);
            }, 500);
          });
      } else {
        this.eventNvgSelect(id);
      }
    };
    const queryParams = this.route.queryParams;
    const queryParamsAndLayoutChanges = merge(queryParams, layoutChanges);
    queryParamsAndLayoutChanges.subscribe((res) => {
      if ('breakpoints' in res && 'matches' in res) {
        handleLayoutChanges(res as BreakpointState);
      } else if ('id' in res && 'type' in res) {
        handleQueryParams(res);
      }
    });
  }

  private setGoogleMapComponentObservable(httpClient: HttpClient) {
    this.apiLoaded = httpClient
      .jsonp(
        'https://maps.googleapis.com/maps/api/js?key=AIzaSyAWHf8YuyJtv42nFtws9Rj4cGmut3S-yTo&libraries=drawing',
        'callback',
      )
      .pipe(
        map(() => {
          this.point = new google.maps.Point(15, 10);
          this.scaleSize = new google.maps.Size(30, 30);
          this.googleMapsApiLoaded = true;
          return true;
        }),
        catchError(() => of(false)),
      );
  }

  private fetchMapImage(id: any) {
    this.reportService.getReportImages(id).subscribe(
      (mapImages) => {
        this.loadingAgresSpinner.toHide();
        this.mapImages = mapImages;
      },
      (e) => {
        console.error(e);
      },
    );
  }

  _downloadShape(): Promise<void> {
    return new Promise((resolve) => {
      const polygonsOptionKeys = [
        {
          key: 'Dose',
          unit: this._getSIPreferenceSubtitleUnit(0).replace(/[()]/g, ''),
        },
        {
          key: 'Veloc.',
          unit: this._getSIPreferenceSubtitleUnit(1).replace(/[()]/g, ''),
        },
        {
          key: 'Transp.',
          unit: this._getSIPreferenceSubtitleUnit(2).replace(/[()]/g, ''),
        },
      ];
      const polygonsAltimetryOptionKeys = [{ key: 'Altitude' }];

      const trailPolygons =
        this._createTurfShapeFilePolygons(polygonsOptionKeys);
      const turfAltimetryPoints = this._createAltimetryTurfPoints(
        polygonsAltimetryOptionKeys,
      );

      const folderEvent = this.trabalho.evt_name;

      const options = {
        folder: folderEvent,
        altimetryFileName: folderEvent + '_altimetria',
        types: {
          point: 'points',
          polygon: 'polygons',
          line: 'lines',
        },
      };

      window['shpwrite'].download(
        {
          type: 'FeatureCollection',
          features: trailPolygons,
          altimetryPoints: turfAltimetryPoints,
        },
        options,
      );

      resolve();
    });
  }

  _createAltimetryTurfPoints(polygonsAltimetryOptionKeys) {
    const turfAltimetryPoints = [];
    if (this.altimetryPoints && this.altimetryPoints.length > 0) {
      this.altimetryPoints.forEach((point, index) => {
        const turfPoint = turf.point(
          [point.location.longitude, point.location.latitude],
          {
            ID: index,
            [polygonsAltimetryOptionKeys[0].key]:
              this.convertUnitPipe.convertValueToSupportedMapUnits(
                point.altitude,
                'm',
              ),
          },
        );
        turfAltimetryPoints.push(turfPoint);
      });
    }
    return turfAltimetryPoints;
  }

  downloadShapefile() {
    this.translateService
      .get('tasks.generate-shapefile')
      .subscribe((res: string) => {
        this.toastr.info(res, '', { disableTimeOut: false });
        try {
          this.nvgShapefileService
            .getShapefilePressignedURL(
              this.nvgName,
              this.trabalho.crop_name,
              this.trabalho.evt_name,
            )
            .subscribe((shapefileURL: string) => {
              this._downloadShapefileZip(shapefileURL);
            });
        } catch (err) {
          this._createShapefileInBrowser();
        }
      });
  }

  _report() {
    this.translateService
      .get('tasks.generate-report')
      .subscribe((res: string) => {
        this.toastr.info(res, '', { disableTimeOut: true });
        this.reportService.getReportImages(this.nvgName).subscribe((images) => {
          const translateUtils = new TranslateUtil();
          const reportBuilder = new ReportBuilder(
            this.translateService,
            this.convertUnitPipe,
            translateUtils.getCurrentLocale(),
          );
          reportBuilder
            .buildReport(
              this.trabalho as Evento,
              this.nvgStorageData as EventoNVG,
              this.logoAgres,
              images,
            )
            .then(() => {
              this.toastr.clear();
              this._checkDetectChanges();
              this.translateService
                .get('tasks.sucess-report')
                .subscribe((msgSucessReport) => {
                  this.toastr.success(msgSucessReport, '', {
                    disableTimeOut: false,
                  });
                });
            });
        });
      });
  }

  ngOnDestroy() {
    this.destroyed = true;
    this.loadingAgresSpinner.toHide();

    if (this.replayController.isRunning()) this.replayController.terminate();
    if (typeof google !== 'undefined') google = undefined;
    clearInterval(this.boundBoxLoadingIntervalRef);
  }

  ngAfterViewInit() {
    this.guiasSideMenuElements.changes.subscribe(
      (guidesSideMenuElements: QueryList<ElementRef>) => {
        if (guidesSideMenuElements.length > 0) {
          (<any>jQuery(guidesSideMenuElements.first.nativeElement)).slimscroll({
            height: HEIGTH_SIDES_MENU,
            color: COLOR_SIDES_MENU,
            allowPageScroll: true,
            alwaysVisible: false,
          });
        }
      },
    );
    $('.ng-sidebar').css({ 'margin-bottom': '45px', 'z-index': '1' });

    jQuery('.tab-divisor').on('click', function (e) {
      e.preventDefault();

      const self = $(this);
      const idTab = self.attr('data-tab-build');

      $('.tab-pane, .tab-divisor').removeClass('active');
      $('.aba').removeClass('abaActive');
      self.addClass('active').children('a').addClass('abaActive');
      $('#' + idTab).addClass('active');
    });

    this.nvgTrabalhoService.trabalho = undefined;
    this.firstLoad = true;
  }

  updateTrailColor(trailIndexToUpdate: number) {
    // Verifica se a trilha no índice especificado existe em trailsM1
    if (this.trailsM1[trailIndexToUpdate]) {
      // Se a trilha no índice especificado é um array
      if (Array.isArray(this.trailsM1[trailIndexToUpdate])) {
        // Atualiza a cor das trilhas no array
        this.updateArrayTrailColor(
          this.trailsM1[trailIndexToUpdate] as Array<any>,
          trailIndexToUpdate,
        );
      } else {
        // Se não é um array, itera sobre as chaves do objeto
        for (let key in this.trailsM1[trailIndexToUpdate] as {}) {
          const raster = this.trailsM1[trailIndexToUpdate][key];
          if (raster) {
            // Atualiza a cor das trilhas no array associado à chave
            this.updateArrayTrailColor(raster, trailIndexToUpdate);
          }
        }
      }
      // Sincroniza trailsM1 com trailsM2 conforme a estrutura (array ou objeto)
      if (
        !Array.isArray(this.trailsM1[trailIndexToUpdate]) &&
        Array.isArray(this.trailsM2[trailIndexToUpdate])
      ) {
        // Se trailsM1 é um objeto e trailsM2 é um array, copia o objeto
        this.trailsM2[trailIndexToUpdate] = {
          ...this.trailsM1[trailIndexToUpdate],
        };
      } else if (
        Array.isArray(this.trailsM1[trailIndexToUpdate]) &&
        Array.isArray(this.trailsM2[trailIndexToUpdate])
      ) {
        // Se ambos são arrays, copia as propriedades de trailsM1 para trailsM2
        Object.assign(
          this.trailsM2[trailIndexToUpdate],
          this.trailsM1[trailIndexToUpdate],
        );
      }
    }
  }

  //Este método atualiza a cor de cada polígono dentro de uma trilha específica.
  //Este método é chamado dentro de updateTrailColor para atualizar todas as trilhas que são arrays
  private updateArrayTrailColor(trail: any[], trailIndexToUpdate: number) {
    trail.forEach((polygon, index) => {
      const value: number =
        this.shouldShowLegend() ||
        trailIndexToUpdate === NVG_MAP_INDEX.SPEED ||
        trailIndexToUpdate === NVG_MAP_INDEX.ALTIMETRY_OR_RECOMMENDATION ||
        trailIndexToUpdate === NVG_MAP_INDEX.RECOMMENDATION_OR_ALTIMETRY
          ? polygon.valueOperation
          : 0;

      const newColor = this.trailClass.trailColorPref(
        'false',
        undefined,
        value,
        this.preferences[trailIndexToUpdate],
        'desc',
      ).trailColorScale;
      trail[index].color = newColor;
    });
  }

  etapaClick() {
    if (this.firstLoad) {
      this.stageSelect(event, this.etapasArr[0], 0);

      this.firstLoad = false;
    }
    if (this.viewportBreakpoints.mobile) return;
    this._showAllGuidesDependingOnGuidesSwitch();
    this.showWorkMark = false;
  }

  // SELECIONAR ETAPA (REALTIME E NVG)
  stageSelect(_event, e, i) {
    // Atualizar os dados relevantes para a etapa selecionada
    if (!this.isIsobusData) {
      this.stageNvgData = e;
      this.etapasNvg = e;
      this.timestamp_etapa_selecionada = e?.timestamp;
      this.endTimestamp_etapa_selecionada = e?.endTimestamp;
      this.travelDistance = e?.travelDist;
      this.setStepPins(this.etapa[i]);
    } else {
      this.totals = e;

      this.stageIsobusObjectData = e;

      this.updateFilteredDataStageInfoEcusIsobus();

      this.updateStageDataForSelectedDevice(i);
    }
  }

  setStepPins(currentStep: { start_location: any; end_location: any }) {
    const startpoint = currentStep.start_location;
    if (startpoint) {
      this.startLng = startpoint.longitude;
      this.startLat = startpoint.latitude;
    }
    const endpoint = currentStep.end_location;
    if (endpoint) {
      this.endLng = endpoint.longitude;
      this.endLat = endpoint.latitude;
    }
  }

  guidesTabClick() {
    this.guideSelect(this.firstGuideIndex);
    if (this.viewportBreakpoints.mobile) return;
    this.showWorkMark = false;
  }

  guideSelect(index: number) {
    this.selectedGuide = this.guides[index];
    this.htmlindexSelectedGuide = index;

    if (this.selectedGuide) {
      this.guides.forEach((guide, i) => {
        if (i === index) {
          guide.show = true;
        } else {
          guide.show = false;
        }
      });

      if (this.lastInfoWindow) {
        this.lastInfoWindow.close();
      }

      this._checkGuideMarkerSwitch();
    }
  }

  _hasMarkerOfType(markersObj, marker) {
    return markersObj[marker] != null && markersObj[marker].length > 0;
  }

  toggleMarker(marker: { checked: boolean; property: string }) {
    const markers = {
      [TIPO_MARCADORES.PAUSE_POINT]: 'pausePointsInit',
      [TIPO_MARCADORES.PAUSE_END_POINT]: 'pausePointsEnd',
      [TIPO_MARCADORES.STOP_POINT]: 'stopPoints',
      [TIPO_MARCADORES.TEMPERATURE_EXCEEDED_POINT]: 'exceededTemperaturePoints',
      [TIPO_MARCADORES.POWER_POINT]: 'powerPoint',
    };

    marker.checked = !marker.checked;

    if (marker.property === TIPO_MARCADORES.GUIDES) {
      this._toggleCheckedStateForGuidesMaker(marker.checked);
    } else if (marker.property === TIPO_MARCADORES.MARKERS) {
      this._toggleShowStateForAllMarkers(marker.checked);
      this._toggleCheckedStateForAllMakersSwitchButton(marker.checked);
    } else if (marker.property === TIPO_MARCADORES.BOUNDARIES) {
      this._toggleCheckedStateForBoundariesMarker(marker.checked);
    } else {
      this.marcadores[markers[marker.property]].forEach((taskMarker) => {
        taskMarker.show = marker.checked;
      });
    }
    this._controlMasterMarkersCheckState();
  }

  _getPreferenceSubtitleByOperation(operation: string | number): string {
    return (
      {
        [OPERACAO.PULVERIZACAO]: SUBTITLE_PREFERENCES.APPLICATION_RATE,
        [OPERACAO.FRUTICULTURA]: SUBTITLE_PREFERENCES.APPLICATION_RATE,
        [OPERACAO.ADUBACAO]: SUBTITLE_PREFERENCES.DISTRIBUTED_LOAD,
        [OPERACAO.PLANTIO]: SUBTITLE_PREFERENCES.SEEDING_RATE,
      }[operation] || ''
    );
  }

  _toggleShowStateForAllMarkers(newState: boolean) {
    Object.keys(this.marcadores).forEach((markerType) => {
      this.marcadores[markerType].forEach((marker) => {
        marker.show = newState;
      });
    });
  }

  _toggleCheckedStateForAllMakersSwitchButton(newState: boolean) {
    this.marcadoresArrayL.forEach((markerSwitch) => {
      if (markerSwitch.children) {
        markerSwitch.checked = newState;
      }
    });
  }

  _toggleCheckedStateForGuidesMaker(newState: boolean) {
    if (this.selectedGuide) {
      const index = this.guides.indexOf(this.selectedGuide);
      this.guides[index].show = newState;
    } else {
      this.guides.forEach((guide) => {
        guide.show = newState;
      });
    }
  }

  _toggleCheckedStateForBoundariesMarker(newState: boolean) {
    this.boundaries = this.boundaries.map((b) => {
      b.visible = newState;
      return b;
    });
  }

  _checkGuideMarkerSwitch() {
    const guidesMarker = this.marcadoresArrayL.find(
      (marker) => marker.property === TIPO_MARCADORES.GUIDES,
    );
    if (guidesMarker && !guidesMarker.checked) this.toggleMarker(guidesMarker);
  }

  _showAllGuidesDependingOnGuidesSwitch() {
    const guidesMarker = this.marcadoresArrayL.find(
      (marker) => marker.property === TIPO_MARCADORES.GUIDES,
    );
    this.selectedGuide = undefined;
    if (guidesMarker?.checked) {
      this.guides.forEach((guide) => {
        guide.show = true;
      });
    }
  }
  setDefaultSubtitle() {
    if (this.subtitles) {
      this.seeButtonSave = true;
      const instRateSubtitle = this.subtitles[FIELD_INST_RATE];
      const speedSubtitle = this.subtitles[FIELD_SPEED];
      const overlapSubtitle = this.subtitles[FIELD_OVERLAP];
      const altimetrySubtitle = this.subtitles[FIELD_ALTIMETRY];
      const recommendationSubtitle = this.hasAltimetryMap
        ? this.preferencesToSubtitleArray(
            this.preferences[NVG_MAP_INDEX.RECOMMENDATION_OR_ALTIMETRY],
          )
        : this.preferencesToSubtitleArray(
            this.preferences[NVG_MAP_INDEX.ALTIMETRY_OR_RECOMMENDATION],
          );

      this.preferences[NVG_MAP_INDEX.INST_RATE] =
        this.subtitleArrayToPreferences(instRateSubtitle);

      this.preferences[NVG_MAP_INDEX.SPEED] =
        this.subtitleArrayToPreferences(speedSubtitle);
      this.preferences[NVG_MAP_INDEX.OVERLAP] =
        this.subtitleArrayToPreferences(overlapSubtitle);

      if (this.hasAltimetryMap) {
        this.preferences[NVG_MAP_INDEX.ALTIMETRY_OR_RECOMMENDATION] =
          this.subtitleArrayToPreferences(altimetrySubtitle);
        this.updateTrailColor(NVG_MAP_INDEX.ALTIMETRY_OR_RECOMMENDATION);
        this.preferences[NVG_MAP_INDEX.RECOMMENDATION_OR_ALTIMETRY] =
          this.subtitleArrayToPreferences(recommendationSubtitle);
        this.updateTrailColor(NVG_MAP_INDEX.RECOMMENDATION_OR_ALTIMETRY);
      } else {
        this.preferences[NVG_MAP_INDEX.ALTIMETRY_OR_RECOMMENDATION] =
          this.subtitleArrayToPreferences(recommendationSubtitle);
        this.updateTrailColor(NVG_MAP_INDEX.ALTIMETRY_OR_RECOMMENDATION);
      }
      this.updateTrailColor(NVG_MAP_INDEX.INST_RATE);
      this.updateTrailColor(NVG_MAP_INDEX.SPEED);
      this.updateTrailColor(NVG_MAP_INDEX.OVERLAP);

      const unitArray = this._getSubtitleUnitsArray('metric');
      this._convertSubtitles(unitArray);
      this.cdr.markForCheck();
      localStorage.setItem(
        USER_MAP_COLOR + this.nvgName,
        JSON.stringify(
          this._convertLegendsToSI(
            this.prepareLegendDataToSave(),
            AppConstants.UNIT_SYSTEM_OPTIONS.METRIC,
          ),
        ),
      );

      this.errorValuesLegend0 = false;
      this.errorValuesLegend1 = false;
    }
  }

  // Função para verificar se a chave `this.selectedDevice` existe no objeto
  checkIfSelectedDeviceExists(selectedDevice: string, object: any): boolean {
    return selectedDevice in object;
  }

  // Verifica e processa dinamicamente com base no dispositivo selecionado
  processSelectedDevice(selectedDevice: string, object: any, subtitles: any) {
    const availableKeys = [
      'applicationRateLiquid',
      'applicationRateSolid',
      'applicationRateSeed',
    ];

    // Verifica se o dispositivo selecionado existe no objeto
    if (!(selectedDevice in object)) {
      console.error('Dispositivo não encontrado.');
      return;
    }

    const device = object[selectedDevice];
    // Verifica qual das chaves disponíveis existe no objeto do dispositivo
    const matchingKey = availableKeys.find((key) => key in device);

    this.loadDistribution = device[matchingKey][1];

    if (!matchingKey) {
      console.error('Nenhuma correspondência encontrada.');
      return;
    }

    // Obtém o subtítulo correspondente dinamicamente
    const instRateSubtitle = subtitles[matchingKey];

    // Lógica de substituição dinâmica
    this.preferences[NVG_MAP_INDEX.INST_RATE] =
      this.subtitleArrayToPreferences(instRateSubtitle);
  }

  setDefaultSubtitleIsobus() {
    if (this.subtitles) {
      this.seeButtonSave = true;
      this.processSelectedDevice(
        this.selectedDevice,
        this.trails,
        this.subtitles,
      );

      const speedSubtitle = this.subtitles[FIELD_SPEED];
      const overlapSubtitle = this.subtitles[FIELD_OVERLAP];

      this.preferences[NVG_MAP_INDEX.SPEED] =
        this.subtitleArrayToPreferences(speedSubtitle);
      this.preferences[NVG_MAP_INDEX.OVERLAP] =
        this.subtitleArrayToPreferences(overlapSubtitle);

      this.updateTrailColor(NVG_MAP_INDEX.INST_RATE);
      this.updateTrailColor(NVG_MAP_INDEX.SPEED);
      this.updateTrailColor(NVG_MAP_INDEX.OVERLAP);

      // Converte as legendas e salva no localStorage no formato esperado
      this._convertAndSaveLegends();

      this.errorValuesLegend0 = false;
      this.errorValuesLegend1 = false;
    } else {
      this.preferences[NVG_MAP_INDEX.INST_RATE] =
        this.subtitles[NVG_MAP_INDEX.INST_RATE];
    }
  }

  private _convertAndSaveLegends() {
    const unitArray = this._getSubtitleUnitsArray('metric');
    this._convertSubtitles(unitArray);

    const legendData = this.prepareLegendDataToSave();
    const convertedLegends = this._convertLegendsToSI(
      legendData,
      AppConstants.UNIT_SYSTEM_OPTIONS.METRIC,
    );
    localStorage.setItem(
      USER_MAP_COLOR + this.nvgName,
      JSON.stringify(convertedLegends),
    );

    this.cdr.markForCheck();
  }

  _getSIPreferenceSubtitleUnitByOperation(operation: string | number) {
    return (
      {
        [OPERACAO.PULVERIZACAO]: 'l/ha',
        [OPERACAO.FRUTICULTURA]: 'l/ha',
        [OPERACAO.ADUBACAO]: 'kg/ha',
        [OPERACAO.PLANTIO]: 'seeds/m',
      }[operation] || ''
    );
  }

  _getSubtitleUnitsArray(unitSystemFrom: string) {
    const unitArray = [];
    for (const mapIndex in NVG_MAP_INDEX) {
      if (NVG_MAP_INDEX.hasOwnProperty(mapIndex)) {
        const unit = this.convertUnitPipe.getUnitFromSISystem(
          this._getSIPreferenceSubtitleUnit(NVG_MAP_INDEX[mapIndex]),
          unitSystemFrom,
        );
        unitArray.push(unit);
      }
    }
    return unitArray;
  }

  _convertSubtitles(unitArray: any[]) {
    for (const mapIndex in NVG_MAP_INDEX) {
      if (NVG_MAP_INDEX.hasOwnProperty(mapIndex)) {
        const subtitleArray = this.preferencesToSubtitleArray(
          this.preferences[NVG_MAP_INDEX[mapIndex]],
        );
        const convertedSubtitle =
          this.convertUnitPipe.convertAllValuesFromArrayToSameUnit(
            subtitleArray,
            unitArray[NVG_MAP_INDEX[mapIndex]],
          );
        this.preferences[NVG_MAP_INDEX[mapIndex]] =
          this.subtitleArrayToPreferences(convertedSubtitle);
      }
    }
  }
  _convertSubtitlesToSI() {
    for (const mapIndex in NVG_MAP_INDEX) {
      const subtitleArray = this.preferencesToSubtitleArray(
        this.preferences[NVG_MAP_INDEX[mapIndex]],
      );
      const convertedSubtitle = [];
      const unit = this.convertUnitPipe.transformUnit(
        this._getSIPreferenceSubtitleUnit(NVG_MAP_INDEX[mapIndex]),
      );
      subtitleArray.forEach((subtitle) => {
        convertedSubtitle.push(
          +this.convertUnitPipe.convertToSIUnit(+subtitle, unit).val.toFixed(2),
        );
      });
      this.preferences[NVG_MAP_INDEX[mapIndex]] =
        this.subtitleArrayToPreferences(convertedSubtitle);
    }
  }
  _convertLegendsToSI(
    legendDataToSave: {
      inst_rate: [number, number, number, number, number];
      speed: [number, number, number, number, number];
      overlap: [number, number, number, number, number];
      altitude: [number, number, number, number, number];
    },
    currentUnitSystem,
  ) {
    const customTrails = [
      FIELD_INST_RATE,
      FIELD_SPEED,
      FIELD_OVERLAP,
      FIELD_ALTIMETRY,
    ];
    const sIPreferenceSubtitleUnit = [
      this._getSIPreferenceSubtitleUnitByOperation(this.operation_type),
      'km/h',
      this._getSIPreferenceSubtitleUnitByOperation(this.operation_type),
    ];
    if (this.hasAltimetryMap) {
      sIPreferenceSubtitleUnit.push('m');
    } else {
      sIPreferenceSubtitleUnit.push(
        this._getSIPreferenceSubtitleUnitByOperation(this.operation_type),
      );
    }
    for (let i = 0; i < customTrails.length; i++) {
      const subtitleArray = legendDataToSave[customTrails[i]];
      if (currentUnitSystem !== AppConstants.UNIT_SYSTEM_OPTIONS.METRIC) {
        const convertedSubtitle = [];
        const unit = this.convertUnitPipe.transformUnit(
          sIPreferenceSubtitleUnit[i],
        );
        subtitleArray.forEach((subtitle) => {
          convertedSubtitle.push(
            +this.convertUnitPipe
              .convertToSIUnit(+subtitle, unit)
              .val.toFixed(2),
          );
        });
        legendDataToSave[customTrails[i]] =
          this.subtitleArrayToPreferences(convertedSubtitle);
      } else {
        legendDataToSave[customTrails[i]] =
          this.subtitleArrayToPreferences(subtitleArray);
      }
    }
    return legendDataToSave;
  }

  subtitleArrayToPreferences(arraySubtitle: number[]) {
    const preferences = <Preferences>{
      red: arraySubtitle[0],
      orange: arraySubtitle[1],
      yellow: arraySubtitle[2],
      lemonGreen: arraySubtitle[3],
      green: arraySubtitle[4],
    };
    return preferences;
  }

  setNvgSubtitle(nvgId: string) {
    const subtitlesFromLocal = this.getSubtitleFromLocalStorage(nvgId);
    if (subtitlesFromLocal) {
      const parsedSubtitles = JSON.parse(subtitlesFromLocal);

      this.preferences[NVG_MAP_INDEX.INST_RATE] =
        parsedSubtitles[FIELD_INST_RATE];
      this.preferences[NVG_MAP_INDEX.SPEED] = parsedSubtitles[FIELD_SPEED];
      this.preferences[NVG_MAP_INDEX.OVERLAP] = parsedSubtitles[FIELD_OVERLAP];
      if (parsedSubtitles[FIELD_ALTIMETRY]) {
        this.preferences[NVG_MAP_INDEX.ALTIMETRY_OR_RECOMMENDATION] =
          parsedSubtitles[FIELD_ALTIMETRY];
        this.updateTrailColor(NVG_MAP_INDEX.ALTIMETRY_OR_RECOMMENDATION);
      }
      this.updateTrailColor(NVG_MAP_INDEX.INST_RATE);
      this.updateTrailColor(NVG_MAP_INDEX.SPEED);
      this.updateTrailColor(NVG_MAP_INDEX.OVERLAP);
      const unitsArray = this._getSubtitleUnitsArray('metric');
      this._convertSubtitles(unitsArray);
    } else {
      this.setDefaultSubtitle();
      this.setDefaultSubtitleIsobus();
    }
  }

  showInfoWindow(
    lat: number,
    long: number,
    infowindow: { open: (marker: MapMarker) => void },
    marker: MapMarker,
  ) {
    if (infowindow) {
      this.guideInfoWindowPoint.lat = lat;
      this.guideInfoWindowPoint.long = long;

      if (this.lastInfoWindow) {
        this.lastInfoWindow.close();
      }
      this.lastInfoWindow = infowindow;
      infowindow.open(marker);
    }
  }

  showMarkerInfoWindow(
    markerInfowindow: { open: (marker: MapMarker) => void },
    marker: MapMarker,
  ) {
    if (this.lastInfoWindow) {
      this.lastInfoWindow.close();
    }
    this.lastInfoWindow = markerInfowindow;
    markerInfowindow.open(marker);
  }

  setStartPinLocation(storageData: any) {
    if (storageData && 'start_location' in storageData) {
      this.startPin[0].lat = storageData.start_location.latitude;
      this.startPin[0].lng = storageData.start_location.longitude;
    } else {
      this.startPin[0].lat = null;
      this.startPin[0].lng = null;
    }
  }

  mapReady(map) {
    const greenFlag = this.eleRef.nativeElement.querySelector(
      '.flag-green-start-work-result',
    );
    if (greenFlag !== null) greenFlag.style.marginBottom = '45px';

    this.map = map;
    this.boundBoxLoadingIntervalRef = setInterval(() => {
      if (this.boundingBox.length > 0 && !this.mapOneLoaded) {
        this.setMapBounds();
        map.fitBounds(this.bounds);
        this.mapOneLoaded = true;
        this.cdr.markForCheck();
        this.mapZoom = map.zoom;
        this.loadingAgresSpinner.toHide();
        clearInterval(this.boundBoxLoadingIntervalRef);
      }
    }, 25);
  }

  mapReadySegundo(map) {
    const greenFlag = this.eleRef.nativeElement.querySelector(
      '.flag-green-start-work-result1',
    );
    if (greenFlag !== null) greenFlag.style.marginBottom = '45px';
    this.map1 = map;

    const boundBoxLoadingIntervalRef = setInterval(() => {
      if (this.mapOneLoaded && !this.mapTwoLoaded) {
        map.fitBounds(this.map.getBounds());
        this.mapTwoLoaded = true;
        map.setZoom(this.map.zoom);
        this.loadingAgresSpinner.toHide();
        clearInterval(boundBoxLoadingIntervalRef);
      }
    }, 25);
  }

  splitMap() {
    if (this.splitScreen === false) {
      this.mapTwoLoaded = false;
      this.splitScreen = true;
      $('#mapa-1')
        .removeClass('col-sm-12 slideOutMap-1')
        .addClass('col-sm-6 slideInMap-1');
      $('#mapa-2')
        .addClass('col-sm-6 slideMap-2')
        .css({ opacity: '0', display: 'block' });
      const indexMap1 = $('.mapType1 option:selected').index();
      if (indexMap1 === 0) {
        $('.mapType2 option').eq(1).prop('selected', true);
        this.mapa1 = NVG_MAP_INDEX.SPEED;
      } else {
        $('.mapType2 option').eq(0).prop('selected', true);
        this.mapa1 = NVG_MAP_INDEX.INST_RATE;
      }

      setTimeout(() => {
        $('#mapa-2').css('opacity', '1');
        $('#mapa-2').css('display', 'block');
        $('.select-map-2').css('display', 'block');
      }, 500);
    } else {
      this.mapTwoLoaded = false;
      this.splitScreen = false;
      $('#mapa-1')
        .removeClass('col-sm-6 slideInMap-1')
        .addClass('col-sm-12 slideOutMap-1');
      $('#mapa-2, .select-map-2').css('display', 'none');
    }
  }

  getBackgroundImageURL(): string {
    if (this.mapImages.singleBackgroundImage) {
      return `data:image/png;base64,${this.mapImages.singleBackgroundImage}`;
    } else {
      return `data:image/png;base64,${this.mapImages.backgroundImages[0]}`;
    }
  }
  getAvailableRasters(): Array<{ key: string; value: string }> {
    const avaliableRasters = [];
    for (const key in this.mapImageIndex) {
      const value = this.mapImageIndex[key];
      if (this.mapImages.rasterImages[value]) {
        const keyTotranslationKey = {
          [FIELD_INST_RATE]: 'application-rate',
          [FIELD_SPEED]: 'map-speed',
          [FIELD_ALTIMETRY]: 'map-altimetry',
          [FIELD_OVERLAP]: 'map-transgression',
        };
        const value = keyTotranslationKey[key];
        avaliableRasters.push({ key, value });
      }
    }
    return avaliableRasters;
  }
  compareMapOptionsOnSelect(
    c1: {
      key: RasterType;
      value: string;
    },
    c2: { key: RasterType; value: string },
  ): boolean {
    return c1 && c2 ? c1.key === c2.key : c1 === c2;
  }

  shouldShowLegend(selectedMap: '0' | '1' = '0'): boolean {
    const excludedTypes: Set<string> = new Set([
      'outros',
      'colheita',
      'navegacao',
      'altitude',
    ]);

    // Verifica se a legenda deve ser exibida com base nas condições especificadas
    return (
      !excludedTypes.has(this.operation_type) || // Se o tipo de operação não está nos tipos excluídos
      this[`mapa${selectedMap}`] === NVG_MAP_INDEX.SPEED || // Ou se o mapa selecionado é de velocidade
      this[`mapa${selectedMap}`] ===
        NVG_MAP_INDEX.ALTIMETRY_OR_RECOMMENDATION || // Ou se é de altimetria ou recomendação
      this[`mapa${selectedMap}`] === NVG_MAP_INDEX.RECOMMENDATION_OR_ALTIMETRY // Ou se é de recomendação ou altimetria
    );
  }

  formatPaths(
    paths: { lat: number | string; long: number | string }[],
  ): { lat: number; lng: number }[] {
    return paths
      .map((path) => ({
        lat: parseFloat(String(path.lat).trim()),
        lng: parseFloat(String(path.long).trim()),
      }))
      .filter((path) => !isNaN(path.lat) && !isNaN(path.lng));
  }

  setTranslateFieldsOnMap(): void {
    this.translateService
      .get(['tasks.inicio', 'tasks.fim'])
      .subscribe((res: { ['tasks.inicio']: string; ['tasks.fim']: string }) => {
        this.labelInicio = res['tasks.inicio'];
        this.labelFim = res['tasks.fim'];
      });

    this.translateService
      .get('tasks.tabs.title-stage')
      .subscribe((resEtapa: string) => {
        this.labelEtapa = resEtapa;
        const startPinLength = this.startPin?.length;
        if (this.startPin != null && startPinLength > 0) {
          let indiceEtapa = 1;
          for (let index = 0; index < startPinLength; index++) {
            this.startPin[index].title = `${resEtapa} ${indiceEtapa++}`;
          }
        }
      });
  }

  splitMapOnRecommendationMapLoading() {
    this.mapa0 = NVG_MAP_INDEX.INST_RATE;
    this.tipo1MapaEscolhido = this.MapasNvg[this.mapa0];

    this.mapa1 = this.hasAltimetryMap
      ? NVG_MAP_INDEX.RECOMMENDATION_OR_ALTIMETRY
      : NVG_MAP_INDEX.ALTIMETRY_OR_RECOMMENDATION;
    this.tipo2MapaEscolhido = this.MapasNvg[this.mapa1];

    if (this.splitScreen === false) {
      this.splitScreen = true;
      $('#mapa-1')
        .removeClass('col-sm-12 slideOutMap-1')
        .addClass('col-sm-6 slideInMap-1');
      $('#mapa-2')
        .addClass('col-sm-6 slideMap-2')
        .css({ opacity: '0', display: 'block' });

      setTimeout(() => {
        $('#mapa-2').css({ opacity: '1', display: 'block' });
        $('.mapType2 option').eq(this.mapa1).prop('selected', true);
        $('.mapType1 option').eq(this.mapa0).prop('selected', true);
      }, 500);
    } else {
      setTimeout(() => {
        $('.mapType2 option').eq(this.mapa1).prop('selected', true);
        $('.mapType1 option').eq(this.mapa0).prop('selected', true);
      }, 500);
    }
  }

  updateMapCenterAndZoom(map, changedMap: 0 | 1) {
    if (changedMap === 0 && this.isMap1BeingEdited && !this.isMap2BeingEdited) {
      if (this.map1) {
        this.map1.fitBounds(map.getBounds());
        this.map1.setZoom(map.zoom);
      }
    }
    if (changedMap === 1 && this.isMap2BeingEdited && !this.isMap1BeingEdited) {
      this.map.fitBounds(map.getBounds());
      this.map.setZoom(map.zoom);
    }
  }

  _createTurfShapeFilePolygons(polygonsOptionKeys) {
    const polygons = [];

    this.trailsM1
      .slice(0, polygonsOptionKeys.length)
      .forEach((trail, trailIndex) => {
        if (trail && Array.isArray(trail)) {
          trail.forEach((e: Record<string, any>, index) => {
            const turfPolygonArray = [];
            e.paths.forEach((coordinate) => {
              turfPolygonArray.push([coordinate.lng, coordinate.lat]);
            });
            if (
              turfPolygonArray[0][0] !==
                turfPolygonArray[e.paths.length - 1][0] ||
              turfPolygonArray[0][1] !== turfPolygonArray[e.paths.length - 1][1]
            ) {
              const lastCoordinate = [e.paths[0].lng, e.paths[0].lat];
              turfPolygonArray.push(lastCoordinate);
            }
            polygons.push(
              turf.polygon([turfPolygonArray], {
                ID: index,
                [polygonsOptionKeys[trailIndex].key]:
                  this.convertUnitPipe.convertValueToSupportedMapUnits(
                    e.valueOperation,
                    polygonsOptionKeys[trailIndex].unit,
                  ),
              }),
            );
          });
        }
      });
    return polygons;
  }

  loadTranslateWords() {
    this.setTranslateFieldsOnMap();
  }

  changeLanguage() {
    this.translateService.onLangChange.subscribe(() => {
      this.setTranslateFieldsOnMap();
    });
  }

  createRecommendedTrail(shapefileArray, propertyToUse) {
    const mapFilterByProperty = shapefileArray.filter(
      (polygon) => polygon.properties[propertyToUse],
    );
    const clearTrail = (trailToClear) => {
      if (trailToClear) trailToClear.length = 0;
    };
    const customTrail = [];
    mapFilterByProperty.forEach((polygon) => {
      const convertedPolygon = this._convertTurfPolygonToCustomWithColor(
        polygon,
        propertyToUse,
      );
      customTrail.push(convertedPolygon);
    });
    const recommendationMapIndex = this.hasAltimetryMap
      ? NVG_MAP_INDEX.RECOMMENDATION_OR_ALTIMETRY
      : NVG_MAP_INDEX.ALTIMETRY_OR_RECOMMENDATION;
    Object.assign(
      this.preferences[recommendationMapIndex],
      this.preferences[NVG_MAP_INDEX.INST_RATE],
    );
    this.trailsM1[recommendationMapIndex] = customTrail;
    clearTrail(this.trailsM2[recommendationMapIndex]);
    Object.assign(
      this.trailsM2[recommendationMapIndex],
      this.trailsM1[recommendationMapIndex],
    );
    this.MapasNvg[recommendationMapIndex] = 'map-recommendation';
    this.splitMapOnRecommendationMapLoading();

    this._checkDetectChanges();
  }

  _setMarcadoresArray(t: {
    marcadores: any;
    guias: {};
    boundaries: string | any[];
  }) {
    this.marcadoresArrayL = [];
    const markers = t.marcadores;
    delete markers['temperaturePoints'];

    const markersObj = {
      pausePointsInit: TIPO_MARCADORES.PAUSE_POINT,
      pausePointsEnd: TIPO_MARCADORES.PAUSE_END_POINT,
      stopPoints: TIPO_MARCADORES.STOP_POINT,
      exceededTemperaturePoints: TIPO_MARCADORES.TEMPERATURE_EXCEEDED_POINT,
      powerPoint: TIPO_MARCADORES.POWER_POINT,
    };

    if (Object.keys(t.guias).length > 0) {
      this.marcadoresArrayL.push(
        this._createMarkerObject(
          TIPO_MARCADORES.GUIDES,
          'tasks.guide.label-guide',
          true,
          false,
        ),
      );
    }

    if (Object.keys(markers).length > 0) {
      this.marcadoresArrayL.push(
        this._createMarkerObject(
          TIPO_MARCADORES.MARKERS,
          'tasks.markers',
          true,
          false,
        ),
      );

      Object.keys(markersObj).forEach((marker) => {
        if (this._hasMarkerOfType(markers, marker)) {
          markers[marker] = markers[marker].map((obj) => ({
            ...obj,
            show: true,
          }));

          this.marcadoresArrayL.push(
            this._createMarkerObject(
              markersObj[marker],
              'tasks.marker.type.' + markersObj[marker],
              true,
              true,
            ),
          );
        }
      });
    }

    if ('boundaries' in t && t.boundaries.length > 0) {
      this.marcadoresArrayL.push(
        this._createMarkerObject(
          TIPO_MARCADORES.BOUNDARIES,
          'tasks.boundaries',
          true,
          false,
        ),
      );
    }
  }

  _getSIPreferenceSubtitleUnit(mapSelected: number) {
    return (
      {
        0: this._getSIPreferenceSubtitleUnitByOperation(this.operation_type),
        1: 'km/h',
        2: this._getSIPreferenceSubtitleUnitByOperation(this.operation_type),
        3: this.hasAltimetryMap
          ? 'm'
          : this._getSIPreferenceSubtitleUnitByOperation(this.operation_type),
        4: this.hasAltimetryMap
          ? this._getSIPreferenceSubtitleUnitByOperation(this.operation_type)
          : 'm',
      }[mapSelected] || ''
    );
  }

  addColorIfMissing(trailData: Array<any>, color: string) {
    trailData.forEach((item) => {
      if (!item.color) {
        item.color = color;
      }
    });
  }

  createNvgTrail(trails: { [key: string]: Array<any> }) {
    if (trails[FIELD_INST_RATE] !== undefined)
      this.trailsM1[NVG_MAP_INDEX.INST_RATE] = trails[FIELD_INST_RATE];
    if (trails?.[FIELD_SPEED] !== undefined)
      this.trailsM1[NVG_MAP_INDEX.SPEED] = trails[FIELD_SPEED];
    if (trails[FIELD_OVERLAP] !== undefined)
      this.trailsM1[NVG_MAP_INDEX.OVERLAP] = trails[FIELD_OVERLAP];
    if (trails[FIELD_ALTIMETRY] !== undefined)
      this.trailsM1[NVG_MAP_INDEX.ALTIMETRY_OR_RECOMMENDATION] =
        trails[FIELD_ALTIMETRY];
    if (
      this.trailsM1[NVG_MAP_INDEX.ALTIMETRY_OR_RECOMMENDATION] &&
      (this.trailsM1[NVG_MAP_INDEX.ALTIMETRY_OR_RECOMMENDATION] as Array<any>)
        .length > 0
    ) {
      this.MapasNvg.push('map-altimetry');
      this.hasAltimetryMap = true;
    }
  }

  createIsobusTrails(trails: { [key: string]: Array<any> }) {
    this.trailsM1[0] = trails[FIELD_INST_RATE];
  }
  _getCoordinatesFromBoundaries(
    boundariesArray: Boundary[] | null,
  ): turf.Feature<turf.Point>[] {
    if (!boundariesArray || boundariesArray.length === 0) {
      return [];
    }

    return boundariesArray.flatMap((boundary) =>
      boundary.path.map(({ lng, lat }) => turf.point([lng, lat])),
    );
  }

  _getStartAndEndPointCoordinatesFromAllSteps(
    stepArray: Array<{ [key: string]: any }>,
  ) {
    if (stepArray && stepArray.length > 0) {
      return stepArray
        .map((e) => {
          return [
            turf.point([e.start_location.longitude, e.start_location.latitude]),
            turf.point([e.end_location.longitude, e.end_location.latitude]),
          ];
        })
        .flat(2);
    }
    return [];
  }

  _getFirstAndLastPointCoordinatesFromEachGuide(guideArray) {
    if (guideArray && guideArray.length > 0) {
      return guideArray
        .map((gui) => {
          if (gui.path.length > 0) {
            return [
              turf.point([gui.path[0].long, gui.path[0].lat]),
              turf.point([
                gui.path[gui.path.length - 1].long,
                gui.path[gui.path.length - 1].lat,
              ]),
            ];
          }
          return [];
        })
        .flat(2);
    }
    return [];
  }

  _getCoordinateFromEachMarker(markersObj) {
    if (markersObj) {
      return Object.values(markersObj)
        .map((markerCatArr) =>
          (markerCatArr as Array<any>).map((marker) =>
            turf.point([marker.location.longitude, marker.location.latitude]),
          ),
        )
        .reduce((acc, val) => acc.concat(val), []);
    }
    return [];
  }

  getPreferenceSubtitle(): [string, string] {
    const legendByOperation: string = this._getPreferenceSubtitleByOperation(
      this.operation_type,
    );

    const preferencesLegends = {
      0: legendByOperation,
      1: SUBTITLE_PREFERENCES.SPEED,
      2: legendByOperation,
      3: this.hasAltimetryMap
        ? SUBTITLE_PREFERENCES.ALTITUDE
        : legendByOperation,
      4: this.hasAltimetryMap,
    };
    return [
      preferencesLegends[this.mapa0] || '',
      preferencesLegends[this.mapa1] || '',
    ];
  }

  saveTx() {
    let legendDataToSave = this.prepareLegendDataToSave();

    const currentUnitSystem = this.convertUnitPipe.getCurrentUnitSystem();
    this._convertLegendsToSI(legendDataToSave, currentUnitSystem);

    if (this.preferencesChanged(legendDataToSave)) {
      this.seeButtonSave = false;
      this.saveLegendData(legendDataToSave);
      for (let i = 0; i < 4; i++) {
        this.updateTrailColor(i);
      }
      const unitsArray = this._getSubtitleUnitsArray(currentUnitSystem);
      this._convertSubtitles(unitsArray);
      this.translateService
        .get('monitoring.maps.saved-caption')
        .subscribe((res: string) => {
          this.alertService.success(res, TimeoutEnum.MediumShort);
        });
    }
  }
  saveLegendData(legendDataToSave: {
    inst_rate: [number, number, number, number, number];
    speed: [number, number, number, number, number];
    overlap: [number, number, number, number, number];
    altitude: [number, number, number, number, number];
  }) {
    localStorage.setItem(
      USER_MAP_COLOR + this.nvgName,
      JSON.stringify(legendDataToSave),
    );
  }
  preferencesChanged(legendData: any): boolean {
    return (
      localStorage.getItem(USER_MAP_COLOR + this.nvgName) !==
      JSON.stringify(legendData)
    );
  }

  operationsFields() {
    if (!this.hasField) {
      const center = this.trails.speed[0].paths[0];
      this.router.navigate(['/my-fields/criar-talhao'], {
        queryParams: { center: JSON.stringify(center) },
      });
    } else {
      this.router.navigate([`/my-fields/ver-talhao/${this.fieldId}`]);
    }
  }

  fetchField() {
    const cropName = this.trabalho.crop_name
      .replace(/\s+/g, '_')
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '')
      .toLowerCase();
    this.operationsFieldsTitle = 'fields.create';
    this.userTalhoesService.getAllTalhoes().subscribe(
      (talhoes) => {
        if (talhoes) {
          talhoes.forEach((field) => {
            const fieldName = field.name
              .replace(/\s+/g, '_')
              .normalize('NFD')
              .replace(/[\u0300-\u036f]/g, '')
              .toLowerCase();

            if (cropName === fieldName) {
              this.hasField = true;
              this.operationsFieldsTitle = 'fields.open-field';
              this.fieldId = field.id;
            }
          });
        }
        this.fieldButton = true;
      },
      () => {
        this.fieldButton = true;
      },
    );
  }

  eventNvgSelect(idNameNvg: any) {
    this.hideCardsPage();

    if (this.viewportBreakpoints.desktop || this.viewportBreakpoints.mobile) {
      this.translateService
        .get('global.loading.operation')
        .subscribe((res: string) => {
          this.loadingAgresSpinner.toShow(res);
        });
    }
    this.nvgStorageService.getStorageData(idNameNvg).then((taskIsobusType) => {
      if (
        taskIsobusType.hasOwnProperty('isISOBUS') &&
        taskIsobusType.isISOBUS === true
      ) {
        this.isIsobusData = true;
        this.taskIsobusType = taskIsobusType;
        this.devicesIsobus = taskIsobusType.devices;
        this.trabalho = taskIsobusType.trabalho;
        this.guias = taskIsobusType.guias;
        this.marcadores = taskIsobusType.marcadores;
        this.trails = taskIsobusType.trails;
        this.subtitles = taskIsobusType.subtitles;
        this.tempoEfetivoOperacao = taskIsobusType?.trabalho?.tot_op_e;
        this.tempoTotalOperacao = taskIsobusType?.trabalho?.tot_op_t;
        this.tempoOciosoOperacao = taskIsobusType?.trabalho?.tot_op_o;
        this.percentTotalOperation = taskIsobusType?.trabalho?.tot_op_o_percent;
        this.altimetryPoints = taskIsobusType.altimetryPoints;

        this.totals = taskIsobusType.totals || [];
        this.totalObjects = this.getLatestEntries(this.totals);
        this.stageInfoIsobus = taskIsobusType.stageInfo;
        this.stageQuantity = taskIsobusType.trabalho.qtde_etapas;
        this.stageInfoIsobusStartTime = this.stageInfoIsobus[0].startTime;

        if (this.stageInfoIsobus.length > 0) {
          const lastStopTime =
            this.stageInfoIsobus[this.stageInfoIsobus.length - 1].stopTime;
          this.stageInfoIsobusStopTime = lastStopTime;
        }

        const stageInfoArray = [];
        const stageInfoArrayEcusData = [];

        // Preenche os arrays
        this.stageInfoIsobus.forEach((stageInfo) => {
          stageInfoArray.push(stageInfo);
        });
        stageInfoArray.forEach((ecusData) => {
          stageInfoArrayEcusData.push(ecusData.ecusData);
        });

        // Verifica se você precisa utilizar os arrays para algo mais

        this.stageIsobusData = taskIsobusType.etapa || [];

        this.stageIsobusMergedData = this.mergeStageAndEtapa(
          this.stageInfoIsobus,
          this.stageIsobusData,
        );

        this.guides = this.guias;
        this.guides = this.guides.map((obj) => ({ ...obj, show: true }));

        const deviceModel = AppConstants.DEVICE_MODEL;

        const currentDevcieModel = taskIsobusType?.trabalho?.model;
        if (currentDevcieModel === deviceModel.AGRONAVEPRO.name) {
          this.distanceUnit = 'm';
        } else {
          this.distanceUnit = 'km';
        }

        this.firstGuideIndex = this.guides.findIndex(
          (guide) => guide.type !== this.guideTypeEnum.PROJECT,
        );

        this.mapLat = 0;
        this.mapLng = 0;
        this.mapZoom = 3;
        this.nvgName = idNameNvg;
        this.firstLoad = true;

        this.createNvgTrail(this.trails);
        this._fitMapBoundsNvg();
        this.setNvgSubtitle(this.nvgName);
        this.loadTranslateWords();
        this.extractDeviceNames();
        this.setDefaultDevice();
        this.updateFilteredData();
      } else {
        this.nvgData = taskIsobusType;
        this.trabalho = this.nvgData.trabalho;
        this.guias = this.nvgData.guias;
        this.marcadores = this.nvgData.marcadores;
        this.etapa = this.nvgData.etapa;
        this.trails = this.nvgData.trails;
        this.subtitles = this.nvgData.subtitles;
        this.altimetryPoints = this.nvgData.altimetryPoints;
        this.boundaries = this.nvgData.boundaries || this.boundaries;

        this.mapLat = 0;
        this.mapLng = 0;
        this.mapZoom = 2;
        this.nvgStorageData = this.nvgData;
        this.nvgName = idNameNvg;
        this.loadTranslateWords();
        this.firstLoad = true;

        this.operationType = this.trabalho.operation;

        const deviceModel = AppConstants.DEVICE_MODEL;
        const currentDevcieModel = this.nvgData?.trabalho?.model;
        if (currentDevcieModel === deviceModel.AGRONAVEPRO.name) {
          this.distanceUnit = 'm';
        } else {
          this.distanceUnit = 'km';
        }
        this.operation_type = this.nvgData?.trabalho?.operation;
        if (this.operation_type === this.OPERATIONS.NAVEGACAO) {
          this.MapasNvg.shift();
          this.MapasNvg.unshift('map-navigation');
        }

        this.tempoEfetivoOperacao = this.nvgData?.trabalho?.tot_op_e;
        this.tempoTotalOperacao = this.nvgData?.trabalho?.tot_op_t;
        this.tempoOciosoOperacao = this.nvgData?.trabalho?.tot_op_o;

        this.etapasArr = [];

        this.nvgData.etapa?.forEach((et: any) => {
          this.etapasArr.push(et);
        });

        this.etapas = this.etapasArr[0];
        this.timestamp_etapa_selecionada = this.etapas?.timestamp;
        this.endTimestamp_etapa_selecionada = this.etapas?.endTimestamp;
        this.createNvgTrail(this.trails);

        if (
          Array.isArray(this.trailsM1[NVG_MAP_INDEX.INST_RATE]) &&
          (this.trailsM1[NVG_MAP_INDEX.INST_RATE] as Array<any>).length > 0
        ) {
          this.setStartPinLocation(this.etapa[0]);
        }

        this.setNvgSubtitle(this.nvgName);
        this.guides = this.guias;
        this.guides = this.guides.map((obj) => ({ ...obj, show: true }));
        this._setMarcadoresArray(this.nvgData);

        this.firstGuideIndex = this.guides.findIndex(
          (guide) => guide.type !== this.guideTypeEnum.PROJECT,
        );

        this._fitMapBoundsNvg();

        this.loadingAgresSpinner.toHide();
      }
      this.loadingAgresSpinner.toHide();
      this.taskSummaryBoxValue = this.taskSummaryBoxApplyedValue();
      this.fetchField();
    });
  }
  getLatestEntries(data: any[]): any {
    const latestEntries: { [key: string]: any } = {};
    data.forEach((item) => {
      const key = Object.keys(item)[0];
      if (!latestEntries[key]) {
        latestEntries[key] = { task: [], ecu: [] };
      }
      if (item[key].task) {
        latestEntries[key].task = item[key].task;
      }
      if (item[key].ecu) {
        latestEntries[key].ecu = item[key].ecu;
      }
    });
    return latestEntries;
  }

  extractDeviceNames() {
    const devices = this.taskIsobusType.devices;
    this.deviceNames = devices.map((device) => ({
      name: device.deviceDesignator,
      serial: device.deviceSerialNumber,
    }));
  }

  setDefaultDevice() {
    if (this.devicesIsobus && this.devicesIsobus.length > 0) {
      this.selectedDevice = this.devicesIsobus[0].deviceDesignator;
      this.setDefaultSubtitleIsobus();
      this.updateFilteredData();
      this.updateFilteredDataStageInfoEcusIsobus();
      this.updateTrailsAndSubtitleIsobus(this.selectedDevice);
    }
  }

  // Método para atualizar trails e subtitles dinamicamente com base no dispositivo selecionado
  updateTrailsAndSubtitleIsobus(_selectedDeviceName: string) {
    for (const rate of this.applicationRates) {
      if (this.trails[this.selectedDevice]?.hasOwnProperty(rate)) {
        this.foundApplicationRate = rate;

        break;
      }
    }

    if (this.foundApplicationRate) {
      // Atualiza inst_rate
      this.trails['inst_rate'] =
        this.trails[this.selectedDevice][this.foundApplicationRate][0];
      this.subtitles[NVG_MAP_INDEX.INST_RATE] =
        this.subtitles[this.foundApplicationRate];
    }
    // Atualiza o mapa
    this.createNvgTrail(this.trails);
    this.setNvgSubtitle(this.nvgName);
  }

  onDeviceNameChange(event: Event) {
    const selectElement = event.target as HTMLSelectElement;
    this.selectedDevice = selectElement.value;

    this.updateFilteredData();
    this.updateFilteredDataStageInfoEcusIsobus();
    this.mergeStageAndEtapa(this.stageInfoIsobus, this.stageIsobusData);
    this.updateTrailsAndSubtitleIsobus(this.selectedDevice);
    this.setDefaultSubtitleIsobus();
    this.setNvgSubtitle(this.nvgName);
    this._fitMapBoundsNvg();

    if (this.stageIsobusMergedData.length > 0) {
      this.updateStageDataForSelectedDevice(0);
    }
  }
  updateFilteredData() {
    if (this.selectedDevice && this.totalObjects[this.selectedDevice]) {
      this.filteredTask = this.totalObjects[this.selectedDevice].task || [];
      this.filteredEcu = this.totalObjects[this.selectedDevice].ecu || [];
    } else {
      this.filteredTask = [];
      this.filteredEcu = [];
    }
  }
  updateFilteredDataStageInfoEcusIsobus() {
    for (const stageInfo of this.stageInfoIsobus) {
      for (const ecusData of stageInfo.ecusData) {
        const deviceName = Object.keys(ecusData)[0];
        if (deviceName === this.selectedDevice) {
          this.filteredEcusDataStageInfo = ecusData[deviceName];
          return;
        }
      }
    }
    this.filteredEcusDataStageInfo = {
      implementWidth: 0,
      numberOfSections: 0,
      averageApplicationRate: [0, ''],
    };
  }
  updateStageDataForSelectedDevice(stageIndex: number) {
    const mergedData = this.stageIsobusMergedData[stageIndex];
    if (mergedData && mergedData[this.selectedDevice]) {
      this.stageIsobusObjectData = mergedData[this.selectedDevice];
    } else {
      this.stageIsobusObjectData = {
        implementWidth: 0,
        numberOfSections: 0,
        averageApplicationRate: [0, ''],
        appVersion: '000',
        machineName: 'isofarm',
        cropType: '',
        operator: 'nome',
        deviceId: 0,
        serialNumber: '00000',
        coverArea: 0,
        travelDist: 0,
        avgSpeed: 0,
        distanceFromAntennaToHitch: 0,
        implWidth: 0,
        timestamp: '',
        endTimestamp: '',
        startLocation: {
          latitude: 0,
          longitude: 0,
        },
        endLocation: {
          latitude: 0,
          longitude: 0,
        },
        startTime: '',
        stopTime: '',
      };
    }
  }
  mergeStageAndEtapa(
    stageInfo: StageInfo[],
    etapa: Etapa[],
  ): MergedData[] | string {
    if (stageInfo.length !== etapa.length) {
      return 'The sizes of `stageInfo` and `step` are not the same.';
    }

    const mergedIsobusTaskSteps: MergedData[] = [];

    for (let i = 0; i < stageInfo.length; i++) {
      const mergedItem: MergedData = {};

      for (const ecuData of stageInfo[i].ecusData) {
        for (const [key, data] of Object.entries(ecuData)) {
          mergedItem[key] = {
            ...data,
            ...etapa[i],
            startTime: stageInfo[i].startTime,
            stopTime: stageInfo[i].stopTime,
          };
        }
      }

      mergedIsobusTaskSteps.push(mergedItem);
    }

    return mergedIsobusTaskSteps;
  }

  getSelectedIndex(target: EventTarget): number {
    if (target instanceof HTMLSelectElement) {
      return target.selectedIndex;
    }
  }

  onChangeMap(type: number, mapa: Map) {
    if (
      this.viewportBreakpoints.mobile &&
      type == undefined &&
      mapa == undefined
    ) {
      this.mapa0 = 0;
      if (this.selectedMapImage.key === FIELD_SPEED) this.mapa0 = 1;
      if (this.selectedMapImage.key === FIELD_ALTIMETRY) this.mapa0 = 2;
      if (this.selectedMapImage.key === FIELD_OVERLAP) this.mapa0 = 3;
      return;
    }

    if (mapa === 0) {
      this.tipo1MapaEscolhido = this.MapasNvg[type];
      this.selectedMap1Value = type;
      if (this.MapasNvg[type] !== 'map-speed') {
        this.selectedMap2Value = this.MapasNvg.indexOf('map-speed');
      }
      if (this.MapasNvg[type] !== 'map-application') {
        this.selectedIsobusApplicationRate0 = null;
      } else {
        if (!Array.isArray(this.trailsM1[0])) {
          this.selectedIsobusApplicationRate0 = Object.keys(
            this.trailsM1[0],
          )[0];
        }
      }
    } else if (mapa === 1) {
      if (this.MapasNvg[type] !== 'map-application') {
        this.selectedIsobusApplicationRate1 = null;
      }
      this.tipo2MapaEscolhido = this.MapasNvg[type];
      this.selectedMap2Value = type;
    }
    this['mapa' + mapa] = type;
    this.updateSelectedMap2Value();
  }
  updateSelectedMap2Value() {
    if (
      this.MapasNvg[this.selectedMap1Value] === 'map-application' ||
      this.MapasNvg[this.selectedMap1Value] === 'map-transgression'
    ) {
      this.selectedMap2Value = this.MapasNvg.indexOf('map-speed');
    } else if (this.MapasNvg[this.selectedMap1Value] === 'map-speed') {
      this.selectedMap2Value = this.MapasNvg.indexOf('map-application');
    } else {
      this.selectedMap2Value = this.MapasNvg.indexOf('map-application');
    }
  }

  NavigationReplay() {
    const REPLAY_SPEED = 250;
    const MAX_REPLAY_SPEED = 250 / 64;
    const MIN_REPLAY_SPEED = 250 * 4;
    let isPaused = false;
    let isPlaying = false;
    let initialized = false;
    let reproductionSpeedInMs = REPLAY_SPEED;
    let numberOfPolygons: number;
    let idx: number;
    let selectedMap: number;
    let timeout: NodeJS.Timeout;

    const play = async () => {
      if (!initialized) {
        await _initialize();
      }
      if (isPaused) {
        _returnFromPause();
      } else {
        _play();
      }
    };

    const pause = () => {
      isPaused = true;
      isPlaying = false;
    };

    const stop = async () => {
      this.translateService
        .get('global.loading.replay-stopping')
        .subscribe((res: string) => {
          this.loadingAgresSpinner.toShow(res);
        });
      await delayToShowSpinnerCorrectly();
      terminate();
      _returnOriginalMap();
      this.loadingAgresSpinner.toHide();
    };

    const terminate = () => {
      clearTimeout(timeout);
      pause();
      _setInitialConditions();
    };

    const canPlay = () => {
      return !isPlaying;
    };

    const canStop = () => {
      return isPlaying || isPaused;
    };

    const speedUp = () => {
      if (reproductionSpeedInMs > MAX_REPLAY_SPEED) reproductionSpeedInMs /= 2;
    };

    const speedDown = () => {
      if (reproductionSpeedInMs < MIN_REPLAY_SPEED) reproductionSpeedInMs *= 2;
    };

    const stepOneForward = async () => {
      if (!initialized) {
        await _initialize();
      }
      pause();
      _draw(idx);
      idx++;
    };

    const stepOneBackwards = () => {
      if (initialized && idx > 0) {
        pause();
        idx--;
        _erase(idx);
      }
    };

    const isRunning = () => {
      return initialized;
    };

    const getCurrentReproductionSpeed = () => {
      return REPLAY_SPEED / reproductionSpeedInMs;
    };

    const _initialize = () => {
      return new Promise(async (resolve) => {
        this.translateService
          .get('global.loading.replay')
          .subscribe((res: string) => {
            this.loadingAgresSpinner.toShow(res);
          });
        await delayToShowSpinnerCorrectly();
        _prepareReplay();
        _setReplayScreenMode();
        _setAllPolygonsToInvisible();
        initialized = true;
        this.loadingAgresSpinner.toHide();
        resolve('');
      });
    };

    const delayToShowSpinnerCorrectly = () => {
      return new Promise((resolve) => {
        setTimeout(() => {
          resolve('');
        }, 50);
      });
    };

    const _treatNonReplayMaps = () => {
      if (selectedMap > 1) {
        selectedMap = 0;
        this._selectMap(selectedMap);
        this.translateService
          .get('tasks.replay.just-available')
          .subscribe((res: string) => {
            this.toastr.info(res, '', { timeOut: 4000 });
          });
      }
    };

    const _prepareReplay = () => {
      selectedMap = $('.mapType1 option:selected').index();
      _treatNonReplayMaps();
      if (Array.isArray(this.trailsM1[selectedMap])) {
        numberOfPolygons = (this.trailsM1[selectedMap] as Array<any>).length;
      }
      _setInitialConditions();
    };

    const _setReplayScreenMode = () => {
      if (this.splitScreen === true) {
        this.splitMap();
      }
    };

    const _setAllPolygonsToInvisible = () => {
      if (Array.isArray(this.trailsM1[selectedMap])) {
        (this.trailsM1[selectedMap] as Array<any>).forEach(
          (polygon) => (polygon.visible = false),
        );
      }
      this._checkDetectChanges();
    };

    const _setInitialConditions = () => {
      isPaused = false;
      isPlaying = false;
      reproductionSpeedInMs = REPLAY_SPEED;
      idx = 0;
      initialized = false;
    };

    const _play = () => {
      isPlaying = true;
      _drawPolygonContinuously();
    };

    const _returnFromPause = () => {
      isPaused = false;
      _play();
    };

    const _returnOriginalMap = () => {
      if (Array.isArray(this.trailsM1[selectedMap])) {
        (this.trailsM1[selectedMap] as Array<any>).forEach(
          (poly) => (poly.visible = true),
        );
      }
      this._checkDetectChanges();
    };

    const _drawPolygonContinuously = () => {
      if (idx < numberOfPolygons) {
        timeout = setTimeout(() => {
          _draw(idx);
          idx++;
          if (!isPaused) _drawPolygonContinuously();
        }, reproductionSpeedInMs);
      } else {
        stop();
      }
    };

    const _draw = (index) => {
      this.trailsM1[selectedMap][index].visible = true;
      if (this.cdr.detectChanges) this._checkDetectChanges();
    };

    const _erase = (index) => {
      this.trailsM1[selectedMap][index].visible = false;
      this._checkDetectChanges();
    };

    const progressBarClick = (value) => {
      pause();
      _setAllPolygonsToInvisible();
      if (Array.isArray(this.trailsM1[selectedMap])) {
        (this.trailsM1[selectedMap] as Array<any>)
          .slice(0, value)
          .forEach((poly) => (poly.visible = true));
      }
      this._checkDetectChanges();
      idx = value;
    };

    const getMaxIndex = () => {
      return numberOfPolygons - 1;
    };

    const getProgress = () => {
      return idx;
    };

    const fitMapAtStep = () => {
      const currentStep = this.trailsM1[selectedMap][idx];
      const centerPoint = _getCenterFromPaths(currentStep.paths);
      this.mapLng = centerPoint.geometry.coordinates[0];
      this.mapLat = centerPoint.geometry.coordinates[1];
      this.map.setCenter({ lat: this.mapLat, lng: this.mapLng });
      if (this.map1)
        this.map1.setCenter({ lat: this.mapLat, lng: this.mapLng });
    };

    const _getCenterFromPaths = (currentPaths: any[]) => {
      const points = [];
      currentPaths.forEach((path) => {
        points.push([path.lng, path.lat]);
      });
      points.push([currentPaths[0].lng, currentPaths[0].lat]);

      const turfCurrentStep = polygon([points]);
      const centerPoint = center(turfCurrentStep);
      return centerPoint;
    };

    const getCurrentLocation = () => {
      const currentPaths = this.trailsM1[selectedMap][idx].paths;
      return currentPaths[currentPaths.length - 1];
    };

    return {
      play,
      pause,
      stop,
      terminate,
      canPlay,
      canStop,
      speedUp,
      speedDown,
      stepOneForward,
      stepOneBackwards,
      isRunning,
      getCurrentReproductionSpeed,
      getMaxIndex,
      getProgress,
      progressBarClick,
      fitMapAtStep,
      getCurrentLocation,
    };
  }
  _getRelevantPoints(
    operationPoints: Array<{ [key: string]: any }> | undefined,
  ) {
    let navigationPoints: turf.helpers.FeatureCollection<
      turf.helpers.Geometry,
      { [name: string]: any }
    >;

    if (this.trails[FIELD_SPEED].length > 0) {
      const minPoint = turf.point([180, 90]);
      const maxPoint = turf.point([-180, -90]);

      for (const trail of this.trails[FIELD_SPEED]) {
        for (const point of trail.paths) {
          if (point.lat > maxPoint.geometry.coordinates[1]) {
            maxPoint.geometry.coordinates[1] = point.lat;
          }

          if (point.lng > maxPoint.geometry.coordinates[0]) {
            maxPoint.geometry.coordinates[0] = point.lng;
          }

          if (point.lat < minPoint.geometry.coordinates[1]) {
            minPoint.geometry.coordinates[1] = point.lat;
          }

          if (point.lng < minPoint.geometry.coordinates[0]) {
            minPoint.geometry.coordinates[0] = point.lng;
          }
        }
      }

      navigationPoints = turf.featureCollection([minPoint, maxPoint]);
    } else {
      operationPoints.push(
        ...this._getStartAndEndPointCoordinatesFromAllSteps(this.etapa),
      );
      operationPoints.push(
        ...this._getFirstAndLastPointCoordinatesFromEachGuide(this.guias),
      );
      operationPoints.push(
        ...this._getCoordinateFromEachMarker(this.marcadores),
      );
      operationPoints.push(
        ...this._getCoordinatesFromBoundaries(this.boundaries),
      );

      navigationPoints = turf.featureCollection(
        operationPoints as Array<
          turf.Feature<turf.helpers.Geometry, { [name: string]: any }>
        >,
      );
    }
    return navigationPoints;
  }

  getPreferenceSubtitleUnit(): [string, string] {
    const getPreferenceSubtitleUnitByMap = (mapSelected) => {
      let unit = this.convertUnitPipe.transformUnit(
        this._getSIPreferenceSubtitleUnit(mapSelected),
      );

      if (
        this.operation_type === OPERACAO.PLANTIO &&
        (mapSelected === 0 || mapSelected === 2)
      ) {
        const res = this.translateService.instant('historico.tabs.seeds');
        const unitArray = unit.split('/');
        unit = `${res}/${unitArray[1]}`;
      }
      return unit;
    };

    return [
      getPreferenceSubtitleUnitByMap(this.mapa0),
      getPreferenceSubtitleUnitByMap(this.mapa1),
    ];
  }

  _controlMasterMarkersCheckState() {
    const childrenL = this.marcadoresArrayL.filter((a) => a.children);
    const childrenCheckedTotal = childrenL.filter((a) => a.checked).length;
    const childrenNotCheckedTotal = childrenL.filter((a) => !a.checked).length;
    const indexOfMarkersSwitch = this.marcadoresArrayL.findIndex(
      (markerSwitch) => markerSwitch.property === TIPO_MARCADORES.MARKERS,
    );

    if (indexOfMarkersSwitch >= 0) {
      const stateOfMarkersSwitch =
        this.marcadoresArrayL[indexOfMarkersSwitch].checked;

      if (mustCheckMarkersSwitch(stateOfMarkersSwitch)) {
        this.marcadoresArrayL[indexOfMarkersSwitch].checked = true;
      } else if (mustUncheckMarkersSwitch(stateOfMarkersSwitch)) {
        this.marcadoresArrayL[indexOfMarkersSwitch].checked = false;
      }
    }

    function mustCheckMarkersSwitch(stateOfMarkersSwitch: boolean) {
      return (
        (childrenCheckedTotal > 0 ||
          childrenCheckedTotal === childrenL.length) &&
        stateOfMarkersSwitch === false
      );
    }

    function mustUncheckMarkersSwitch(stateOfMarkersSwitch: boolean) {
      return (
        childrenNotCheckedTotal === childrenL.length &&
        stateOfMarkersSwitch === true
      );
    }
  }

  _convertTurfPolygonToCustomWithColor(turfPolygon, propertyToUse) {
    const color = this.trailClass.trailColorPref(
      'false',
      undefined,
      turfPolygon.properties[propertyToUse],
      this.preferences[NVG_MAP_INDEX.INST_RATE],
      'desc',
    ).trailColorScale;

    const customPolygon = {
      color: color,
      paths: [],
      valueOperation: turfPolygon.properties[propertyToUse],
    };

    if (turfPolygon.geometry.type === 'MultiPolygon') {
      customPolygon.paths = turfPolygon.geometry.coordinates.map((polygon) => {
        const lastIndex = polygon[0].length;
        return polygon[0].map((coord, index) => {
          if (index < lastIndex) return { lat: coord[1], lng: coord[0] };
        });
      });
    } else if (turfPolygon.geometry.type === 'Polygon') {
      const lastIndex = turfPolygon.geometry.coordinates[0].length;
      customPolygon.paths = turfPolygon.geometry.coordinates[0].map(
        (coord, index) => {
          if (index < lastIndex) return { lat: coord[1], lng: coord[0] };
        },
      );
    }

    return customPolygon;
  }
  getRasterImageURL(
    rasterType: RasterType = this.selectedMapImage.key,
  ): string {
    const index = this.mapImageIndex[rasterType];
    return `data:image/png;base64,${this.mapImages.rasterImages[index]}`;
  }
  _checkDetectChanges() {
    if (!this.destroyed) this.cdr.detectChanges();
  }

  dashboard() {
    this.router.navigate(['/']);
  }

  _downloadShapefileZip(url: string) {
    const link = document.createElement('a');
    link.setAttribute('target', '_self');
    link.setAttribute('href', url);
    link.setAttribute('download', 'download');
    document.body.appendChild(link);
    link.click();
    link.remove();
  }

  _createShapefileInBrowser() {
    this._downloadShape().then(() => {
      this._clearShapefileDownloadVisualState();
    });
  }

  _clearShapefileDownloadVisualState() {
    this.translateService
      .get('tasks.success-shapefile')
      .subscribe((sucText: string) => {
        this.toastr.clear();
        this.toastr.success(sucText, '', { disableTimeOut: false });
      });

    this._checkDetectChanges();
  }

  _toggleSidebar() {
    this._opened = !this._opened;

    if (this._opened) {
      $('.etapa-menu').css('margin-right', '5px');
      $('.btn-collapse-expand-side')
        .find('i')
        .removeClass('agres-arrow-left agres-lg')
        .addClass('agres-arrow-right agres-lg');
    } else {
      $('.etapa-menu').css('margin-right', '25px');
      $('.btn-collapse-expand-side')
        .find('i')
        .removeClass('agres-arrow-right agres-lg')
        .addClass('agres-arrow-left agres-lg');
    }
  }

  showRecommendationMapUploadModal() {
    this.showRecommendationMapUpload = true;
    this.referenceCoordinateSubject.next({
      lat: this.mapLat,
      lng: this.mapLng,
    });
  }

  onReceiveRecommendationMap(event: any) {
    const { shapefileArray, propertyToUse } = event;
    this.createRecommendedTrail(shapefileArray, propertyToUse);
  }

  changeMapType(options: { isFirstMap: boolean; mapTypeId: string }) {
    const { isFirstMap, mapTypeId } = options;
    if (isFirstMap) {
      this.map.setMapTypeId(mapTypeId);
    } else {
      this.map1.setMapTypeId(mapTypeId);
    }
  }

  _selectMap(mapIndex) {
    $('.mapType1 option').eq(mapIndex).prop('selected', true);
    this.tipo1MapaEscolhido = this.MapasNvg[mapIndex];
    this.mapa0 = mapIndex;
  }
  selectSystemUnit(index: string | number) {
    const unitsArray = this._getSubtitleUnitsArray(this.currentUnitSystem);
    this.convertUnitPipe.use(this.unitSystemOptions[index]);
    this.currentUnitSystem = this.unitSystemOptions[index];
    this._convertSubtitles(unitsArray);
  }

  _createMarkerObject(
    property: string,
    title: string,
    checked: boolean,
    childen: boolean,
  ) {
    return {
      property: property,
      title: title,
      checked: checked,
      children: childen,
    };
  }

  hideCardsPage() {
    $('.nav-tabs li:first').trigger('click');
    $('.card').css('pointer-events', 'none');
    (<any>jQuery('.etapa-sidemenu')).slimscroll({
      height: HEIGTH_SIDES_MENU,
      color: COLOR_SIDES_MENU,
      allowPageScroll: true,
      alwaysVisible: false,
    });

    $('#filter').css('display', 'none');
  }

  preferencesToSubtitleArray(
    preferences: Preferences,
  ): [number, number, number, number, number] {
    const subtitleArray: number[] = [];
    subtitleArray.push(preferences?.red);
    subtitleArray.push(preferences?.orange);
    subtitleArray.push(preferences?.yellow);
    subtitleArray.push(preferences?.lemonGreen);
    subtitleArray.push(preferences?.green);
    return subtitleArray as [number, number, number, number, number];
  }

  getSubtitleFromLocalStorage(nvgId: string) {
    const subtitles = localStorage.getItem(USER_MAP_COLOR + nvgId);
    return subtitles;
  }
  prepareLegendDataToSave(): {
    [FIELD_INST_RATE]: [number, number, number, number, number];
    [FIELD_SPEED]: [number, number, number, number, number];
    [FIELD_OVERLAP]: [number, number, number, number, number];
    [FIELD_ALTIMETRY]: [number, number, number, number, number];
  } {
    const instRateSubtitles: [number, number, number, number, number] =
      this.preferencesToSubtitleArray(
        this.preferences[NVG_MAP_INDEX.INST_RATE],
      );
    const speedSubtitles: [number, number, number, number, number] =
      this.preferencesToSubtitleArray(this.preferences[NVG_MAP_INDEX.SPEED]);
    const overlapSubtitles: [number, number, number, number, number] =
      this.preferencesToSubtitleArray(this.preferences[NVG_MAP_INDEX.OVERLAP]);
    const altimetrySubtitles: [number, number, number, number, number] =
      this.preferencesToSubtitleArray(
        this.preferences[NVG_MAP_INDEX.ALTIMETRY_OR_RECOMMENDATION],
      );

    const legendObject = {
      [FIELD_INST_RATE]: instRateSubtitles,
      [FIELD_SPEED]: speedSubtitles,
      [FIELD_OVERLAP]: overlapSubtitles,
      [FIELD_ALTIMETRY]: altimetrySubtitles,
    };
    return legendObject;
  }

  _fitMapBoundsNvg() {
    const operationPoints: Array<turf.Feature> = [];
    const navigationPoints = this._getRelevantPoints(operationPoints);

    this.boundingBox = turf.bbox(navigationPoints);
    this.centerMap = turf.center(navigationPoints);
    this.mapLng = this.centerMap.geometry.coordinates[0];
    this.mapLat = this.centerMap.geometry.coordinates[1];
    this.mapZoom = 15;
  }

  setMaxGoogleMapsZoomValueOnMapArea(
    googleMapBounds: google.maps.LatLngBounds,
  ) {
    const maxZoomService = new google.maps.MaxZoomService();

    maxZoomService.getMaxZoomAtLatLng(
      googleMapBounds.getCenter(),
      (_response) => {
        this.MAP_MAX_ZOOM = this.MAP_MAX_ZOOM_DEFAULT;
      },
    );
  }
  mapReadyReportGenerateTrigger(map) {
    map.fitBounds(this.bounds);
    this.cdr.markForCheck();
    map.addListener('tilesloaded', () => {
      $('#gerarReport').trigger('click');
    });
  }

  setMapBounds() {
    const gMapBounds = new google.maps.LatLngBounds(
      new google.maps.LatLng(this.boundingBox[1], this.boundingBox[0]),
      new google.maps.LatLng(this.boundingBox[3], this.boundingBox[2]),
    );
    this.bounds = gMapBounds;
    this.setMaxGoogleMapsZoomValueOnMapArea(gMapBounds);
  }

  clickOnMap() {
    if (this.lastInfoWindow) {
      this.lastInfoWindow.close();
    }
  }

  goBack() {
    this._location.back();
  }
  taskSummaryBoxApplyedValue(): [
    string,
    string | undefined,
    boolean | undefined,
  ] {
    if (!this.app_vol) {
      return ['', undefined, false];
    } else {
      if (this.operation_type === this.OPERATIONS.ADUBACAO) {
        let value: string;
        if (this.app_vol) {
          value = this.app_vol + '';
        } else {
          value = this.convertUnitPipe.transform(
            (this.trabalho as any)?.app_vol,
            'kg',
          );
        }
        return ['monitoring.maps.title-mass', value, true];
      } else if (this.operation_type === this.OPERATIONS.PLANTIO) {
        return [
          'tasks.tabs.label-number-of-seeds',
          this.app_vol ? this.app_vol : (this.trabalho as any)?.app_vol,
          true,
        ];
      } else {
        let value: string;
        if (this.app_vol) {
          value = this.app_vol + '';
        } else {
          value = this.convertUnitPipe.transform(
            (this.trabalho as any)?.app_vol,
            'l',
          );
        }
        return ['tasks.tabs.label-applied-volume', value, true];
      }
    }
  }
  getMapPolygonPaths(e) {
    return e;
  }
}
