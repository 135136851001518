import { UntypedFormGroup } from '@angular/forms';

export class GenericValidator {
  constructor(
    public validationMessages: { [key: string]: { [key: string]: string } },
  ) {}

  processMessages(container: UntypedFormGroup): { [key: string]: string } {
    let messages = {};
    for (let controlKey in container.controls) {
      if (controlKey in container.controls) {
        const c = container.controls[controlKey];
        if (c instanceof UntypedFormGroup) {
          let childMessages = this.processMessages(c);
          Object.assign(messages, childMessages);
        } else {
          if (this.validationMessages[controlKey]) {
            messages[controlKey] = '';
            if ((c.dirty || c.touched) && c.errors) {
              Object.keys(c.errors).map((messageKey) => {
                if (this.validationMessages[controlKey][messageKey])
                  messages[controlKey] +=
                    this.validationMessages[controlKey][messageKey];
              });
            }
          }
        }
      }
    }
    return messages;
  }
}
