import { KeyValue } from '@angular/common';
import { Component, Input, Output, EventEmitter } from '@angular/core';
import { Preferences } from 'app/shared/models/preferences.model';
@Component({
  selector: 'app-legends-control',
  templateUrl: './legends-control.component.html',
  styleUrls: ['./legends-control.component.scss'],
})
export class LegendsControlComponent {
  @Input() preferenceSubtitle: string;
  @Input() preferenceSubtitleUnit: string;
  @Input() preferences: Array<Preferences & Record<string, number>>;
  @Input() preferencesIndex: number;
  @Input() preferencesInputSorting: Preferences;
  @Output() legendHasValidValues = new EventEmitter<boolean>();
  @Input() showError = false;

  trackByFn(index: number) {
    return index;
  }
  valueAscOrder = (
    a: KeyValue<string, number>,
    b: KeyValue<string, number>,
  ): number => {
    return this.compareValues(
      this.preferencesInputSorting[a.key],
      this.preferencesInputSorting[b.key],
    );
  };
  private compareValues(a: number, b: number): number {
    if (a > b) {
      return 1;
    } else {
      if (a === b) {
        return 0;
      } else {
        return -1;
      }
    }
  }

  watchInputsTx() {
    const legendEntries: [string, number][] = Object.entries(
      this.preferences[this.preferencesIndex],
    );
    legendEntries.sort((a: [string, number], b: [string, number]) => {
        return this.compareValues(
          this.preferencesInputSorting[a[0]],
          this.preferencesInputSorting[b[0]],
        );
      })
    const { ordered: isLegendOrdered } = legendEntries.reduce(
      (acc: { value: number; ordered: boolean }, curr: [string, number]) => {
          return {
            value: curr[1],
            ordered: curr[1] > acc.value && acc.ordered && curr !== null,
          };
        },
        { value: -Infinity, ordered: true },
      );
    this.showError = !isLegendOrdered;
    this.legendHasValidValues.emit(isLegendOrdered);
  }
}
