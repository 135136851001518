<div *ngIf="isMenuFieldFileOpen" class="menu-field-file">
  <div class="information">
    <div class="information__card" *ngIf="!shpFiles">
      <p>{{ 'archive.list' | translate }}</p>
    </div>
  </div>
  <div *ngIf="shpFiles">
    <div class="listManager" *ngIf="files.recommendation_map.length > 0">
      <p>{{ 'fields.recommendation-files' | translate }}</p>
      <div *ngFor="let item of files.recommendation_map; let i = index">
        <div class="listManager__file">
          <input
            (click)="updateSelectedItem(item, $event)"
            type="checkbox"
            name="selecionados"
            id="itensSelecionados_{{ item }}" /><mat-slide-toggle
            color="primary"
            (change)="showMaps(item, $event, 'recommendation_files')"
            >{{ item | translate }}</mat-slide-toggle
          >
        </div>
      </div>
    </div>
    <div class="listManager" *ngIf="files.guides.length > 0">
      <p>{{ 'fields.guides-files' | translate }}</p>
      <div *ngFor="let item of files.guides; let i = index">
        <div class="listManager__file">
          <input
            (click)="updateSelectedItem(item, $event)"
            type="checkbox"
            name="selecionados"
            id="itensSelecionados_{{ item }}" />
          <mat-slide-toggle color="primary" (change)="showMaps(item, $event, 'guides_files')">{{
            item | translate
          }}</mat-slide-toggle>
        </div>
      </div>
    </div>
    <div class="listManager" *ngIf="files.boundaries">
      <p>{{ 'fields.boundaries-files' | translate }}</p>
      <div *ngFor="let item of files.boundaries; let i = index">
        <div class="listManager__file">
          <mat-slide-toggle color="primary" [checked]="true" (change)="showMaps(item, $event, 'boundaries')">{{
            item | translate
          }}</mat-slide-toggle>
        </div>
      </div>
    </div>
  </div>
  <ng-template #uploadFile>
    <app-upload-field-files
      [fieldKey]="fieldName"
      [offCanvasUploadArquivos]="offCanvasUploadArquivos"
      [geometry]="geometry"
      (updateFilesList)="getFiles()"></app-upload-field-files>
  </ng-template>
  <app-offcanvas
    size="medium"
    [show]="false"
    [showSubject]="offCanvasUploadArquivos"
    title="file-manager.upload-files"
    [bodyTemplate]="uploadFile">
  </app-offcanvas>
  <ng-template #sendFiles>
    <app-send-field-files
      [fileName]="fileName"
      [fieldKey]="fieldName"
      [offCanvasDevices]="offCanvasDevices"
      [filesToAssociate]="filesToAssociate"></app-send-field-files>
  </ng-template>
  <app-offcanvas
    size="medium"
    [show]="false"
    [showSubject]="offCanvasDevices"
    title="fields.send-files-to-device"
    [bodyTemplate]="sendFiles">
  </app-offcanvas>
  <div class="buttons">
    <app-button
      type="button"
      color="warning"
      icon="agres-upload"
      (btnClick)="openOffCanvasUpload()"
      title="{{ 'global.upload' | translate }}">
    </app-button>
    <app-button
      *ngIf="filesToAssociate.length > 0"
      type="button"
      color="success"
      icon="agres-download"
      (btnClick)="downloadFile()"
      title="{{ 'global.download' | translate }}">
    </app-button>
    <app-button
      *ngIf="filesToAssociate.length > 0"
      id="btn-selecionados"
      type="button"
      color="primary"
      icon="agres-send"
      (btnClick)="openOffCanvasDevices()"
      title="{{ 'file-manager.send-to-device' | translate }}">
    </app-button>
  </div>
</div>
