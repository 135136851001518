<app-alert></app-alert>

<div class="panel">
  <div class="panel__group">
    <ng-container class="select_mobile" *ngIf="devices && viewportBreakpoints.mobile">
      <label>{{ 'global.select-ecu' | translate }}</label>
      <select class="panel-selectDevices" [(ngModel)]="selectedDevice" (change)="onChangeECUDevice()">
        <option *ngFor="let device of devices" [value]="device.deviceSerialNumber" [disabled]="devices.length === 1">
          {{ device.deviceDesignator ?? device.deviceSerialNumber }}
        </option>
      </select>
    </ng-container>
    <div class="panel__group--buttons hidden__mobile">
      <ng-container *ngIf="!viewportBreakpoints.mobile">
        <ng-container class="select" *ngIf="devices">
          <select class="panel-selectDevices" [(ngModel)]="selectedDevice" (change)="onChangeECUDevice()">
            <option
              *ngFor="let device of devices"
              [value]="device.deviceSerialNumber"
              [disabled]="devices.length === 1">
              {{ device.deviceDesignator ?? device.deviceSerialNumber }}
            </option>
          </select>
        </ng-container>
        <app-button
          ng-sidebar-content
          type="button"
          color="info"
          icon="agres-app"
          title="{{ 'sidebar.title-open-panel' | translate }}"
          (btnClick)="_toggleSidebar()">
        </app-button>

        <app-modal
          title="sidebar.title-recommendation-maps"
          [show]="showRecommendationMapUpload"
          [showSubject]="showModalSubject">
          <app-form-recommendation-map-upload
            [showModalSubject]="showModalSubject"
            [referenceCoordinateSubject]="referenceCoordinateSubject"
            (recommendationMapSent)="onReceiveRecommendationMap($event)"
            (modalUpdatedState)="(undefined)">
          </app-form-recommendation-map-upload>
        </app-modal>

        <app-button
          type="button"
          color="warning"
          icon="agres-upload"
          (btnClick)="showRecommendationMapUploadModal()"
          title="{{ 'tasks.map-recommendation' | translate }}">
        </app-button>
      </ng-container>

      <app-button
        type="button"
        color="success"
        icon="agres-download"
        (btnClick)="downloadShapefile()"
        title="Shapefile">
      </app-button>

      <app-button
        type="button"
        id="icon-report-btn"
        color="info"
        id="btn-report"
        icon="agres-download"
        (btnClick)="_report()"
        title="{{ 'global.report' | translate }}">
      </app-button>

      <app-button
        type="button"
        color="return"
        icon="agres-return"
        (btnClick)="goBack()"
        title="{{ 'global.back' | translate }}">
      </app-button>
    </div>
  </div>
</div>

<ng-template #taskDataTabs>
  <app-tabs>
    <!-- TAB RESUMO -->
    <app-tab #tab1 tabTitle="{{ 'tasks.tabs.title-resume' | translate }}">
      <app-horizontal-scroll *ngIf="tab1.selected">
        <div class="resume-box">
          <app-data-box
            [data]="
              [
                ['tasks.tabs.label-field', trabalho?.crop_name],
                ['tasks.created-on', trabalho?.tal_timestamp | date: 'dd/MM/yyyy - HH:mm' : trabalho?.timezone],
                ['tasks.tabs.label-event', trabalho?.evt_name]
              ] | filterDataBoxArray
            "></app-data-box>
        </div>

        <div class="resume-box">
          <app-data-box
            [data]="
              [
                ['tasks.tabs.label-ecu', deviceName],
                ['tasks.tabs.label-machine', trabalho?.machine_name],
                ['tasks.tabs.label-equipment', 'tasks.equipment.' + trabalho?.model | translate],
                ['tasks.tabs.label-number-serie', ' ' + trabalho?.serial_number]
              ] | filterDataBoxArray
            "></app-data-box>
        </div>

        <div class="resume-box">
          <app-data-box
            [data]="
              [
                [
                  'tasks.tabs.label-operation',
                  trabalho?.operation !== undefined ? ('tasks.operation.' + trabalho?.operation | translate) : ''
                ],
                ['tasks.tabs.label-distance', trabalho?.travel_dist | convertUnit: 'km'],
                taskSummaryBoxApplyedValue(),
                [
                  'tasks.tabs.label-covered-area',
                  trabalho?.cover_area !== undefined ? (trabalho?.cover_area | convertUnit: 'ha') : ''
                ]
              ] | filterDataBoxArray
            "></app-data-box>
        </div>

        <div class="resume-box">
          <app-data-box
            [data]="
              [
                ['tasks.tabs.title-operation-time', tempoTotalOperacao],
                [
                  'tasks.tabs.date-start',
                  trabalho?.timestamp !== 'Invalid date'
                    ? (trabalho?.timestamp | date: 'dd/MM/yyyy - HH:mm' : trabalho?.timezone)
                    : ''
                ],
                [
                  'tasks.tabs.date-end',
                  trabalho?.endTimestamp !== 'Invalid date'
                    ? (trabalho?.endTimestamp | date: 'dd/MM/yyyy - HH:mm' : trabalho?.timezone)
                    : ''
                ]
              ] | filterDataBoxArray
            ">
          </app-data-box>
        </div>

        <div class="resume-box">
          <app-data-box
            [data]="
              [
                [
                  'tasks.tabs.label-transgression',
                  trabalho?.overlap_info?.overlap_percent
                    ? (trabalho?.overlap_info.overlap_percent | number: '1.1-2' + '%')
                    : ''
                ],
                [
                  'tasks.tabs.label-average-speed',
                  speed ? (speed | number: '1.1-2') : (trabalho?.avg_speed | convertUnit: 'km/h')
                ],
                ['tasks.tabs.label-effective-time', tempoEfetivoOperacao + ' x ' + tempoOciosoOperacao]
              ] | filterDataBoxArray
            "></app-data-box>
        </div>
      </app-horizontal-scroll>
    </app-tab>

    <app-tab #tab2 tabTitle="{{ 'tasks.tabs.title-event' | translate }}">
      <app-horizontal-scroll *ngIf="tab2.selected">
        <div class="resume-box">
          <app-data-box
            [data]="
              [
                ['tasks.tabs.label-event', trabalho?.evt_name],
                [
                  'tasks.created-on',
                  trabalho?.tal_timestamp !== 'Invalid date'
                    ? (trabalho?.tal_timestamp | date: 'dd/MM/yyyy - HH:mm' : trabalho?.timezone)
                    : ''
                ],

                ['tasks.tabs.label-field', trabalho?.crop_name]
              ] | filterDataBoxArray
            "></app-data-box>
        </div>

        <div class="resume-box">
          <app-data-box
            [data]="
              [
                ['tasks.tabs.label-machine', trabalho?.machine_name],
                ['tasks.tabs.label-number-serie', ' ' + trabalho?.serial_number]
              ] | filterDataBoxArray
            "></app-data-box>
        </div>

        <div class="resume-box">
          <app-data-box
            [data]="
              [
                [
                  'tasks.tabs.label-operation',
                  trabalho?.operation !== undefined ? ('tasks.operation.' + trabalho?.operation | translate) : ''
                ],
                ['tasks.tabs.label-distance', trabalho?.travel_dist | convertUnit: 'km'],
                taskSummaryBoxApplyedValue(),
                [
                  'tasks.tabs.label-covered-area',
                  trabalho?.cover_area !== undefined ? (trabalho?.cover_area | convertUnit: 'ha') : ''
                ]
              ] | filterDataBoxArray
            "></app-data-box>
        </div>

        <div class="resume-box">
          <app-data-box
            [data]="
              [
                [
                  'tasks.tabs.title-operation-time',
                  tempoTotalOperacao ? tempoTotalOperacao : trabalho?.tempoTotalOperacao
                ],
                [
                  'tasks.tabs.date-start',
                  trabalho?.timestamp !== 'Invalid date'
                    ? (trabalho?.timestamp | date: 'dd/MM/yyyy - HH:mm' : trabalho?.timezone)
                    : ''
                ],
                [
                  'tasks.tabs.date-end',
                  trabalho?.endTimestamp !== 'Invalid date'
                    ? (trabalho?.endTimestamp | date: 'dd/MM/yyyy - HH:mm' : trabalho?.timezone)
                    : ''
                ]
              ] | filterDataBoxArray
            ">
          </app-data-box>
        </div>

        <div class="resume-box">
          <app-data-box
            [data]="
              [
                [
                  'tasks.tabs.label-transgression',
                  trabalho?.overlap_info?.overlap_percent
                    ? (trabalho?.overlap_info.overlap_percent | number: '1.1-2') + '%'
                    : ''
                ],
                [
                  'tasks.tabs.label-average-speed',
                  speed ? (speed | number: '1.1-2') : (trabalho?.avg_speed | convertUnit: 'km/h')
                ],
                [
                  'tasks.tabs.label-effective-time',
                  (tempoEfetivoOperacao ? tempoEfetivoOperacao : trabalho?.tempoEfetivoOperacao) +
                    ' x ' +
                    (tempoOciosoOperacao ? tempoOciosoOperacao : trabalho?.tempoOciosoOperacao)
                ]
              ] | filterDataBoxArray
            "></app-data-box>
        </div>
      </app-horizontal-scroll>
    </app-tab>

    <app-tab
      *ngIf="etapasArr.length > 0"
      #tab3
      tabTitle="{{ 'tasks.tabs.title-stage' | translate }}"
      [active]="etapasArr.length > 0"
      (tabClick)="etapaClick()">
      <app-horizontal-scroll *ngIf="tab3.selected">
        <div [ngClass]="viewportBreakpoints.mobile ? 'col-md-4' : 'col-md-2'">
          <article style="max-height: 200px">
            <div *ngFor="let e of etapasArr; let i = index" [attr.data-index]="i" (click)="stageSelect($event, e, i)">
              <app-button
                type="button"
                color="success"
                icon="agres-download"
                title="{{ 'tasks.tabs.stage.label-stage' | translate }} {{ i + 1 }}">
              </app-button>
            </div>
          </article>
        </div>
        <div [ngClass]="viewportBreakpoints.mobile ? 'col-md-8' : 'col-md-10'">
          <app-horizontal-scroll>
            <div class="resume-box">
              <app-data-box
                [data]="
                  [
                    [
                      'tasks.tabs.stage.label-start-date',
                      timestamp_etapa_selecionada | date: 'dd/MM/yyyy - HH:mm' : trabalho?.timezone
                    ],
                    [
                      'tasks.tabs.stage.label-end-date',
                      endTimestamp_etapa_selecionada | date: 'dd/MM/yyyy - HH:mm' : trabalho?.timezone
                    ],
                    ['tasks.tabs.stage.label-operator', etapas?.e?.operator ? etapas?.e?.operator : etapasNvg?.operator]
                  ] | filterDataBoxArray
                ">
              </app-data-box>
            </div>

            <div class="resume-box">
              <ng-container *ngIf="etapasNvg?.app_vol === ERROR_ON_FIELD">
                <span>
                  <strong>
                    {{ 'global.error' | translate }}
                  </strong>
                </span>
              </ng-container>

              <app-data-box
                [data]="
                  [
                    [
                      'tasks.tabs.stage.label-culture',
                      etapas?.e?.crop_type ? etapas?.e?.crop_type : etapasNvg?.crop_type
                    ],

                    taskSummaryBoxApplyedValue(),

                    [
                      'tasks.tabs.label-covered-area',
                      etapas?.operation?.cover_area
                        ? (etapas?.operation?.cover_area | number: '1.1-2')
                        : (etapasNvg?.cover_area | convertUnit: 'ha')
                    ]
                  ] | filterDataBoxArray
                ">
              </app-data-box>

              <ng-container *ngIf="etapasNvg?.cover_area === ERROR_ON_FIELD; else numericCoverArea">
                <span>
                  <strong>{{ 'global.error' | translate }}</strong>
                </span>
              </ng-container>
            </div>

            <div class="resume-box">
              <ng-container *ngIf="etapasNvg?.avg_rate === ERROR_ON_FIELD; else numericAvgRate">
                <span>
                  <strong>{{ 'global.error' | translate }}</strong>
                </span>
              </ng-container>
              <ng-template #numericAvgRate>
                <app-data-box
                  [data]="
                    [
                      [
                        'tasks.tabs.stage.label-planted-seeds-rate',
                        etapas?.operation?.avg_rate
                          ? (etapas?.operation?.avg_rate | number: '1.1-2')
                          : (etapasNvg?.avg_rate | number: '1.1-2') + ('tasks.tabs.seeds' | translate),
                        operation_type === OPERATIONS.PLANTIO
                      ],
                      [
                        'tasks.tabs.stage.label-applied-rate',
                        etapas?.operation?.avg_rate
                          ? (etapas?.operation?.avg_rate | number: '1.1-2')
                          : (etapasNvg?.avg_rate | convertUnit: 'kg/ha'),
                        operation_type === OPERATIONS.ADUBACAO
                      ],
                      [
                        'tasks.tabs.stage.label-applied-rate',
                        etapas?.operation?.avg_rate
                          ? (etapas?.operation?.avg_rate | number: '1.1-2')
                          : (etapasNvg?.avg_rate | convertUnit: 'l/ha'),
                        operation_type !== OPERATIONS.PLANTIO && operation_type !== OPERATIONS.ADUBACAO
                      ],
                      [
                        'tasks.tabs.label-distance-calculated',
                        etapas?.operation?.travel_dist
                          ? (etapas?.operation?.travel_dist | number: '1.1-2')
                          : (etapasNvg?.travel_dist | convertUnit: 'km')
                      ],
                      [
                        'tasks.tabs.label-average-speed',
                        etapas?.speed ? (etapas?.speed | number: '1.1-2') : (etapasNvg?.avg_speed | convertUnit: 'km/h')
                      ]
                    ] | filterDataBoxArray
                  ">
                </app-data-box>
              </ng-template>
            </div>

            <div
              class="resume-box"
              *ngIf="
                etapas &&
                (etapas.operation.impl_width ||
                  etapasNvg.impl_width ||
                  etapas?.operation?.sec_number ||
                  etapasNvg?.sec_number)
              ">
              <app-data-box
                [data]="
                  [
                    [
                      'tasks.tabs.stage.label-width',
                      etapas?.operation?.impl_width
                        ? etapas?.operation?.impl_width
                        : (etapasNvg?.impl_width | convertUnit: 'm'),
                      etapas.operation.impl_width || etapasNvg.impl_width || etapas.operation?.impl_width !== undefined
                    ],

                    [
                      'tasks.tabs.stage.label-number-of-lines',
                      etapas?.operation?.sec_number,
                      operation_type === OPERATIONS.PLANTIO
                    ],
                    [
                      'tasks.tabs.stage.label-sections',
                      ' ' + etapasNvg?.sec_number,
                      operation_type !== OPERATIONS.PLANTIO
                    ]
                  ] | filterDataBoxArray
                ">
              </app-data-box>
            </div>

            <div class="resume-box">
              <app-data-box
                [data]="
                  [
                    [
                      'tasks.tabs.stage.label-number-of-ecus',
                      etapas?.e?.ecu_number ? etapas?.e?.ecu_number : etapasNvg?.ecu_number,
                      operation_type !== OPERATIONS.PLANTIO && !etapas.e?.ecu_number && !etapasNvg.ecu_number
                    ],
                    [
                      'tasks.tabs.stage.label-nozzles',
                      etapas?.e?.nozz_number ? etapas?.e?.nozz_number : etapasNvg?.nozz_number,
                      operation_type === OPERATIONS.PLANTIO || operation_type === OPERATIONS.ADUBACAO
                    ],
                    [
                      'tasks.tabs.stage.label-nozzle-space',
                      etapas?.e?.nozz_spacing ? etapas?.e?.nozz_spacing : (etapasNvg?.nozz_spacing | convertUnit: 'cm')
                    ],
                    [
                      'tasks.tabs.stage.label-app-version',
                      etapas?.e?.app_version ? etapas?.e?.app_version : etapasNvg?.app_version
                    ]
                  ] | filterDataBoxArray
                ">
              </app-data-box>
            </div>
          </app-horizontal-scroll>
        </div>
      </app-horizontal-scroll>
    </app-tab>

    <app-tab
      *ngIf="guides?.length > 0"
      #tab4
      tabTitle="{{ 'tasks.tabs.title-guides' | translate }}"
      [active]="guides.length > 0"
      (tabClick)="guidesTabClick()">
      <app-horizontal-scroll *ngIf="tab4.selected">
        <div [ngClass]="viewportBreakpoints.mobile ? 'col-md-4' : 'col-md-2'">
          <article style="max-height: 200px">
            <div *ngFor="let guide of guides; let i = index">
              <ng-container *ngIf="guide.type !== guideTypeEnum.PROJECT">
                <app-button
                  type="button"
                  color="success"
                  icon="agres-download"
                  (btnClick)="guideSelect(i)"
                  title="{{ 'tasks.guide.label-guide' | translate }} {{ i + 1 }}">
                </app-button>
              </ng-container>
            </div>
          </article>
        </div>
        <div [ngClass]="viewportBreakpoints.mobile ? 'col-md-8' : 'col-md-10'">
          <app-horizontal-scroll>
            <div class="resume-box">
              <p class="resume-box__item">
                {{ 'tasks.guides.label-guide-type' | translate }}
                <span>
                  <strong>
                    {{ 'tasks.guide.label-guide' | translate }}
                    {{ 'tasks.guide.type.' + selectedGuide?.type | translate }}
                  </strong>
                </span>
              </p>
              <div class="resume-box--pontoA" *ngIf="selectedGuide?.path[0] !== undefined">
                <app-data-box
                  [data]="
                    [
                      ['tasks.guide.point-A', ''],
                      ['tasks.guide.lat', selectedGuide?.path[0].lat | number: '1.1-6'],
                      ['tasks.guide.long', selectedGuide?.path[0].long | number: '1.1-6']
                    ] | filterDataBoxArray
                  ">
                </app-data-box>
              </div>

              <div
                class="resume-box--pontoB"
                *
                ngIf="selectedGuide?.path[selectedGuide?.path.length - 1] !== undefined">
                <app-data-box
                  [data]="
                    [
                      ['tasks.guide.point-B', ''],
                      ['tasks.guide.lat', selectedGuide?.path[selectedGuide?.path.length - 1].lat | number: '1.1-6'],
                      ['tasks.guide.long', selectedGuide?.path[selectedGuide?.path.length - 1].long | number: '1.1-6']
                    ] | filterDataBoxArray
                  ">
                </app-data-box>
              </div>
            </div>

            <div class="resume-box">
              <app-data-box
                [data]="[['tasks.guide.ab_distance', selectedGuide?.dist_A_B | convertUnit: 'm']] | filterDataBoxArray">
              </app-data-box>
            </div>
          </app-horizontal-scroll>
        </div>
      </app-horizontal-scroll>
    </app-tab>

    <app-tab
      *ngIf="boundaries.length > 0"
      #tab5
      [active]="boundaries?.length > 0"
      tabTitle="{{ 'tasks.tabs.title-boundaries' | translate }}">
      <app-horizontal-scroll *ngIf="tab5.selected">
        <ng-container * ngFor="let boundary of boundaries">
          <div class="resume-box">
            <div class="resume-box--boundary">
              <app-data-box
                [data]="
                  [
                    ['tasks.boundary.area', boundary.area | convertUnit: 'ha'],
                    ['tasks.boundary.created-at', boundary.timestamp | date: 'dd/MM/yyyy - HH:mm'],
                    ['tasks.boundary.counter_clockwise', '', boundary.direction !== 'clockwise'],
                    ['tasks.boundary.clockwise', '', boundary.direction === 'clockwise']
                  ] | filterDataBoxArray
                ">
              </app-data-box>
            </div>
          </div>
        </ng-container>
      </app-horizontal-scroll>
    </app-tab>
  </app-tabs>
</ng-template>

<!--MOBILE -->
<div *ngIf="viewportBreakpoints.mobile">
  <ng-container [ngTemplateOutlet]="taskDataTabs"></ng-container>
</div>
<!-- LEGENDAS MOBILE-->
<div class="card card__body" *ngIf="viewportBreakpoints.mobile">
  <div *ngIf="viewportBreakpoints.mobile && mapImages">
    <select
      (change)="onChangeMap(undefined, undefined)"
      [(ngModel)]="selectedMapImage"
      [compareWith]="compareMapOptionsOnSelect">
      <option *ngFor="let raster of getAvailableRasters()" [ngValue]="raster">
        {{ 'tasks.' + raster.value | translate }}
      </option>
    </select>
    <img [src]="getBackgroundImageURL()" alt="" />
    <img [src]="getRasterImageURL()" alt="" />
    <div style="margin-top: 270px; margin-bottom: -5px" *ngIf="viewportBreakpoints.mobile && !shouldShowLegend()"></div>
  </div>
  <div
    style="margin-top: 250px; margin-bottom: 0px"
    *ngIf="mapImages && viewportBreakpoints.mobile && shouldShowLegend()">
    <hr />
  </div>
</div>

<!--DESKTOP -->
<ng-sidebar-container #container class="sidebar-body" *ngIf="!viewportBreakpoints.mobile">
  <!-- TABS INFO DESKTOP -->
  <div ng-sidebar-content class="sidebar-tabs">
    <div class="spacing-sidebar">
      <ng-container [ngTemplateOutlet]="taskDataTabs"></ng-container>
    </div>
  </div>

  <!-- CONFIGURAÇÕES DO MAPA -->
  <ng-sidebar [(opened)]="_opened" position="left" mode="push">
    <div class="sidebar-right">
      <p>
        {{ 'tasks.config-map' | translate }}
      </p>
      <div class="sidebar-right__mapConfig">
        <!-- BOTAO ABRIR SEGUNDO MAPA -->
        <div class="mb-4">
          <app-button
            type="button"
            color="primary"
            icon="agres-column"
            (btnClick)="splitMap()"
            title="{{ (!splitScreen ? 'tasks.button-map' : 'tasks.button-map-close') | translate }}">
          </app-button>
        </div>

        <!-- BOTAO SISTEMA METRICO -->
        <div ng-sidebar-content>
          <ul id="units">
            <li>
              <app-button
                type="button"
                color="primary"
                class="splitscreen"
                icon="agres-arrow-down"
                title="{{ 'unitSystem.' + currentUnitSystem | translate }}">
              </app-button>
              <ul>
                <li *ngFor="let system of unitSystemOptions; let i = index" (click)="selectSystemUnit(i)">
                  <a>{{ 'unitSystem.' + system | translate }}</a>
                </li>
              </ul>
            </li>
          </ul>
        </div>
        <hr />
        <!-- LEGENDA CONTROL -->
        <ng-container *ngIf="shouldShowLegend('0')">
          <app-legends-control
            [preferenceSubtitleUnit]="getPreferenceSubtitleUnit()[0]"
            [preferenceSubtitle]="getPreferenceSubtitle()[0]"
            [preferences]="preferences"
            [preferencesIndex]="mapa0"
            [showSaveDefaultInputButton]="!replayController?.isRunning()"
            [preferencesInputSorting]="preferencesInputSorting"
            [showError]="errorValuesLegend0"
            (legendHasValidValues)="legendValidityChange($event, 'errorValuesLegend0')">
          </app-legends-control>
        </ng-container>

        <div class="row text-center">
          <div class="col-md-6">
            <input
              checked
              type="radio"
              name="filtroMap0Type"
              class="mt-5 filtroMapType"
              id="filtroMap0Type_0"
              (click)="changeMapType({ isFirstMap: true, mapTypeId: mapTypesOptions[0] })" />
            <label for="filtroMap0Type_0" class="cursor-pointer">
              <span class="spacing-label">
                {{ 'tasks.map-types-options.satellite' | translate }}
              </span>
            </label>
          </div>
          <div class="col-md-6">
            <input
              type="radio"
              name="filtroMap0Type"
              class="mt-5 filtroMapType"
              id="filtroMap0Type_1"
              (click)="changeMapType({ isFirstMap: true, mapTypeId: mapTypesOptions[1] })" />
            <label for="filtroMap0Type_1" class="cursor-pointer">
              <span class="spacing-label">
                {{ 'tasks.map-types-options.terrain' | translate }}
              </span>
            </label>
          </div>
        </div>
        <!-- LEGENDAS SEGUNDO MAPA -->
        <ng-container *ngIf="splitScreen">
          <hr />
          <ng-container *ngIf="shouldShowLegend('1')">
            <app-legends-control
              [preferenceSubtitleUnit]="getPreferenceSubtitleUnit()[1]"
              [preferenceSubtitle]="getPreferenceSubtitle()[1]"
              [preferences]="preferences"
              [preferencesIndex]="mapa1"
              [showSaveDefaultInputButton]="!replayController?.isRunning()"
              [preferencesInputSorting]="preferencesInputSorting"
              [showError]="errorValuesLegend1"
              (legendHasValidValues)="legendValidityChange($event, 'errorValuesLegend1')">
            </app-legends-control>
          </ng-container>
          <div class="row text-center">
            <div class="col-md-6">
              <input
                checked
                type="radio"
                name="filtroMap1Type"
                class="mt-5 filtroMapType"
                id="filtroMap1Type_0"
                (click)="changeMapType({ isFirstMap: false, mapTypeId: mapTypesOptions[0] })" />
              <label for="filtroMap1Type_0" class="cursor-pointer">
                <span class="spacing-label">
                  {{ 'tasks.map-types-options.satellite' | translate }}
                </span>
              </label>
            </div>
            <div class="col-md-6">
              <input
                type="radio"
                name="filtroMap1Type"
                class="mt-5 filtroMapType"
                id="filtroMap1Type_1"
                (click)="changeMapType({ isFirstMap: false, mapTypeId: mapTypesOptions[1] })" />
              <label for="filtroMap1Type_1" class="cursor-pointer">
                <span class="spacing-label">
                  {{ 'tasks.map-types-options.terrain' | translate }}
                </span>
              </label>
            </div>
          </div>
        </ng-container>

        <div class="btn-padding">
          <ng-container *ngIf="shouldShowLegend('0') || shouldShowLegend('1')">
            <button class="legends-save" (click)="saveTx()" [disabled]="!seeButtonSave">
              {{ 'monitoring.maps.btn-save' | translate }}
            </button>
            <button
              class="legends-reset"
              (click)="setDefaultSubtitle()"
              [disabled]="replayController?.isRunning() && !seeButtonSave">
              {{ 'monitoring.maps.default' | translate }}
            </button>
          </ng-container>
        </div>

        <hr />
        <!-- MARCADORES -->
        <ng-container *ngIf="marcadoresArrayL?.length > 0">
          <app-map-markers
            [marcadoresArrayL]="marcadoresArrayL"
            (changeMarkers)="toggleMarker($event)"></app-map-markers>
        </ng-container>
      </div>

      <!-- REPLAY DE NAVEGAÇÃO -->
      <div class="sidebar_right__mapReplay">
        <hr />
        <ng-container>
          <app-replay [replayController]="replayController"></app-replay>
        </ng-container>
      </div>
    </div>
  </ng-sidebar>

  <!-- MAPA 1 E MAPA 2 COM SELECT 1 E SELECT 2 -->
  <div ng-sidebar-content class="map-content">
    <div class="row" *ngIf="apiLoaded | async">
      <div id="mapa-1" class="col-sm-12">
        <div ng-sidebar-content class="navbar select-map-1">
          <div>
            <select
              id="selectMapWithNvgCard"
              class="mt-0 mapType1"
              [attr.disabled]="replayController?.isRunning() ? '' : null"
              (change)="onChangeMap($event.target.selectedIndex, 0)">
              <option *ngFor="let mapa of MapasNvg">
                {{ 'tasks.' + mapa | translate }}
              </option>
            </select>
          </div>
          <div *ngIf="!(trailsM1[0].constructor.name === 'Array') && mapa0 === 0">
            <select [(ngModel)]="selectedIsobusApplicationRate0">
              <option *ngFor="let item of trailsM1[0] | keyvalue" [value]="item.key">
                {{ 'tasks.map-application-isobus-' + item.key | translate }}
              </option>
            </select>
          </div>
        </div>
        <google-map
          #googleMap="googleMap"
          (tilesloaded)="mapReady(googleMap.googleMap)"
          (mapMouseover)="isMap1BeingEdited = true"
          (mapMouseout)="isMap1BeingEdited = false"
          (centerChanged)="updateMapCenterAndZoom(googleMap.googleMap, 0)"
          (zoomChanged)="updateMapCenterAndZoom(googleMap.googleMap, 0)"
          (mapClick)="clickOnMap()"
          [options]="mapOptions"
          width="100%"
          height="59vh">
          <map-polygon
            *ngFor="
              let trail of (trailsM1[0].constructor.name === 'Array' && mapa0 === 0) || !selectedIsobusApplicationRate0
                ? trailsM1[mapa0]
                : trailsM1[mapa0][selectedIsobusApplicationRate0]
            "
            [paths]="trail.paths"
            [options]="{
              fillOpacity: 0.5,
              strokeWeight: 0,
              zIndex: 1,
              clickable: false,
              fillColor: trail.color,
              visible: trail.visible
            }">
          </map-polygon>

          <map-polygon
            *ngFor="let boundary of boundaries"
            [paths]="boundary.path"
            [options]="{
              fillOpacity: 0,
              strokeColor: 'magenta',
              strokeWeight: 2,
              visible: boundary.visible,
              zIndex: 3,
              clickable: false
            }">
          </map-polygon>

          <ng-container *ngFor="let guide of guides; let i = index">
            <ng-container *ngIf="guide.type !== guideTypeEnum.CUSTOM && guide?.type !== guideTypeEnum.PROJECT">
              <map-marker
                #marker="mapMarker"
                (mapClick)="showInfoWindow(guide.path[0].lat, guide.path[0].long, infowindow, marker)"
                [label]="{
                  color: 'black',
                  text: 'A',
                  fontWeight: 'bold'
                }"
                [options]="{
                  position: { lat: guide.path[0].lat, lng: guide.path[0].long },
                  visible: guide?.show,
                  zIndex: 2,
                  icon: {
                    url: 'http://maps.google.com/mapfiles/ms/icons/green.png',
                    labelOrigin: point,
                    scaledSize: scaleSize
                  }
                }">
              </map-marker>
              <map-marker
                #marker1="mapMarker"
                (mapClick)="
                  showInfoWindow(
                    guide.path[guide.path.length - 1].lat,
                    guide.path[guide.path.length - 1].long,
                    infowindow,
                    marker1
                  )
                "
                [label]="{
                  color: 'black',
                  text: 'B',
                  fontWeight: 'bold'
                }"
                [options]="{
                  visible: guide?.show,
                  position: {
                    lat: guide.path[guide.path.length - 1].lat,
                    lng: guide.path[guide.path.length - 1].long
                  },
                  icon: {
                    url: 'http://maps.google.com/mapfiles/ms/icons/green.png',
                    labelOrigin: point,
                    scaledSize: scaleSize
                  },
                  zIndex: 2
                }">
              </map-marker>
              <map-info-window
                #infowindow="mapInfoWindow"
                [position]="{ lat: guideInfoWindowPoint.lat, lng: guideInfoWindowPoint.long }">
                <div>
                  <h3>{{ 'tasks.guide.type.' + guide.type | translate }} {{ i + 1 }}</h3>
                </div>
                <div>{{ 'tasks.guide.ab_distance' | translate }} {{ guide.dist_A_B | convertUnit: 'm' }}</div>
                <div class="mt-5 fntSize-110">
                  <strong>{{ 'tasks.guide.coordinates' | translate }}</strong>
                </div>
                <div>
                  <strong>{{ 'tasks.guide.point' | translate }} A</strong>
                </div>
                <div>{{ 'tasks.guide.lat' | translate }} {{ guide.path[0].lat | number: '1.1-6' }}°</div>
                <div>{{ 'tasks.guide.long' | translate }} {{ guide.path[0].long | number: '1.1-6' }}°</div>
                <div>
                  <strong>{{ 'tasks.guide.point' | translate }} B</strong>
                </div>
                <div>
                  {{ 'tasks.guide.lat' | translate }}
                  {{ guide.path[guide.path.length - 1].lat | number: '1.1-6' }}°
                </div>
                <div>
                  {{ 'tasks.guide.long' | translate }}
                  {{ guide.path[guide.path.length - 1].long | number: '1.1-6' }}°
                </div>
              </map-info-window>
            </ng-container>

            <map-polyline
              [path]="formatPaths(guide.path)"
              [options]="{
                strokeColor: 'blue',
                strokeWeight: 3,
                strokeOpacity: 0.6,
                visible: guide?.show,
                zIndex: 2
              }">
            </map-polyline>
          </ng-container>

          <ng-container *ngFor="let marker of nvgStorageData?.marcadores.stopPoints; let i = index">
            <map-marker
              #stopPointMarker="mapMarker"
              (mapClick)="showMarkerInfoWindow(stopPointInfoWindow, stopPointMarker)"
              [options]="{
                position: { lat: marker.location.latitude, lng: marker.location.longitude },
                visible: marker?.show,
                icon: {
                  url: 'http://maps.google.com/mapfiles/kml/paddle/ylw-square.png',
                  scaledSize: scaleSize
                },
                zIndex: 2
              }">
            </map-marker>

            <map-info-window
              #stopPointInfoWindow="mapInfoWindow"
              [position]="{ lat: marker.location.latitude, lng: marker.location.longitude }">
              <div>
                <p>{{ 'tasks.marker.type.stopPoint' | translate }} {{ i + 1 }}</p>
              </div>
              <div class="mt-5">
                {{ 'tasks.marker.start' | translate }}
                {{ marker.timestamp | date: 'dd/MM/yyyy - HH:mm' : nvgStorageData?.trabalho.timezone }}
              </div>
              <div class="mt-5">{{ 'tasks.marker.timeStopped' | translate }} {{ marker.time_stopped }}</div>
              <div class="mt-5">{{ 'tasks.marker.step' | translate }} {{ marker.step }}</div>
            </map-info-window>
          </ng-container>

          <ng-container *ngFor="let marker of nvgStorageData?.marcadores.powerPoints; let i = index">
            <map-marker
              #powerPointMarker="mapMarker"
              (mapClick)="showMarkerInfoWindow(powerPointInfoWindow, powerPointMarker)"
              [options]="{
                position: { lat: marker.location.latitude, lng: marker.location.longitude },
                visible: marker?.show,
                icon: {
                  url: 'http://maps.google.com/mapfiles/kml/shapes/arrow.png',
                  scaledSize: scaleSize
                },
                zIndex: 2
              }">
            </map-marker>

            <map-info-window
              #powerPointInfoWindow="mapInfoWindow"
              [position]="{ lat: marker.location.latitude, lng: marker.location.longitude }">
              <div>
                <p>{{ 'tasks.marker.type.powerPoints' | translate }} {{ i + 1 }}</p>
              </div>
              <div class="mt-5">
                {{ marker.timestamp | date: 'dd/MM/yyyy - HH:mm' : nvgStorageData?.trabalho.timezone }}
              </div>
              <div class="mt-5">{{ 'tasks.marker.timeOff' | translate }} {{ marker.time_off }}</div>
              <div class="mt-5">{{ 'tasks.marker.step' | translate }} {{ marker.step }}</div>
            </map-info-window>
          </ng-container>

          <ng-container *ngFor="let marker of nvgStorageData?.marcadores.exceededTemperaturePoints; let i = index">
            <map-marker
              #exceededTemperature="mapMarker"
              (mapClick)="showMarkerInfoWindow(exceededTemperatureInfoWindow, exceededTemperature)"
              [options]="{
                position: { lat: marker.location.latitude, lng: marker.location.longitude },
                visible: marker.show,
                icon: {
                  url: 'http://maps.google.com/mapfiles/kml/pushpin/red-pushpin.png',
                  scaledSize: scaleSize
                },
                zIndex: 2
              }">
            </map-marker>

            <map-info-window
              #exceededTemperatureInfoWindow="mapInfoWindow"
              [position]="{ lat: marker.location.latitude, lng: marker.location.longitude }">
              <div>
                <p>{{ 'tasks.marker.type.exceededTemperaturePoint' | translate }} {{ i + 1 }}</p>
              </div>
              <div class="mt-5">
                {{ marker.timestamp | date: 'dd/MM/yyyy - HH:mm' : nvgStorageData?.trabalho.timezone }}
              </div>
              <div class="mt-5">{{ 'tasks.marker.temperature' | translate }} {{ marker.temperature }}</div>
              <div class="mt-5">{{ 'tasks.marker.step' | translate }} {{ marker.step }}</div>
            </map-info-window>
          </ng-container>

          <ng-container *ngFor="let marker of nvgStorageData?.marcadores.pausePointsInit; let i = index">
            <map-marker
              #pauseMarker="mapMarker"
              (mapClick)="showMarkerInfoWindow(pauseInitInfoWindow, pauseMarker)"
              [options]="{
                position: { lat: marker.location.latitude, lng: marker.location.longitude },
                visible: marker?.show,
                icon: {
                  url: 'http://maps.google.com/mapfiles/kml/paddle/pause.png',
                  scaledSize: scaleSize
                },
                zIndex: 2
              }">
            </map-marker>
            <map-info-window
              #pauseInitInfoWindow="mapInfoWindow"
              [position]="{ lat: marker.location.latitude, lng: marker.location.longitude }">
              <div>
                <p>{{ 'tasks.marker.type.pausePointInit' | translate }} {{ i + 1 }}</p>
              </div>
              <div class="mt-5">
                {{ marker.timestamp | date: 'dd/MM/yyyy - HH:mm' : nvgStorageData?.trabalho.timezone }}
              </div>
              <div class="mt-5">{{ 'tasks.marker.step' | translate }} {{ marker.step }}</div>
            </map-info-window>
          </ng-container>
          <ng-container *ngFor="let marker of nvgStorageData?.marcadores.pausePointsEnd; let i = index">
            <map-marker
              #pauseEndMarker="mapMarker"
              (mapClick)="showMarkerInfoWindow(pauseEndInfoWindow, pauseEndMarker)"
              [options]="{
                position: { lat: marker.location.latitude, lng: marker.location.longitude },
                visible: marker?.show,
                icon: {
                  url: 'http://maps.google.com/mapfiles/kml/paddle/go.png',
                  scaledSize: scaleSize
                },
                zIndex: 2
              }">
            </map-marker>
            <map-info-window
              #pauseEndInfoWindow="mapInfoWindow"
              [position]="{ lat: marker.location.latitude, lng: marker.location.longitude }">
              <div>
                <p>{{ 'tasks.marker.type.pausePointEnd' | translate }} {{ i + 1 }}</p>
              </div>
              <div class="mt-5">
                {{ marker.timestamp | date: 'dd/MM/yyyy - HH:mm' : nvgStorageData?.trabalho.timezone }}
              </div>
              <div class="mt-5">{{ 'tasks.marker.pauseDuration' | translate }} {{ marker.pause_duration }}</div>
              <div class="mt-5">{{ 'tasks.marker.step' | translate }} {{ marker.step }}</div>
            </map-info-window>
          </ng-container>

          <ng-container *ngIf="googleMapsApiLoaded">
            <map-marker
              *ngFor="let pin of startPin; let i = index"
              [options]="{
                position: { lat: pin?.lat, lng: pin?.lng },
                visible: showWorkMark,
                label: {
                  text: labelEtapa,
                  className: 'flag-green-start-work-result'
                },
                icon: {
                  url: './assets/images/default/flag-line-svg-green.svg',
                  scaleSize: scaleSize
                }
              }">
            </map-marker>
            <map-marker
              *ngIf="startLat && startLng"
              [options]="{
                position: { lat: startLat, lng: startLng },
                visible: showWorkMark,
                label: {
                  text: labelInicio,
                  className: 'flag-green-start-work-result'
                },
                icon: {
                  url: './assets/images/default/flag-line-svg-green.svg',
                  scaleSize: scaleSize
                }
              }">
            </map-marker>
            <map-marker
              *ngIf="endLat && endLng"
              [options]="{
                position: { lat: endLat, lng: endLng },
                visible: showWorkMark,
                label: {
                  text: labelFim,
                  className: 'flag-green-start-work-result'
                },
                icon: {
                  url: './assets/images/default/flag-line-svg-red.svg',
                  scaleSize: scaleSize
                }
              }">
            </map-marker>
          </ng-container>

          <map-marker
            *ngIf="replayController?.isRunning()"
            [options]="{
              position: {
                lat: replayController?.getCurrentLocation().lat,
                lng: replayController?.getCurrentLocation().lng
              },
              icon: {
                url: './assets/images/default/tractor.png',
                scaleSize: scaleSize
              }
            }">
          </map-marker>
        </google-map>
      </div>

      <div id="mapa-2" class="col-sm-6" *ngIf="splitScreen">
        <div ng-sidebar-content class="navbar select-map-2">
          <select
            class="mt-0 mapType2"
            id="selectMap2WithNvgCard"
            [attr.disabled]="replayController?.isRunning() ? '' : null"
            [(ngModel)]="selectedMap2Value"
            (change)="onChangeMap($event.target.selectedIndex, 1)">
            <option *ngFor="let mapa of MapasNvg; let i = index" [value]="i">
              {{ 'tasks.' + mapa | translate }}
            </option>
          </select>
          <div *ngIf="!(trailsM2[0].constructor.name === 'Array') && mapa1 === 0">
            <select [(ngModel)]="selectedIsobusApplicationRate1">
              <option *ngFor="let item of trailsM2[0] | keyvalue" [value]="item.key">
                {{ 'tasks.map-application-isobus-' + item.key | translate }}
              </option>
            </select>
          </div>
        </div>

        <google-map
          #googleMap1="googleMap"
          (mapMouseover)="isMap2BeingEdited = true"
          (mapMouseout)="isMap2BeingEdited = false"
          (tilesloaded)="mapReadySegundo(googleMap1.googleMap)"
          (centerChanged)="updateMapCenterAndZoom(googleMap1.googleMap, 1)"
          (zoomChanged)="updateMapCenterAndZoom(googleMap1.googleMap, 1)"
          (mapClick)="clickOnMap()"
          [options]="secondMapOptions"
          width="100%"
          height="59vh">
          <map-polygon
            *ngFor="
              let trail of (trailsM2[0].constructor.name === 'Array' && mapa1 === 0) || !selectedIsobusApplicationRate1
                ? trailsM2[mapa1]
                : trailsM2[mapa1][selectedIsobusApplicationRate1]
            "
            [paths]="trail.paths"
            [options]="{
              fillOpacity: 0.5,
              strokeWeight: 0,
              zIndex: 1,
              clickable: false,
              fillColor: trail.color,
              visible: trail.visible
            }">
          </map-polygon>

          <map-polygon
            *ngFor="let boundary of boundaries"
            [paths]="boundary.path"
            [options]="{
              fillOpacity: 0,
              strokeColor: 'magenta',
              strokeWeight: 2,
              visible: boundary.visible,
              zIndex: 3,
              clickable: false
            }">
          </map-polygon>

          <ng-container *ngFor="let guide of guides; let i = index">
            <ng-container *ngIf="guide.type !== guideTypeEnum.CUSTOM && guide?.type !== guideTypeEnum.PROJECT">
              <map-marker
                #marker="mapMarker"
                (mapClick)="showInfoWindow(guide.path[0].lat, guide.path[0].long, infowindow, marker)"
                [label]="{
                  color: 'black',
                  text: 'A',
                  fontWeight: 'bold'
                }"
                [options]="{
                  position: { lat: guide.path[0].lat, lng: guide.path[0].long },
                  visible: guide?.show,
                  zIndex: 2,
                  icon: {
                    url: 'http://maps.google.com/mapfiles/ms/icons/green.png',
                    labelOrigin: point,
                    scaledSize: scaleSize
                  }
                }">
              </map-marker>
              <map-marker
                #marker1="mapMarker"
                (mapClick)="
                  showInfoWindow(
                    guide.path[guide.path.length - 1].lat,
                    guide.path[guide.path.length - 1].long,
                    infowindow,
                    marker1
                  )
                "
                [label]="{
                  color: 'black',
                  text: 'B',
                  fontWeight: 'bold'
                }"
                [options]="{
                  visible: guide?.show,
                  position: {
                    lat: guide.path[guide.path.length - 1].lat,
                    lng: guide.path[guide.path.length - 1].long
                  },
                  icon: {
                    url: 'http://maps.google.com/mapfiles/ms/icons/green.png',
                    labelOrigin: point,
                    scaledSize: scaleSize
                  },
                  zIndex: 2
                }">
              </map-marker>
              <map-info-window
                #infowindow="mapInfoWindow"
                [position]="{ lat: guideInfoWindowPoint.lat, lng: guideInfoWindowPoint.long }">
                <div>
                  <h3>{{ 'tasks.guide.type.' + guide.type | translate }} {{ i + 1 }}</h3>
                </div>
                <div>{{ 'tasks.guide.ab_distance' | translate }} {{ guide.dist_A_B | convertUnit: 'm' }}</div>
                <div class="mt-5 fntSize-110">
                  <strong>{{ 'tasks.guide.coordinates' | translate }}</strong>
                </div>
                <div>
                  <strong>{{ 'tasks.guide.point' | translate }} A</strong>
                </div>
                <div>{{ 'tasks.guide.lat' | translate }} {{ guide.path[0].lat | number: '1.1-6' }}°</div>
                <div>{{ 'tasks.guide.long' | translate }} {{ guide.path[0].long | number: '1.1-6' }}°</div>
                <div>
                  <strong>{{ 'tasks.guide.point' | translate }} B</strong>
                </div>
                <div>
                  {{ 'tasks.guide.lat' | translate }}
                  {{ guide.path[guide.path.length - 1].lat | number: '1.1-6' }}°
                </div>
                <div>
                  {{ 'tasks.guide.long' | translate }}
                  {{ guide.path[guide.path.length - 1].long | number: '1.1-6' }}°
                </div>
              </map-info-window>
            </ng-container>
            <map-polyline
              [path]="formatPaths(guide.path)"
              [options]="{
                strokeColor: 'blue',
                strokeWeight: 3,
                strokeOpacity: 0.6,
                visible: guide?.show,
                zIndex: 2
              }">
            </map-polyline>
          </ng-container>

          <ng-container *ngFor="let marker of nvgStorageData?.marcadores.stopPoints; let i = index">
            <map-marker
              #stopPointMarker="mapMarker"
              (mapClick)="showMarkerInfoWindow(stopPointInfoWindow, stopPointMarker)"
              [options]="{
                position: { lat: marker.location.latitude, lng: marker.location.longitude },
                visible: marker?.show,
                icon: {
                  url: 'http://maps.google.com/mapfiles/kml/paddle/ylw-square.png',
                  scaledSize: scaleSize
                },
                zIndex: 2
              }">
            </map-marker>
            <map-info-window
              #stopPointInfoWindow="mapInfoWindow"
              [position]="{ lat: marker.location.latitude, lng: marker.location.longitude }">
              <div>
                <p>{{ 'tasks.marker.type.stopPoint' | translate }} {{ i + 1 }}</p>
              </div>
              <div class="mt-5">
                {{ 'tasks.marker.start' | translate }}
                {{ marker.timestamp | date: 'dd/MM/yyyy - HH:mm' : nvgStorageData?.trabalho.timezone }}
              </div>
              <div class="mt-5">{{ 'tasks.marker.timeStopped' | translate }} {{ marker.time_stopped }}</div>
              <div class="mt-5">{{ 'tasks.marker.step' | translate }} {{ marker.step }}</div>
            </map-info-window>
          </ng-container>

          <ng-container *ngFor="let marker of nvgStorageData?.marcadores.powerPoints; let i = index">
            <map-marker
              #powerPointMarker="mapMarker"
              (mapClick)="showMarkerInfoWindow(powerPointInfoWindow, powerPointMarker)"
              [options]="{
                position: { lat: marker.location.latitude, lng: marker.location.longitude },
                visible: marker?.show,
                icon: {
                  url: 'http://maps.google.com/mapfiles/kml/shapes/arrow.png',
                  scaledSize: scaleSize
                },
                zIndex: 2
              }">
            </map-marker>
            <map-info-window
              #powerPointInfoWindow="mapInfoWindow"
              [position]="{ lat: marker.location.latitude, lng: marker.location.longitude }">
              <div>
                <p>{{ 'tasks.marker.type.powerPoints' | translate }} {{ i + 1 }}</p>
              </div>
              <div class="mt-5">
                {{ marker.timestamp | date: 'dd/MM/yyyy - HH:mm' : nvgStorageData?.trabalho.timezone }}
              </div>
              <div class="mt-5">{{ 'tasks.marker.timeOff' | translate }} {{ marker.time_off }}</div>
              <div class="mt-5">{{ 'tasks.marker.step' | translate }} {{ marker.step }}</div>
            </map-info-window>
          </ng-container>

          <ng-container *ngFor="let marker of nvgStorageData?.marcadores.exceededTemperaturePoints; let i = index">
            <map-marker
              #exceededTemperature="mapMarker"
              (mapClick)="showMarkerInfoWindow(exceededTemperatureInfoWindow, exceededTemperature)"
              [options]="{
                position: { lat: marker.location.latitude, lng: marker.location.longitude },
                visible: marker.show,
                icon: {
                  url: 'http://maps.google.com/mapfiles/kml/pushpin/red-pushpin.png',
                  scaledSize: scaleSize
                },
                zIndex: 2
              }">
            </map-marker>
            <map-info-window
              #exceededTemperatureInfoWindow="mapInfoWindow"
              [position]="{ lat: marker.location.latitude, lng: marker.location.longitude }"
              >>
              <div>
                <p>{{ 'tasks.marker.type.exceededTemperaturePoint' | translate }} {{ i + 1 }}</p>
              </div>
              <div class="mt-5">
                {{ marker.timestamp | date: 'dd/MM/yyyy - HH:mm' : nvgStorageData?.trabalho.timezone }}
              </div>
              <div class="mt-5">{{ 'tasks.marker.temperature' | translate }} {{ marker.temperature }}</div>
              <div class="mt-5">{{ 'tasks.marker.step' | translate }} {{ marker.step }}</div>
            </map-info-window>
          </ng-container>

          <ng-container *ngFor="let marker of nvgStorageData?.marcadores.pausePointsInit; let i = index">
            <map-marker
              #pauseMarker="mapMarker"
              (mapClick)="showMarkerInfoWindow(pauseInitInfoWindow, pauseMarker)"
              [options]="{
                position: { lat: marker.location.latitude, lng: marker.location.longitude },
                visible: marker?.show,
                icon: {
                  url: 'http://maps.google.com/mapfiles/kml/paddle/pause.png',
                  scaledSize: scaleSize
                },
                zIndex: 2
              }">
            </map-marker>
            <map-info-window
              #pauseInitInfoWindow="mapInfoWindow"
              [position]="{ lat: marker.location.latitude, lng: marker.location.longitude }">
              <div>
                <p>{{ 'tasks.marker.type.pausePointInit' | translate }} {{ i + 1 }}</p>
              </div>
              <div class="mt-5">
                {{ marker.timestamp | date: 'dd/MM/yyyy - HH:mm' : nvgStorageData?.trabalho.timezone }}
              </div>
              <div class="mt-5">{{ 'tasks.marker.step' | translate }} {{ marker.step }}</div>
            </map-info-window>
          </ng-container>

          <ng-container *ngFor="let marker of nvgStorageData?.marcadores.pausePointsEnd; let i = index">
            <map-marker
              #pauseEndMarker="mapMarker"
              (mapClick)="showMarkerInfoWindow(pauseEndInfoWindow, pauseEndMarker)"
              [options]="{
                position: { lat: marker.location.latitude, lng: marker.location.longitude },
                visible: marker?.show,
                icon: {
                  url: 'http://maps.google.com/mapfiles/kml/paddle/go.png',
                  scaledSize: scaleSize
                },
                zIndex: 2
              }">
            </map-marker>
            <map-info-window
              #pauseEndInfoWindow="mapInfoWindow"
              [position]="{ lat: marker.location.latitude, lng: marker.location.longitude }">
              <div>
                <p>{{ 'tasks.marker.type.pausePointEnd' | translate }} {{ i + 1 }}</p>
              </div>
              <div class="mt-5">
                {{ marker.timestamp | date: 'dd/MM/yyyy - HH:mm' : nvgStorageData?.trabalho.timezone }}
              </div>
              <div class="mt-5">{{ 'tasks.marker.pauseDuration' | translate }} {{ marker.pause_duration }}</div>
              <div class="mt-5">{{ 'tasks.marker.step' | translate }} {{ marker.step }}</div>
            </map-info-window>
          </ng-container>

          <ng-container *ngIf="googleMapsApiLoaded">
            <map-marker
              *ngFor="let pin of startPin; let i = index"
              [options]="{
                position: { lat: pin?.lat, lng: pin?.lng },
                visible: showWorkMark,
                label: {
                  text: labelEtapa,
                  className: 'flag-green-start-work-result'
                },
                icon: {
                  url: './assets/images/default/flag-line-svg-green.svg',
                  scaleSize: scaleSize
                }
              }">
            </map-marker>
            <map-marker
              *ngIf="startLat && startLng"
              [options]="{
                position: { lat: pin?.lat, lng: pin?.lng },
                visible: showWorkMark,
                label: {
                  text: labelInicio,
                  className: 'flag-green-start-work-result'
                },
                icon: {
                  url: './assets/images/default/flag-line-svg-green.svg',
                  scaleSize: scaleSize
                }
              }">
            </map-marker>
            <map-marker
              *ngIf="endLat && endLng"
              [options]="{
                position: { lat: pin?.lat, lng: pin?.lng },
                visible: showWorkMark,
                label: {
                  text: labelInicio,
                  className: 'flag-green-start-work-result'
                },
                icon: {
                  url: './assets/images/default/flag-line-svg-red.svg',
                  scaleSize: scaleSize
                }
              }">
            </map-marker>
          </ng-container>
        </google-map>
      </div>
      <!-- REPLAY DESKTOP -->
      <div class="form-row">
        <div class="col-xs-5 replayButtonMapDiv" style="z-index: 10 !important">
          <div class="ibox float-e-margins">
            <button
              *ngIf="replayController?.isRunning()"
              [disabled]="false"
              class="btn btn-replay-go-to-step"
              tooltip="{{ 'tasks.replay.go-to-step' | translate }}"
              (click)="replayController.fitMapAtStep()">
              <i class="agres-refresh-ccw agres-xl"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-sidebar-container>

<!-- FOOTER -->
<app-menu-pwa>
  <div class="pwa__list--info">
    <button type="button" (click)="dashboard()">
      <i class="agres-app"></i>
      <p>{{ 'sidebar.title-dashboard' | translate }}</p>
    </button>
  </div>
  <ng-container *ngIf="!viewportBreakpoints.mobile">
    <div class="pwa__list--info">
      <button ng-sidebar-content type="button" (click)="_toggleSidebar()">
        <i class="agres-panel"></i>
        <p>{{ 'sidebar.title-open-panel' | translate }}</p>
      </button>
    </div>
    <div class="pwa__list--info">
      <button type="button" (click)="showRecommendationMapUploadModal()">
        <i class="agres-upload"></i>
        <p>{{ 'tasks.map-recommendation' | translate }}</p>
      </button>
    </div>
  </ng-container>
  <div class="pwa__list--info">
    <button type="button" (click)="downloadShapefile()">
      <i class="agres-download"></i>
      <p>{{ 'global.download' | translate }} Shapefile</p>
    </button>
  </div>
  <div class="pwa__list--info">
    <button type="button" id="icon-report-btn" (click)="_report()">
      <i class="agres-download"></i>
      <p>{{ 'global.report' | translate }}</p>
    </button>
  </div>
</app-menu-pwa>
