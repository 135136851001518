import { Component, Input, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { _roleEnumUserClass } from 'app/utils/role.utils';
import { AppConstants } from 'app/app.constants';
import { AuthorizationService } from 'app/core/services/authorization.service';
import { Subject, Subscription } from 'rxjs';
import { AnalyticsService } from 'app/core/services/analytics.service';

interface DashboardCard {
  title: string;
  iconClass: string;
  description: string;
  route: () => void;
  disabled: boolean;
}

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements OnInit {
  @Input() receivedLinkButton: string;
  public userId: string;
  public metadata;
  public dataAtual;
  public string;
  public modalSignUp = true;
  public nomeUsuarioLogado: any;
  public isFreeTier: boolean = true;
  public auth;
  public showOffCanvasSubject: Subject<boolean> = new Subject();
  public isCardDisabled;

  showOffCanvas: boolean = false;
  userRole = '';
  ROLE_USERS_ENUM = _roleEnumUserClass.ROLE_ENUM;
  cards: DashboardCard[] = [];
  cardDisabled: false;
  languageChangeSubscription: Subscription;

  constructor(
    public translateService: TranslateService,
    public toastr: ToastrService,
    public router: Router,
    public route: ActivatedRoute,
    public authorizationService: AuthorizationService,
    private readonly analyticsService: AnalyticsService,
  ) {
    this.dataAtual = new Intl.DateTimeFormat('pt-BR', {
      day: 'numeric',
      month: 'short',
      year: 'numeric',
    }).format(this.dataAtual);
    this.auth = authorizationService.gethandler();
    this.route.queryParams.subscribe((params) => {
      const msg = params['msg'];
      if (msg != null && msg != '') {
        this.toastr.warning(msg);
      }
    });
  }

  async ngOnInit() {
    this.analyticsService.sendPageView('/dashboard/');
    this.analyticsService.trackEvent(
      'accessed_the_dashboard_page',
      'Dashboard',
      '',
    );

    this.userRole = new AuthorizationService().getLoggedUserRole();
    this.mountCards();
    const usuarioLogado = JSON.parse(
      localStorage.getItem(AppConstants.KEYS_LOCAL_STORAGE.ISO_USUARIO),
    );
    this.userId = usuarioLogado.id;
    this.nomeUsuarioLogado = usuarioLogado.Nome;
    this.isFreeTier = _roleEnumUserClass.isRoleFreetier();

    const hideModal: boolean =
      window.localStorage.getItem(
        AppConstants.KEYS_LOCAL_STORAGE.HIDE_MODAL,
      ) === 'true';
    this.showOffCanvas = this.isFreeTier && !hideModal;
    this.languageChangeSubscription =
      this.translateService.onLangChange.subscribe(() => {
        this.mountCards();
      });
  }

  async mountCards() {
    //cards
    const titleNavigationReport = await this.translateService
      .get('dashboard.title-navigation-report')
      .toPromise();
    const descriptionNavigationReport = await this.translateService
      .get('dashboard.title-navigation-msg')
      .toPromise();
    const titleMonitoringFleet = await this.translateService
      .get('dashboard.title-monitoring-fleet')
      .toPromise();
    const descriptionMonitoringFleet = await this.translateService
      .get('dashboard.title-monitoring-msg')
      .toPromise();
    const titleFieldNotebook = await this.translateService
      .get('dashboard.title-field')
      .toPromise();
    const descriptionFieldNotebook = await this.translateService
      .get('dashboard.title-field-msg')
      .toPromise();
    const titleMyJobs = await this.translateService
      .get('dashboard.title-my-equipament')
      .toPromise();
    const descriptionMyJobs = await this.translateService
      .get('dashboard.title-my-equipament-msg')
      .toPromise();
    const titleWeatherForecast = await this.translateService
      .get('dashboard.title-weather')
      .toPromise();
    const descriptionWeatherForecast = await this.translateService
      .get('dashboard.title-weather-msg')
      .toPromise();
    const titleHelpCenter = await this.translateService
      .get('dashboard.title-support')
      .toPromise();
    const descriptionHelpCenter = await this.translateService
      .get('dashboard.title-support-msg')
      .toPromise();

    this.cards = [
      {
        title: titleNavigationReport,
        iconClass: 'agres-navigation-report',
        description: descriptionNavigationReport,
        route: () => this.myReportCardClickedHandler(),
        disabled: this.myReportCardClickedDisable(),
      },
      {
        title: titleMonitoringFleet,
        iconClass: 'agres-fleet-monitoring',
        description: descriptionMonitoringFleet,
        route: () => this.myFleetMonitoringCardClickedHandler(),
        disabled: this.myFleetMonitoringCardClickedDisable(),
      },
      {
        title: titleFieldNotebook,
        iconClass: 'agres-field',
        description: descriptionFieldNotebook,
        route: () => this.myFieldNotebookCardClickedHandler(),
        disabled: this.myFieldNotebookCardClickedDisable(),
      },
      {
        title: titleMyJobs,
        iconClass: 'agres-my-jobs',
        description: descriptionMyJobs,
        route: () => this.myJobsCardClickedHandler(),
        disabled: this.myJobsCardClickedDisable(),
      },
      {
        title: titleWeatherForecast,
        iconClass: 'agres-weather-sun',
        description: descriptionWeatherForecast,
        route: () => this.myWeatherForecastCardClickedHandler(),
        disabled: this.myWeatherForecastCardClickedDisable(),
      },
      {
        title: titleHelpCenter,
        iconClass: 'agres-help',
        description: descriptionHelpCenter,
        route: () => this.myHelpCenterCardClickedHandler(),
        disabled: false,
      },
    ];
    if (!this.isAdm()) {
      this.cards.splice(4, 1);
    }
  }

  navigateAndHandleClick(route: () => void) {
    route();
  }

  isAdm(): boolean {
    const usuario = JSON.parse(
      localStorage.getItem(AppConstants.KEYS_LOCAL_STORAGE.ISO_USUARIO),
    );
    return usuario.role === this.ROLE_USERS_ENUM.Admin;
  }

  navigate(paginaSelecionada) {
    this.router.navigate([paginaSelecionada]);
  }
  handleCardClick(resource: string, path: string) {
    if (this.auth.permits(this.auth.permissions.NAVIGATE, resource)) {
      this.navigate(path);
    } else {
      this.translateService
        .get('global.alert.users-premium-msg')
        .subscribe((msg: string) => {
          this.toastr.warning(msg);
        });
    }
  }

  dashboard() {
    this.router.navigate(['/']);
  }

  myJobsCardClickedHandler() {
    this.handleCardClick(
      this.auth.resources.FIELD_ADMINISTRATION,
      '../administrativo/meus-equipamentos',
    );
  }

  myReportCardClickedHandler() {
    this.handleCardClick(
      this.auth.resources.NAVIGATION_RESULT,
      '../gestao-operacao/gestao-arquivo',
    );
  }

  myFleetMonitoringCardClickedHandler() {
    this.handleCardClick(
      this.auth.resources.FIELD_ADMINISTRATION,
      '../gestao-operacao/monitoramento-frota',
    );
  }

  myWeatherForecastCardClickedHandler() {
    this.handleCardClick(
      this.auth.resources.WEATHER_FORECAST,
      '../gestao-operacao/previsao-tempo',
    );
  }

  myFieldNotebookCardClickedHandler() {
    this.handleCardClick(
      this.auth.resources.FIELD_ADMINISTRATION,
      '../my-fields',
    );
  }

  myHelpCenterCardClickedHandler() {
    this.handleCardClick(
      this.auth.resources.CUSTOMER_SUPPORT,
      '../suporte-tecnico/tutoriais',
    );
  }

  myReportCardClickedDisable() {
    return this.auth.forbids(
      this.auth.permissions.NAVIGATE,
      this.auth.resources.NAVIGATION_RESULT,
    );
  }

  myFleetMonitoringCardClickedDisable(): boolean {
    return (
      this.auth &&
      this.auth.forbids(
        this.auth.permissions.NAVIGATE,
        this.auth.resources.FIELD_ADMINISTRATION,
      )
    );
  }

  myJobsCardClickedDisable(): boolean {
    return this.auth.forbids(
      this.auth.permissions.NAVIGATE,
      this.auth.resources.FIELD_ADMINISTRATION,
    );
  }

  myWeatherForecastCardClickedDisable() {
    return this.auth.forbids(
      this.auth.permissions.NAVIGATE,
      this.auth.resources.WEATHER_FORECAST,
    );
  }

  myFieldNotebookCardClickedDisable(): boolean {
    return (
      this.auth &&
      this.auth.forbids(
        this.auth.permissions.NAVIGATE,
        this.auth.resources.FIELD_ADMINISTRATION,
      )
    );
  }
}
