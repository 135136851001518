<div class="ui-breadcrumb">
  <div class="ui-breadcrumb__container">
    <h1 class="ui-breadcrumb__container--title">Alert<span class="ui-breadcrumb__container--title---span">.</span></h1>
  </div>
</div>
<div class="bg-white">
  <div class="container mb-4 mt-5">
    <app-alert></app-alert>
    <app-button
      id="btn-selecionados"
      type="button"
      color="success"
      icon="agres-check"
      (click)="alertService.success('Success!!')"
      title="Success">
    </app-button>
    <app-button type="button" color="danger" icon="agres-close" (click)="alertService.error('Error!!')" title="Error">
    </app-button>
    <app-button
      type="button"
      color="info"
      icon="agres-help"
      (click)="alertService.info('Some info....')"
      title="Information">
    </app-button>
    <app-button
      type="button"
      color="warning"
      icon="agres-help"
      (click)="alertService.warning('Warning: ...')"
      title="Warning">
    </app-button>
    <app-button type="button" color="return" icon="agres-help" (click)="alertService.clear()" title="Clear">
    </app-button>
  </div>
</div>
<div class="ui-breadcrumb">
  <div class="ui-breadcrumb__container">
    <h1 class="ui-breadcrumb__container--title">colors<span class="ui-breadcrumb__container--title---span">.</span></h1>
  </div>
</div>
<div class="bg-white">
  <div class="container">
    <div class="palette-grid">
      <div class="palette-headers">
        <h6>Primary Colors</h6>
        <p>Main colors of the site. We're making it shine ☀️</p>
      </div>
      <div class="palette palette--main-color">
        <div class="palette__colors">
          <div class="palette__color" style="--bg-color: #5b5b5f"></div>
          <div class="palette__shades">
            <div class="palette__shades__item" style="--bg-color: #eeeeef">#EEEEEF</div>
            <div class="palette__shades__item" style="--bg-color: #bdbdbf">#BDBDBF</div>
            <div class="palette__shades__item" style="--bg-color: #7b7b7f">#7B7B7F</div>
          </div>
        </div>
        <div class="palette__info">
          <div class="palette__info__name">Primary Agres</div>
          <div class="palette__info__hexcode">#5B5B5F</div>
        </div>
      </div>
      <div class="palette palette--main-color">
        <div class="palette__colors">
          <div class="palette__color" style="--bg-color: #083d77"></div>
          <div class="palette__shades">
            <div class="palette__shades__item" style="--bg-color: #e9ebf0">#E9EBF0</div>
            <div class="palette__shades__item" style="--bg-color: #a9b3c4">#A9B3C4</div>
            <div class="palette__shades__item" style="--bg-color: #54678a">#54678A</div>
          </div>
        </div>
        <div class="palette__info">
          <div class="palette__info__name">Twilight Blue</div>
          <div class="palette__info__hexcode">#083d77</div>
        </div>
      </div>
      <div class="palette-headers">
        <h6>Secondary Colors</h6>
        <p>Remaining colors that make up the palette. Also useful for status/info alerts.</p>
      </div>
      <div class="palette palette--secondary-color">
        <div class="palette__colors">
          <div class="palette__color" style="--bg-color: #eeb600"></div>
          <div class="palette__shades">
            <div class="palette__shades__item" style="--bg-color: #fef8e5">#FEF8E5</div>
            <div class="palette__shades__item" style="--bg-color: #f8e299">#F8E299</div>
            <div class="palette__shades__item" style="--bg-color: #f1c433">#F1C433</div>
          </div>
        </div>
        <div class="palette__info">
          <div class="palette__info__name">Philippine Golden Yellow</div>
          <div class="palette__info__hexcode">#EEB600</div>
        </div>
      </div>
      <div class="palette palette--secondary-color">
        <div class="palette__colors">
          <div class="palette__color" style="--bg-color: #972426"></div>
          <div class="palette__shades">
            <div class="palette__shades__item" style="--bg-color: #f4e8e9">#F4E8E9</div>
            <div class="palette__shades__item" style="--bg-color: #d5a7a8">#D5A7A8</div>
            <div class="palette__shades__item" style="--bg-color: #ab4f51">#AB4F51</div>
          </div>
        </div>
        <div class="palette__info">
          <div class="palette__info__name">Vivid Auburn</div>
          <div class="palette__info__hexcode">#972426</div>
        </div>
      </div>
      <div class="palette palette--secondary-color">
        <div class="palette__colors">
          <div class="palette__color" style="--bg-color: #288b4a"></div>
          <div class="palette__shades">
            <div class="palette__shades__item font-white" style="--bg-color: #e9f3ec">#E9F3EC</div>
            <div class="palette__shades__item font-white" style="--bg-color: #a9d0b6">#A9D0B6</div>
            <div class="palette__shades__item font-white" style="--bg-color: #53a26e">#53A26E</div>
          </div>
        </div>
        <div class="palette__info">
          <div class="palette__info__name">Lucky Green</div>
          <div class="palette__info__hexcode">#288B4A</div>
        </div>
      </div>
      <div class="palette-headers">
        <h6>Many Shades of Gray</h6>
        <p>Let's try to keep the number of grays below 50.</p>
      </div>
      <div class="palette palette--grays">
        <div class="palette__color" style="--bg-color: #e8e9e9"></div>
        <div class="palette__info">
          <div class="palette__info__name">Platinum</div>
          <div class="palette__info__hexcode">#E8E9E9</div>
        </div>
      </div>
      <div class="palette palette--grays">
        <div class="palette__color" style="--bg-color: #d1d3d4"></div>
        <div class="palette__info">
          <div class="palette__info__name">Light Gray</div>
          <div class="palette__info__hexcode">#D1D3D4</div>
        </div>
      </div>
      <div class="palette palette--grays">
        <div class="palette__color" style="--bg-color: #babdbf"></div>
        <div class="palette__info">
          <div class="palette__info__name">Gray</div>
          <div class="palette__info__hexcode">#BABDBF</div>
        </div>
      </div>
      <div class="palette palette--grays">
        <div class="palette__color" style="--bg-color: #808488"></div>
        <div class="palette__info">
          <div class="palette__info__name">Old Silver</div>
          <div class="palette__info__hexcode">#808488</div>
        </div>
      </div>
      <div class="palette palette--grays">
        <div class="palette__color" style="--bg-color: #666a6d"></div>
        <div class="palette__info">
          <div class="palette__info__name">Dim Gray</div>
          <div class="palette__info__hexcode">#666A6D</div>
        </div>
      </div>
      <div class="palette palette--grays">
        <div class="palette__color" style="--bg-color: #4d5052"></div>
        <div class="palette__info">
          <div class="palette__info__name">Feldgrau</div>
          <div class="palette__info__hexcode">#4D5052</div>
        </div>
      </div>
      <div class="palette palette--grays">
        <div class="palette__color" style="--bg-color: #333537"></div>
        <div class="palette__info">
          <div class="palette__info__name">Jet</div>
          <div class="palette__info__hexcode">#333537</div>
        </div>
      </div>
      <div class="palette palette--grays">
        <div class="palette__color" style="--bg-color: #1c1d1e"></div>
        <div class="palette__info">
          <div class="palette__info__name">Eerie Black</div>
          <div class="palette__info__hexcode">#1C1D1E</div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="ui-breadcrumb">
  <div class="ui-breadcrumb__container">
    <h1 class="ui-breadcrumb__container--title">
      Breadcrumb<span class="ui-breadcrumb__container--title---span">.</span>
    </h1>
  </div>
</div>
<div class="bg-white">
  <div class="container">
    <div class="design-headers">
      <h6 class="mb-5">Breadcrumb Example</h6>
      <div class="page-design">
        <app-breadcrumb></app-breadcrumb>
      </div>
      <div class="page-design">
        <app-breadcrumb></app-breadcrumb>
        <div class="page-design__right">
          <app-button
            type="submit"
            color="primary"
            icon="agres-eye"
            size="full"
            title="{{ 'auth.title-button' | translate }}">
          </app-button>

          <app-button
            type="button"
            styleClass="buttons buttons__primary"
            iconClass="agres-check"
            title="Button Primary"></app-button>
          <app-button type="button" styleClass="buttons buttons__danger" title="Button Danger"></app-button>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="ui-breadcrumb">
  <div class="ui-breadcrumb__container">
    <h1 class="ui-breadcrumb__container--title">
      Sweet Alert<span class="ui-breadcrumb__container--title---span">.</span>
    </h1>
  </div>
</div>
<div class="bg-white">
  <div class="container">
    <div class="design-headers">
      <h6 class="mb-5">Sweet Alert Example</h6>
      <div class="page-design">
        <div class="btn-group">
          <app-sweet-alert
            [showAlertSubject]="initialState.sweetAlertsSubject.warning"
            [showAlert]="false"
            buttonTitle="Sweet Alert Alert"
            type="warning"
            title="Warning"
            bodyText="Lorem Ipsum is simply dummy text of the printing and typesetting industry."></app-sweet-alert>
          <app-sweet-alert
            [showAlertSubject]="initialState.sweetAlertsSubject.success"
            [showAlert]="false"
            buttonTitle="Sweet Alert 1"
            type="success"
            title="Success"
            bodyText="Lorem Ipsum is simply dummy text of the printing and typesetting industry."></app-sweet-alert>
          <app-sweet-alert
            [showAlertSubject]="initialState.sweetAlertsSubject.info"
            [showAlert]="false"
            buttonTitle="Sweet Alert 1"
            type="info"
            title="Info"
            bodyText="Lorem Ipsum is simply dummy text of the printing and typesetting industry."></app-sweet-alert>
          <app-sweet-alert
            [showAlertSubject]="initialState.sweetAlertsSubject.error"
            [showAlert]="false"
            buttonTitle="Alert Set on Timer"
            type="error"
            title="Error"
            bodyText="This alert will disappear after 3 seconds."></app-sweet-alert>
          <!-- TODO: Aqui será customizado após a alteração dos buttons do projeto, de momento deixar assim -->
          <app-link
            type="warning"
            styleClass="button__primary"
            (click)="initialState.sweetAlertsSubject.warning.next(true)"
            title="Sweet Alert Warning">
          </app-link>
          <app-link
            type="success"
            styleClass="button__success"
            (click)="initialState.sweetAlertsSubject.success.next(true)"
            title="Sweet Alert Success">
          </app-link>
          <app-link
            type="info"
            styleClass="button__edit"
            (click)="initialState.sweetAlertsSubject.info.next(true)"
            title="Sweet Alert Info">
          </app-link>
          <app-link
            type="error"
            styleClass="button__delete"
            (click)="initialState.sweetAlertsSubject.error.next(true)"
            title="Sweet Alert Error">
          </app-link>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="ui-breadcrumb">
  <div class="ui-breadcrumb__container">
    <h1 class="ui-breadcrumb__container--title">Icons<span class="ui-breadcrumb__container--title---span">.</span></h1>
  </div>
</div>
<div class="bg-white">
  <div class="container">
    <div class="design-headers">
      <h6 class="mb-5">Icons Example</h6>
      <div class="page-design">
        <div class="row">
          <div class="col-md-4">
            <div class="card">
              <div class="card__body bg-light">
                <div class="card__block card__center">
                  <app-agres-icons styleClass="text-primary" iconClass="agres-isofarm agres-6x"></app-agres-icons>
                  <h4 class="card__title text-danger">Agres</h4>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-8">
            <div class="row">
              <div class="col-md-6">
                <div class="card">
                  <div class="card__body bg-light">
                    <app-agres-icons
                      iconClass="agres-agres"
                      styleClass="text-primary"
                      title="{{ 'auth.welcome' | translate }}"></app-agres-icons>
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="card">
                  <div class="card__body bg-light">
                    <app-agres-icons
                      link="https://agres.com.br/quem_somos/"
                      styleClass="text-muted"
                      iconClass="agres-agres"
                      target="_blank"
                      title="{{ 'auth.welcome' | translate }}">
                    </app-agres-icons>
                  </div>
                </div>
              </div>
              <div class="col-md-12">
                <div class="card">
                  <div class="card__body bg-light">
                    <app-agres-icons
                      link="https://agres.com.br/quem_somos/"
                      styleClass="text-primary"
                      iconClass="agres-agres"
                      target="_blank"
                      title="{{ 'auth.welcome' | translate }}">
                    </app-agres-icons>
                  </div>
                </div>
              </div>
              <div class="col-md-4">
                <div class="card">
                  <div class="card__body bg-light">
                    <app-agres-icons
                      styleClass="text-danger"
                      iconClass="agres-agres"
                      title="{{ 'auth.welcome' | translate }}"></app-agres-icons>
                  </div>
                </div>
              </div>
              <div class="col-md-4">
                <div class="card">
                  <div class="card__body bg-light">
                    <app-agres-icons
                      link="https://agres.com.br/quem_somos/"
                      styleClass="text-warning"
                      iconClass="agres-agres"
                      target="_blank"
                      title="{{ 'auth.welcome' | translate }}">
                    </app-agres-icons>
                  </div>
                </div>
              </div>
              <div class="col-md-4">
                <div class="card">
                  <div class="card__body bg-light">
                    <app-agres-icons
                      link="https://agres.com.br/quem_somos/"
                      styleClass="text-success"
                      iconClass="agres-agres"
                      target="_blank"
                      title="{{ 'auth.welcome' | translate }}">
                    </app-agres-icons>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="ui-breadcrumb">
  <div class="ui-breadcrumb__container">
    <h1 class="ui-breadcrumb__container--title">
      Tooltips<span class="ui-breadcrumb__container--title---span">.</span>
    </h1>
  </div>
</div>
<div class="bg-white">
  <div class="container">
    <div class="design-headers">
      <h6 class="mb-5">Tooltips Example</h6>
      <p>Buttons Tooltips Example</p>
      <div class="btn-group">
        <app-button
          type="button"
          styleClass="buttons buttons__primary"
          [tooltip]="'Position Left!'"
          position="left"
          title="Position Left"></app-button>
        <app-button
          type="button"
          styleClass="buttons buttons__primary"
          [tooltip]="'Position Right!'"
          position="right"
          title="Position Right"></app-button>
        <app-button
          type="button"
          styleClass="buttons buttons__primary"
          [tooltip]="'Position Bottom!'"
          position="below"
          title="Position Bottom"></app-button>
        <app-button
          type="button"
          styleClass="buttons buttons__primary"
          [tooltip]="'Position Top!'"
          position="above"
          title="Position Top"></app-button>
      </div>
    </div>
    <div class="design-headers">
      <p>Inputs Tooltips Example</p>
      <div class="row">
        <div class="mb-3 col-md-6">
          <label for="">Position Left!</label>
          <input type="text" class="input-field" [tooltip]="'Position Left!'" position="left" />
        </div>
        <div class="mb-3 col-md-6">
          <label for="">Position Right!</label>
          <input type="text" class="input-field" [tooltip]="'Position Right!'" position="right" />
        </div>
        <div class="mb-3 col-md-6">
          <label for=""
            >Position Top & Icon! <i class="agres-agres" [tooltip]="'Position Top!'" position="above"></i
          ></label>
          <input type="text" class="input-field" [tooltip]="'Position Top!'" position="above" />
        </div>
        <div class="mb-3 col-md-6">
          <label for="">Position Bottom!</label>
          <input type="text" class="input-field" [tooltip]="'Position Bottom!'" position="below" />
        </div>
      </div>
    </div>
    <div class="design-headers">
      <p>Canvas Tooltips Example</p>
      <div class="row">
        <div class="mb-3 col-md-4">
          <label for="">Position Dynamic Dark!</label> <br />
          <canvas class="canvas" [tooltip]="coordinates" position="dynamic">Position Dynamic Dark!</canvas>
        </div>
        <div class="mb-3 col-md-4">
          <label for="">Position Dynamic White!</label> <br />
          <canvas class="canvas" [tooltip]="coordinates" position="dynamic" theme="light"
            >Position Dynamic White!</canvas
          >
        </div>
        <div class="mb-3 col-md-4">
          <label for="">Position Dynamic showDelay!</label> <br />
          <canvas class="canvas" [tooltip]="coordinates" position="dynamic" showDelay="300" hideDelay="500"
            >Position Dynamic showDelay!</canvas
          >
        </div>
      </div>
    </div>
  </div>
</div>
<div class="ui-breadcrumb">
  <div class="ui-breadcrumb__container">
    <h1 class="ui-breadcrumb__container--title">Logo<span class="ui-breadcrumb__container--title---span">.</span></h1>
  </div>
</div>
<div class="bg-white">
  <div class="container">
    <div class="design-headers">
      <h6 class="mb-6">Logo Example Tooltip</h6>
    </div>
    <div class="design-headers">
      <div class="row">
        <div class="col-md-4 mb-4">
          <app-logo
            src="../../../assets/images/default/logo-agres.svg"
            styleClass="logo-agres"
            title="Teste"
            [tooltip]="'Position Right!'"
            position="right"></app-logo>
        </div>
        <div class="col-md-4 mb-4">
          <app-logo
            src="../../../assets/images/default/logo-isofarm.svg"
            styleClass="logo-agres"
            title="Teste"
            [tooltip]="'Position Right!'"
            position="right"></app-logo>
        </div>
        <div class="col-md-4">
          <app-logo
            src="../../../assets/images/default/logo-isofarm-white.svg"
            styleClass="logo-bg"
            title="Teste"
            [tooltip]="'Position Right!'"
            position="right"></app-logo>
        </div>
        <div class="col-md-4 mb-4">
          <app-logo
            src="../../../assets/images/default/icone-isofarm.svg"
            styleClass="icons-bg"
            title="Teste"
            [tooltip]="'Position Right!'"
            position="right"></app-logo>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="ui-breadcrumb">
  <div class="ui-breadcrumb__container">
    <h1 class="ui-breadcrumb__container--title">
      Bagdes & Span<span class="ui-breadcrumb__container--title---span">.</span>
    </h1>
  </div>
</div>
<div class="bg-white">
  <div class="container">
    <div class="design-headers">
      <h6 class="mb-5">Badge & Icons Examples</h6>
      <div class="design-headers">
        <div class="row">
          <div class="col-md-6">
            <p>Badge Background & Icons Examples</p>
            <app-badge iconClass="agres-agres" styleClass="badge" title="Agres"></app-badge>
            <app-badge iconClass="agres-agres" styleClass="badge badge--info" title="Agres"></app-badge>
            <app-badge iconClass="agres-agres" styleClass="badge badge--warning" title="Agres"></app-badge>
            <app-badge iconClass="agres-agres" styleClass="badge badge--danger" title="Agres"></app-badge>
            <app-badge iconClass="agres-agres" styleClass="badge badge--success" title="Agres"></app-badge>
          </div>
          <div class="col-md-6">
            <p>Badge Small Background & Icons Examples</p>
            <div class="elements-group">
              <app-badge iconClass="agres-agres" styleClass="badge badge--small" title="Agres"></app-badge>
              <app-badge iconClass="agres-agres" styleClass="badge badge--info badge--small" title="Agres"></app-badge>
              <app-badge
                iconClass="agres-agres"
                styleClass="badge badge--warning badge--small"
                title="Agres"></app-badge>
              <app-badge
                iconClass="agres-agres"
                styleClass="badge badge--danger badge--small"
                title="Agres"></app-badge>
              <app-badge
                iconClass="agres-agres"
                styleClass="badge badge--success badge--small"
                title="Agres"></app-badge>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <p>Badge Alt & Icons Examples</p>
            <app-badge iconClass="agres-agres" styleClass="badge badge--alt-primary" title="Agres"></app-badge>
            <app-badge iconClass="agres-agres" styleClass="badge badge--alt-info" title="Agres"></app-badge>
            <app-badge iconClass="agres-agres" styleClass="badge badge--alt-warning" title="Agres"></app-badge>
            <app-badge iconClass="agres-agres" styleClass="badge badge--alt-danger" title="Agres"></app-badge>
            <app-badge iconClass="agres-agres" styleClass="badge badge--alt-success" title="Agres"></app-badge>
          </div>
          <div class="col-md-6">
            <p>Badge Alt Small Outline & Icons Examples</p>
            <app-badge
              iconClass="agres-agres"
              styleClass="badge badge--small badge--alt-primary"
              title="Agres"></app-badge>
            <app-badge
              iconClass="agres-agres"
              styleClass="badge badge--small badge--alt-info"
              title="Agres"></app-badge>
            <app-badge
              iconClass="agres-agres"
              styleClass="badge badge--small badge--alt-warning"
              title="Agres"></app-badge>
            <app-badge
              iconClass="agres-agres"
              styleClass="badge badge--small badge--alt-danger"
              title="Agres"></app-badge>
            <app-badge
              iconClass="agres-agres"
              styleClass="badge badge--small badge--alt-success"
              title="Agres"></app-badge>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <p>Badge Outline & Icons Examples</p>
            <app-badge iconClass="agres-agres" styleClass="badge badge--line-primary" title="Agres"></app-badge>
            <app-badge iconClass="agres-agres" styleClass="badge badge--line-info" title="Agres"></app-badge>
            <app-badge iconClass="agres-agres" styleClass="badge badge--line-warning" title="Agres"></app-badge>
            <app-badge iconClass="agres-agres" styleClass="badge badge--line-danger" title="Agres"></app-badge>
            <app-badge iconClass="agres-agres" styleClass="badge badge--line-success" title="Agres"></app-badge>
          </div>
          <div class="col-md-6">
            <p>Badge Small Outline & Icons Examples</p>
            <app-badge
              iconClass="agres-agres"
              styleClass="badge badge--small badge--line-primary"
              title="Agres"></app-badge>
            <app-badge
              iconClass="agres-agres"
              styleClass="badge badge--small badge--line-info"
              title="Agres"></app-badge>
            <app-badge
              iconClass="agres-agres"
              styleClass="badge badge--small badge--line-warning"
              title="Agres"></app-badge>
            <app-badge
              iconClass="agres-agres"
              styleClass="badge badge--small badge--line-danger"
              title="Agres"></app-badge>
            <app-badge
              iconClass="agres-agres"
              styleClass="badge badge--small badge--line-success"
              title="Agres"></app-badge>
          </div>
        </div>
        <h6 class="mb-5">Badge</h6>
        <div class="row">
          <div class="col-md-6">
            <p>Badge Background & Icons Examples</p>
            <app-badge styleClass="badge" title="Agres"></app-badge>
            <app-badge styleClass="badge badge--info" title="Agres"></app-badge>
            <app-badge styleClass="badge badge--warning" title="Agres"></app-badge>
            <app-badge styleClass="badge badge--danger" title="Agres"></app-badge>
            <app-badge styleClass="badge badge--success" title="Agres"></app-badge>
          </div>
          <div class="col-md-6">
            <p>Badge Small Background & Icons Examples</p>
            <div class="elements-group">
              <app-badge styleClass="badge badge--small" title="Agres"></app-badge>
              <app-badge styleClass="badge badge--info badge--small" title="Agres"></app-badge>
              <app-badge styleClass="badge badge--warning badge--small" title="Agres"></app-badge>
              <app-badge styleClass="badge badge--danger badge--small" title="Agres"></app-badge>
              <app-badge styleClass="badge badge--success badge--small" title="Agres"></app-badge>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <p>Badge Alt & Icons Examples</p>
            <app-badge styleClass="badge badge--alt-primary" title="Agres"></app-badge>
            <app-badge styleClass="badge badge--alt-info" title="Agres"></app-badge>
            <app-badge styleClass="badge badge--alt-warning" title="Agres"></app-badge>
            <app-badge styleClass="badge badge--alt-danger" title="Agres"></app-badge>
            <app-badge styleClass="badge badge--alt-success" title="Agres"></app-badge>
          </div>
          <div class="col-md-6">
            <p>Badge Alt Small Outline & Icons Examples</p>
            <app-badge styleClass="badge badge--small badge--alt-primary" title="Agres"></app-badge>
            <app-badge styleClass="badge badge--small badge--alt-info" title="Agres"></app-badge>
            <app-badge styleClass="badge badge--small badge--alt-warning" title="Agres"></app-badge>
            <app-badge styleClass="badge badge--small badge--alt-danger" title="Agres"></app-badge>
            <app-badge styleClass="badge badge--small badge--alt-success" title="Agres"></app-badge>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <p>Badge Outline & Icons Examples</p>
            <app-badge styleClass="badge badge--line-primary" title="Agres"></app-badge>
            <app-badge styleClass="badge badge--line-info" title="Agres"></app-badge>
            <app-badge styleClass="badge badge--line-warning" title="Agres"></app-badge>
            <app-badge styleClass="badge badge--line-danger" title="Agres"></app-badge>
            <app-badge styleClass="badge badge--line-success" title="Agres"></app-badge>
          </div>
          <div class="col-md-6">
            <p>Badge Small Outline & Icons Examples</p>
            <app-badge styleClass="badge badge--small badge--line-primary" title="Agres"></app-badge>
            <app-badge styleClass="badge badge--small badge--line-info" title="Agres"></app-badge>
            <app-badge styleClass="badge badge--small badge--line-warning" title="Agres"></app-badge>
            <app-badge styleClass="badge badge--small badge--line-danger" title="Agres"></app-badge>
            <app-badge styleClass="badge badge--small badge--line-success" title="Agres"></app-badge>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="ui-breadcrumb">
  <div class="ui-breadcrumb__container">
    <h1 class="ui-breadcrumb__container--title">
      Informative Banner<span class="ui-breadcrumb__container--title---span">.</span>
    </h1>
  </div>
</div>
<div class="bg-white">
  <div class="container">
    <div class="design-headers">
      <h6 class="mb-6">Informative Banner Example</h6>
    </div>
    <div class="design-headers">
      <div class="row">
        <p>Informative Banner Example</p>
        <div class="col-md-5">
          <app-informative
            styleClass="informative danger-alert"
            title="Your Account is not secured. Verify your phone and email addres, so that we can secure you.">
          </app-informative>
        </div>
        <div class="col-md-6">
          <app-informative
            styleClass="informative info-alert"
            title=" Here are the groups we suggest goining based on your profile">
          </app-informative>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="ui-breadcrumb">
  <div class="ui-breadcrumb__container">
    <h1 class="ui-breadcrumb__container--title">Toastr<span class="ui-breadcrumb__container--title---span">.</span></h1>
  </div>
</div>
<div class="bg-white">
  <div class="container">
    <div class="design-headers">
      <h6 class="mb-6">Toastr Example</h6>
    </div>
    <div class="design-headers">
      <div class="row">
        <div class="col-md-5">
          <p>Standard</p>
          <app-toastr styleClass="toastr primary-alert text-center" title="General (Full screen width)"> </app-toastr>

          <app-toastr styleClass="toastr success-alert text-center" title="Sucess (Full screen width)"> </app-toastr>

          <app-toastr styleClass="toastr danger-alert text-center" title="Error (Full screen width)"> </app-toastr>

          <app-toastr styleClass="toastr warning-alert text-center" title="Error (Full screen width)"> </app-toastr>

          <app-toastr styleClass="toastr info-alert text-center" title="Info (Full screen width)"> </app-toastr>
        </div>
        <div class="col-md-5">
          <p>Dismissible</p>
          <app-toastr styleClass="toastr primary-alert" title="General (Full screen width)" iconClass="agres-close">
          </app-toastr>
          <app-toastr styleClass="toastr success-alert" title="Sucess (Full screen width)" iconClass="agres-close">
          </app-toastr>
          <app-toastr styleClass="toastr danger-alert" title="Error (Full screen width)" iconClass="agres-close">
          </app-toastr>
          <app-toastr styleClass="toastr warning-alert" title="Error (Full screen width)" iconClass="agres-close">
          </app-toastr>
          <app-toastr styleClass="toastr info-alert" title="Info (Full screen width)" iconClass="agres-close">
          </app-toastr>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="ui-breadcrumb">
  <div class="ui-breadcrumb__container">
    <h1 class="ui-breadcrumb__container--title">
      Buttons<span class="ui-breadcrumb__container--title---span">.</span>
    </h1>
  </div>
</div>
<div class="bg-white">
  <div class="container">
    <div class="design-headers">
      <h6 class="mb-5">Buttons</h6>
      <div class="row">
        <div class="col-md-12">
          <app-button
            type="button"
            styleClass="buttons buttons__primary"
            title="{{ 'fields.tutorial-field-edit' | translate }}"></app-button>
          <app-button
            type="button"
            styleClass="buttons buttons__secondary"
            title="{{ 'fields.tutorial-field-edit' | translate }}"></app-button>
          <app-button
            type="button"
            styleClass="buttons buttons__success"
            title="{{ 'fields.tutorial-field-edit' | translate }}"></app-button>
          <app-button
            type="button"
            styleClass="buttons buttons__danger"
            title="{{ 'fields.tutorial-field-edit' | translate }}"></app-button>
          <app-button
            type="button"
            styleClass="buttons buttons__warning"
            title="{{ 'fields.tutorial-field-edit' | translate }}"></app-button>
          <app-button
            type="button"
            styleClass="buttons buttons__alert"
            title="{{ 'fields.tutorial-field-edit' | translate }}"></app-button>
          <app-button
            type="button"
            styleClass="buttons buttons__dark"
            title="{{ 'fields.tutorial-field-edit' | translate }}"></app-button>
          <app-button
            type="button"
            styleClass="buttons buttons__info"
            title="{{ 'fields.tutorial-field-edit' | translate }}"></app-button>
        </div>
      </div>
      <h6 class="mb-5">Buttons Small</h6>
      <div class="row">
        <div class="col-md-12">
          <app-button
            type="button"
            styleClass="buttons buttons__primary buttons__primary--small"
            title="{{ 'fields.tutorial-field-edit' | translate }}"></app-button>
          <app-button
            type="button"
            styleClass="buttons buttons__secondary buttons__secondary--small"
            title="{{ 'fields.tutorial-field-edit' | translate }}"></app-button>
          <app-button
            type="button"
            styleClass="buttons buttons__success buttons__success--small"
            title="{{ 'fields.tutorial-field-edit' | translate }}"></app-button>
          <app-button
            type="button"
            styleClass="buttons buttons__danger buttons__danger--small"
            title="{{ 'fields.tutorial-field-edit' | translate }}"></app-button>
          <app-button
            type="button"
            styleClass="buttons buttons__warning buttons__warning--small"
            title="{{ 'fields.tutorial-field-edit' | translate }}"></app-button>
          <app-button
            type="button"
            styleClass="buttons buttons__alert buttons__alert--small"
            title="{{ 'fields.tutorial-field-edit' | translate }}"></app-button>
          <app-button
            type="button"
            styleClass="buttons buttons__dark buttons__dark--small"
            title="{{ 'fields.tutorial-field-edit' | translate }}"></app-button>
          <app-button
            type="button"
            styleClass="buttons buttons__info buttons__info--small"
            title="{{ 'fields.tutorial-field-edit' | translate }}"></app-button>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="bg-white">
  <div class="container">
    <div class="design-headers">
      <h6 class="mb-5">Buttons & Icons Examples</h6>
      <div class="row">
        <div class="col-md-12">
          <app-button
            type="button"
            styleClass="buttons buttons__primary"
            iconClass="agres-check"
            title="{{ 'fields.tutorial-field-edit' | translate }}"></app-button>
          <app-button
            type="button"
            styleClass="buttons buttons__secondary"
            iconClass="agres-check"
            title="{{ 'fields.tutorial-field-edit' | translate }}"></app-button>
          <app-button
            type="button"
            styleClass="buttons buttons__success"
            iconClass="agres-check"
            title="{{ 'fields.tutorial-field-edit' | translate }}"></app-button>
          <app-button
            type="button"
            styleClass="buttons buttons__danger"
            iconClass="agres-check"
            title="{{ 'fields.tutorial-field-edit' | translate }}"></app-button>
          <app-button
            type="button"
            styleClass="buttons buttons__warning"
            iconClass="agres-check"
            title="{{ 'fields.tutorial-field-edit' | translate }}"></app-button>
          <app-button
            type="button"
            styleClass="buttons buttons__alert"
            iconClass="agres-check"
            title="{{ 'fields.tutorial-field-edit' | translate }}"></app-button>
          <app-button
            type="button"
            styleClass="buttons buttons__dark"
            iconClass="agres-check"
            title="{{ 'fields.tutorial-field-edit' | translate }}"></app-button>
          <app-button
            type="button"
            styleClass="buttons buttons__info"
            iconClass="agres-check"
            title="{{ 'fields.tutorial-field-edit' | translate }}"></app-button>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="bg-white">
  <div class="container">
    <div class="design-headers">
      <h6 class="mb-5">Buttons Small & Icons Examples</h6>
      <div class="row">
        <div class="col-md-12">
          <app-button
            type="button"
            styleClass="buttons buttons__primary buttons__primary--small"
            iconClass="agres-check"
            title="{{ 'fields.tutorial-field-edit' | translate }}"></app-button>
          <app-button
            type="button"
            styleClass="buttons buttons__secondary buttons__secondary--small"
            iconClass="agres-check"
            title="{{ 'fields.tutorial-field-edit' | translate }}"></app-button>
          <app-button
            type="button"
            styleClass="buttons buttons__success buttons__success--small"
            iconClass="agres-check"
            title="{{ 'fields.tutorial-field-edit' | translate }}"></app-button>
          <app-button
            type="button"
            styleClass="buttons buttons__danger buttons__danger--small"
            iconClass="agres-check"
            title="{{ 'fields.tutorial-field-edit' | translate }}"></app-button>
          <app-button
            type="button"
            styleClass="buttons buttons__warning buttons__warning--small"
            iconClass="agres-check"
            title="{{ 'fields.tutorial-field-edit' | translate }}"></app-button>
          <app-button
            type="button"
            styleClass="buttons buttons__alert buttons__alert--small"
            iconClass="agres-check"
            title="{{ 'fields.tutorial-field-edit' | translate }}"></app-button>
          <app-button
            type="button"
            styleClass="buttons buttons__dark buttons__dark--small"
            iconClass="agres-check"
            title="{{ 'fields.tutorial-field-edit' | translate }}"></app-button>
          <app-button
            type="button"
            styleClass="buttons buttons__info buttons__info--small"
            iconClass="agres-check"
            title="{{ 'fields.tutorial-field-edit' | translate }}"></app-button>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="bg-white">
  <div class="container">
    <div class="design-headers">
      <h6 class="mb-5">Buttons Alt</h6>
      <div class="row">
        <div class="col-md-12">
          <app-button
            type="button"
            styleClass="buttons buttons__alt-primary"
            title="{{ 'fields.tutorial-field-edit' | translate }}"></app-button>
          <app-button
            type="button"
            styleClass="buttons buttons__alt-secondary"
            title="{{ 'fields.tutorial-field-edit' | translate }}"></app-button>
          <app-button
            type="button"
            styleClass="buttons buttons__alt-success"
            title="{{ 'fields.tutorial-field-edit' | translate }}"></app-button>
          <app-button
            type="button"
            styleClass="buttons buttons__alt-danger"
            title="{{ 'fields.tutorial-field-edit' | translate }}"></app-button>
          <app-button
            type="button"
            styleClass="buttons buttons__alt-warning"
            title="{{ 'fields.tutorial-field-edit' | translate }}"></app-button>
          <app-button
            type="button"
            styleClass="buttons buttons__alt-alert"
            title="{{ 'fields.tutorial-field-edit' | translate }}"></app-button>
          <app-button
            type="button"
            styleClass="buttons buttons__alt-dark"
            title="{{ 'fields.tutorial-field-edit' | translate }}"></app-button>
          <app-button
            type="button"
            styleClass="buttons buttons__alt-info"
            title="{{ 'fields.tutorial-field-edit' | translate }}"></app-button>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="bg-white">
  <div class="container">
    <div class="design-headers">
      <h6 class="mb-5">Buttons Alt Small & Icons Examples</h6>
      <div class="row">
        <div class="col-md-12">
          <app-button
            type="button"
            styleClass="buttons buttons__alt-primary buttons__alt-primary--small"
            iconClass="agres-check"
            title="{{ 'fields.tutorial-field-edit' | translate }}"></app-button>
          <app-button
            type="button"
            styleClass="buttons buttons__alt-secondary buttons__alt-secondary--small"
            iconClass="agres-check"
            title="{{ 'fields.tutorial-field-edit' | translate }}"></app-button>
          <app-button
            type="button"
            styleClass="buttons buttons__alt-success buttons__alt-success--small"
            iconClass="agres-check"
            title="{{ 'fields.tutorial-field-edit' | translate }}"></app-button>
          <app-button
            type="button"
            styleClass="buttons buttons__alt-danger buttons__alt-danger--small"
            iconClass="agres-check"
            title="{{ 'fields.tutorial-field-edit' | translate }}"></app-button>
          <app-button
            type="button"
            styleClass="buttons buttons__alt-warning buttons__alt-warning--small"
            iconClass="agres-check"
            title="{{ 'fields.tutorial-field-edit' | translate }}"></app-button>
          <app-button
            type="button"
            styleClass="buttons buttons__alt-alert buttons__alt-alert--small"
            iconClass="agres-check"
            title="{{ 'fields.tutorial-field-edit' | translate }}"></app-button>
          <app-button
            type="button"
            styleClass="buttons buttons__alt-dark buttons__alt-dark--small"
            iconClass="agres-check"
            title="{{ 'fields.tutorial-field-edit' | translate }}"></app-button>
          <app-button
            type="button"
            styleClass="buttons buttons__alt-info buttons__alt-info--small"
            iconClass="agres-check"
            title="{{ 'fields.tutorial-field-edit' | translate }}"></app-button>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="bg-white">
  <div class="container">
    <div class="design-headers">
      <h6 class="mb-5">Buttons Outline</h6>
      <div class="row">
        <div class="col-md-12">
          <app-button
            type="button"
            styleClass="buttons buttons__outline-primary"
            title="{{ 'fields.tutorial-field-edit' | translate }}"></app-button>
          <app-button
            type="button"
            styleClass="buttons buttons__outline-secondary"
            title="{{ 'fields.tutorial-field-edit' | translate }}"></app-button>
          <app-button
            type="button"
            styleClass="buttons buttons__outline-success"
            title="{{ 'fields.tutorial-field-edit' | translate }}"></app-button>
          <app-button
            type="button"
            styleClass="buttons buttons__outline-danger"
            title="{{ 'fields.tutorial-field-edit' | translate }}"></app-button>
          <app-button
            type="button"
            styleClass="buttons buttons__outline-warning"
            title="{{ 'fields.tutorial-field-edit' | translate }}"></app-button>
          <app-button
            type="button"
            styleClass="buttons buttons__outline-alert"
            title="{{ 'fields.tutorial-field-edit' | translate }}"></app-button>
          <app-button
            type="button"
            styleClass="buttons buttons__outline-dark"
            title="{{ 'fields.tutorial-field-edit' | translate }}"></app-button>
          <app-button
            type="button"
            styleClass="buttons buttons__outline-info"
            title="{{ 'fields.tutorial-field-edit' | translate }}"></app-button>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="bg-white">
  <div class="container">
    <div class="design-headers">
      <h6 class="mb-5">Buttons Outline Small & Icons Examples</h6>
      <div class="row">
        <div class="col-md-12">
          <app-button
            type="button"
            styleClass="buttons buttons__outline-primary buttons__outline-primary--small"
            iconClass="agres-check"
            title="{{ 'fields.tutorial-field-edit' | translate }}"></app-button>
          <app-button
            type="button"
            styleClass="buttons buttons__outline-secondary buttons__outline-secondary--small"
            iconClass="agres-check"
            title="{{ 'fields.tutorial-field-edit' | translate }}"></app-button>
          <app-button
            type="button"
            styleClass="buttons buttons__outline-success buttons__outline-success--small"
            iconClass="agres-check"
            title="{{ 'fields.tutorial-field-edit' | translate }}"></app-button>
          <app-button
            type="button"
            styleClass="buttons buttons__outline-danger buttons__outline-danger--small"
            iconClass="agres-check"
            title="{{ 'fields.tutorial-field-edit' | translate }}"></app-button>
          <app-button
            type="button"
            styleClass="buttons buttons__outline-warning buttons__outline-warning--small"
            iconClass="agres-check"
            title="{{ 'fields.tutorial-field-edit' | translate }}"></app-button>
          <app-button
            type="button"
            styleClass="buttons buttons__outline-alert buttons__outline-alert--small"
            iconClass="agres-check"
            title="{{ 'fields.tutorial-field-edit' | translate }}"></app-button>
          <app-button
            type="button"
            styleClass="buttons buttons__outline-dark buttons__outline-dark--small"
            iconClass="agres-check"
            title="{{ 'fields.tutorial-field-edit' | translate }}"></app-button>
          <app-button
            type="button"
            styleClass="buttons buttons__outline-info buttons__outline-info--small"
            iconClass="agres-check"
            title="{{ 'fields.tutorial-field-edit' | translate }}"></app-button>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="bg-white">
  <div class="container">
    <div class="design-headers">
      <h6 class="mb-5">Buttons Disabled</h6>
      <div class="row">
        <div class="col-md-12">
          <app-button
            disabled="disabled"
            type="button"
            styleClass="buttons buttons__primary"
            title="{{ 'fields.tutorial-field-edit' | translate }}"></app-button>
          <app-button
            disabled="disabled"
            type="button"
            styleClass="buttons buttons__secondary"
            title="{{ 'fields.tutorial-field-edit' | translate }}"></app-button>
          <app-button
            disabled="disabled"
            type="button"
            styleClass="buttons buttons__success"
            title="{{ 'fields.tutorial-field-edit' | translate }}"></app-button>
          <app-button
            disabled="disabled"
            type="button"
            styleClass="buttons buttons__danger"
            title="{{ 'fields.tutorial-field-edit' | translate }}"></app-button>
          <app-button
            disabled="disabled"
            type="button"
            styleClass="buttons buttons__warning"
            title="{{ 'fields.tutorial-field-edit' | translate }}"></app-button>
          <app-button
            disabled="disabled"
            type="button"
            styleClass="buttons buttons__alert"
            title="{{ 'fields.tutorial-field-edit' | translate }}"></app-button>
          <app-button
            disabled="disabled"
            type="button"
            styleClass="buttons buttons__dark"
            title="{{ 'fields.tutorial-field-edit' | translate }}"></app-button>
          <app-button
            disabled="disabled"
            type="button"
            styleClass="buttons buttons__info"
            title="{{ 'fields.tutorial-field-edit' | translate }}"></app-button>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="ui-breadcrumb">
  <div class="ui-breadcrumb__container">
    <h1 class="ui-breadcrumb__container--title">
      Off Canvas<span class="ui-breadcrumb__container--title---span">.</span>
    </h1>
  </div>
</div>
<div class="bg-white">
  <div class="container">
    <div class="design-headers">
      <h6 class="mb-5">Off Canvas</h6>
      <div class="row">
        <div class="col-md-12">
          <app-offcanvas
          [showSubject]="initialState.offCanvas.medium"
          title="Title OffCanvas Medium"></app-offcanvas>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="ui-breadcrumb">
  <div class="ui-breadcrumb__container">
    <h1 class="ui-breadcrumb__container--title">Tabs<span class="ui-breadcrumb__container--title---span">.</span></h1>
  </div>
</div>
<div class="bg-white">
  <div class="container">
    <div class="design-headers">
      <h6 class="mb-5">Tabs</h6>
      <div class="row">
        <div class="col-md-12">
          <app-tabs>
            <app-tab #tab1 tabTitle="{{ 'sidebar.title-dashboard' | translate }}">
              <ng-container *ngIf="tab1.active">
                {{ 'sidebar.title-dashboard' | translate }}
              </ng-container>
            </app-tab>
            <app-tab #tab2 tabTitle="{{ 'sidebar.title-management-map' | translate }}">
              <ng-container *ngIf="tab2.active">
                <p>Aqui é um exemplo de formulário</p>
                <form>
                  <div class="form-group">
                    <label>Email address</label>
                    <input type="email" class="input-field" id="exampleInputEmail1" aria-describedby="emailHelp" />
                    <small id="emailHelp" class="form-text text-muted"
                      >We'll never share your email with anyone else.</small
                    >
                  </div>
                  <div class="form-group">
                    <label for="exampleInputPassword1">Password</label>
                    <input type="password" class="input-field" id="exampleInputPassword1" />
                  </div>
                </form>
              </ng-container>
            </app-tab>
            <app-tab #tab3 tabTitle="{{ 'sidebar.title-operation-management' | translate }}">
              <ng-container *ngIf="tab3.active">
                {{ 'sidebar.title-operation-management' | translate }}
              </ng-container>
            </app-tab>
            <app-tab #tab4 tabTitle="{{ 'sidebar.title-design-system' | translate }}">
              <ng-container *ngIf="tab4">
                {{ 'sidebar.title-design-system' | translate }}
              </ng-container>
            </app-tab>
          </app-tabs>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="ui-breadcrumb">
  <div class="ui-breadcrumb__container">
    <h1 class="ui-breadcrumb__container--title">
      Select Menu<span class="ui-breadcrumb__container--title---span">.</span>
    </h1>
  </div>
</div>
<div class="bg-white">
  <div class="container">
    <div class="design-headers">
      <h6 class="mb-5">Select Menu</h6>
      <div class="row">
        <div class="col-md-12">
          <app-select-menu></app-select-menu>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="ui-breadcrumb">
  <div class="ui-breadcrumb__container">
    <h1 class="ui-breadcrumb__container--title">Shadow<span class="ui-breadcrumb__container--title---span">.</span></h1>
  </div>
</div>
<div class="bg-white">
  <div class="container">
    <div class="design-headers">
      <h6 class="mb-5">Shadow</h6>
      <div class="row">
        <div class="col-md-2">
          <app-shadow styleClass="shadow1">
            <div class="box"></div>
          </app-shadow>
        </div>
        <div class="col-md-2">
          <app-shadow styleClass="shadow2">
            <div class="box"></div>
          </app-shadow>
        </div>
        <div class="col-md-2">
          <app-shadow styleClass="shadow3">
            <div class="box"></div>
          </app-shadow>
        </div>
        <div class="col-md-2">
          <app-shadow styleClass="shadow4">
            <div class="box"></div>
          </app-shadow>
        </div>
        <div class="col-md-2">
          <app-shadow styleClass="shadow5">
            <div class="box"></div>
          </app-shadow>
        </div>
        <div class="col-md-2">
          <app-shadow styleClass="shadow6">
            <div class="box"></div>
          </app-shadow>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="ui-breadcrumb">
  <div class="ui-breadcrumb__container">
    <h1 class="ui-breadcrumb__container--title">
      Text Area<span class="ui-breadcrumb__container--title---span">.</span>
    </h1>
  </div>
</div>
<div class="bg-white">
  <div class="container">
    <div class="design-headers">
      <h6 class="mb-5">Text Area</h6>
      <div class="row">
        <div class="col-md-4">
          <app-text-area styleClass="text-area text-area--primary"></app-text-area>
        </div>
        <div class="col-md-4">
          <app-text-area styleClass="text-area text-area--danger"></app-text-area>
          <p>Required</p>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="ui-breadcrumb">
  <div class="ui-breadcrumb__container">
    <h1 class="ui-breadcrumb__container--title">Cards<span class="ui-breadcrumb__container--title---span">.</span></h1>
  </div>
</div>
<div class="bg-white">
  <div class="container">
    <div class="design-headers">
      <h6 class="mb-5">Cards</h6>
      <div class="row">
        <div class="col-md-5">
          <p>Large Card</p>
          <app-card styleClass="card-large"></app-card>
        </div>
        <div class="col-md-3">
          <p>Medium Card</p>
          <app-card styleClass="card-medium"></app-card>
        </div>
        <div class="col-md-1">
          <p>Small Card</p>
          <app-card styleClass="card-small"></app-card>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="ui-breadcrumb">
  <div class="ui-breadcrumb__container">
    <h1 class="ui-breadcrumb__container--title">
      Novos Icones<span class="ui-breadcrumb__container--title---span">.</span>
    </h1>
  </div>
</div>
<div>
  <div class="container">
    <div class="design-headers">
      <div class="row">
        <h6 class="mb-5">Agres & Isofarm</h6>
        <div class="col-md-2">
          <div class="card">
            <div class="card__body text-center">
              <app-agres-icons iconsClass="agres-eye agres-2x"></app-agres-icons>
              <p>agres-agres</p>
            </div>
          </div>
        </div>
        <div class="col-md-2">
          <div class="card">
            <div class="card__body text-center">
              <app-agres-icons iconsClass="agres-eye-off agres-2x"></app-agres-icons>
              <p>agres-agres</p>
            </div>
          </div>
        </div>
        <hr />
        <h6 class="mb-5">Users</h6>
        <br />
        <div class="col-md-2">
          <div class="card">
            <div class="card__body text-center">
              <app-agres-icons iconsClass="agres-user agres-2x"></app-agres-icons>
              <p>agres-user</p>
            </div>
          </div>
        </div>
        <div class="col-md-2">
          <div class="card">
            <div class="card__body text-center">
              <app-agres-icons iconsClass="agres-users agres-2x"></app-agres-icons>
              <p>agres-users</p>
            </div>
          </div>
        </div>
        <div class="col-md-2">
          <div class="card">
            <div class="card__body text-center">
              <app-agres-icons iconsClass="agres-user-minus agres-2x"></app-agres-icons>
              <p>agres-users-minus</p>
            </div>
          </div>
        </div>
        <div class="col-md-2">
          <div class="card">
            <div class="card__body text-center">
              <app-agres-icons iconsClass="agres-user-plus agres-2x"></app-agres-icons>
              <p>agres-user-plus</p>
            </div>
          </div>
        </div>
        <div class="col-md-2">
          <div class="card">
            <div class="card__body text-center">
              <app-agres-icons iconsClass="agres-user-x agres-2x"></app-agres-icons>
              <p>agres-user-x</p>
            </div>
          </div>
        </div>
        <div class="col-md-2">
          <div class="card">
            <div class="card__body text-center">
              <app-agres-icons iconsClass="agres-user-check agres-2x"></app-agres-icons>
              <p>agres-user-check</p>
            </div>
          </div>
        </div>
        <hr />
        <h6 class="mb-5">Check</h6>
        <br />
        <div class="col-md-2">
          <div class="card">
            <div class="card__body text-center">
              <app-agres-icons iconsClass="agres-check agres-2x"></app-agres-icons>
              <p>agres-check</p>
            </div>
          </div>
        </div>
        <div class="col-md-2">
          <div class="card">
            <div class="card__body text-center">
              <app-agres-icons iconsClass="agres-check-square agres-2x"></app-agres-icons>
              <p>agres-check-square</p>
            </div>
          </div>
        </div>
        <div class="col-md-2">
          <div class="card">
            <div class="card__body text-center">
              <app-agres-icons iconsClass="agres-check-circle agres-2x"></app-agres-icons>
              <p>agres-check-circle</p>
            </div>
          </div>
        </div>
        <hr />
        <h6 class="mb-5">Weather</h6>
        <br />
        <div class="col-md-2">
          <div class="card">
            <div class="card__body text-center">
              <app-agres-icons iconsClass="agres-weather-cloud agres-2x"></app-agres-icons>
              <p>agres-weather-cloud</p>
            </div>
          </div>
        </div>
        <div class="col-md-2">
          <div class="card">
            <div class="card__body text-center">
              <app-agres-icons iconsClass="agres-weather-snow agres-2x"></app-agres-icons>
              <p>agres-weather-snow</p>
            </div>
          </div>
        </div>
        <div class="col-md-2">
          <div class="card">
            <div class="card__body text-center">
              <app-agres-icons iconsClass="agres-weather-rain agres-2x"></app-agres-icons>
              <p>agres-weather-rain</p>
            </div>
          </div>
        </div>
        <div class="col-md-2">
          <div class="card">
            <div class="card__body text-center">
              <app-agres-icons iconsClass="agres-weather-off agres-2x"></app-agres-icons>
              <p>agres-weather-off</p>
            </div>
          </div>
        </div>
        <div class="col-md-2">
          <div class="card">
            <div class="card__body text-center">
              <app-agres-icons iconsClass="agres-weather-lightning agres-2x"></app-agres-icons>
              <p>agres-weather-lightning</p>
            </div>
          </div>
        </div>
        <div class="col-md-2">
          <div class="card">
            <div class="card__body text-center">
              <app-agres-icons iconsClass="agres-weather-drizzle agres-2x"></app-agres-icons>
              <p>agres-weather-drizzle</p>
            </div>
          </div>
        </div>
        <div class="col-md-2">
          <div class="card">
            <div class="card__body text-center">
              <app-agres-icons iconsClass="agres-weather-sunset agres-2x"></app-agres-icons>
              <p>agres-weather-sunset</p>
            </div>
          </div>
        </div>
        <div class="col-md-2">
          <div class="card">
            <div class="card__body text-center">
              <app-agres-icons iconsClass="agres-weather-sunrise agres-2x"></app-agres-icons>
              <p>agres-weather-sunrise</p>
            </div>
          </div>
        </div>
        <div class="col-md-2">
          <div class="card">
            <div class="card__body text-center">
              <app-agres-icons iconsClass="agres-weather-sun agres-2x"></app-agres-icons>
              <p>agres-weather-sun</p>
            </div>
          </div>
        </div>
        <div class="col-md-2">
          <div class="card">
            <div class="card__body text-center">
              <app-agres-icons iconsClass="agres-weather-umbrella agres-2x"></app-agres-icons>
              <p>agres-weather-umbrella</p>
            </div>
          </div>
        </div>
        <div class="col-md-2">
          <div class="card">
            <div class="card__body text-center">
              <app-agres-icons iconsClass="agres-weather-wind agres-2x"></app-agres-icons>
              <p>agres-weather-wind</p>
            </div>
          </div>
        </div>
        <div class="col-md-2">
          <div class="card">
            <div class="card__body text-center">
              <app-agres-icons iconsClass="agres-weather-thermometer agres-2x"></app-agres-icons>
              <p>agres-weather-thermometer</p>
            </div>
          </div>
        </div>
        <hr />
        <h6 class="mb-5">Eye</h6>
        <br />
        <div class="col-md-2">
          <div class="card">
            <div class="card__body text-center">
              <app-agres-icons iconsClass="agres-eye agres-2x"></app-agres-icons>
              <p>agres-eye</p>
            </div>
          </div>
        </div>
        <div class="col-md-2">
          <div class="card">
            <div class="card__body text-center">
              <app-agres-icons iconsClass="agres-eye-off agres-2x"></app-agres-icons>
              <p>agres-eye-off</p>
            </div>
          </div>
        </div>
        <div class="col-md-2">
          <div class="card">
            <div class="card__body text-center">
              <app-agres-icons iconsClass="agres-target agres-2x"></app-agres-icons>
              <p>agres-target</p>
            </div>
          </div>
        </div>
        <hr />
        <h6 class="mb-5">Others</h6>
        <br />
        <div class="col-md-2">
          <div class="card">
            <div class="card__body text-center">
              <app-agres-icons iconsClass="agres-fullscreen agres-2x"></app-agres-icons>
              <p>agres-fullscreen</p>
            </div>
          </div>
        </div>
        <div class="col-md-2">
          <div class="card">
            <div class="card__body text-center">
              <app-agres-icons iconsClass="agres-close agres-2x"></app-agres-icons>
              <p>agres-close</p>
            </div>
          </div>
        </div>
        <div class="col-md-2">
          <div class="card">
            <div class="card__body text-center">
              <app-agres-icons iconsClass="agres-close-square agres-2x"></app-agres-icons>
              <p>agres-close-square</p>
            </div>
          </div>
        </div>
        <div class="col-md-2">
          <div class="card">
            <div class="card__body text-center">
              <app-agres-icons iconsClass="agres-close-octagon agres-2x"></app-agres-icons>
              <p>agres-close-octagon</p>
            </div>
          </div>
        </div>
        <div class="col-md-2">
          <div class="card">
            <div class="card__body text-center">
              <app-agres-icons iconsClass="agres-close-circle agres-2x"></app-agres-icons>
              <p>agres-close-circle</p>
            </div>
          </div>
        </div>
        <div class="col-md-2">
          <div class="card">
            <div class="card__body text-center">
              <app-agres-icons iconsClass="agres-copy agres-2x"></app-agres-icons>
              <p>agres-copy</p>
            </div>
          </div>
        </div>
        <div class="col-md-2">
          <div class="card">
            <div class="card__body text-center">
              <app-agres-icons iconsClass="agres-zoom-in agres-2x"></app-agres-icons>
              <p>agres-zoom-in</p>
            </div>
          </div>
        </div>
        <div class="col-md-2">
          <div class="card">
            <div class="card__body text-center">
              <app-agres-icons iconsClass="agres-zoom-out agres-2x"></app-agres-icons>
              <p>agres-zoom-out</p>
            </div>
          </div>
        </div>
        <div class="col-md-2">
          <div class="card">
            <div class="card__body text-center">
              <app-agres-icons iconsClass="agres-upload agres-2x"></app-agres-icons>
              <p>agres-upload</p>
            </div>
          </div>
        </div>
        <div class="col-md-2">
          <div class="card">
            <div class="card__body text-center">
              <app-agres-icons iconsClass="agres-upload-cloud agres-2x"></app-agres-icons>
              <p>agres-upload-cloud</p>
            </div>
          </div>
        </div>
        <div class="col-md-2">
          <div class="card">
            <div class="card__body text-center">
              <app-agres-icons iconsClass="agres-download agres-2x"></app-agres-icons>
              <p>agres-download</p>
            </div>
          </div>
        </div>
        <div class="col-md-2">
          <div class="card">
            <div class="card__body text-center">
              <app-agres-icons iconsClass="agres-download-cloud agres-2x"></app-agres-icons>
              <p>agres-download-cloud</p>
            </div>
          </div>
        </div>
        <div class="col-md-2">
          <div class="card">
            <div class="card__body text-center">
              <app-agres-icons iconsClass="agres-calendar agres-2x"></app-agres-icons>
              <p>agres-calendar</p>
            </div>
          </div>
        </div>
        <div class="col-md-2">
          <div class="card">
            <div class="card__body text-center">
              <app-agres-icons iconsClass="agres-alert-triangle agres-2x"></app-agres-icons>
              <p>agres-alert-triangle</p>
            </div>
          </div>
        </div>
        <div class="col-md-2">
          <div class="card">
            <div class="card__body text-center">
              <app-agres-icons iconsClass="agres-octagon agres-2x"></app-agres-icons>
              <p>agres-octagon</p>
            </div>
          </div>
        </div>
        <div class="col-md-2">
          <div class="card">
            <div class="card__body text-center">
              <app-agres-icons iconsClass="agres-circle agres-2x"></app-agres-icons>
              <p>agres-circle</p>
            </div>
          </div>
        </div>
        <div class="col-md-2">
          <div class="card">
            <div class="card__body text-center">
              <app-agres-icons iconsClass="agres-filter agres-2x"></app-agres-icons>
              <p>agres-filter</p>
            </div>
          </div>
        </div>
        <div class="col-md-2">
          <div class="card">
            <div class="card__body text-center">
              <app-agres-icons iconsClass="agres-mail agres-2x"></app-agres-icons>
              <p>agres-mail</p>
            </div>
          </div>
        </div>
        <div class="col-md-2">
          <div class="card">
            <div class="card__body text-center">
              <app-agres-icons iconsClass="agres-password agres-2x"></app-agres-icons>
              <p>agres-password</p>
            </div>
          </div>
        </div>
        <div class="col-md-2">
          <div class="card">
            <div class="card__body text-center">
              <app-agres-icons iconsClass="agres-reload agres-2x"></app-agres-icons>
              <p>agres-reload</p>
            </div>
          </div>
        </div>
        <div class="col-md-2">
          <div class="card">
            <div class="card__body text-center">
              <app-agres-icons iconsClass="agres-refresh-ccw agres-2x"></app-agres-icons>
              <p>agres-refresh-ccw</p>
            </div>
          </div>
        </div>
        <div class="col-md-2">
          <div class="card">
            <div class="card__body text-center">
              <app-agres-icons iconsClass="agres-menu agres-2x"></app-agres-icons>
              <p>agres-menu</p>
            </div>
          </div>
        </div>

        <div class="col-md-2">
          <div class="card">
            <div class="card__body text-center">
              <app-agres-icons iconsClass="agres-lock agres-2x"></app-agres-icons>
              <p>agres-lock</p>
            </div>
          </div>
        </div>
        <div class="col-md-2">
          <div class="card">
            <div class="card__body text-center">
              <app-agres-icons iconsClass="agres-unlock agres-2x"></app-agres-icons>
              <p>agres-unlock</p>
            </div>
          </div>
        </div>
        <div class="col-md-2">
          <div class="card">
            <div class="card__body text-center">
              <app-agres-icons iconsClass="agres-clock agres-2x"></app-agres-icons>
              <p>agres-clock</p>
            </div>
          </div>
        </div>
        <div class="col-md-2">
          <div class="card">
            <div class="card__body text-center">
              <app-agres-icons iconsClass="agres-login agres-2x"></app-agres-icons>
              <p>agres-login</p>
            </div>
          </div>
        </div>
        <div class="col-md-2">
          <div class="card">
            <div class="card__body text-center">
              <app-agres-icons iconsClass="agres-logout agres-2x"></app-agres-icons>
              <p>agres-logout</p>
            </div>
          </div>
        </div>
        <div class="col-md-2">
          <div class="card">
            <div class="card__body text-center">
              <app-agres-icons iconsClass="agres-help agres-2x"></app-agres-icons>
              <p>agres-help</p>
            </div>
          </div>
        </div>
        <div class="col-md-2">
          <div class="card">
            <div class="card__body text-center">
              <app-agres-icons iconsClass="agres-customer agres-2x"></app-agres-icons>
              <p>agres-customer</p>
            </div>
          </div>
        </div>
        <div class="col-md-2">
          <div class="card">
            <div class="card__body text-center">
              <app-agres-icons iconsClass="agres-manual agres-2x"></app-agres-icons>
              <p>agres-manual</p>
            </div>
          </div>
        </div>
        <div class="col-md-2">
          <div class="card">
            <div class="card__body text-center">
              <app-agres-icons iconsClass="agres-app agres-2x"></app-agres-icons>
              <p>agres-app</p>
            </div>
          </div>
        </div>
        <div class="col-md-2">
          <div class="card">
            <div class="card__body text-center">
              <app-agres-icons iconsClass="agres-navigation-report agres-2x"></app-agres-icons>
              <p>agres-navigation-report</p>
            </div>
          </div>
        </div>
        <div class="col-md-2">
          <div class="card">
            <div class="card__body text-center">
              <app-agres-icons iconsClass="agres-fleet-monitoring agres-2x"></app-agres-icons>
              <p>agres-fleet-monitoring</p>
            </div>
          </div>
        </div>
        <div class="col-md-2">
          <div class="card">
            <div class="card__body text-center">
              <app-agres-icons iconsClass="agres-field agres-2x"></app-agres-icons>
              <p>agres-field</p>
            </div>
          </div>
        </div>
        <div class="col-md-2">
          <div class="card">
            <div class="card__body text-center">
              <app-agres-icons iconsClass="agres-terminals agres-2x"></app-agres-icons>
              <p>agres-terminals</p>
            </div>
          </div>
        </div>
        <div class="col-md-2">
          <div class="card">
            <div class="card__body text-center">
              <app-agres-icons iconsClass="agres-search agres-2x"></app-agres-icons>
              <p>agres-search</p>
            </div>
          </div>
        </div>
        <div class="col-md-2">
          <div class="card">
            <div class="card__body text-center">
              <app-agres-icons iconsClass="agres-crop agres-2x"></app-agres-icons>
              <p>agres-crop</p>
            </div>
          </div>
        </div>
        <div class="col-md-2">
          <div class="card">
            <div class="card__body text-center">
              <app-agres-icons iconsClass="agres-list agres-2x"></app-agres-icons>
              <p>agres-list</p>
            </div>
          </div>
        </div>
        <div class="col-md-2">
          <div class="card">
            <div class="card__body text-center">
              <app-agres-icons iconsClass="agres-link agres-2x"></app-agres-icons>
              <p>agres-link</p>
            </div>
          </div>
        </div>
        <div class="col-md-2">
          <div class="card">
            <div class="card__body text-center">
              <app-agres-icons iconsClass="agres-message-square agres-2x"></app-agres-icons>
              <p>agres-message-square</p>
            </div>
          </div>
        </div>
        <div class="col-md-2">
          <div class="card">
            <div class="card__body text-center">
              <app-agres-icons iconsClass="agres-message-circle agres-2x"></app-agres-icons>
              <p>agres-message-circle</p>
            </div>
          </div>
        </div>
        <div class="col-md-2">
          <div class="card">
            <div class="card__body text-center">
              <app-agres-icons iconsClass="agres-mic agres-2x"></app-agres-icons>
              <p>agres-mic</p>
            </div>
          </div>
        </div>
        <div class="col-md-2">
          <div class="card">
            <div class="card__body text-center">
              <app-agres-icons iconsClass="agres-mic-off agres-2x"></app-agres-icons>
              <p>agres-mic-off</p>
            </div>
          </div>
        </div>
        <div class="col-md-2">
          <div class="card">
            <div class="card__body text-center">
              <app-agres-icons iconsClass="agres-navigation agres-2x"></app-agres-icons>
              <p>agres-navigation</p>
            </div>
          </div>
        </div>
        <div class="col-md-2">
          <div class="card">
            <div class="card__body text-center">
              <app-agres-icons iconsClass="agres-navigation-up agres-2x"></app-agres-icons>
              <p>agres-navigation-up</p>
            </div>
          </div>
        </div>
        <div class="col-md-2">
          <div class="card">
            <div class="card__body text-center">
              <app-agres-icons iconsClass="agres-delete agres-2x"></app-agres-icons>
              <p>agres-delete</p>
            </div>
          </div>
        </div>
        <div class="col-md-2">
          <div class="card">
            <div class="card__body text-center">
              <app-agres-icons iconsClass="agres-tool agres-2x"></app-agres-icons>
              <p>agres-tool</p>
            </div>
          </div>
        </div>
        <div class="col-md-2">
          <div class="card">
            <div class="card__body text-center">
              <app-agres-icons iconsClass="agres-wifi agres-2x"></app-agres-icons>
              <p>agres-wifi</p>
            </div>
          </div>
        </div>
        <div class="col-md-2">
          <div class="card">
            <div class="card__body text-center">
              <app-agres-icons iconsClass="agres-wifi-off agres-2x"></app-agres-icons>
              <p>agres-wifi-off</p>
            </div>
          </div>
        </div>
        <div class="col-md-2">
          <div class="card">
            <div class="card__body text-center">
              <app-agres-icons iconsClass="agres-move agres-2x"></app-agres-icons>
              <p>agres-move</p>
            </div>
          </div>
        </div>
        <div class="col-md-2">
          <div class="card">
            <div class="card__body text-center">
              <app-agres-icons iconsClass="agres-map-pin agres-2x"></app-agres-icons>
              <p>agres-map-pin</p>
            </div>
          </div>
        </div>
        <div class="col-md-2">
          <div class="card">
            <div class="card__body text-center">
              <app-agres-icons iconsClass="agres-settings agres-2x"></app-agres-icons>
              <p>agres-settings</p>
            </div>
          </div>
        </div>
        <div class="col-md-2">
          <div class="card">
            <div class="card__body text-center">
              <app-agres-icons iconsClass="agres-share agres-2x"></app-agres-icons>
              <p>agres-share</p>
            </div>
          </div>
        </div>
        <div class="col-md-2">
          <div class="card">
            <div class="card__body text-center">
              <app-agres-icons iconsClass="agres-blocked agres-2x"></app-agres-icons>
              <p>agres-blocked</p>
            </div>
          </div>
        </div>
        <div class="col-md-2">
          <div class="card">
            <div class="card__body text-center">
              <app-agres-icons iconsClass="agres-table agres-2x"></app-agres-icons>
              <p>agres-table</p>
            </div>
          </div>
        </div>
        <div class="col-md-2">
          <div class="card">
            <div class="card__body text-center">
              <app-agres-icons iconsClass="agres-rotate-cw agres-2x"></app-agres-icons>
              <p>agres-rotate-cw</p>
            </div>
          </div>
        </div>
        <div class="col-md-2">
          <div class="card">
            <div class="card__body text-center">
              <app-agres-icons iconsClass="agres-rotate-ccw agres-2x"></app-agres-icons>
              <p>agres-rotate-ccw</p>
            </div>
          </div>
        </div>
        <div class="col-md-2">
          <div class="card">
            <div class="card__body text-center">
              <app-agres-icons iconsClass="agres-save agres-2x"></app-agres-icons>
              <p>agres-save</p>
            </div>
          </div>
        </div>
        <div class="col-md-2">
          <div class="card">
            <div class="card__body text-center">
              <app-agres-icons iconsClass="agres-loader agres-2x"></app-agres-icons>
              <p>agres-loader</p>
            </div>
          </div>
        </div>
        <div class="col-md-2">
          <div class="card">
            <div class="card__body text-center">
              <app-agres-icons iconsClass="agres-plus agres-2x"></app-agres-icons>
              <p>agres-blocked</p>
            </div>
          </div>
        </div>
        <div class="col-md-2">
          <div class="card">
            <div class="card__body text-center">
              <app-agres-icons iconsClass="agres-plus-square agres-2x"></app-agres-icons>
              <p>agres-plus-square</p>
            </div>
          </div>
        </div>
        <div class="col-md-2">
          <div class="card">
            <div class="card__body text-center">
              <app-agres-icons iconsClass="agres-plus-circle agres-2x"></app-agres-icons>
              <p>agres-plus-circle</p>
            </div>
          </div>
        </div>
        <div class="col-md-2">
          <div class="card">
            <div class="card__body text-center">
              <app-agres-icons iconsClass="agres-print agres-2x"></app-agres-icons>
              <p>agres-print</p>
            </div>
          </div>
        </div>
        <div class="col-md-2">
          <div class="card">
            <div class="card__body text-center">
              <app-agres-icons iconsClass="agres-edit agres-2x"></app-agres-icons>
              <p>agres-edit</p>
            </div>
          </div>
        </div>
        <div class="col-md-2">
          <div class="card">
            <div class="card__body text-center">
              <app-agres-icons iconsClass="agres-power agres-2x"></app-agres-icons>
              <p>agres-power</p>
            </div>
          </div>
        </div>
        <hr />
        <h6 class="mb-5">Media e Player</h6>
        <br />
        <div class="col-md-2">
          <div class="card">
            <div class="card__body text-center">
              <app-agres-icons iconsClass="agres-image agres-2x"></app-agres-icons>
              <p>agres-image</p>
            </div>
          </div>
        </div>
        <div class="col-md-2">
          <div class="card">
            <div class="card__body text-center">
              <app-agres-icons iconsClass="agres-video agres-2x"></app-agres-icons>
              <p>agres-video</p>
            </div>
          </div>
        </div>
        <div class="col-md-2">
          <div class="card">
            <div class="card__body text-center">
              <app-agres-icons iconsClass="agres-video-off agres-2x"></app-agres-icons>
              <p>agres-video-off</p>
            </div>
          </div>
        </div>
        <div class="col-md-2">
          <div class="card">
            <div class="card__body text-center">
              <app-agres-icons iconsClass="agres-voice agres-2x"></app-agres-icons>
              <p>agres-voice</p>
            </div>
          </div>
        </div>
        <div class="col-md-2">
          <div class="card">
            <div class="card__body text-center">
              <app-agres-icons iconsClass="agres-volume agres-2x"></app-agres-icons>
              <p>agres-volume</p>
            </div>
          </div>
        </div>
        <div class="col-md-2">
          <div class="card">
            <div class="card__body text-center">
              <app-agres-icons iconsClass="agres-volume-1 agres-2x"></app-agres-icons>
              <p>agres-volume-1</p>
            </div>
          </div>
        </div>
        <div class="col-md-2">
          <div class="card">
            <div class="card__body text-center">
              <app-agres-icons iconsClass="agres-volume-2 agres-2x"></app-agres-icons>
              <p>agres-volume-2</p>
            </div>
          </div>
        </div>
        <div class="col-md-2">
          <div class="card">
            <div class="card__body text-center">
              <app-agres-icons iconsClass="agres-volume-x agres-2x"></app-agres-icons>
              <p>agres-volume-x</p>
            </div>
          </div>
        </div>
        <div class="col-md-2">
          <div class="card">
            <div class="card__body text-center">
              <app-agres-icons iconsClass="agres-pause agres-2x"></app-agres-icons>
              <p>agres-pause</p>
            </div>
          </div>
        </div>
        <div class="col-md-2">
          <div class="card">
            <div class="card__body text-center">
              <app-agres-icons iconsClass="agres-pause-circle agres-2x"></app-agres-icons>
              <p>agres-pause-circle</p>
            </div>
          </div>
        </div>
        <div class="col-md-2">
          <div class="card">
            <div class="card__body text-center">
              <app-agres-icons iconsClass="agres-stop agres-2x"></app-agres-icons>
              <p>agres-stop</p>
            </div>
          </div>
        </div>
        <div class="col-md-2">
          <div class="card">
            <div class="card__body text-center">
              <app-agres-icons iconsClass="agres-stop-circle agres-2x"></app-agres-icons>
              <p>agres-stop-circle</p>
            </div>
          </div>
        </div>
        <div class="col-md-2">
          <div class="card">
            <div class="card__body text-center">
              <app-agres-icons iconsClass="agres-skip-forward agres-2x"></app-agres-icons>
              <p>agres-skip-forward</p>
            </div>
          </div>
        </div>
        <div class="col-md-2">
          <div class="card">
            <div class="card__body text-center">
              <app-agres-icons iconsClass="agres-skip-back agres-2x"></app-agres-icons>
              <p>agres-skip-back</p>
            </div>
          </div>
        </div>
        <div class="col-md-2">
          <div class="card">
            <div class="card__body text-center">
              <app-agres-icons iconsClass="agres-fast-forward agres-2x"></app-agres-icons>
              <p>agres-fast-forward</p>
            </div>
          </div>
        </div>
        <div class="col-md-2">
          <div class="card">
            <div class="card__body text-center">
              <app-agres-icons iconsClass="agres-fast-rewind agres-2x"></app-agres-icons>
              <p>agres-fast-rewind</p>
            </div>
          </div>
        </div>
        <div class="col-md-2">
          <div class="card">
            <div class="card__body text-center">
              <app-agres-icons iconsClass="agres-play agres-2x"></app-agres-icons>
              <p>agres-play</p>
            </div>
          </div>
        </div>
        <div class="col-md-2">
          <div class="card">
            <div class="card__body text-center">
              <app-agres-icons iconsClass="agres-play-circle agres-2x"></app-agres-icons>
              <p>gres-play-circle</p>
            </div>
          </div>
        </div>
        <div class="col-md-2">
          <div class="card">
            <div class="card__body text-center">
              <app-agres-icons iconsClass="agres-repeat agres-2x"></app-agres-icons>
              <p>agres-repeat</p>
            </div>
          </div>
        </div>
        <div class="col-md-2">
          <div class="card">
            <div class="card__body text-center">
              <app-agres-icons iconsClass="agres-monitor agres-2x"></app-agres-icons>
              <p>agres-monitor</p>
            </div>
          </div>
        </div>
        <div class="col-md-2">
          <div class="card">
            <div class="card__body text-center">
              <app-agres-icons iconsClass="agres-smartphone agres-2x"></app-agres-icons>
              <p>agres-smartphone</p>
            </div>
          </div>
        </div>
        <div class="col-md-2">
          <div class="card">
            <div class="card__body text-center">
              <app-agres-icons iconsClass="agres-tablet agres-2x"></app-agres-icons>
              <p>agres-tablet</p>
            </div>
          </div>
        </div>
        <div class="col-md-2">
          <div class="card">
            <div class="card__body text-center">
              <app-agres-icons iconsClass="agres-slider agres-2x"></app-agres-icons>
              <p>agres-slider</p>
            </div>
          </div>
        </div>
        <hr />
        <h6 class="mb-5">File & Folder</h6>
        <br />
        <div class="col-md-2">
          <div class="card">
            <div class="card__body text-center">
              <app-agres-icons iconsClass="agres-file agres-2x"></app-agres-icons>
              <p>agres-file</p>
            </div>
          </div>
        </div>
        <div class="col-md-2">
          <div class="card">
            <div class="card__body text-center">
              <app-agres-icons iconsClass="agres-file-text agres-2x"></app-agres-icons>
              <p>agres-file-text</p>
            </div>
          </div>
        </div>
        <div class="col-md-2">
          <div class="card">
            <div class="card__body text-center">
              <app-agres-icons iconsClass="agres-file-plus agres-2x"></app-agres-icons>
              <p>agres-file-plus</p>
            </div>
          </div>
        </div>
        <div class="col-md-2">
          <div class="card">
            <div class="card__body text-center">
              <app-agres-icons iconsClass="agres-file-minus agres-2x"></app-agres-icons>
              <p>agres-file-minus</p>
            </div>
          </div>
        </div>
        <hr />
        <div class="col-md-2">
          <div class="card">
            <div class="card__body text-center">
              <app-agres-icons iconsClass="agres-folder agres-2x"></app-agres-icons>
              <p>agres-folder</p>
            </div>
          </div>
        </div>
        <div class="col-md-2">
          <div class="card">
            <div class="card__body text-center">
              <app-agres-icons iconsClass="agres-folder-plus agres-2x"></app-agres-icons>
              <p>agres-folder-plus</p>
            </div>
          </div>
        </div>
        <div class="col-md-2">
          <div class="card">
            <div class="card__body text-center">
              <app-agres-icons iconsClass="agres-folder-minus agres-2x"></app-agres-icons>
              <p>agres-folder-minus</p>
            </div>
          </div>
        </div>
        <hr />
        <h6 class="mb-5">Arrows</h6>
        <br />
        <div class="col-md-2">
          <div class="card">
            <div class="card__body text-center">
              <app-agres-icons iconsClass="agres-arrow-up agres-2x"></app-agres-icons>
              <p>agres-arrow-up</p>
            </div>
          </div>
        </div>
        <div class="col-md-2">
          <div class="card">
            <div class="card__body text-center">
              <app-agres-icons iconsClass="agres-arrow-left agres-2x"></app-agres-icons>
              <p>agres-arrow-left</p>
            </div>
          </div>
        </div>
        <div class="col-md-2">
          <div class="card">
            <div class="card__body text-center">
              <app-agres-icons iconsClass="agres-arrow-right agres-2x"></app-agres-icons>
              <p>agres-arrow-right</p>
            </div>
          </div>
        </div>
        <div class="col-md-2">
          <div class="card">
            <div class="card__body text-center">
              <app-agres-icons iconsClass="agres-arrow-down agres-2x"></app-agres-icons>
              <p>agres-arrow-down</p>
            </div>
          </div>
        </div>
        <hr />
        <div class="col-md-2">
          <div class="card">
            <div class="card__body text-center">
              <app-agres-icons iconsClass="agres-arrows-up agres-2x"></app-agres-icons>
              <p>agres-arrows-up</p>
            </div>
          </div>
        </div>
        <div class="col-md-2">
          <div class="card">
            <div class="card__body text-center">
              <app-agres-icons iconsClass="agres-arrows-left agres-2x"></app-agres-icons>
              <p>agres-arrows-left</p>
            </div>
          </div>
        </div>
        <div class="col-md-2">
          <div class="card">
            <div class="card__body text-center">
              <app-agres-icons iconsClass="agres-arrows-right agres-2x"></app-agres-icons>
              <p>agres-arrows-right</p>
            </div>
          </div>
        </div>
        <div class="col-md-2">
          <div class="card">
            <div class="card__body text-center">
              <app-agres-icons iconsClass="agres-arrows-down agres-2x"></app-agres-icons>
              <p>agres-arrows-down</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="ui-breadcrumb">
    <div class="ui-breadcrumb__container">
      <h1 class="ui-breadcrumb__container--title">
        Dropdown<span class="ui-breadcrumb__container--title---span">.</span>
      </h1>
    </div>
  </div>
  <div class="container">
    <div class="design-headers">
      <div class="row">
        <div class="card">
          <div class="card__body">
            <div class="col-md-6">
              <app-dropdown></app-dropdown>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="ui-breadcrumb">
  <div class="ui-breadcrumb__container">
    <h1 class="ui-breadcrumb__container--title">
      Selects<span class="ui-breadcrumb__container--title---span">.</span>
    </h1>
  </div>
</div>
<div class="container">
  <div class="design-headers">
    <div class="row">
      <div class="card">
        <div class="card__body">
          <div class="col-md-4">
            <app-select title="{{ 'fields.tutorial-field-edit' | translate }}"></app-select>
          </div>
          <div class="col-md-4">
            <app-select-search title="{{ 'fields.tutorial-field-edit' | translate }}"></app-select-search>
          </div>
          <div class="col-md-4">
            <app-select-waterfall title="{{ 'fields.tutorial-field-edit' | translate }}"></app-select-waterfall>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="ui-breadcrumb">
  <div class="ui-breadcrumb__container">
    <h1 class="ui-breadcrumb__container--title">Table<span class="ui-breadcrumb__container--title---span">.</span></h1>
  </div>
</div>
<div class="container">
  <div class="design-headers">
    <div class="row">
      <div class="card">
        <div class="card__body">
          <div class="col-md-12">
            <app-table></app-table>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="ui-breadcrumb">
  <div class="ui-breadcrumb__container">
    <h1 class="ui-breadcrumb__container--title">
      Input & Icons<span class="ui-breadcrumb__container--title---span">.</span>
    </h1>
  </div>
</div>
<div class="container">
  <div class="design-headers">
    <div class="row">
      <div class="card">
        <div class="card__body">
          <div class="col-md-12">
            <app-input
              type="text"
              iconClass="agres-user"
              labelText="{{ 'global.asterisk' | translate }}"
              name="name"
              value=""
              inputText="{{ 'my-profile.user-data.full-name' | translate }}"></app-input>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
