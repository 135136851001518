<app-alert></app-alert>
<div class="panel">
  <div class="panel__group">
    <div class="panel__search">
      <div class="panel__flex">
        <form role="search">
          <div class="field field__search">
            <div class="field__wrap">
              <input
                [matAutocomplete]="auto"
                name="searchCity"
                (input)="onInputChange($event)"
                type="search"
                aria-label="search"
                placeholder="{{ 'global.search' | translate }}"
                class="field__input"
                required="true"
                [disabled]="isPolygonClosed" />
              <span class="field__view">
                <app-agres-icons iconsClass="agres-info" tooltip="{{ 'fields.tooltip-input-search' | translate }}">
                </app-agres-icons>
              </span>
            </div>
          </div>
          <mat-autocomplete #auto="matAutocomplete">
            <mat-option
              *ngFor="let location of locations"
              [value]="location.formatted_address"
              (onSelectionChange)="centerGoogleMap(location.geometry.location.lat, location.geometry.location.lng)">
              {{ location.formatted_address }}
            </mat-option>
          </mat-autocomplete>
        </form>
      </div>
    </div>
  </div>
  <div class="panel__group">
    <div class="panel__group--buttons hidden__mobile">
      <app-button
        type="button"
        color="return"
        icon="agres-return"
        (btnClick)="cancelEditPolygons()"
        title="{{ 'global.back' | translate }}">
      </app-button>
      <app-button
        type="button"
        color="danger"
        icon="agres-close"
        [disabled]="!isPolygonClosed"
        (btnClick)="clearPolygons()"
        id="buttonClearTalhao"
        title="{{ 'global.cancel' | translate }}">
      </app-button>
      <app-button
        type="button"
        color="primary"
        icon="agres-field"
        [disabled]="!isGoogleMapLoaded"
        (btnClick)="callTogglePolygonDrawing()"
        [title]="togglePolygonEditButtonTitle()">
      </app-button>
    </div>
  </div>
</div>
<ng-template #resultBody>
  <div *ngIf="isPolygonClosed && !isEditingPolygon">
    <form [formGroup]="createFieldForm" (ngSubmit)="createTalhao()">
      <div class="field" id="campoNomeTalhao">
        <div class="field__label" for="floatingInputNameTalhao" id="labelNomeTalhao">
          {{ 'global.area' | translate }}
        </div>
        <div class="field__wrap">
          <input class="field__input" formControlName="area" type="text" value="" readonly />
          <span class="field__view">
            <app-agres-icons iconsClass="agres-field"></app-agres-icons>
          </span>
        </div>
      </div>
      <div class="field">
        <div class="field__label" for="campoNomeTalhao">
          {{ 'global.title' | translate }}
        </div>
        <div class="field__wrap">
          <input
            formControlName="name"
            type="text"
            class="field__input"
            id="campoNomeTalhao"
            [ngClass]="{ 'has-error': displayMessage.name }" />
          <span class="field__input--alert" *ngIf="displayMessage.name" [innerHTML]="displayMessage.name"> </span>
        </div>
      </div>
      <div class="field">
        <div class="field__label" for="fieldColor">
          {{ 'global.color' | translate }}
        </div>
        <div class="field__wrap">
          <input
            type="color"
            id="fieldColor"
            class="field__input"
            (onclick)="selectClick($event)"
            (change)="selectCoresTalhao($event)" />
        </div>
      </div>
      <div class="field">
        <div class="field__label" for="description">
          {{ 'global.description' | translate }}
        </div>
        <div>
          <textarea formControlName="description" rows="20" id="description" (input)="verifyTextAreaSize()"> </textarea>
          <div *ngIf="exceededCharacterLimit">
            <span style="color: #ff5757">{{ 'global.validate.description-field-size' | translate }}</span>
          </div>
        </div>
      </div>
    </form>
  </div>
</ng-template>
<ng-template #resultFooter>
  <div class="hidden__mobile">
    <app-button
      type="submit"
      color="primary"
      icon="agres-field"
      (click)="createTalhao()"
      [disabled]="!createFieldForm.valid || !isPolygonClosed || blockButton"
      title="{{ 'global.save' | translate }}"
      id="buttonCreateTalhao">
    </app-button>
  </div>
  <app-menu-pwa>
    <div class="pwa__list--info">
      <button type="submit" (click)="hideOffCanvas()" data-bs-dismiss="offcanvas">
        <i class="agres-return"></i>
        <p>{{ 'global.back' | translate }}</p>
      </button>
    </div>
    <div class="pwa__list--info">
      <button
        type="submit"
        [disabled]="!createFieldForm.valid || !isPolygonClosed || blockButton"
        id="buttonCreateTalhao"
        (click)="createTalhao()">
        <i class="agres-save"></i>
        <p>{{ 'global.save' | translate }}</p>
      </button>
    </div>
  </app-menu-pwa>
</ng-template>
<app-offcanvas
  size="medium"
  [show]="false"
  [showSubject]="offCanvasOne"
  [bodyTemplate]="resultBody"
  [footerTemplate]="resultFooter"
  title="{{ 'fields.create' | translate }}">
</app-offcanvas>
<div class="mapMobile">
  <app-mapa-talhao
    (startMapLoaded)="onGoogleMapLoaded($event)"
    [updatePolygons]="onUpdatePolygons()"
    [resetData]="fetchFieldData"
    [polygonColorsSubject]="mapPolygonColorSubject"
    [googleMapCenterCoordinate]="googleMapCenterCoordinate"
    [updateIsPolygonClosed]="updateIsPolygonClosed"
    [geometry]="geometry"
    mapHeight="1000px">
  </app-mapa-talhao>
</div>
<app-tutoriais-talhoes></app-tutoriais-talhoes>
<app-menu-pwa>
  <div class="pwa__list--info pwa__list--info--active">
    <button [disabled]="!isGoogleMapLoaded" (click)="callTogglePolygonDrawing()">
      <i class="agres-field"></i>
      <p>{{ togglePolygonEditButtonTitle() }}</p>
    </button>
  </div>
  <div class="pwa__list--info pwa__list--info--active">
    <button [disabled]="!isPolygonClosed" (click)="clearPolygons()" id="buttonClearTalhao">
      <i class="agres-close"></i>
      <p>{{ 'global.cancel' | translate }}</p>
    </button>
  </div>
</app-menu-pwa>
