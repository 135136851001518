<section class="searchMobile">
  <div class="cardList">
    <div class="cardField" *ngFor="let item of paginationList()">
      <div class="cardField__content">
        <div class="cardField__content--info" (click)="onCardClick(item.id, fieldIdToBeDeleted)">
          <div class="cardField__content--info---image">
            <img src="{{ item.staticMapImage }}" alt="{{ item.name }}" />
          </div>
          <div class="cardField__content--info---description">
            <h1>{{ item.name }}</h1>
            <p>{{ item?.city }}</p>
            <p>
              {{ item.totalArea }} {{ 'global.hectare' | translate }}
              <span *ngIf="item?.ndvi" class="tagNdvi">NDVI</span>
            </p>
          </div>
        </div>
        <div class="cardField__content--menu">
          <div
            class="cardField__content--menu---icon"
            (click)="onMenuClick($event)"
            id="menuCard"
            data-bs-toggle="dropdown">
            <app-agres-icons iconsClass="agres-menu-card"></app-agres-icons>
          </div>
          <div class="dropdown-menu" aria-labelledby="menuCard">
            <app-link
              styleClass="dropdown-item"
              (click)="emitEventEditTalhaoById(item.id, item._rev, fieldIdToBeDeleted)"
              [isDisabled]="enableActionButton(item.id, fieldIdToBeDeleted)"
              title="{{ 'global.edit' | translate }}">
            </app-link>
            <app-link
              styleClass="dropdown-item"
              (click)="changeTalhaoIdOnDelete(item.id, item._rev, fieldIdToBeDeleted)"
              [isDisabled]="enableActionButton(item.id, fieldIdToBeDeleted)"
              title="{{ 'global.delete' | translate }}">
            </app-link>
          </div>
        </div>
      </div>
      <div class="cardField__footer">
        <app-button
          type="button"
          color="card"
          size="full"
          icon="agres-action"
          (btnClick)="onCardClick(item.id, fieldIdToBeDeleted)"
          title="{{ 'global.view' | translate }}">
        </app-button>
      </div>
    </div>
  </div>
</section>
<div *ngIf="totalFields === 0">
  <div class="information">
    <div class="information__card">
      <p>{{ 'global.no-data' | translate }}</p>
    </div>
  </div>
</div>
<app-pagination
  *ngIf="originalsTalhoesItens.length > 0"
  #pagination
  (pageChanged)="clickPagination($event)"
  [collectionSize]="originalsTalhoesItens.length"
  [pageSize]="rowsPerPage"
  [firstLastButtons]="true"
  [startingPage]="page"
  [maxSize]="25">
</app-pagination>
