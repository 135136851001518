import { EventoISOBUS } from 'app/shared/models/eventoISOBUS';
import { EventoNVG } from 'app/shared/models/eventoNVG';
import { DevicePropertyAttributes, ExtendedDeviceElement } from 'isoxml';
import { Position } from '@turf/turf';

export type Path = {
  lat: number;
  lng: number;
};
export type Trail = {
  paths: Path[];
  valueOperation?: number;
};
export type TrailSection = {
  devSecLat: Position;
  devSecLng: Position;
};
export interface TurfBase {
  type: string;
  geometry: {
    type: string;
    coordinates: Object;
  };
  properties: Object;
}
export interface TurfPoint extends TurfBase {
  type: 'Feature';
  geometry: {
    type: 'Point';
    coordinates: Position;
  };
  properties: {};
}
export interface TurfPolygon extends TurfBase {
  type: 'Feature';
  geometry: {
    type: 'Polygon';
    coordinates: Position[][];
  };
  properties: {
    value?: number;
  };
}
export type Coordinates = {
  lat: number;
  lng: number;
  op: number;
};
export type TrailSections = {
  trailSectionLeft: TrailSection;
  trailSectionRight: TrailSection;
  valueOperation: number;
};
export interface TrailInfo {
  lat: number;
  lng: number;
  op: number;
}
interface ApplicationRateFleetMonitoringTrails {
  applicationRateLiquid?: [Trail[], string];
  applicationRateSolid?: [Trail[], string];
  applicationRateSeed?: [Trail[], string];
}
export interface FleetMonitoringTrails
  extends ApplicationRateFleetMonitoringTrails {
  speed?: [Trail[], string];
}
export interface ParsedISOBUSJSON {
  devices: DevicePropertiesAndReferences[];
  stageInfo: StageInfo[];
}
export interface StageInfo {
  deviceDesignator?: string[];
  deviceSerialNumber?: string[];
  startTime: Date | null;
  stopTime: Date | null;
  timeLogData: TimeLogDataValues;
  trailData: FleetMonitoringTrails;
}
export interface TimeLogDataValues {
  [ddi: string]: {
    [det: string]: number[];
  };
}
export interface DDIValues {
  [det: string]: any;
}
export interface StartStopTime {
  startTime: Date | null;
  stopTime: Date | null;
}
export interface DeviceInfoStage {
  deviceDesignator?: string[];
  deviceSerialNumber?: string[];
}
export type DeviceElementReference = {
  deviceDesignator?: string;
  deviceSerialNumber?: string;
  references: {
    [deviceElementType: string]: string[][];
  };
};
export type DeviceElements = {
  deviceDesignator: string;
  deviceSerialNumber: string;
  elements: ExtendedDeviceElement[];
};
export type DevicePropertiesAndReferences = {
  deviceDesignator: string;
  deviceSerialNumber: string;
  properties: DevicePropertyAttributes;
  references: {
    [reference: string]: string[][];
  };
};
export type DeviceElementAndStageInfo = {
  DETs: Array<string>;
  stageInfo: Array<StageInfo> | null;
};

type IsobusObjectType = {
  [serialNumber: string]: Partial<
    Record<string, [number, string]> &
      Record<
        'trailDataUnits',
        {
          [key in keyof ApplicationRateFleetMonitoringTrails]: string;
          }
        | string
      > &
      Record<
        'trailData',
        {
            [key in keyof ApplicationRateFleetMonitoringTrails]: Trail[];
          }
        | Trail[]
      >
  >;
};

export class IsobusObjectBuilder {
  private readonly _isobusObject: IsobusObjectType = {};

  get isobusObject(): IsobusObjectType {
    return this._isobusObject;
  }

  private readonly devices: DevicePropertiesAndReferences[];
  private readonly deviceElementsAndStageInfoBySerialNumber: {
    [serialNumber: string]: DeviceElementAndStageInfo;
  } = {};
  private readonly stageInfo: StageInfo[];

  constructor(parsedTask: EventoNVG | EventoISOBUS) {
    if ('devices' in parsedTask) {
      this.devices = parsedTask.devices;
      this.stageInfo = parsedTask.stageInfo;
    }

    this.setStageInfoByDeviceSerialNumber();
    this.buildIsobusObject();
  }

  private buildIsobusObject() {
    for (const key in this.deviceElementsAndStageInfoBySerialNumber) {
      this.isobusObject[key] = {};
      const deviceElemAndStageInfoBySerialNumber =
        this.deviceElementsAndStageInfoBySerialNumber[key];
      this.setApplicationRateTrails(deviceElemAndStageInfoBySerialNumber, key);
      this.setEffectiveTotalTime(deviceElemAndStageInfoBySerialNumber, key); // OK
      this.setIneffectiveTotalTime(deviceElemAndStageInfoBySerialNumber, key); // OK
      this.setTotalOperationTime(deviceElemAndStageInfoBySerialNumber, key); // OK
      this.setStageStartTime(deviceElemAndStageInfoBySerialNumber, key); // FAZER
      this.setStageEndTime(deviceElemAndStageInfoBySerialNumber, key); // FAZER
      this.setNumberOfSections(deviceElemAndStageInfoBySerialNumber, key); // FAZER
      this.setEventArea(deviceElemAndStageInfoBySerialNumber, key); // FAZER
      this.setImplementWidth(deviceElemAndStageInfoBySerialNumber, key); // FAZER
      this.setDistance(deviceElemAndStageInfoBySerialNumber, key); // FAZER
    }
  }

  private setStageInfoByDeviceSerialNumber() {
    this.devices.forEach((device) => {
      this.filterDeviceElementByDeviceSerialNumber(device);
      this.filterStagesByDevice(device.deviceSerialNumber);
    });
  }

  private filterDeviceElementByDeviceSerialNumber(
    device: DevicePropertiesAndReferences,
  ) {
    const DETs = [];

    for (let reference in device.references) {
      const DETType = device.references[reference];
      const dets = DETType.filter((det) => det[0].includes('DET-'));
      dets.forEach((det) => DETs.push(det[0]));
    }

    this.deviceElementsAndStageInfoBySerialNumber[device.deviceSerialNumber] = {
      DETs,
      stageInfo: null,
    };
  }

  private filterStagesByDevice(deviceSerialNumber: string) {
    const stageInfoByDevice = this.stageInfo.filter((stage) =>
      stage.deviceSerialNumber.some((e) => e === deviceSerialNumber),
    );
    this.deviceElementsAndStageInfoBySerialNumber[
      deviceSerialNumber
    ].stageInfo = stageInfoByDevice;
  }

  private setApplicationRateTrails(
    deviceElementsAndStageInfo: DeviceElementAndStageInfo,
    serialNumber: string,
  ) {
    const trailDataWithUnits = deviceElementsAndStageInfo.stageInfo
      .map((e) => e.trailData)
      .reduce(
        (prev: any, cur: any) => {
          const applicationRateObject = {
            applicationRateLiquid: cur.applicationRateLiquid?.[0].concat(
              prev.applicationRateLiquid ?? [],
            ),
            applicationRateSeed: cur.applicationRateSeed?.[0].concat(
              prev.applicationRateSeed ?? [],
            ),
            applicationRateSolid: cur.applicationRateSolid?.[0].concat(
              prev.applicationRateSolid ?? [],
            ),
            units: {
              applicationRateLiquidUnit: cur.applicationRateLiquid?.[1],
              applicationRateSeedUnit: cur.applicationRateSeed?.[1],
              applicationRateSolidUnit: cur.applicationRateSolid?.[1],
            },
          };
          return applicationRateObject;
        },
        {
          applicationRateLiquid: [],
          applicationRateSeed: [],
          applicationRateSolid: [],
          units: {
            applicationRateLiquidUnit: '',
            applicationRateSeedUnit: '',
            applicationRateSolidUnit: '',
          },
        },
      );
    const singleApplicationRateKey =
      this.getSingleApplicationRateKey(trailDataWithUnits);
    if (
      typeof singleApplicationRateKey === 'string' &&
      singleApplicationRateKey in trailDataWithUnits
    ) {
      this.isobusObject[serialNumber].trailDataUnits =
        trailDataWithUnits.units[singleApplicationRateKey];
      delete trailDataWithUnits.units;
      this.isobusObject[serialNumber].trailData =
        trailDataWithUnits[singleApplicationRateKey];
    } else {
      this.isobusObject[serialNumber].trailDataUnits = {
        ...trailDataWithUnits.units,
      };
      delete trailDataWithUnits.units;
      this.isobusObject[serialNumber].trailData = trailDataWithUnits;
    }
  }
  getSingleApplicationRateKey(trailDataWithUnits): string | false {
    const emptyApplicationRateSolid =
      trailDataWithUnits.applicationRateSolid == undefined ||
      trailDataWithUnits.applicationRateSolid?.length === 0;
    const emptyApplicationLiquid =
      trailDataWithUnits.applicationRateLiquid == undefined ||
      trailDataWithUnits.applicationRateLiquid?.length === 0;
    const emptyApplicationSeed =
      trailDataWithUnits.applicationRateSeed == undefined ||
      trailDataWithUnits.applicationRateSeed?.length === 0;
    if (
      !emptyApplicationRateSolid &&
      emptyApplicationLiquid &&
      emptyApplicationSeed
    ) {
      return 'applicationRateSolid';
    } else if (
      !emptyApplicationSeed &&
      emptyApplicationRateSolid &&
      emptyApplicationLiquid
    ) {
      return 'applicationRateSeed';
    } else if (
      !emptyApplicationLiquid &&
      emptyApplicationSeed &&
      emptyApplicationRateSolid
    ) {
      return 'applicationRateLiquid';
    } else {
      return false;
    }
  }
  private setEffectiveTotalTime(
    deviceElementsAndStageInfo: DeviceElementAndStageInfo,
    serialNumber: string,
  ) {
    this.populateIsobusEntry(
      deviceElementsAndStageInfo,
      serialNumber,
      'effectiveTotalTime',
    );
  }

  private setIneffectiveTotalTime(
    deviceElementsAndStageInfo: DeviceElementAndStageInfo,
    serialNumber: string,
  ) {
    this.populateIsobusEntry(
      deviceElementsAndStageInfo,
      serialNumber,
      'ineffectiveTotalTime',
    );
  }

  private setTotalOperationTime(
    deviceElementsAndStageInfo: DeviceElementAndStageInfo,
    serialNumber: string,
  ) {
    //resumo.tempo total da operacao
    const stageInfoLength = deviceElementsAndStageInfo.stageInfo.length;
    const startTime = new Date(
      deviceElementsAndStageInfo.stageInfo[0].startTime,
    );
    const stopTime = new Date(
      deviceElementsAndStageInfo.stageInfo[stageInfoLength - 1].stopTime,
    );
    const totalTime = stopTime.getTime() - startTime.getTime();
    this.isobusObject[serialNumber].totalOperationTime = [totalTime, 'ms'];
  }

  private setEventArea(
    deviceElementsAndStageInfo: DeviceElementAndStageInfo,
    serialNumber: string,
  ) {
    //evento.area
    this.populateIsobusEntry(
      deviceElementsAndStageInfo,
      serialNumber,
      'totalArea',
    );
  }

  private setNumberOfSections(
    deviceElementsAndStageInfo: DeviceElementAndStageInfo,
    serialNumber: string,
  ) {
    //evento.n_sec
    const selectedDevice = this.devices.filter(
      (device) => device.deviceSerialNumber === serialNumber,
    );
    const numberOfSections = selectedDevice.filter(
      (device) => device.references['Section'],
    ).length;

    this.isobusObject[serialNumber].numberOfSections = [
      numberOfSections,
      'n.a.',
    ];
  }

  private setStageStartTime(
    deviceElementsAndStageInfo: DeviceElementAndStageInfo,
    serialNumber: string,
  ) {
    //etapa.data inicio
    const startTimeArray: [number, string][] = [];
    const stageInfoLength = deviceElementsAndStageInfo.stageInfo.length;

    for (let stageNumber = 0; stageNumber < stageInfoLength; stageNumber++) {
      startTimeArray.push([
        new Date(deviceElementsAndStageInfo.stageInfo[0].startTime).getTime(),
        'ms',
      ]);
    }

    this.isobusObject[serialNumber].stageStartTime = startTimeArray as any;
  }

  private setStageEndTime(
    deviceElementsAndStageInfo: DeviceElementAndStageInfo,
    serialNumber: string,
  ) {
    //etapa.data final
    const stopTimeArray: [number, string][] = [];
    const stageInfoLength = deviceElementsAndStageInfo.stageInfo.length;

    for (let stageNumber = 0; stageNumber < stageInfoLength; stageNumber++) {
      stopTimeArray.push([
        new Date(deviceElementsAndStageInfo.stageInfo[0].stopTime).getTime(),
        'ms',
      ]);
    }

    this.isobusObject[serialNumber].stageStopTime = stopTimeArray as any;
  }

  private setDistance(
    deviceElementsAndStageInfo: DeviceElementAndStageInfo,
    serialNumber: string,
  ) {
    //evento.largura_implemento?
    this.populateIsobusEntry(
      deviceElementsAndStageInfo,
      serialNumber,
      'totalDistance',
    );
  }
  // private setAverageSpeed(deviceElementsAndStageInfo:DeviceElementAndStageInfo){
  //   //evento.largura_implemento?
  // }

  private setImplementWidth(
    deviceElementsAndStageInfo: DeviceElementAndStageInfo,
    serialNumber: string,
  ) {
    //evento.largura_implemento?
    const timeLogData = deviceElementsAndStageInfo.DETs.map((det) => {
      const stageInfosByDeviceElement = deviceElementsAndStageInfo.stageInfo
        .filter((stage) => stage.timeLogData?.actualWorkingWidth?.[det])
        .map((stage) => stage.timeLogData.actualWorkingWidth[det]);

      return stageInfosByDeviceElement;
    });

    for (const arr of timeLogData) {
      for (const position of arr) {
        position[0] = null;
      }
    }

    this.isobusObject[serialNumber].actualWorkingWidth = timeLogData as any;
  }

  // private setNumberOfNozzles(deviceElementsAndStageInfo:DeviceElementAndStageInfo){
  //   //evento.largura_implemento?
  // }

  private populateIsobusEntry(
    deviceElementsAndStageInfo: DeviceElementAndStageInfo,
    serialNumber: string,
    ddi: string,
  ) {
    const timeLogData = deviceElementsAndStageInfo.DETs.map((det) => {
      const stageInfosByDeviceElement = deviceElementsAndStageInfo.stageInfo
        .filter((stage) => stage.timeLogData?.[ddi]?.[det])
        .map((stage) => stage.timeLogData[ddi][det]);

      return stageInfosByDeviceElement;
    });

    this.isobusObject[serialNumber][ddi] = (
      timeLogData.find((dets) => dets.length !== 0) as any
    )?.reduce((a, b) => [a[0] + b[0], b[3]], [null, null, null, 'n.a.']) ?? [
      null,
      null,
      null,
      'n.a.',
    ];
  }
}
