import { AbstractControl } from '@angular/forms';
import { controllers } from 'chart.js';

export class ValidateId {


static ValidadeCPFCNPJ(


    control: AbstractControl
    ): { [key: string]: boolean } | null {

        if (control.value === undefined || control.value.length === 0)
            return null;

        if (control.value.length < 11) {
            return { cpf: true };
        }
        if (control.value.length > 11 && control.value.length < 14) {
            return { cnpj: true };
        }

        if (control.value.length == 11 || control.value.length == 14) {
            const checkRepetedNumber = control.value.match(/(.)\1{10,}/)
            if (checkRepetedNumber!== null && checkRepetedNumber.length > 0) {
                return  control.value <= 11 ? { cpf: true } : { cnpj: true } ;
            }
        }

        let isCPF = control.value.length === 11;

        let id = Math.trunc(parseInt(control.value) / 100)
            .toString()
            .split('')
            .reverse()
            .map((e) => parseInt(e));
        const idArrayFirstDigit: number[] = [0, 0, ...id];

        let idSumFirstDigit: number = idArrayFirstDigit.reduce(
            (a, b, index) =>
                b * (isCPF ? index : index >= 10 ? index - 8 : index) + a
        );

        let firstDigit: number = idSumFirstDigit % 11;
        firstDigit = 11 - firstDigit;
        firstDigit = firstDigit >= 10 ? 0 : firstDigit;

        let idArraySecondDigit: number[] = [0, firstDigit, ...id];
        if (isCPF) idArraySecondDigit.unshift(0);
        let idSumSecondDigit = idArraySecondDigit.reduce(
            (a, b, index) =>
                b * (isCPF ? index : index < 9 ? index + 1 : index - 7) + a
        );

        let secondDigit: number = idSumSecondDigit % 11;
        secondDigit = 11 - secondDigit;
        secondDigit = secondDigit >= 10 ? 0 : secondDigit;

        if (control.value.slice(-2) !== `${firstDigit}${secondDigit}`) {
            return isCPF ? { cpf: true } : { cnpj: true };
        }



        return null;
    }
}
