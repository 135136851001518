<div class="panel">
  <div class="panel__group">
    <div class="panel__search">
      <form onsubmit="event.preventDefault();" role="search">
        <div class="field">
          <div class="field__wrap">
            <input
              id="search"
              type="text"
              placeholder="{{ 'global.search' | translate }}"
              (keyup)="searchOperations($event)"
              class="field__input" />
            <span class="field__view">
              <app-agres-icons iconsClass="agres-search"></app-agres-icons>
            </span>
          </div>
        </div>
      </form>
    </div>
  </div>
  <div class="panel__group">
    <div class="panel__group--buttons hidden__mobile">
      <app-button
        type="button"
        color="success"
        icon="agres-download"
        (btnClick)="exportAllSelected()"
        title="{{ 'global.export-select' | translate }}">
      </app-button>
      <app-button
        type="button"
        color="success"
        icon="agres-download"
        (btnClick)="exportToExcellAllOperations()"
        title="{{ 'global.export-all' | translate }}">
      </app-button>
      <app-button
        type="button"
        color="primary"
        icon="agres-field"
        (btnClick)="addOperation()"
        title="{{ 'global.create' | translate }}">
      </app-button>
      <app-button
        type="button"
        color="return"
        icon="agres-return"
        (btnClick)="backField()"
        title="{{ 'global.back' | translate }}">
      </app-button>
      <!-- <button
          class="breadcrumbs__body--colRight---btnExportAllSelected"
          [hidden]="!hasSelectedItem"
          (click)="exportAllSelected()"
          id="btn-selecionados"
          #btnSelecionados>
          {{ 'global.export-select' | translate }}
        </button> -->
      <!-- <button type="button" *ngFor="let operation of operationsItens;" class="breadcrumbs__body--colRight---btnSearchDate" (click)="openSearchModal()">
          {{ 'global.search' | translate }}
        </button> -->
      <!-- <button type="button" class="breadcrumbs__body--colRight---btnCreatedOperation" (click)="addOperation()">
          {{ 'global.create' | translate }}
        </button> -->
    </div>
  </div>
</div>

<section class="listagemOperacao">
  <div class="row tabelaDeOperacao">
    <table class="table tabelaDeOperacao--table" id="search">
      <thead>
        <tr>
          <th><input id="selectAll" type="checkbox" /></th>
          <th>
            {{ 'tasks.table.crop' | translate }}
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M14 5.1665H2C1.72667 5.1665 1.5 4.93984 1.5 4.6665C1.5 4.39317 1.72667 4.1665 2 4.1665H14C14.2733 4.1665 14.5 4.39317 14.5 4.6665C14.5 4.93984 14.2733 5.1665 14 5.1665Z"
                fill="#00092E" />
              <path
                d="M12 8.5H4C3.72667 8.5 3.5 8.27333 3.5 8C3.5 7.72667 3.72667 7.5 4 7.5H12C12.2733 7.5 12.5 7.72667 12.5 8C12.5 8.27333 12.2733 8.5 12 8.5Z"
                fill="#00092E" />
              <path
                d="M9.33341 11.8335H6.66675C6.39341 11.8335 6.16675 11.6068 6.16675 11.3335C6.16675 11.0602 6.39341 10.8335 6.66675 10.8335H9.33341C9.60675 10.8335 9.83341 11.0602 9.83341 11.3335C9.83341 11.6068 9.60675 11.8335 9.33341 11.8335Z"
                fill="#00092E" />
            </svg>
          </th>
          <th>
            {{ 'tasks.table.equipament' | translate }}
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M14 5.1665H2C1.72667 5.1665 1.5 4.93984 1.5 4.6665C1.5 4.39317 1.72667 4.1665 2 4.1665H14C14.2733 4.1665 14.5 4.39317 14.5 4.6665C14.5 4.93984 14.2733 5.1665 14 5.1665Z"
                fill="#00092E" />
              <path
                d="M12 8.5H4C3.72667 8.5 3.5 8.27333 3.5 8C3.5 7.72667 3.72667 7.5 4 7.5H12C12.2733 7.5 12.5 7.72667 12.5 8C12.5 8.27333 12.2733 8.5 12 8.5Z"
                fill="#00092E" />
              <path
                d="M9.33341 11.8335H6.66675C6.39341 11.8335 6.16675 11.6068 6.16675 11.3335C6.16675 11.0602 6.39341 10.8335 6.66675 10.8335H9.33341C9.60675 10.8335 9.83341 11.0602 9.83341 11.3335C9.83341 11.6068 9.60675 11.8335 9.33341 11.8335Z"
                fill="#00092E" />
            </svg>
          </th>
          <th>
            {{ 'tasks.table.operation' | translate }}
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M14 5.1665H2C1.72667 5.1665 1.5 4.93984 1.5 4.6665C1.5 4.39317 1.72667 4.1665 2 4.1665H14C14.2733 4.1665 14.5 4.39317 14.5 4.6665C14.5 4.93984 14.2733 5.1665 14 5.1665Z"
                fill="#00092E" />
              <path
                d="M12 8.5H4C3.72667 8.5 3.5 8.27333 3.5 8C3.5 7.72667 3.72667 7.5 4 7.5H12C12.2733 7.5 12.5 7.72667 12.5 8C12.5 8.27333 12.2733 8.5 12 8.5Z"
                fill="#00092E" />
              <path
                d="M9.33341 11.8335H6.66675C6.39341 11.8335 6.16675 11.6068 6.16675 11.3335C6.16675 11.0602 6.39341 10.8335 6.66675 10.8335H9.33341C9.60675 10.8335 9.83341 11.0602 9.83341 11.3335C9.83341 11.6068 9.60675 11.8335 9.33341 11.8335Z"
                fill="#00092E" />
            </svg>
          </th>
          <th>
            {{ 'tasks.table.product' | translate }}
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M14 5.1665H2C1.72667 5.1665 1.5 4.93984 1.5 4.6665C1.5 4.39317 1.72667 4.1665 2 4.1665H14C14.2733 4.1665 14.5 4.39317 14.5 4.6665C14.5 4.93984 14.2733 5.1665 14 5.1665Z"
                fill="#00092E" />
              <path
                d="M12 8.5H4C3.72667 8.5 3.5 8.27333 3.5 8C3.5 7.72667 3.72667 7.5 4 7.5H12C12.2733 7.5 12.5 7.72667 12.5 8C12.5 8.27333 12.2733 8.5 12 8.5Z"
                fill="#00092E" />
              <path
                d="M9.33341 11.8335H6.66675C6.39341 11.8335 6.16675 11.6068 6.16675 11.3335C6.16675 11.0602 6.39341 10.8335 6.66675 10.8335H9.33341C9.60675 10.8335 9.83341 11.0602 9.83341 11.3335C9.83341 11.6068 9.60675 11.8335 9.33341 11.8335Z"
                fill="#00092E" />
            </svg>
          </th>
          <th>
            {{ 'tasks.table.application' | translate }}
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M14 5.1665H2C1.72667 5.1665 1.5 4.93984 1.5 4.6665C1.5 4.39317 1.72667 4.1665 2 4.1665H14C14.2733 4.1665 14.5 4.39317 14.5 4.6665C14.5 4.93984 14.2733 5.1665 14 5.1665Z"
                fill="#00092E" />
              <path
                d="M12 8.5H4C3.72667 8.5 3.5 8.27333 3.5 8C3.5 7.72667 3.72667 7.5 4 7.5H12C12.2733 7.5 12.5 7.72667 12.5 8C12.5 8.27333 12.2733 8.5 12 8.5Z"
                fill="#00092E" />
              <path
                d="M9.33341 11.8335H6.66675C6.39341 11.8335 6.16675 11.6068 6.16675 11.3335C6.16675 11.0602 6.39341 10.8335 6.66675 10.8335H9.33341C9.60675 10.8335 9.83341 11.0602 9.83341 11.3335C9.83341 11.6068 9.60675 11.8335 9.33341 11.8335Z"
                fill="#00092E" />
            </svg>
          </th>
          <th>
            {{ 'tasks.table.action' | translate }}
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M14 5.1665H2C1.72667 5.1665 1.5 4.93984 1.5 4.6665C1.5 4.39317 1.72667 4.1665 2 4.1665H14C14.2733 4.1665 14.5 4.39317 14.5 4.6665C14.5 4.93984 14.2733 5.1665 14 5.1665Z"
                fill="#00092E" />
              <path
                d="M12 8.5H4C3.72667 8.5 3.5 8.27333 3.5 8C3.5 7.72667 3.72667 7.5 4 7.5H12C12.2733 7.5 12.5 7.72667 12.5 8C12.5 8.27333 12.2733 8.5 12 8.5Z"
                fill="#00092E" />
              <path
                d="M9.33341 11.8335H6.66675C6.39341 11.8335 6.16675 11.6068 6.16675 11.3335C6.16675 11.0602 6.39341 10.8335 6.66675 10.8335H9.33341C9.60675 10.8335 9.83341 11.0602 9.83341 11.3335C9.83341 11.6068 9.60675 11.8335 9.33341 11.8335Z"
                fill="#00092E" />
            </svg>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr scope="row" *ngFor="let operation of operationsItens.slice(pagination[0], pagination[1]); let i = index">
          <td>
            <input
              (click)="updateSelectedItem(i, $event.target.checked)"
              type="checkbox"
              name="selecionados"
              id="itensSelecionados"
              class="table__thead--select"
              [checked]="selectedItens[i + this.pageAtual * this.rowsPerPage]" />
          </td>
          <td>
            <img [src]="getOperationImagePath(operation.type.operation)" class="" alt="..." />
            <div class="table__row--info">
              <p class="table__row--info---text">{{ operation.crop.name }}</p>
              <span class="table__row--info---small"> {{ 'tasks.type.' + operation.type.operation | translate }}</span>
            </div>
          </td>
          <td data-column="Equipamento">
            <div>
              <p class="table__row--info---text">{{ operation.machine.name }}</p>
              <span class="table__row--info---small">{{ operation.machine.model }}</span>
            </div>
          </td>
          <td data-column="Operador & Data Operação">
            <p class="table__row--info---text">{{ operation.operator.name }}</p>
            <span class="table__row--info---small"
              >{{ operation.startedAt | date: 'dd/MM/yyyy-HH:mm' }} -
              {{ operation.finishedAt | date: 'dd/MM/yyyy-HH:mm' }}</span
            >
          </td>
          <td data-column="Produto">
            <p class="table__row--info---text">{{ operation.product.name }}</p>
            <span class="table__row--info---small">{{ operation.product.supplier.name }}</span>
          </td>
          <td data-column="Aplicação">
            <p class="table__row--info---text">{{ operation.crop.stage }}</p>
            <span class="table__row--info---small"
              >{{ operation.application.rate }} / {{ operation.application.rateUnit }}</span
            >
          </td>
          <td data-column="Ação" class="tabelaDeOperacao--btnsAcao">
            <app-button
              (btnClick)="verTarefaTalhaoById(operation.id)"
              type="button"
              color="primary"
              icon="agres-eye"
              title="{{ 'global.view' | translate }}">
            </app-button>
            <app-button (btnClick)="editOperation(operation.id)" type="button" color="info" icon="agres-edit">
            </app-button>
            <app-button
              (btnClick)="deleteTarefaTalhaoBtnClick(operation)"
              type="button"
              color="danger"
              icon="agres-delete">
            </app-button>
          </td>
        </tr>
      </tbody>
    </table>
    <div
      class="row row-cols-1 row-cols-md-3 g-4 responsiveCards"
      *ngFor="let operation of operationsItens.slice(pagination[0], pagination[1]); let i = index">
      <div class="col">
        <div class="card h-100 text-center responsiveCards-cards">
          <div class="checkbox">
            <input
              id="check"
              type="checkbox"
              name="check"
              value="check"
              (click)="updateSelectedItem(i, $event.target.checked)"
              [checked]="selectedItens[i + this.pageAtual * this.rowsPerPage]"
              id="btn-selecionados"
              #btnSelecionados />
            <label for="check"> {{ 'operation.export.select-to-export' | translate }} </label>
          </div>
          <img [src]="getOperationImagePath(operation.type.operation)" class="" alt="..." />
          <div class="card-body responsiveCards-body">
            <h5 class="card-title">{{ operation.crop.name }}</h5>
            <small>{{ 'tasks.type.' + operation.type.operation | translate }}</small>
            <p class="card-text">
              {{ operation.operator.name }}
            </p>
            <p class="card-text">
              {{ operation.startedAt | date: 'dd/MM/yyyy-HH:mm' }}
              -
              {{ operation.finishedAt | date: 'dd/MM/yyyy-HH:mm' }}
            </p>
          </div>
          <div class="card-footer responsiveCards-footer">
            <app-button
              (btnClick)="verOperacoesId(operation.id)"
              type="button"
              color="primary"
              icon="agres-eye"
              title="{{ 'operation.view-operation-btn' | translate }}">
            </app-button>
            <app-button (btnClick)="editOperation(operation.id)" type="button" color="info" icon="agres-edit">
            </app-button>
            <app-button
              (btnClick)="deleteOperationBtnClick(operation)"
              type="button"
              color="danger"
              icon="agres-delete">
            </app-button>
          </div>
        </div>
      </div>
    </div>
    <div class="pagination">
      <div class="pagination__left">
        <p>
          {{ 'tasks.table.total' | translate }} {{ operationsItens.length }}
          {{ 'tasks.table.text' | translate }}
        </p>
      </div>
      <div class="pagination__right" *ngIf="operationsItens.length > rowsPerPage">
        <ul>
          <li><a (click)="clickPagination(pageAtual - 1, undefined)">«</a></li>
          <li *ngFor="let page of pages; let i = index">
            <a (click)="clickPagination(i, $event)">{{ i + 1 }}</a>
          </li>
          <li><a (click)="clickPagination(pageAtual + 1, undefined)">»</a></li>
        </ul>
      </div>
    </div>
  </div>
</section>
<app-modal
  [showSubject]="showSearchModalSubject"
  title="{{ 'operation.modal.title' | translate }}"
  [show]="showSearchModal">
  <form>
    <div class="modal-body">
      <div class="row">
        <div class="input-field--spacing col-md-6">
          <label class="input-field__label">{{ 'operation.table-export.initial-date' | translate }}</label>
          <input type="date" [(ngModel)]="dataInicio" id="dataInicio" name="dataInicio" class="input-field" />
        </div>
        <div class="input-field--spacing col-md-6">
          <label class="input-field__label">{{ 'operation.table-export.final-date' | translate }}</label>
          <input type="date" [(ngModel)]="dataFinal" id="dataFinal" name="dataFinal" class="input-field" />
        </div>
        <div class="input-field--spacing">
          <div class="text-date-invalid" style="display: none" id="div-invalid-date">
            {{ 'operation.modal.error-invalid' | translate }}
          </div>
          <div class="text-date-invalid" style="display: none" id="div-date-end-greater-date-ini">
            {{ 'global.alert.msg-error-invalid-date' | translate }}
          </div>
          <div class="text-date-invalid" style="display: none" id="div-date-end-greated-today">
            {{ 'global.alert.msg-error-invalid-date' | translate }}
          </div>
        </div>
      </div>
      <div class="text-date-invalid" style="display: none" id="div-invalid-date">
        {{ 'global.alert.msg-error-invalid-date' | translate }}
      </div>
      <div class="text-date-invalid" style="display: none" id="div-date-end-greater-date-ini">
        {{ 'global.alert.msg-error-invalid-date' | translate }}
      </div>
      <div class="text-date-invalid" style="display: none" id="div-date-end-greated-today">
        {{ 'global.alert.msg-error-invalid-date' | translate }}
      </div>
    </div>
    <div class="modal-footer">
      <app-button
        (btnClick)="closeSearchModal()"
        type="button"
        color="return"
        icon="agres-cancel"
        title="{{ 'global.cancel' | translate }}">
      </app-button>
      <app-button
        (btnClick)="clearFilter()"
        type="reset"
        color="return"
        icon="agres-close"
        title="{{ 'global.clear' | translate }}">
      </app-button>
      <app-button
        *ngIf="filteredData"
        (btnClick)="searchFilter()"
        id="btnConfirmFilter"
        color="info"
        icon="agres-search"
        title="{{ 'global.search' | translate }}">
      </app-button>
    </div>
  </form>
</app-modal>
<app-menu-pwa>
  <div class="pwa__list--info">
    <button type="button" (click)="backField()">
      <i class="agres-return"></i>
      <p>{{ 'global.back' | translate }}</p>
    </button>
  </div>
  <div class="pwa__list--info">
    <button type="button" (click)="exportAllSelected()">
      <i class="agres-download"></i>
      <p>{{ 'global.export-select' | translate }}</p>
    </button>
  </div>
  <div class="pwa__list--info">
    <button type="button" (click)="exportToExcellAllOperations()">
      <i class="agres-download"></i>
      <p>{{ 'global.export-all' | translate }}</p>
    </button>
  </div>
  <div class="pwa__list--info">
    <button type="button" (click)="addOperation()">
      <i class="agres-file"></i>
      <p>{{ 'global.create' | translate }}</p>
    </button>
  </div>
</app-menu-pwa>
<app-confirmar-modal
  *ngIf="mostrarModalConfirmacao"
  [(modal)]="mostrarModalConfirmacao"
  [mensagem]="mensagemModalConfirmacao"
  [tipo]="tipoModalConfirmacao"
  [titulo]="tituloModalConfirmacao"
  [conteudo]="operationIdToBeDeleted"
  [confirmtitle]="modalTranslateButtonConfirmText"
  [canceltitle]="modalTranslateButtonCancelText"
  (modalChange)="mostrarModalConfirmacao = $event">
</app-confirmar-modal>
